

<app-header
    *ngIf="!(_router.url === '/error')"></app-header>
<!-- <app-header></app-header> -->
<div class="main-content">
  <app-loader></app-loader>
  <router-outlet></router-outlet>
</div>
<app-footer></app-footer>
