<!-- <div class="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center" *ngIf="show">
  <div class="bg-white p-4 rounded-lg shadow-lg">
    <p class="mb-4">{{ message }}</p>
    <div class="flex justify-end">
      <button
          type="submit"
          class="w-32 h-12 mb-1 rounded-full border-[1px] border-indigo-700 bg-gray-200 p-0.5 font-bold"
          (click)="confirm()"
        >
          Confirm
        </button>&nbsp;
        <button
          type="submit"
          class="w-32 h-12 mb-1 rounded-full border-[1px] border-indigo-700 bg-gray-200 p-0.5 font-bold"
          (click)="cancel()"
        >
          Cancel
        </button>

    </div>
  </div>
</div> -->

<!-- https://medium.com/@mimranisrar6/creating-a-custom-confirmation-dialog-for-an-anchor-tag-4223514a7b0f -->

@if(show){
  <div class="fixed inset-0 flex items-center justify-center z-50 backdrop-blur confirm-dialog ">
    <div class="relative px-4 min-h-screen md:flex md:items-center md:justify-center">
        <div class=" opacity-25 w-full h-full absolute z-10 inset-0"></div>
        <div class="bg-white rounded-[14px] border border-gray-400 md:max-w-max md:mx-auto p-4 fixed inset-x-0 bottom-0 z-50 mb-4 mx-4 md:relative shadow-lg">
            <div class="md:flex items-center">
                <div class="rounded-full border border-gray-300 flex items-center justify-center w-20 h-16 flex-shrink-0 mx-auto">
                <i class="bx bx-error text-3xl">
                &#9888;
                </i>
                </div>
                <div class="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                <p class="font-bold text-[16px]">{{lang.Alert}}</p>
                <p class="text-[16px] text-gray-700 mt-1">{{message}}.
                </p>
                </div>
            </div>
            <div class="text-center md:text-right mt-4 md:flex md:justify-end gap-2">
                <button id="confirm-delete-btn" class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 border border-red-500  rounded-full font-semibold text-[14px] md:ml-2 md:order-1" (click)="confirm()">
                    {{lang.Confirm}}
                </button>&nbsp;
                <button id="confirm-cancel-btn" class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 border border-gray-400 rounded-full font-semibold text-[14px] mt-4 md:mt-0 md:order-2" (click)="cancel()">
                {{lang.Cancel}}
                </button>
            </div>
        </div>
    </div>
  </div>
  }

