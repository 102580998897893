import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrl: './pagenotfound.component.scss'
})
export class PagenotfoundComponent {
ShowModelPopup: boolean =false;
  ShowModelPopupTitle: any;
  ShowModelPopupMessage: any;

  constructor( public router:Router,private route: ActivatedRoute){

  }
ngOnInit(): void {
  //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
  //Add 'implements OnInit' to the class.

  this.ShowModelPopup =true;
  this.ShowModelPopupTitle ='Info'
    this.ShowModelPopupMessage ='Oops, it seems that this page does not exist.'

  
}

onClosedModelpopup(confirmed: boolean) {
  if (confirmed) {
    this.ShowModelPopup = false;
    this.router.navigate(['']);
  } else {
    this.ShowModelPopup = false;
    this.router.navigate(['']);
  }
}

}
