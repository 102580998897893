


import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  public message:any
  public language:any
  public termsandconditions:any;
  public discloureAggrement:any;
constructor() { }



setMessage(data:any){


this.message =data
}
getMessage(){
return this.message
}




}
