


import { Component, OnInit } from "@angular/core";
import { LoaderService } from "./../services/loader.service";
import { Observable } from "rxjs";
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss'
})
export class LoaderComponent implements OnInit {
  isLoading: Observable<boolean>;

  constructor(private loaderService: LoaderService) {
    this.isLoading = this.loaderService.loading$;
  }

  ngOnInit(): void {}
}