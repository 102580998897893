<div class="grid grid-cols-1 sm:text-center sm:justify-center gap-4 pt-60 mx-12">

  @if(paymenttransactionResponse){
  <div class="col-span-1 text-center">
    <div class="h-8 bg-gray-200
    overflow-x-auto
relative rounded-lg shadow-lg">
<div class="absolute inset-0
        bg-gradient-to-r
from-green-500 to-white
        animate-scroll rounded-lg">
</div>
<div class="absolute inset-0
        flex items-center justify-center
       text-black text-xl font-semibold">
       {{lang.Please_wait_the_enrollment_is_in_process}}</div>
</div>
    <!-- Please wait the enrollment is in process...<span><img src="./../../assets/img/image.gif" width="100"></span> -->

  </div>

}
@if(paymentSucessResponse){
  <div class="col-span-1 text-center w-full underline decoration-indigo-700 font-bold">
{{lang.Enrollment_successful}}
</div>
<div class="col-span-1 text-center w-full"  [innerHTML]="subscriptiondetails"   style="overflow-x:auto;z-index:-1"></div>
}
</div>


@if(showEnrollmentModel){
  <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">

    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

        <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl border-[1px] border-indigo-700">
          <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <!-- <svg class="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                </svg> -->

                <svg class="h-10 w-10 text-green-700 animate-pulse"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M5 12l5 5l10 -10" /></svg>
              </div>
              <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <h3 class="text-2xl font-semibold leading-6 text-gray-900" id="modal-title">{{showHeaderMessage}}</h3>
                <div class="mt-2">
                  <p class="text-xl text-gray-500">{{showModelContent}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-2 py-1 sm:flex sm:flex-row-reverse sm:px-6 border-[1px]  border-t-indigo-700">
            <button
            type="submit"
            class="w-36 h-14 mb-1 rounded-full border-2  border-indigo-700 bg-gray-200 p-0.5 font-bold transition duration-700 ease-in-out hover:bg-white" (click)="closeModelpopup()"
          >{{lang.Ok}}



          </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  }
  @if(ShowModelPopup){
    <app-modelpopupconfirmation [show]="ShowModelPopup" [languageData]="languageData" [showHeaderMessage]="ShowModelPopupTitle" [showModelContent]="ShowModelPopupMessage" (closedModelpopup)="onClosedModelpopup($event)">

    </app-modelpopupconfirmation>
    }
