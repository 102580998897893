

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-confirmation-model',
  templateUrl: './confirmation-model.component.html',
  styleUrl: './confirmation-model.component.scss'
})
export class ConfirmationModelComponent {
  @Input() show = false;
  @Input() message = 'Are you sure?';
  @Input() languageData:any;
  @Output() confirmed = new EventEmitter<boolean>();
  public lang: any = {};
  selectedLanguage: any;

  ngOnInit(): void {
    this.lang = {};
    this.languageData =   this.languageData
    this.selectedLanguage = sessionStorage.getItem("language")
    for (let token of this.languageData) {
      this.lang[token.key] = token[this.selectedLanguage];
    }
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

  }
  confirm() {
    this.confirmed.emit(true);
    this.show = false;
  }

  cancel() {
    this.confirmed.emit(false);
    this.show = false;
  }




}
