<!-- <div class="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center" *ngIf="show">
  <div class="bg-white p-4 rounded-lg shadow-lg">
    <p class="mb-4">{{ message }}</p>
    <div class="flex justify-end">
      <button
          type="submit"
          class="w-32 h-12 mb-1 rounded-full border-[1px] border-indigo-700 bg-gray-200 p-0.5 font-bold"
          (click)="confirm()"
        >
          Confirm
        </button>&nbsp;
        <button
          type="submit"
          class="w-32 h-12 mb-1 rounded-full border-[1px] border-indigo-700 bg-gray-200 p-0.5 font-bold"
          (click)="cancel()"
        >
          Cancel
        </button>

    </div>
  </div>
</div> -->

<!-- https://medium.com/@mimranisrar6/creating-a-custom-confirmation-dialog-for-an-anchor-tag-4223514a7b0f -->

@if(show){
  <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <!--
      Background backdrop, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <!--
          Modal panel, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        -->
        <div class="relative transform overflow-hidden rounded-xl bg-white text-center mx-auto shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-7xl max-w-2xl">
          <div class="bg-white">
            <div class="">
              <div class="flex items-center justify-center font-bold text-2xl h-16 border border-b-indigo-700 bg-[#00297A] text-white object-fit">
                {{headMessage}}
              </div>
              <div class="text-center">
                <pdf-viewer [src]="pdfData"  style=" height: 60vh;z-index:9999;overflow-y: scroll;" class="px-0 w-fill object-fit" ></pdf-viewer>

              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 border border-t-indigo-700">
            <button
            type="button"
            class="w-36 h-14 mb-1 rounded-full border-2 border-indigo-200 bg-indigo-200 p-0.5 font-bold hover:bg-white"
            (click)="cancel()"
          >
         {{lang.Close}}
          </button>
            <!-- <button type="button" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">Deactivate</button>
            <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
    <div class="bg-white p-2 rounded-lg shadow-lg object-fit">
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-[16px] font-semibold text-center"> {{headMessage}}</h2>
        <button class="text-gray-500 hover:text-gray-800" (click)="cancel()">&times;</button>

      </div>
        <pdf-viewer [src]="pdfData"  style="width: 100vh; height: 60vh;z-index:9999;overflow-y: scroll;" class="px-0" ></pdf-viewer>

        <div class="flex justify-end gap-2 mt-3">

          <button
            type="button"
            class="w-36 h-14 mb-1 rounded-full border-2 border-gray-700 bg-gray-200 p-0.5 font-bold"
            (click)="cancel()"
          >
         Close
          </button>


        </div>
      </div>

      </div> -->
  }
<!--  -->
