import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {

  constructor(public  _router: Router){

  }
  title = 'gb';
  ngOnInit(): void {

    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    // sessionStorage.setItem("language","English")
    if(sessionStorage.getItem("language")){

    }
    else{
      sessionStorage.setItem("language","English")
    }
}

  }
