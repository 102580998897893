import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component'
import { SignupFormComponent } from './signup-form/signup-form.component';
import { PaymentsComponent } from './payments/payments.component';
import { CorporateSignupComponent } from './corporate-signup/corporate-signup.component'
import { CorporatepaymentComponent} from './corporatepayment/corporatepayment.component'


const routes: Routes = [
  { path: ':formLink', component: SignupFormComponent,pathMatch: 'full' },
  { path: 'payment/form', component: PaymentsComponent },
  { path: 'corporate/:formLink', component: CorporateSignupComponent },
  { path: 'corporate/:formLink/:employeeLink', component: CorporateSignupComponent },
  { path: 'header', component: HeaderComponent },
  { path: 'footer', component: FooterComponent },
  { path: 'payment/corporate', component: CorporatepaymentComponent },
  { path: '**', component: PagenotfoundComponent },
  { path: 'error', component: PagenotfoundComponent },
];

@NgModule({
  // imports: [RouterModule.forRoot(routes)],
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }



