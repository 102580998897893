
import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, inject, ViewChild,AfterViewInit, Renderer2, ViewChildren, QueryList, SimpleChanges  } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  ReactiveFormsModule,
  AbstractControl,
  FormArray,
} from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { CustomDatePipe } from './../pipes/customdate.pipe';
import {
  BsDatepickerConfig,
  BsDatepickerModule,
} from 'ngx-bootstrap/datepicker';
import { DecimalPipe, formatNumber } from '@angular/common';
import { SignupformService } from './../services/signupform.service';
import { Meta } from '@angular/platform-browser';
import { environment } from './../environments/environment'
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SignaturePad } from 'angular2-signaturepad';
import Swal from 'sweetalert2';
import { PhoneNumberPipe } from './../pipes/phone-number.pipe'
import {
  floatWith2Decimals,
  groupBy,
  sumBy,
  adjustWith2DecimalsForUI,
} from "./../commonfunctions/commonFunction";
import { SharedService } from '../shared/shared.service';
@Component({
  selector: 'app-signup-form',
  templateUrl: './signup-form.component.html',
  styleUrl: './signup-form.component.scss',
  providers: [CustomDatePipe, DecimalPipe,HttpClient,PhoneNumberPipe],
})
export class SignupFormComponent implements AfterViewInit {
  myDateValue: Date | undefined;
  Object = Object;
  date: Date = new Date();
  bsConfig: Partial<BsDatepickerConfig>;
  // bsConfig: any;
  minDate: Date = new Date();
  maxDate: Date = new Date();
  childminDate: Date = new Date();
  childmaxDate: Date = new Date();
  showBenificiaryForm=true;
  showbenificaryTable =false;
  isEditable = true;
  enableEditIndex = null;
  addeditbenficiary =false
  benficiaryCount =false;
  benficiaryForm =new FormGroup(
    {
      customerType: new FormControl(''),
      // type: new FormControl(''),
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      isMinor: new FormControl(''),
      dob: new FormControl(''),
      relationship: new FormControl(''),
      percentage: new FormControl(''),
      contingentFirstName: new FormControl(''),
      contingentLastName: new FormControl(''),
      trusteeFirstName: new FormControl(''),
      trusteeLastName: new FormControl(''),
    })

    iafggenderForm =new FormGroup(
      {
        memberinfo: new FormControl(''),
        gender: new FormControl(''),
        spouseinfo: new FormControl(''),
        spousegender: new FormControl(''),

      })

  userForm = new FormGroup(
    {
      planEnrollmentDate: new FormControl(''),
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      email: new FormControl(''),
      phone_number: new FormControl(''),
      street_address_line1: new FormControl(''),
      street_address_line2: new FormControl(''),
      apt: new FormControl(''),
      city: new FormControl(''),
      province: new FormControl(''),
      postal_code: new FormControl(''),
      date_of_birth: new FormControl(''),
      gender: new FormControl(''),
      date_of_hiring: new FormControl(''),
      company_name: new FormControl(''),
      job_title: new FormControl(''),
      working_20hours: new FormControl(''),
      hours_per_week: new FormControl(''),
      provincial_health_coverage: new FormControl(''),
      dateOfArrival: new FormControl(''),
      isEmployeeImigrant: new FormControl(''),
      applylandedstaus: new FormControl(''),
      countryOrigin: new FormControl(''),
      coverageRequiredOfEmployee: new FormControl(''),
      dependentdateOfArrival: new FormControl(''),
      coverageRequiredOfDependents: new FormControl(''),
      dependentcountryOrigin: new FormControl(''),
      dependentLandedImigrant: new FormControl(''),
      dependentapplylandedstaus: new FormControl(''),
      dependentMorethan3years: new FormControl(''),
      having_spouse: new FormControl(''),
      spousefirstname: new FormControl(''),
      spouselastname: new FormControl(''),
      spousegender: new FormControl(''),
      // spouseemail: ["", Validators.compose([Validators.required, emailValidator])],
      // spouseemail: new FormControl(''),
      spousedateofbirth: new FormControl(''),
      spousehealthcard: new FormControl(''),
      Spousenameofthecarrier: new FormControl(''),
      spousecob: new FormControl(''),

      having_dependent_children: new FormControl(''),
      no_of_children: new FormControl(''),
      // childrenDetails: this.formBuilder.array([this.initChildDetails()]),
      // childrenDetails:  new FormControl(''),

      termsandconditions: new FormControl(''),
      disclouseradvisor: new FormControl(''),
      recaptchaReactivePerInfo: new FormControl(''),
    },
    { updateOn: 'change' }
  );
  paymentForm = new FormGroup(
    {
      cutomerId: new FormControl(''),
      PublicApiKey: new FormControl(''),
      SuccessUri: new FormControl(''),
      FailUri: new FormControl(''),
      FirstName: new FormControl(''),
      LastName: new FormControl(''),
      paymentRadioCheck: new FormControl(''),
      email: new FormControl(''),
      CardNumber: new FormControl(''),
      ExpirationMonth: new FormControl(''),
      ExporationYear: new FormControl(''),
      cvv: new FormControl(''),

      // signature:["", Validators.required],
      nameonthecard: new FormControl(''),

      // aptcheck: ["", Validators.compose([apt_suitecheck])],
      aptcheck: new FormControl(''),

      streetaddress: new FormControl(''),
      streetaddressline2: new FormControl(''),
      city: new FormControl(''),
      province: new FormControl(''),
      postalcode: new FormControl(''),
      recaptchaReactive: new FormControl(''),
    },
    { updateOn: 'change' }
  );
  bankpayForm = new FormGroup(
    {
      banknumber: new FormControl(''),

      transitnumber: new FormControl(''),
      accountnumber: new FormControl(''),
      bankname: new FormControl(''),
      voidcheck: new FormControl(''),
      checkbankdetails: new FormControl(''),
      bankaptcheck: new FormControl(''),
      bankstreetaddress: new FormControl(''),
      bankstreetaddressline2: new FormControl(''),
      bankcity: new FormControl(''),
      bankprovince: new FormControl(''),
      bankpostalcode: new FormControl(''),
    },
    { updateOn: 'change' }
  );
  insuranceForm: FormGroup;
  // insuranceForm = new FormGroup(
  //   {


  //     memberHeight: new FormControl(''),
  //     memberHeightUnit: new FormControl(''),
  //     memberWeight: new FormControl(''),
  //     memberWeightUnit: new FormControl(''),
  //     spouseHeight: new FormControl(''),
  //     spouseHeightUnit: new FormControl(''),
  //     spouseWeight: new FormControl(''),
  //     spouseWeightUnit: new FormControl(''),



  //   },
  //   { updateOn: 'change' }
  // );
  submitted = false;
  private http = inject(HttpClient);
  public languageTokensArray: any = [];
  public lang: any = {};
  public selectedLanguage: any;
  public configprovinceres: any;
  activeTab: any = 0;
  previousTab:any = -1;
  bsValue = new Date();
  invalidPostalCodeForProvince: boolean = false;
  provineList: any;
  provineListPayment: any;
  provineListcreditPayment:any
  plansData: any = [{ groups: [{ plans: [] }] }];
  configplanEnrollmentDateFull: any;
  childInfoAddDataArray: any = [];
  addbenificaryData:any =[]
  childTableEditRowIndex: number = 0;
  public staticData: any = [];
  public ticks: any[] | undefined;
  public cardBackground: any = [];
  public cardColors: any = [];
  tabs = [
    {
      title: 'Personal Information',
      content: 'Content for the first tab.',
      background: 'bg-gray-200',
    },
    {
      title: 'Plan Selection',
      content: 'Content for the second tab.',
      background: 'bg-gray-200',
    },
    {
      title: 'Enrollment Summary',
      content: 'Content for the second tab.',
      background: 'bg-gray-200',
    },
  ];

  public paymentUri: any;
  public paymentsuccessuri: any;
  public customerid: any;
  public publicapikey: any;
  public cardType: string = '';
  public invalidcreditcard: boolean = false;
  public recaptchaResponse: any;
  public fusbillinfocaptchavalue: any;
  childTableRowsLength: number = 0;
  childInfoModalAddAndUpdateButton: string = '';
  BenficairyModalAddAndUpdateButton: string = '';
  noOfChildrenChangedValue: any = 1;
  showModal = false;
  showDeleteModal = false;
  showModelDialog = false;
  ShowModelPopup=false;
  showPADModelDiaslog = false;
  modalMessage = 'Do you want to proceed?';
  deleteChildIndex: any;
  editChildInfo: any;
  editChildIndex: any;
  editBenficiaryIndex:any;
  spouseplaceholdername: any;
  checkedradioValue: boolean = false;
  pdfData: any;
  formConfigResult: any;
  public disabledelement: any;
  planobjdata: any = {};
  public planAmount: any;
  // planobjdata: { packageId: any; packageName: any; planproductname: any; groupid: any; groupName: any; id: any; name: any; planLevel: any; planLevelParent: any; fusebillPlanID: any; planFrequencyID: any; isBundle: any; coverage: any; planCoverage: any; bundledProducts: never[]; products: never[]; };
  productobj: any;
  public planssummarymain: any = [];
  public planssummarymainLife: any = [];
  public planssummaryopt: any = [];
  public plansskumain: any = [];
  public plandetailsobjvalue: any;
  public optionstitle:any = [];
  paymentForm2: any;
  finalenrollemntsummary: any = [];
  finalenrollemntsummaryLife: any = [];
  planSummaryMainResult: any = [];
  banknameres: any;
  bankerrormsg: any;
  public verifybutton: boolean = false;
  public verifybankdetails: boolean = false;
  bankverifyDetails: any;
  bankfile: any;
  isFileUploaded: boolean = false;
  bankinvalidPostalCode: boolean = false;
  pdfscrc:any='https://api.groupbenefitz.ca/app/server/en/terms-conditions-en.pdf'
  header: any;
  failedarray: any =[];
  signatureNeeded: any;
  creditCradInvalidProvince: any;
  showClearButton: boolean =false;
  checked: {[userId:string]: boolean} = {};
  missingInfo: any=[];
  missingInformation =false;
  planOptionsModel =false;
  optionmessage: any;
  planoptionsValues: any =[];
  link: any;
  generatePADaggrement: any;
  padAggrementModel =false;
  padAggrementFinalModel =false;
  public signaturestatus: boolean = true;
  public rejectPADModel: boolean = true;
  public agreestatus: boolean = false;
  signModelForBankPay =false;
  closePADModel=false;
  signaturecheckvalue1cc: boolean =false;
  public signaturemessagecc: boolean = false;
  public signaturecheckcc: boolean = true;
  paymentemail: any;
  public signaturecheckvalue1: boolean = false;
  public signaturemessagepad: boolean = false;
  public acceptaggrepad: boolean = true;
  public padaggrementerror: any;
  public signaturecheck: boolean = true;
  public signaturemessage: boolean = false;
  imagedisplay: any;
  showpaderrorMessage: boolean = false;
  showagree: boolean =true;
  ShowModelPopupTitle: any;
  ShowModelPopupMessage: any;
  invalidCardDetails: boolean =false;
  public showexpiryyearerror: boolean = false;
  public showexpirymontherror: boolean = false;
  public futureexpiredate: boolean = false;
  visitedTabs: Set<string> = new Set<string>();
  imageDisplayShow=false;
  questionaries=false;
  benficiary =false
  public normalview: boolean = false;
  public pdfview: boolean = false;
  applicantSpousename: any;
  applicantfirstname: any;
  applicantChildrenname: any;
  completeapplicantinfo: any;

  public formData: any;
  public allFormData: any = {};
  public lastUpdatedType: any = "";
  public signUpData: any = {};
  public premiums: any = {};
  planCoverageName: any;
  insuranceplanid: any;
  smokerCheckValue: any;
  acceptanceCoverage: any;
  paymentFirstName: any;
  paymentLastName: any;
  questions:any = [
    { label: '3) In the last 12 months, have you used, in any form whatsoever, tobacco, nicotine or cannabis mixed with tobacco?' },
    { label: '4) Have you flown as a pilot, student or crew member in the last two years or do you have any intention to do so?' },
    { label: '5) Have you engaged in or do you intend to participate in scuba diving, parachuting or other hazardous sport or activity?' },
    { label: '6) Do you intend to travel or reside outside Canada or the United States for more than a month?' },
    { label: '7) Have you had a request for life, disability or critical illness insurance declined, postponed, rated or modified in any way?' },
    { label: '8) Are you now actively engaged in your occupation on a full-time basis? If “No”, please provide details including reason why you are not working on a full-time basis.' },
    { label: '9) Have you ever had or ever been treated for cancer, tumour, cyst, polyp or other growth, moles, anemia, blood disorder or any form of malignant disease?' },
    { label: '10) Have you ever had or have you ever been treated for chest pain, angina, heart attack, high blood pressure, abnormal ECG, stroke, paralysis, transient ischemic attack (TIA), elevated cholesterol, or other disorders of the heart or aorta, blood vessels or circulatory system? Diabetes, pancreatitis,thyroid or other endocrine disorder? Lung or other respiratory disease or disorder? Any disorder of the eyes (excluding near or far sightedness), ears,vocal chords or larynx including loss of speech?'},
    { label: '11) Have you ever been treated for or diagnosed with kidney, bladder, prostate (including an elevated PSA test result) or breast disorder (including cysts,lumps, biopsy or abnormal mammogram or ultrasound) or other genitourinary disorder, hepatitis B or C (including carrier), cirrhosis or other liver disorder, ulcerative colitis, Crohn’s disease or other disorder of the gastrointestinal tract?' },
    { label: '12) Have you ever had or have you ever been treated for dizziness, seizures, epilepsy, tremor, paresthesia, loss of balance, numbness, multiple sclerosis,Alzheimer’s disease, Parkinson’s disease, amyotrophic lateral sclerosis (ALS) or any other neurological disorder? Stress, anxiety, depression or any other psychiatric disorder? Disease or disorder of muscles, ligaments, tendons, bones or joints including but not limited to arthritis, lupus in any form,amputation or deformity?'},
    { label: '13) Have you ever used marijuana, heroin, morphine, cocaine, LSD, barbiturates, amphetamines, or any other drug or narcotic, except as prescribed by your physician?'},
    { label: '14) a). Do you presently drink more than 12 alcoholic beverages per week? If “Yes”, state number, kind and frequency consumed.'},
    { label: '14) b). Have you ever changed your pattern of drinking (increased or decreased), received advice or treatment for, or attended any rehabilitation program for alcohol or drug use?'},
    { label: '15) Have you any condition for which hospitalization, further testing, investigation or surgery has been advised, or which have not yet been done, or for which you are still awaiting results?'},
    { label: '16) Are you taking any prescribed medication? If “Yes”, state name of medication and reason for use.'},
    { label: '17) Are you aware of any symptoms or complaints regarding your health for which you have not yet consulted a physician or received treatment?'},
    { label: '18) Have you been absent from work for more than seven consecutive days within the past year due to sickness or injury?'},
    { label: '19) Has there been a variation in your weight in the past year? If “Yes”, please provide details including reason and number of pounds/kilograms gained or lost.'},
    { label: '20) Females only: Are you currently pregnant? If “Yes”, please provide your estimated due date and advise of any complications with current or past pregnancies.'},
    { label: '21) During the past 10 years, have you consulted a physician, received treatment or been hospitalized, had surgery or any test (other than routine checkup or minor injury) for any disease, disorder or ailment not already mentioned?'},
    { label: '22) Have you ever received or claimed benefits or a pension for sickness, injury or impairment?'},
    { label: '23) o you have any pending criminal offences, criminal convictions, had your driver’s license suspended, or within the past 3 years been convicted of more than 3 traffic violations?'}

    // Add other questions here
 ];
 tableForm!: FormGroup;
  beneficariesDetails: any;
  showaddmorebenficiary = true;
  benficiaryCountMessage: any;
  beneficiaryError = false;
  beneficiaryErrorMessage=''
  iafgCheck: any;
  iafginfo =false;
  memberDateofBirth =false;
  minValue = 0;
  maxValue:any;
  sliderValue:any;
  cutoffValue:any;
  units:any = [];

  greenWidth:any;
  orangeWidth:any;
  filledWidth:any;
  cutoffWidth:any;
  rangeValues:any = [];
  names: any[]  = [
    { label: 'Guaranteed', position: 10 },    // Display "Start" at 0%
    { label: 'Pending', position: 0}, // Display "Quarter" at 25%

  ];
   greenValue: any;
  yellowValue: any;
  languageData: any;
  CreditCradFirstName: any;
  CreditCradLastName: any;
  constructor(
    private Shared: SharedService,
    private renderer: Renderer2,
    private _SignupformService: SignupformService,
    private _PhoneNumberPipe:PhoneNumberPipe,
    private formBuilder: FormBuilder,
    private _CustomDatePipe: CustomDatePipe,
    public elementRef: ElementRef,
    private _decimalPipe: DecimalPipe,
    private meta: Meta,
    public router:Router,private route: ActivatedRoute
  ) {

    this.minDate = new Date();
    this.minDate.setFullYear(1950);
    this.minDate.setMonth(0); // January
    this.minDate.setDate(1); // 1st

    // Set maxDate to December 31st, 2030
    this.maxDate = new Date();
    this.maxDate.setFullYear(2008);
    this.maxDate.setMonth(new Date().getMonth()); // current month
    this.maxDate.setDate(new Date().getDate()); // current date


    this.childminDate = new Date();
    this.childminDate.setFullYear(2006);
    this.childminDate.setMonth(0); // January
    this.childminDate.setDate(1); // 1st

    // Set maxDate to December 31st, 2030
    this.childmaxDate = new Date();
    this.childmaxDate.setFullYear(2024);
    this.childmaxDate.setMonth(new Date().getMonth()); // current month
    this.childmaxDate.setDate(new Date().getDate()); // current date
    this.bsConfig = {
      containerClass: 'theme-default',
      dateInputFormat: 'MM-DD-YYYY',
      minDate: this.minDate,
      maxDate: this.maxDate,
    };
    this.tableForm = this.formBuilder.group({
      rows: this.formBuilder.array([])
    });
    this.addRow();
    this.benficiaryForm =this.formBuilder.group(
      {
        customerType: ['', Validators.required],
        // type: ['', Validators.required],
        firstName: [
          '',
          Validators.compose([Validators.required, Validators.minLength(2)]),
        ],
        lastName: [
          '',
          Validators.compose([Validators.required, Validators.minLength(2)]),
        ],
        isMinor:  [''],
        dob: [''],
        relationship: ['', Validators.required],
        contingentFirstName: ['', Validators.required],
        contingentLastName: ['', Validators.required],
        percentage: ['', Validators.required],
        trusteeFirstName: [''],
        trusteeLastName: [''],

      })

      this.iafggenderForm =this.formBuilder.group(
        {

          memberinfo: ['', Validators.required],
          gender: [''],
          spouseinfo: [''],
          spousegender: ['']})

    this.userForm = this.formBuilder.group(
      {
        planEnrollmentDate: ['', Validators.required],
        firstName: [
          '',
          Validators.compose([Validators.required, Validators.minLength(2)]),
        ],
        lastName: [
          '',
          Validators.compose([Validators.required, Validators.minLength(2)]),
        ],
        email: [
          '',
          [
            Validators.required,
            Validators.pattern(
              '^(?!\\s)[_A-Za-z0-9._%+-]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'
            ),
          ],
        ],
        // email: ["", Validators.compose([Validators.required, emailValidator])],



        // phoneNumber: ['', [Validators.required, Validators.pattern(/^\(\d{3}\) \d{3}-\d{4}$/)]],

        // phone_number: [
        //   '',
        //   Validators.compose([
        //     Validators.required,
        //     Validators.pattern(/^\(\d{3}\) \d{3}-\d{4}$/),
        //   ]),
        // ],
        phone_number: ["",
          Validators.compose([Validators.required, Validators.minLength(17), Validators.maxLength(17), Validators.pattern(/^[^A-Za-z@#$%&*{}'";:<>?]+$/)]),
        ],
        // matrialstatus:["", Validators.required],

        // apt_suite: ["", Validators.compose([apt_suitecheck])],
        apt: [''],
        street_address_line1: ['', Validators.required],
        street_address_line2: [''],
        city: ['', Validators.required],
        province: ['', Validators.required],

        postal_code: ['', Validators.compose([Validators.required])],
        // postalcode: ["", Validators.required],
        date_of_birth: ['', Validators.required],
        gender: ['', Validators.required],
        date_of_hiring: ['', Validators.required],
        company_name: ['', Validators.required],
        job_title: ['', Validators.required],
        working_20hours: [
          '',
          Validators.compose([Validators.required, hourselectioncondition]),
        ],
        hours_per_week: [
          '40',
          Validators.compose([Validators.required, validatehoursperweek]),
        ],
        provincial_health_coverage: [
          '',
          Validators.compose([Validators.required]),
        ],

        dateOfArrival: [''],
        isEmployeeImigrant: [''],
        applylandedstaus: [''],
        countryOrigin: [''],
        coverageRequiredOfEmployee: [''],

        dependentdateOfArrival: [''],
        coverageRequiredOfDependents: [''],
        dependentcountryOrigin: [''],
        dependentLandedImigrant: [''],
        dependentapplylandedstaus: [''],
        dependentMorethan3years: [''],

        having_spouse: ['', Validators.required],
        spousefirstname: [''],
        spouselastname: [''],
        spousegender: [''],
        // spouseemail: ["", Validators.compose([Validators.required, emailValidator])],
        // spouseemail: [
        //   '',
        //   [
        //     Validators.required,
        //     // Validators.pattern(this.commonPatternsService.emailPattern),
        //     Validators.pattern(
        //       '^(?!\\s)[_A-Za-z0-9._%+-]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'
        //     ),
        //     //pattern(/^(?!\\s)*[\sA-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-z]{2,3}(?!\\s)*$/i),
        //     //          pattern('^(?!\\s)[_A-Za-z0-9-]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$')])],
        //   ],
        // ],
        spousedateofbirth: [''],
        having_dependent_children: ['', Validators.required],
        no_of_children: [''],
        // childrenDetails: this.formBuilder.array([this.initChildDetails()]),

        spousehealthcard: ['', Validators.required],
        Spousenameofthecarrier: [''],
        spousecob: [''],

        termsandconditions: ['', Validators.requiredTrue],
        disclouseradvisor: ['', Validators.requiredTrue],
        recaptchaReactivePerInfo: [''],
        // recaptchaReactivePerInfo: [""],
        // validatorcaptcha:["", Validators.requiredTrue],
      },
      { updateOn: 'change' }
    );

    this.insuranceForm  = this.formBuilder.group({
      memberHeight: [''],
      memberHeightUnit: [''],
      memberWeight: [''],
      memberWeightUnit: [''],
      spouseHeight: [''],
      spouseHeightUnit: [''],
      spouseWeight: [''],
      spouseWeightUnit: [''],
      ...this.questions.reduce((acc:any, _: any, index: number) => {
        acc[`memberQuestion${index + 1}`] = [''];
        acc[`spouseQuestion${index + 1}`] = [''];
        return acc;
     }, {})

    });

    this.paymentForm = this.formBuilder.group({
      cutomerId: [''],
      PublicApiKey: [''],
      SuccessUri: [''],
      FailUri: [''],
      FirstName: ['', Validators.required],
      LastName: ['', Validators.required],
      email: [''],
      paymentRadioCheck: ['', Validators.required],
      CardNumber: [
        '',
        Validators.compose([Validators.required, creditcardvalidation]),
      ],
      ExpirationMonth: ['', Validators.required],
      ExporationYear: ['', Validators.required],
      cvv: [
        '',
        Validators.compose([Validators.required, Validators.minLength(3)]),
      ],

      // signature:["", Validators.required],
      nameonthecard: [''],

      // aptcheck: ["", Validators.compose([apt_suitecheck])],
      aptcheck: [''],

      streetaddress: ['', Validators.required],
      streetaddressline2: [''],
      city: ['', Validators.required],
      province: ['', Validators.required],
      postalcode: ['', Validators.compose([Validators.required])],
      recaptchaReactive: ['', Validators.required],
    });
    this.bankpayForm = this.formBuilder.group({
      banknumber: [
        '',
        Validators.compose([Validators.required, checkbanknumber]),
      ],

      transitnumber: [
        '',
        Validators.compose([Validators.required, checktransitnumber]),
      ],
      accountnumber: [
        '',
        Validators.compose([Validators.required, checkaccountnumber]),
      ],
      bankname: ['', Validators.required],
      voidcheck: ['', Validators.compose([Validators.required])],
      checkbankdetails: [''],
      // bankaptcheck: ["", Validators.compose([apt_suitecheck])],
      bankaptcheck: [''],
      bankstreetaddress: ['', Validators.required],
      bankstreetaddressline2: [''],
      bankcity: ['', Validators.required],
      bankprovince: ['', Validators.required],
      bankpostalcode: ['', Validators.compose([Validators.required])],
    });
  }

  get rows(): FormArray {
    return this.tableForm.get('rows') as FormArray;
  }

  addRow() {
    const row = this.formBuilder.group({
      firstName: [
        '',
        Validators.compose([Validators.required, Validators.minLength(2)]),
      ],
      lastName: [
        '',
        Validators.compose([Validators.required, Validators.minLength(2)]),
      ],
      relationship: ['', Validators.required],
      percentage: ['', Validators.required]
    });
    this.rows.push(row);
  }

  removeRow(index: number) {
    this.rows.removeAt(index);
  }
  @ViewChild(SignaturePad) signaturePad!: SignaturePad;

  // @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
  //   e.preventDefault();
  // }
  signaturePadOptions: Object = {


    'canvasWidth': 400,
    'canvasHeight': 150
  };
  public cartcheckvalue: boolean = true;
  public plansnexttab: boolean = true;

  // @ViewChild('sigPad')
  // sigPad!: ElementRef<HTMLCanvasElement>;
  // @ViewChild('sigPad') sigPad;
  sigPadElement:any;
  context:any;
  isDrawing = false;
  img:any;
  // @ViewChild('sigPad', { static: false }) sigPad!: ElementRef<HTMLCanvasElement>;

  @ViewChild('canvasElement', { static: false }) canvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild('canvasElementpad', { static: false }) canvaspad!: ElementRef<HTMLCanvasElement>;
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  @ViewChild('scrollContainerPlans') scrollContainerPlans!: ElementRef;
  @ViewChild('optionNumberInput') optionNumberInput!: ElementRef;
  @ViewChild('optionPlanDetailsObj') optionPlanDetailsObj!: ElementRef;
  @ViewChild('planCheckdetails') planCheckdetails!: ElementRef;
  @ViewChildren('optionUnseelct') checkboxes!: QueryList<ElementRef>;


  private ctx: CanvasRenderingContext2D | null = null;
  private ctxpad: CanvasRenderingContext2D | null = null;
  private resizeObserver: ResizeObserver | null = null;
    private drawing = false;
    sigPadElementpad:any;
    contextpad:any;
    isDrawingpad = false;

    @ViewChild('sigPad') sigPad:any;
    coverageValue:any;
    minimumCoverage:any; // Set minimum coverage threshold
    extraCoverage:any;
    extraCoverage1:any;
    showSpouseSignature = false;
    showIAFGerrorMessage = false;
    ConfirmModel =false;
    showyellowbar =false
    criticalplancheck =false
    termLifeplancheck =false
  ngOnInit(): void {
this.getLanguageTokens()
this.updateSlider()

    // setTimeout(() => this.initializeCanvas(), 20000);
    // setTimeout(() => this.initializeCanvaspad(), 40000);
    sessionStorage.setItem("timeStamp",new Date().getTime().toString())

    this.getIPAddress()
    this.postAnalyticsData()
    // this.getUserDetails()

    setInterval(() => {

      this.postAnalyticsData()
    }, 30000);

    sessionStorage.setItem("lanToken",'en')

          sessionStorage.removeItem("fileurl")
          sessionStorage.removeItem("filename")
          sessionStorage.removeItem("signature")
    // this.paymentUri = environment.app.globalgrp + "#/paymentResponse";

    // this.paymentsuccessuri = environment.app.globalgrp + "#/paymentResponse";

    // this.customerid = sessionStorage.getItem("customerId");
    // this.publicapikey = sessionStorage.getItem("publicApiKey");

    // this.sigPadElement = this.sigPad.nativeElement;

    // this.context = this.sigPadElement.getContext('2d');
    // this.context.strokeStyle = 'black';

    if (sessionStorage.getItem("formlink")) {
      this.link=sessionStorage.getItem("formlink")
    } else {
     this.link=sessionStorage.getItem("formlink")

    }

    if (sessionStorage.getItem("paymentMethod")) {
    } else {
      sessionStorage.setItem("paymentMethod", "CC");
    }

    //localgrp
    //globalgrp
    this.fusbillinfocaptchavalue = '6LfVtGwUAAAAALHn9Ycaig9801f6lrPmouzuKF11';
    this.paymentUri = environment.app.globalgrp +this.link+"#/payment/form";

    this.paymentsuccessuri = environment.app.globalgrp +this.link+ "#/payment/form";

    this.customerid = sessionStorage.getItem("customerId");
    this.publicapikey = sessionStorage.getItem("publicApiKey");
    // this.testFun();

    let form = 'SHZMOxgC0301';

    // console.log(this.route.snapshot.params['formLink'])
    if (this.route.snapshot.params['formLink'] != undefined) {
    this._SignupformService.getDataConfig(this.route.snapshot.params['formLink']).subscribe({
      next: (result: any) => {
        // console.log(result);

        if(result.status=="200"){
          if(result.data.waddellForm){
            window.open(environment.app.waddellink+this.route.snapshot.params['formLink'],"_self")

          }
            if(result.data.iafgOnly){


                window.open(environment.app.iafglink+this.route.snapshot.params['formLink'],"_self")

              }
              if(result.data.kixcareOnly){


                window.open(environment.app.kixcare+this.route.snapshot.params['formLink'],"_self")

              }
          this.iafgCheck= result.data.iafg
          sessionStorage.setItem("formlink", this.route.snapshot.params['formLink']);

          this.formConfigResult = result.data;
          this.configprovinceres = result.data.provinces.sort((a: any, b: any) =>
            a.shortName > b.shortName ? 1 : -1
          );
          this.languageTokensArray = result.data.language_details.languageTokens;
          this.beneficariesDetails = result.data.validations.beneficiaries


          this.configplanEnrollmentDateFull =
            result.data.validations.customer.planEnrollmentDatesFullMonth;
            setTimeout(() => {
              this.pdfData = this.formConfigResult.links.termsConditions;

            }, 10000);
          // this.lang = {};

          // for (let token of this.languageTokensArray) {
          //   this.lang[token.key] = token.value;
          // }
          sessionStorage.setItem(
            "brokerlogo",
            environment.app.logourl + this.formConfigResult.logo
          );
          sessionStorage.setItem(
            "brokerlink",
            // environment.app.grp + result.data.form.broker.link
            environment.app.grp + result.data.link

          );
          sessionStorage.setItem(
            "formName",
            // environment.app.grp + result.data.form.broker.link
            result.data.form.name

          );

          result.data.form.name
          sessionStorage.setItem(
            "provincelist",
            JSON.stringify(result.data.broker_licensed_provinces)
          );
          sessionStorage.setItem(
            "countrydetails",
            JSON.stringify(result.data.countries)
          );
          this.meta.updateTag(
            { name: 'keywords', content: this.formConfigResult.form.keywords },
            'name=keywords'
          );
          this.meta.updateTag(
            {
              name: 'description',
              content: this.formConfigResult.form.description,
            },
            'name=description'
          );

          // this.meta.updateTag({
          //   property: "og:url",
          //   content: environment.app.globalgrp + this.router.url,
          // });
          this.meta.updateTag({
            property: 'og:title',
            content: this.formConfigResult.form.broker.name,
          });
          this.meta.updateTag({ property: 'og:type', content: '' });
          this.meta.updateTag({
            property: 'og:image',
            content: environment.app.grp + this.formConfigResult.logo,
          });
          this.meta.updateTag({
            property: 'og:description',
            content: this.formConfigResult.form.broker.description,
          });

          if (
            !this.formConfigResult.form.broker.discoverable ||
            this.formConfigResult.form.broker.discoverable == 0
          ) {
            this.meta.addTag({ name: 'robots', content: 'noindex' });
          }
        }
        else {
          this.ShowModelPopup =true
          this.ShowModelPopupMessage =result.message ? result.message : result.error
          this.ShowModelPopupTitle =this.lang.Info

        }


      },
      error: (error) => {
        if([0,500,501,502,503].includes(error.status)){


          this.ShowModelPopup =true
          this.ShowModelPopupMessage = this.lang.Sorry_the_server_is_currently_undergoing_maintenance_Please_try_again_later_We_apologize_for_your_inconvenience;
          this.ShowModelPopupTitle =this.lang.Info
        }
        else{
          this.ShowModelPopup =true
          this.ShowModelPopupMessage =error.error.message
              ? error.error.message
              : error.error.error,
          this.ShowModelPopupTitle =this.lang.Info
        // Swal.fire({
        //   title: this.lang.Info,
        //   html: error.error.message
        //     ? error.error.message
        //     : error.error.error,

        //   showCancelButton: false,
        //   width: "30%",
        //   confirmButtonText: "Ok",
        //   // customClass: {
        //   //   header: 'swal-header-orange',
        //   //   popup: 'custom-swal-dialog',
        //   //   confirmButton: 'custom-ok-button-class',
        //   // }
        // }).then((result) => {
        //   if (result.value) {
        //     this.router.navigate(["error"]);
        //     if (
        //       error.error.message.includes("incomplete") ||
        //       error.error.message.includes(
        //         "The email address already exists"
        //       )
        //     ) {
        //       let formLink = sessionStorage.getItem("formlink");
        //       sessionStorage.clear();
        //       this.router.navigate([formLink]);
        //       window.location.reload();
        //     }
        //   } else {
        //   }
        // });
      }
      },
    });
  }else{
    this.router.navigate(["error"]);
  }
  // this.demofill()
  }
  public getLanguageTokens(){
    let body= {
      "project":"SignupForm",
      "url":"GIG"
    }
    this.http
    .post('https://languageengineapi.aitestpro.com/string/project-url-strings',body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .subscribe(
      (response: any) => {
        console.log(response)
        this.lang = {};
        this.languageData = response.strings
        this.selectedLanguage = sessionStorage.getItem("language")
        for (let token of response.strings) {
          this.lang[token.key] = token[this.selectedLanguage];
        }

        this.tabs = [
          {
            title: this.lang.Personal_Information,
            content: 'Content for the first tab.',
            background: 'bg-gray-200',
          },
          {
            title: this.lang.Plan_Selection,
            content: 'Content for the second tab.',
            background: 'bg-gray-200',
          },
          {
            title: this.lang.Enrollment_Summary,
            content: 'Content for the second tab.',
            background: 'bg-gray-200',
          },
        ];

      })


  }


  updateSlider() {
    const value = this.sliderValue;
    this.greenWidth = (this.cutoffValue / this.maxValue) * 100;
    this.orangeWidth = 100 - this.greenWidth;
    this.filledWidth = (value / this.maxValue) * 100;
    this.cutoffWidth = this.greenWidth;
  }
  public demofill() {
    // ////this.consoleMessage(this.genRandonString(3));
    this.userForm.controls["planEnrollmentDate"].setValue("12-01-2022");
    this.userForm.controls["firstName"].setValue("JohnDemo");
    this.userForm.controls["lastName"].setValue("Test");
    this.userForm.controls["email"].setValue(
      "johndemoaitp" + this.genRandonString(3) + "@aitestpro.com"
    );
    this.userForm.controls["phone_number"].setValue("+1 (989) 898-9898");
    this.userForm.controls["apt"].setValue("A");
    this.userForm.controls["street_address_line1"].setValue("2 Seyton Dr");
    this.userForm.controls["street_address_line2"].setValue("");
    this.userForm.controls["city"].setValue("Nepean");
    this.userForm.controls["province"].setValue("ON");
    this.userForm.controls["postal_code"].setValue("K2K 3E7");
    this.userForm.controls["date_of_birth"].setValue("10-01-1980");
    this.userForm.controls["gender"].setValue("Male");
    this.userForm.controls["date_of_hiring"].setValue("06-09-2024");
    this.userForm.controls["date_of_hiring"].setValue("06-09-2024");
    this.userForm.controls["company_name"].setValue("Ideabytes");
    this.userForm.controls["job_title"].setValue("Employee");
    this.userForm.controls["working_20hours"].setValue("true");
    this.userForm.controls["provincial_health_coverage"].setValue("true");
    this.userForm.controls["having_spouse"].setValue("false");
    this.userForm.controls["having_dependent_children"].setValue("false");
    this.userForm.controls["termsandconditions"].setValue("true");
    this.userForm.controls["disclouseradvisor"].setValue("true");
    // this.userForm.controls['recaptchaReactivePerInfo'].setValue(true)

    //     termsandconditions: ["", Validators.requiredTrue],
    //     disclouseradvisor: ["", Validators.requiredTrue],
    //     recaptchaReactivePerInfo: ["", Validators.required],

    // this.userForm.setValue("planenrollmentdate")
    // this.userForm = fb.group(
    //   {
    //     planenrollmentdate: ["", Validators.required],
  }

  public genRandonString(length:any) {
    var chars = "abcdefghijklmnopqrstuvwxyz0123456789";
    var charLength = chars.length;
    var result = "";
    for (var i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * charLength));
    }
    return result;
  }
  ngAfterViewInit(): void {

    // setTimeout(() => {
    //   this.sigPadElement = this.sigPad.nativeElement;

    //   this.context = this.sigPadElement.getContext('2d');
    // }, 10000);

    // setTimeout(() => this.initializeCanvas(), 10000);
    // setTimeout(() => this.initializeCanvaspad(), 40000);

    // this.initializeCanvas();

  }
  setupResizeObserver(): void {
    this.resizeObserver = new ResizeObserver(() => this.resizeCanvas());
    this.resizeObserver.observe(this.canvas.nativeElement);
  }
  postAnalyticsData(){



    var pageName = sessionStorage.getItem("pageName")

    if(sessionStorage.getItem("ipaddress")){

    fetch(environment.app.grp+"api/common/analytics", {

    // Adding method type
    method: "POST",

    // Adding body or contents to send
    body: JSON.stringify({
      "ipAddress":sessionStorage.getItem("ipaddress"),
      "timestamp":sessionStorage.getItem("timeStamp"),
      "pageName":pageName?pageName:"PersonalInfo",
      "gbReferer":window.document.referrer || "",
      "pageUrl":window.location.href
    }),

    headers: {
        "Content-type": "application/json; charset=UTF-8"
    }
})
    }


  }
  getIPAddress()
    {
      fetch('https://jsonip.com', { mode: 'cors' })
  .then((resp) => resp.json())
  .then((ip) => {

    // //this.consoleMessage(ip)
sessionStorage.setItem("ipaddress",ip.ip);

  });


    }

  initializeCanvas(): void {
    if (this.canvas) {
      const canvasElement = this.canvas.nativeElement;
      this.ctx = canvasElement.getContext('2d');
      // this.ctx.strokeStyle = 'red';
      if (this.ctx) {
        // console.log('Canvas context initialized successfully');
        this.resizeCanvas();
        this.setupResizeObserver();
      } else {
        // console.error('Failed to get 2D context');
      }
    } else {
      // console.error('Canvas element is not available');
    }




  }


  initializeCanvaspad():void{
    if (this.canvaspad) {
      const canvasElementpad = this.canvaspad.nativeElement;
      this.ctxpad = canvasElementpad.getContext('2d');
      // this.ctxpad.strokeStyle = 'red';
      if (this.ctxpad) {
        // console.log('Canvas context initialized successfully');
        this.resizeCanvaspad();
      } else {
        // console.error('Failed to get 2D context');
      }
    } else {
      // console.error('Canvas element is not available');
    }
  }
  resizeCanvas(): void {
    if (this.canvas && this.ctx) {
      const canvasElement = this.canvas.nativeElement;
      canvasElement.width = canvasElement.offsetWidth;
      canvasElement.height = canvasElement.offsetHeight;
      // console.log('Canvas resized:', canvasElement.width, canvasElement.height);
    } else {
      // console.error('Canvas element or context is not available');
    }
  }
  resizeCanvaspad(): void {
    if (this.canvaspad && this.ctxpad) {
      const canvasElementpad = this.canvaspad.nativeElement;
      canvasElementpad.width = canvasElementpad.offsetWidth;
      canvasElementpad.height = canvasElementpad.offsetHeight;
      // console.log('Canvas resized:', canvasElementpad.width, canvasElementpad.height);
    } else {
      // console.error('Canvas element or context is not available');
    }
  }
  startDrawing(event: MouseEvent): void {
    if (this.ctx) {
      this.drawing = true;
      // this.ctx.beginPath();
      // this.ctx.moveTo(event.offsetX, event.offsetY);
      const coords = this.relativeCoords(event);
      this.ctx.moveTo(coords.x, coords.y);
      // console.log('Drawing started at:', event.offsetX, event.offsetY);
    } else {
      // console.error('Context not initialized');
    }

    this.isDrawing = true;

  }

  draw(event: MouseEvent): void {
    if (this.drawing && this.ctx) {
      const coords = this.relativeCoords(event);

      this.ctx.lineTo(coords.x, coords.y);
      this.ctx.stroke();

      // console.log('Drawing at:', event.offsetX, event.offsetY);
    }


  }

  stopDrawing(): void {
    if (this.drawing) {
      this.drawing = false;
      // console.log('Drawing stopped');
    }
  }



  clearCreditCardSignature() {
    const canvasElement = this.canvas.nativeElement;
    // this.ctx.clearRect(0, 0, canvasElement.width, canvasElement.height);
    // this.ctx.beginPath();
    this.signaturecheckvalue1cc = false;
    sessionStorage.removeItem("signature")
    this.signaturemessagecc =true
    this.signaturecheckcc =true
  }

  clearPadSignature() {
    // const canvasElement = this.canvaspad.nativeElement;
    // this.ctxpad.clearRect(0, 0, canvasElement.width, canvasElement.height);
    // this.ctxpad.beginPath();
    // this.signaturecheckvalue1 = false;
    // // this.signaturecheckvalue1 = false;
    // this.signaturemessagepad =true
    // sessionStorage.removeItem("signature")
  }
  // clear(): void {
  //   const canvasElement = this.canvas.nativeElement;
  //   this.ctx.clearRect(0, 0, canvasElement.width, canvasElement.height);
  // }

  save(): void {
    const canvasElement = this.canvas.nativeElement;
    const dataURL = canvasElement.toDataURL('image/png');
    // console.log(dataURL); // Use this data URL to save the image or send it to a server
  }


  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    // console.log(this.signaturePad.toDataURL());
  }
  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    // console.log('begin drawing');
  }

  clearSignature() {
    this.signaturePad.clear();
    this.signaturecheckvalue1cc = false;
  }

  saveSignature() {
    const base64Data = this.signaturePad.toDataURL();
    // console.log(base64Data);
    // You can send the base64Data to your server here
  }
  get f(): { [key: string]: AbstractControl } {
    return this.userForm.controls;
  }

  get b(): { [key: string]: AbstractControl } {
    return this.benficiaryForm.controls;
  }

  get IA(): { [key: string]: AbstractControl } {
    return this.iafggenderForm.controls;
  }


  getControl(rowIndex: number, controlName: string) {
    return this.rows.at(rowIndex).get(controlName);
  }
  isControlInvalid(rowIndex: number, controlName: string): boolean {
    const control:any = this.getControl(rowIndex, controlName);
    return control.invalid && (control.dirty || control.touched);
  }

  getErrorMessage(rowIndex: number, controlName: string): string {
    const control:any = this.getControl(rowIndex, controlName);
    if (control.hasError('required')) {
      return `${controlName} is required.`;
    } else if (control.hasError('min')) {
      return `${controlName} must be a positive number.`;
    } else if (control.hasError('pattern')) {
      return `${controlName} can only contain letters and spaces.`;
    }
    return '';
  }
  get payment(): { [key: string]: AbstractControl } {
    return this.paymentForm.controls;
  }
  get bankpay(): { [key: string]: AbstractControl } {
    return this.bankpayForm.controls;
  }

  setActiveTab(index: number) {
    // alert(index)
    this.previousTab = this.activeTab;
    this.activeTab = index;
    this.visitedTabs.add(this.previousTab);
  }
  @HostListener('document:mouseup', ['$event'])
  onMouseUp(e:any) {
    this.drawing = false;
  }


  // onMouseDown(e:any) {

  //   if (this.ctx) {
  //   this.drawing = true;
  //   const coords = this.relativeCoords(e);
  //   this.ctx.moveTo(coords.x, coords.y);
  //   }

  //   //this.consoleMessage("down")
  // }

  // onMouseMove(e:any) {
  //   if (this.drawing && this.ctx) {

  //     const coords = this.relativeCoords(e);
  //     this.ctx.lineTo(coords.x, coords.y);
  //     this.ctx.stroke();
  //     this.signaturecheckvalue1cc = true;
  //     this.signaturemessagecc = false
  //     this.signaturecheckcc = false;
  //     //this.consoleMessage("up")


  // }


  // }

  onMouseMove(e:any) {

    this.signaturecheckvalue1cc = true;
    this.signaturemessagecc =false
    this.showIAFGerrorMessage =false


    // if (this.isDrawing) {
    //   const coords = this.relativeCoords(e);
    //   this.context.lineTo(coords.x, coords.y);
    //   this.context.stroke();
    //   //this.consoleMessage("up")
    //   this.signaturecheckvalue1cc = true;
    //   this.signaturemessagecc = false
    //   this.signaturecheckcc = false;
    // }
  }
  onMouseDown(e:any) {
    this.isDrawing = true;
    // const coords = this.relativeCoords(e);
    // this.context.moveTo(coords.x, coords.y);

    //this.consoleMessage("down")
  }

  // onMouseMove(e) {

  //   if (this.isDrawing) {
  //     const coords = this.relativeCoords(e);
  //     this.context.lineTo(coords.x, coords.y);
  //     this.context.stroke();
  //     //this.consoleMessage("up")
  //     this.signaturecheckvalue1cc = true;
  //     this.signaturemessagecc = false
  //     this.signaturecheckcc = false;
  //   }
  // }
  onMouseDownpad(e:any) {


    if (this.ctxpad) {
      this.isDrawingpad = true;
      const coords = this.relativeCoords(e);
      this.ctxpad.moveTo(coords.x, coords.y);
      }

  }

  onMouseMovepad(e:any) {
    if (this.isDrawingpad && this.ctxpad) {
      const coords = this.relativeCoords(e);
      this.ctxpad.lineTo(coords.x, coords.y);
      this.ctxpad.stroke();
      this.signaturecheckvalue1 = true;
      this.signaturemessagepad =false;
    }
  }


  private relativeCoords(event: any): { x: number, y: number } {
    const bounds = event.target.getBoundingClientRect();
    const cords = {
      clientX: event.clientX || event.changedTouches[0].clientX,
      clientY: event.clientY || event.changedTouches[0].clientY
    };
    const x = cords.clientX - bounds.left;
    const y = cords.clientY - bounds.top;
    return {x, y};
  }

  formatPhoneNumber(event: any) {
    // const input = event.target.value.replace(/\D/g, '');
    // if (input.length <= 3) {
    //   event.target.value = `(${input}`;
    // } else if (input.length <= 6) {
    //   event.target.value = `(${input.slice(0, 3)}) ${input.slice(3)}`;
    // } else {
    //   event.target.value = `(${input.slice(0, 3)}) ${input.slice(
    //     3,
    //     6
    //   )}-${input.slice(6, 10)}`;
    // }
  }
  phoneFormat(event: any) {
    const inputValue = this.userForm.get("phone_number")?.value;

    this.userForm.get("phone_number")?.setValue(this._PhoneNumberPipe.transform(inputValue), { emitEvent: false });
  }
  alphabatesOnly(event: any) {
    const key = event.key || String.fromCharCode(event.keyCode);
    // Check if the key is an alphabetical character (A-Z or a-z) or a space
    if (/^[a-zA-ZÀ-ÿ ]*$/.test(key)) {
      return true;
    } else {
      return false;
    }



  }
  public paymentmethod(event:any) {
    // this.signaturePad.clear();
    // this.clearPadSignature()
      // this.clearCreditCardSignature()
    if (event.target.value == "PAD") {
      // this.bankpay = true;
      // this.creditcardform = false;
      sessionStorage.setItem("paymentMethod", "PAD");
      // $("#bankpay").prop("checked", true);

    } else {

      // this.bankpay = false;
      // this.creditcardform = true;
      sessionStorage.setItem("paymentMethod", "CC");
      // $("#creditcard").prop("checked", true);

      this.bankfile =""
      this.imagedisplay =false
      this.imagedisplay =""
     sessionStorage.removeItem("fileurl")
     sessionStorage.removeItem("filename")
      this.isFileUploaded = true
      this.Shared.setMessage("")
      this.bankpayForm.get('voidcheck')?.reset();

      // $('.paymentmethod').not(this).prop('checked', false);
    }
  }

  public provincelist(event: any) {
    // alert(event.target.value)

    if (event.target.value == 'QC') {
      // jQuery("#provinceinfo-modal").modal("show");
    }

    this.configprovinceres.forEach((element: any) => {
      if (element.shortName == event.target.value) {
        this.provineList = element;
        // this.provincialHealthcareUrl = element.provincialHealthcareUrl;
        // this.provincialZipcodes = element.zipcodes.split(",");
        // this.provincelistid = element.id;
        // this.state_id = parseInt(element.id);
        // this.fusebillid = parseInt(element.fusebillId);
        // this.statename = element.name;
      }
    });

    // ////this.consoleMessage(this.userForm.get('postalcode').value)
    if (this.userForm.controls['postal_code'].value) {
      if (
        this.provineList.zipcodes
          .split(',')
          .indexOf(this.userForm.controls['postal_code'].value[0]) == -1
      ) {
        this.invalidPostalCodeForProvince = true;
        this.userForm.controls['postal_code'].markAsTouched();
      } else {
        this.invalidPostalCodeForProvince = false;
      }

      if (this.userForm.controls['postal_code'].value.length == 0) {
        this.invalidPostalCodeForProvince = false;
      }
    }
  }
  public provincelistpayment(event: any) {
    // alert(event.target.value)

    if (event.target.value == 'QC') {
      // jQuery("#provinceinfo-modal").modal("show");
    }

    this.configprovinceres.forEach((element: any) => {
      if (element.shortName == event.target.value) {
        this.provineListPayment = element;
        // this.provincialHealthcareUrl = element.provincialHealthcareUrl;
        // this.provincialZipcodes = element.zipcodes.split(",");
        // this.provincelistid = element.id;
        // this.state_id = parseInt(element.id);
        // this.fusebillid = parseInt(element.fusebillId);
        // this.statename = element.name;
      }
    });

    // ////this.consoleMessage(this.userForm.get('postalcode').value)
    if (this.bankpayForm.controls['bankpostalcode'].value) {
      if (
        this.provineListPayment.zipcodes
          .split(',')
          .indexOf(this.bankpayForm.controls['bankpostalcode'].value[0]) == -1
      ) {
        this.bankinvalidPostalCode = true;
        this.bankpayForm.controls['bankpostalcode'].markAsTouched();
      } else {
        this.bankinvalidPostalCode = false;
      }

      if (this.bankpayForm.controls['bankpostalcode'].value.length == 0) {
        this.bankinvalidPostalCode = false;
      }
    }
  }
  public provincelistCreditPayment(event: any) {
    // alert(event.target.value)

    if (event.target.value == 'QC') {
      // jQuery("#provinceinfo-modal").modal("show");
    }

    this.configprovinceres.forEach((element: any) => {
      if (element.shortName == event.target.value) {
        this.provineListcreditPayment = element;
        // this.provincialHealthcareUrl = element.provincialHealthcareUrl;
        // this.provincialZipcodes = element.zipcodes.split(",");
        // this.provincelistid = element.id;
        // this.state_id = parseInt(element.id);
        // this.fusebillid = parseInt(element.fusebillId);
        // this.statename = element.name;
      }
    });

    // ////this.consoleMessage(this.userForm.get('postalcode').value)
    if (this.paymentForm.controls['postalcode'].value) {
      if (
        this.provineListcreditPayment.zipcodes
          .split(',')
          .indexOf(this.paymentForm.controls['postalcode'].value[0]) == -1
      ) {
        this.creditCradInvalidProvince = true;
        this.paymentForm.controls['postalcode'].markAsTouched();
      } else {
        this.creditCradInvalidProvince = false;
      }

      if (this.paymentForm.controls['postalcode'].value.length == 0) {
        this.creditCradInvalidProvince = false;
      }
    }
  }
  changeTextToUppercase(field: any, event: any) {
    const obj: any = {};
    obj['postal_code'] =
      this.userForm.controls['postal_code'].value?.toUpperCase();

    this.userForm.patchValue(obj);
    if (
      this.provineList.zipcodes.split(',').indexOf(event.target.value[0]) == -1
    ) {
      this.invalidPostalCodeForProvince = true;
    } else {
      this.invalidPostalCodeForProvince = false;
      var postalRegexp = /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/gm;
      if (event.target.value && !postalRegexp.test(event.target.value)) {
        this.invalidPostalCodeForProvince = true;
      } else {
        this.invalidPostalCodeForProvince = false;
      }
    }

    if (event.target.value.length == 0) {
      this.invalidPostalCodeForProvince = false;
    }
  }
  changeTextToUppercasePayment(field: any, event: any) {
    const obj: any = {};
    obj['bankpostalcode'] =
      this.bankpayForm.controls['bankpostalcode'].value?.toUpperCase();

    this.bankpayForm.patchValue(obj);
    if (
      this.provineListPayment.zipcodes
        .split(',')
        .indexOf(event.target.value[0]) == -1
    ) {
      this.bankinvalidPostalCode = true;
    } else {
      this.bankinvalidPostalCode = false;
      var postalRegexp = /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/gm;
      if (event.target.value && !postalRegexp.test(event.target.value)) {
        this.bankinvalidPostalCode = true;
      } else {
        this.bankinvalidPostalCode = false;
      }
    }

    if (event.target.value.length == 0) {
      this.bankinvalidPostalCode = false;
    }
  }
  changeTextToUppercaseCreditPayment(field: any, event: any) {
    const obj: any = {};
    obj['postalcode'] =
      this.paymentForm.controls['postalcode'].value?.toUpperCase();

    this.paymentForm.patchValue(obj);
    if (
      this.provineListcreditPayment.zipcodes
        .split(',')
        .indexOf(event.target.value[0]) == -1
    ) {
      this.creditCradInvalidProvince = true;
    } else {
      this.creditCradInvalidProvince = false;
      var postalRegexp = /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/gm;
      if (event.target.value && !postalRegexp.test(event.target.value)) {
        this.creditCradInvalidProvince = true;
      } else {
        this.creditCradInvalidProvince = false;
      }
    }

    if (event.target.value.length == 0) {
      this.creditCradInvalidProvince = false;
    }
  }

  memberinfogenderCheck(event:any){
    if (event.target.value == 'true') {
      this.iafggenderForm
      .get('gender')
      ?.setValidators([Validators.required]);
    }
    else{
      this.iafggenderForm.get('gender')?.reset();
      this.iafggenderForm.get('gender')?.clearValidators();
    }
  }
  hideIAFGgenderfrom(){
    this.iafginfo =false
  }

  spouseInfoCheck(event: any) {
    if (event.target.value == 'true') {
      this.userForm
        .get('spousefirstname')
        ?.setValidators([Validators.required, Validators.minLength(2)]);
      this.userForm
        .get('spouselastname')
        ?.setValidators([Validators.required, Validators.minLength(2)]);
      this.userForm.get('spousegender')?.setValidators([Validators.required]);
      this.userForm
        .get('spousedateofbirth')
        ?.setValidators([Validators.required]);
      this.userForm
        .get('spousehealthcard')
        ?.setValidators([Validators.required]);
      if (this.userForm.value.provincial_health_coverage == 'false') {
        this.userForm
          .get('coverageRequiredOfDependents')
          ?.setValidators([Validators.required]);
      }
      else{
        this.userForm.get('coverageRequiredOfDependents')?.reset();
        this.userForm.get('coverageRequiredOfDependents')?.clearValidators();
        this.userForm
        .get('coverageRequiredOfDependents')
        ?.updateValueAndValidity();
      }
    } else {
      if (this.userForm.value.having_dependent_children == 'true') {
        if (this.userForm.value.provincial_health_coverage == 'false') {
          this.userForm
            .get('coverageRequiredOfDependents')
            ?.setValidators([Validators.required]);
        }
        else{
          this.userForm.get('coverageRequiredOfDependents')?.reset();
          this.userForm.get('coverageRequiredOfDependents')?.clearValidators();
          this.userForm
          .get('coverageRequiredOfDependents')
          ?.updateValueAndValidity();
        }
      } else {
        // this.showlandeddependentDetails =false;

        // $("#coverageRequiredOfDependentsno").click();
        this.userForm.get('coverageRequiredOfDependents')?.reset();
        this.userForm.get('dependentdateOfArrival')?.reset();
        this.userForm.get('dependentcountryOrigin')?.reset();
        this.userForm.get('dependentLandedImigrant')?.reset();
        this.userForm.get('dependentapplylandedstaus')?.reset();
        this.userForm.get('dependentMorethan3years')?.reset();
        this.userForm.get('coverageRequiredOfDependents')?.clearValidators();
        this.userForm
          .get('coverageRequiredOfDependents')
          ?.updateValueAndValidity();
      }

      this.userForm.get('spousefirstname')?.clearValidators();
      this.userForm.get('spousefirstname')?.updateValueAndValidity();

      this.userForm.get('spouselastname')?.clearValidators();
      this.userForm.get('spouselastname')?.updateValueAndValidity();

      this.userForm.get('spousegender')?.clearValidators();
      this.userForm.get('spousegender')?.updateValueAndValidity();

      this.userForm.get('spousedateofbirth')?.clearValidators();
      this.userForm.get('spousedateofbirth')?.updateValueAndValidity();

      this.userForm.get('spousehealthcard')?.clearValidators();
      this.userForm.get('spousehealthcard')?.updateValueAndValidity();
    }
  }

  spousehealthcard(event: any) {
    if (event.target.value == 'true') {
      this.userForm
        .get('Spousenameofthecarrier')
        ?.setValidators([Validators.required]);
      this.userForm.get('spousecob')?.setValidators([Validators.required]);
    } else {
      this.userForm.get('Spousenameofthecarrier')?.clearValidators();
      this.userForm.get('Spousenameofthecarrier')?.updateValueAndValidity();

      this.userForm.get('spousecob')?.clearValidators();
      this.userForm.get('spousecob')?.updateValueAndValidity();
    }
  }
  workingHoursCheck(event: any) {}
  public formatCreditCardNumber(event: any): void {
    let inputVal = event.target.value.replace(/\s+/g, ''); // Remove existing spaces

    if (inputVal.length > 0) {
      // Format the input with spaces every 4 digits
      inputVal = inputVal.match(/.{1,4}/g).join(' ');
    }
    this.paymentForm.patchValue({ CardNumber: inputVal });

    // this.creditCardForm.patchValue({ CardNumber: inputVal });
  }
  public formatAmericaCreditCardNumber(event: any): void {
    let inputVal = event.target.value.replace(/\s+/g, ''); // Remove existing spaces

    if (inputVal.length > 0) {
      // Format the input with spaces every 4 digits
      // inputVal = inputVal.match(/.{1,4}/g).join(' ');

      inputVal = inputVal.replace(
        /(\d{4})(\d{1,6})?(\d{1,5})?/,
        function (match: any, p1: any, p2: any, p3: any) {
          return p1 + (p2 ? ' ' + p2 : '') + (p3 ? ' ' + p3 : '');
        }
      );
    }
    this.paymentForm.patchValue({ CardNumber: inputVal });
    // if (this.isValidAmexCard(inputVal.replaceAll(' ',''))) {
    //   console.log("This is a valid American Express card number.");
    // } else {
    //   console.log("This is not a valid American Express card number.");
    // }
    // this.creditCardForm.patchValue({ CardNumber: inputVal });
  }
  public isValidAmexCard(cardNumber: any) {
    // Remove any non-digit characters
    const cleanedCardNumber = cardNumber.replace(/\D/g, '');

    // Check if the card number has 15 digits and starts with 34 or 37
    const isAmex = /^3[47]\d{13}$/.test(cleanedCardNumber);

    return isAmex;
  }
  public isValidAmexCardNumber(val: any) {
    // Define the regex pattern for formatted Amex card number
    const amexPattern = /^3[47][0-9]{2} [0-9]{6} [0-9]{5}$/;
    console.log(amexPattern);
    return amexPattern.test(val.replaceAll(' ', ''));
  }
  public creditcardlblurmethod(event: any) {
    // this.postalvalue = event.target.value
    this.validateCreditCardNumber(event);

    let val = event.target.value;

    // console.log(val.slice(0, 2));

    const inputField = this.paymentForm.controls['CardNumber'];
    // this.userForm.controls['postalcode']

    if (val.slice(0, 2) == 34 || val.slice(0, 2) == 37) {
      // this.creditcardvalidationAmerica(val)
      // inputField.setValidators([Validators.required, creditcardvalidationAmerica(val)]);
      // inputField.updateValueAndValidity();

      this.formatAmericaCreditCardNumber(event);
      // this.isValidAmexCardNumber(val)
    } else {
      // this.creditcardvalidation(val)
      // inputField.setValidators([Validators.required, creditcardvalidation(val)]);
      // inputField.updateValueAndValidity();

      this.formatCreditCardNumber(event);
    }

    val = val.replaceAll(' ', '');
    let checksum = 0; // running checksum total
    let j = 1; // takes value of 1 or 2
    // Process each digit one by one starting from the last
    for (let i = val.length - 1; i >= 0; i--) {
      let calc = 0;
      // Extract the next digit and multiply by 1 or 2 on alternative digits.
      calc = Number(val.charAt(i)) * j;

      // If the result is in two digits add 1 to the checksum total
      if (calc > 9) {
        checksum = checksum + 1;
        calc = calc - 10;
      }
      // Add the units element to the checksum total
      checksum = checksum + calc;
      // Switch the value of j
      if (j == 1) {
        j = 2;
      } else {
        j = 1;
      }
    }
    //Check if it is divisible by 10 or not.
    // return (checksum % 10) == 0;
    if (checksum % 10 == 0) {
      this.invalidcreditcard = false;
    } else {
      this.invalidcreditcard = true;
      // console.log("tetshere")
    }

    // if (event.target.value.length == 0) {
    //   this.invalidcreditcard = false;
    //   consol.log("false")
    // }
  }

  validateCreditCardNumber(val: any) {
    const cardImage = document.getElementById('card-image');

    console.log(val.target.value);
    const visaPattern = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    const mastPattern = /^(?:5[1-5][0-9]{14})$/;
    const amexPattern = /^(?:3[47][0-9]{13})$/;
    const discPattern = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;

    const ccNum: string = val.target.value.replaceAll(' ', '');

    const isVisa = visaPattern.test(ccNum) === true;
    const isMast = mastPattern.test(ccNum) === true;
    const isAmex = amexPattern.test(ccNum) === true;
    const isDisc = discPattern.test(ccNum) === true;

    if (isVisa || isMast || isAmex || isDisc) {
      // at least one regex matches, so the card number is valid.
      if (isVisa) {
        // this.cardType = 'Visa card'
        this.cardType = './../../assets/img/visa.svg';
        // Visa-specific logic goes here
      } else if (isMast) {
        // this.cardType = 'Master card'
        this.cardType = './../../assets/img/mastercard.svg';
        // Mastercard-specific logic goes here
      } else if (isAmex) {
        this.cardType = './../../assets/img/american-express.svg';
        // cardImage['src']= 'path/to/amex.png';
        // cardImage.style.display = 'inline';
        // AMEX-specific logic goes here
      } else if (isDisc) {
        this.cardType = './../../assets/img/discover.svg';
        // Discover-specific logic goes here
      }
      // return of (null);
    } else {
      this.cardType = '';
      // cardImage.style.display = 'none';
      // return of({
      //   invalid: true
      // });
      // alert("Please enter a valid card number.");
    }
  }
  resolved(captchaResponse: any) {
    this.recaptchaResponse = captchaResponse;
  }
  filterExpiryDate(event: any) {
    let val = ''

  if(this.paymentForm.controls['ExpirationMonth'].value && this.paymentForm.controls['ExporationYear'].value){
val=this.paymentForm.controls['ExpirationMonth'].value+"/"+this.paymentForm.controls['ExporationYear'].value
  }
  // console.log('VAL', val);
    let regex:any = val.match(/^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/);

    if (regex) {
      // console.log('VALID format');
    } else {
      // console.log('INVALID format');
    }

    var exp = new Date(
      this.checkFullYearFormat(1 * regex[2]),
      1 * regex[1],
      1
    ).valueOf();
    var now = new Date();
    var currMonth = new Date(now.getFullYear(), now.getMonth(), 1).valueOf();
    // console.log(currMonth)
    if (exp <= currMonth) {
      this.invalidCardDetails =true
      // alert('Invalid Expiry Date. Date should be greater than current date');


    } else {
      this.invalidCardDetails =false
      // alert('Valid Expiry Date');
    }
  }

  checkFullYearFormat(yearVal: any) {
    var FIXED_YEAR = 50;
    if (yearVal < 100) {
      var nowYear = new Date().getFullYear();
      yearVal += Math.floor(nowYear / 100) * 100;
      if (yearVal > nowYear + FIXED_YEAR) {
        yearVal -= 100;
      } else if (yearVal <= nowYear - 100 + FIXED_YEAR) {
        yearVal += 100;
      }
    }
    return yearVal;
  }
  // public changeexpiryyear(event) {
  //   let today;
  //   let someday;
  //   let expirymonth = this.paymentForm.get("ExpirationMonth").value;

  //   let expiryyear = "20" + event.target.value;

  //   // alert(expiryyear)

  //   var oneDate = moment();
  //   var yearName = oneDate.format("YYYY");

  //   var oneMonth = moment();
  //   var MonthName = oneMonth.format("MM");

  //   if (expiryyear == yearName) {
  //     if (expirymonth < MonthName) {
  //       this.showexpiryyearerror = true;
  //       this.futureexpiredate = false;
  //     } else {
  //       this.showexpiryyearerror = false;
  //       this.showexpirymontherror = false;
  //       // this.showexpiryyearerror =false
  //       today = this.addMonths(new Date(), +6);
  //       someday = new Date();

  //       someday.setFullYear(expiryyear, expirymonth, 0);

  //       if (someday >= today) {
  //         this.futureexpiredate = false;
  //       } else {
  //         this.futureexpiredate = true;
  //         this.showexpiryyearerror = false;
  //         this.showexpirymontherror = false;
  //       }
  //     }
  //   } else {
  //     this.showexpiryyearerror = false;
  //     this.showexpirymontherror = false;
  //     today = this.addMonths(new Date(), +6);
  //     someday = new Date();

  //     someday.setFullYear(expiryyear, expirymonth, 0);
  //     if (expirymonth && expiryyear) {
  //       if (someday >= today) {
  //         this.futureexpiredate = false;
  //       } else {
  //         this.futureexpiredate = true;
  //         this.showexpiryyearerror = false;
  //         this.showexpirymontherror = false;
  //       }
  //     }
  //   }
  // }
  // public addMonths(date:any, months:any) {
  //   date.setMonth(date.getMonth() + months);
  //   return date;
  //   // alert(date)
  // }
  // public changeexpirymonth(event) {
  //   let today;
  //   let someday;

  //   let expiryyear;

  //   if (this.paymentForm.get("ExporationYear").value) {
  //     expiryyear = "20" + this.paymentForm.get("ExporationYear").value;
  //   } else {
  //     expiryyear = this.paymentForm.get("ExporationYear").value;
  //   }

  //   let expirymonth = event.target.value;

  //   var oneDate = moment();
  //   var yearName = oneDate.format("YYYY");

  //   var oneMonth = moment();
  //   var MonthName = oneMonth.format("MM");

  //   if (expiryyear == yearName) {
  //     if (expirymonth < MonthName) {
  //       this.showexpirymontherror = true;
  //       this.futureexpiredate = false;
  //     } else {
  //       this.showexpiryyearerror = false;
  //       this.showexpirymontherror = false;
  //       today = this.addMonths(new Date(), +6);
  //       someday = new Date();

  //       someday.setFullYear(expiryyear, event.target.value, 0);

  //       if (someday >= today) {
  //         this.futureexpiredate = false;
  //       } else {
  //         this.futureexpiredate = true;
  //         this.showexpiryyearerror = false;
  //         this.showexpirymontherror = false;
  //       }
  //     }
  //   } else {
  //     this.showexpirymontherror = false;
  //     this.showexpiryyearerror = false;
  //     this.futureexpiredate = false;

  //     today = this.addMonths(new Date(), +6);
  //     someday = new Date();

  //     someday.setFullYear(expiryyear, event.target.value, 0);
  //     if (expiryyear && event.target.value) {
  //       if (someday >= today) {
  //         this.futureexpiredate = false;
  //       } else {
  //         this.futureexpiredate = true;
  //         this.showexpiryyearerror = false;
  //         this.showexpirymontherror = false;
  //       }
  //     }
  //   }
  // }

  public isMinorCheck(value:any){
    if (value.target.value == 'true') {


      this.benficiaryForm.get('dob')?.setValidators(Validators.required);
      this.benficiaryForm.get('dob')?.updateValueAndValidity();

      this.benficiaryForm.get('trusteeFirstName')?.setValidators(Validators.required);
      this.benficiaryForm.get('trusteeFirstName')?.updateValueAndValidity();


      this.benficiaryForm.get('trusteeLastName')?.setValidators(Validators.required);
      this.benficiaryForm.get('trusteeLastName')?.updateValueAndValidity();
    }else{
      this.benficiaryForm.get('dob')?.clearValidators();
      this.benficiaryForm.get('dob')?.updateValueAndValidity();

      this.benficiaryForm.get('trusteeFirstName')?.clearValidators();
      this.benficiaryForm.get('trusteeFirstName')?.updateValueAndValidity();


      this.benficiaryForm.get('trusteeLastName')?.clearValidators();
      this.benficiaryForm.get('trusteeLastName')?.updateValueAndValidity();
    }

  }

  public isChildCheck(value:any){
    if (value.target.value == 'CHILD') {
      this.benficiaryForm.get('isMinor')?.setValidators(Validators.required);
      this.benficiaryForm.get('isMinor')?.updateValueAndValidity();
    }else{
      this.benficiaryForm.get('dob')?.reset();
      this.benficiaryForm.get('trusteeFirstName')?.reset();
      this.benficiaryForm.get('trusteeLastName')?.reset();

      this.benficiaryForm.get('isMinor')?.clearValidators();
      this.benficiaryForm.get('isMinor')?.updateValueAndValidity();
      this.benficiaryForm.get('dob')?.clearValidators();
      this.benficiaryForm.get('dob')?.updateValueAndValidity();

      this.benficiaryForm.get('trusteeFirstName')?.clearValidators();
      this.benficiaryForm.get('trusteeFirstName')?.updateValueAndValidity();


      this.benficiaryForm.get('trusteeLastName')?.clearValidators();
      this.benficiaryForm.get('trusteeLastName')?.updateValueAndValidity();


    }

  }

  public addeditbenficarySubmit(){

    if (this.benficiaryForm.invalid) {
      console.log(this.benficiaryForm.value);
      const invalid: any = [];
      const controls: any = this.benficiaryForm.controls;

      for (let name in controls) {
        if (controls[name].invalid) {
          invalid.push(name)
        }
      }

      console.log(invalid)
      return;
    }

    let obj = {
      customerType:this.benficiaryForm.value.customerType,
      type:"Primary",
      firstName:this.benficiaryForm.value.firstName,
      lastName:this.benficiaryForm.value.lastName,
      isMinor:this.benficiaryForm.value.isMinor ||"",
      dob :this.benficiaryForm.value.dob || null,
      relationship:this.benficiaryForm.value.relationship,
      percentage :parseFloat(this.benficiaryForm.value.percentage??'0'),
      contingentFirstName:this.benficiaryForm.value.contingentFirstName,
      contingentLastName:this.benficiaryForm.value.contingentLastName,
      trusteeFirstName:this.benficiaryForm.value.trusteeFirstName || "",
      trusteeLastName:this.benficiaryForm.value.trusteeLastName || "",


    };
    if (this.BenficairyModalAddAndUpdateButton == 'Add') {
         this.addbenificaryData.push(obj);
      // this.childTableRowsLength = this.childInfoAddDataArray.length;
      // this.userForm
      //   .get('no_of_children')
      //   ?.setValue(this.childInfoAddDataArray.length);
      this.benficiaryForm.reset()
      this.benficiaryForm.get('dob')?.reset();
      this.benficiaryForm.get('dob')?.clearValidators();
      this.addeditbenficiary = false;
      sessionStorage.setItem(
        'addbenificaryData',
        JSON.stringify(this.addbenificaryData)
      );
      var total = 0;
      for (let i = 0; i < this.addbenificaryData.length; i++) {
        total += parseFloat(this.addbenificaryData[i].percentage);
      }

      if(total>=100){
        this.showaddmorebenficiary =false;
      }else{
        this.showaddmorebenficiary =true;

      }




    } else {
      // this.benficiaryForm.value?['contactDetails']={}
      // this.benficiaryForm.value?['trusteeDetails']={'firstName':'','lastName':''}
      console.log(this.addbenificaryData[this.editBenficiaryIndex])
      console.log(this.benficiaryForm.value)
      this.addbenificaryData[this.editBenficiaryIndex] = obj;
      this.addeditbenficiary = false;
      console.log(this.addbenificaryData)
      sessionStorage.setItem(
        'addbenificaryData',
        JSON.stringify(this.addbenificaryData)
      );
      var total = 0;
      for (let i = 0; i < this.addbenificaryData.length; i++) {
        total += parseFloat(this.addbenificaryData[i].percentage);
      }

      if(total>=100){
        this.showaddmorebenficiary =false;
      }else{
        this.showaddmorebenficiary =true;

      }

      this.benficiaryForm.reset()
      this.benficiaryForm.get('dob')?.reset();
      this.benficiaryForm.get('dob')?.clearValidators();
    }


  }
  public addnewbenficiary(){
    this.addeditbenficiary =true
    this.BenficairyModalAddAndUpdateButton = 'Add';

  }
public savebenficiary(value:any) {
  if (this.benficiaryForm.invalid) {
    console.log(this.benficiaryForm.value);
    return;
  }
  console.log(JSON.stringify(this.benficiaryForm.value, null, 2));
  // alert(this.insurancestatename)
  let obj = {
    customerType:this.benficiaryForm.value.customerType,
    type:"Primary",
    firstName:this.benficiaryForm.value.firstName,
    lastName:this.benficiaryForm.value.lastName,
    isMinor:this.benficiaryForm.value.isMinor ||"",
    dob :this.benficiaryForm.value.dob || null,
    relationship:this.benficiaryForm.value.relationship,
    percentage :parseFloat(this.benficiaryForm.value.percentage??'0'),
    contingentFirstName:this.benficiaryForm.value.contingentFirstName,
    contingentLastName:this.benficiaryForm.value.contingentLastName,
    trusteeFirstName:this.benficiaryForm.value.trusteeFirstName || "",
    trusteeLastName:this.benficiaryForm.value.trusteeLastName || "",


  };
  this.addbenificaryData.push(obj)

  console.log(this.addbenificaryData)
  this.benficiaryForm.reset()
  this.showBenificiaryForm=false;
  this.showbenificaryTable =true
  let licenceExist = false;



  var total = 0;
  for (let i = 0; i < this.addbenificaryData.length; i++) {
    total += parseFloat(this.addbenificaryData[i].percentage);
  }

  if(total>=100){
    this.showaddmorebenficiary =false;
  }else{
    this.showaddmorebenficiary =true;

  }

    sessionStorage.setItem(
      'addbenificaryData',
      JSON.stringify(this.addbenificaryData)
    );

}

public deleteBenficiary(license: any, i: number) {
  Swal.fire({
    title: this.lang.Alert,
    text: this.lang.Are_you_sure_you_want_to_delete_this_Benficiary,
    showDenyButton: false,
    showCancelButton: true,
    confirmButtonText: this.lang.Proceed,
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      this.addbenificaryData.splice(i, 1);

      if (this.addbenificaryData.length == 0) {
        this.showBenificiaryForm=true;
  this.showbenificaryTable =false
      } else {
        this.showBenificiaryForm=false;
        this.showbenificaryTable =true
      }
      // sessionStorage.setItem(
      //   'addlicenseformData',
      //   JSON.stringify(this.addlicenseformData)
      // );
      sessionStorage.setItem(
        'addbenificaryData',
        JSON.stringify(this.addbenificaryData)
      );
      var total = 0;
      for (let i = 0; i < this.addbenificaryData.length; i++) {
        total += parseFloat(this.addbenificaryData[i].percentage);
      }

      if(total>=100){
        this.showaddmorebenficiary =false;
      }else{
        this.showaddmorebenficiary =true;

      }
      // const validateBeneficiaries = this.addbenificaryData.reduce((acc: any, obj: any) => {

      //   if (!acc[obj.type]) {
      //     acc[obj.type] = 0;
      //   }
      //   acc[obj.type] += parseFloat(obj.percentage);
      //             return acc;
      // }, {});

      // for (const key in validateBeneficiaries) {

      //   if (validateBeneficiaries.hasOwnProperty(key)) {

      //     console.log(`The type ${key} with perc: ${validateBeneficiaries[key]}`);

      //     if (validateBeneficiaries[key] > 100) {
      //       this.beneficiaryErrorMessage += `${key} type has percentage issue. \n`
      //       this.showaddmorebenficiary =true
      //     }
      //     if (validateBeneficiaries[key] < 100) {
      //       this.beneficiaryErrorMessage += `${key} type has percentage issue. \n`
      //       this.showaddmorebenficiary =true        }
      //     if (validateBeneficiaries[key] == 100) {
      //       this.showaddmorebenficiary =false

      //     }
      //     // else{
      //     //   this.benficiaryCount =true
      //     //   this.benficiaryCountMessage='Please Enter Benefeciary Percentage equal to 100'
      //     // }
      //   }
      // }

      // if (this.beneficiaryErrorMessage != "") {
      //   //error
      // return false;
      // }
    }
  });
}
public finalbenificiarySubmit() {
  var total = 0;
  for (let i = 0; i < this.addbenificaryData.length; i++) {
    total += parseFloat(this.addbenificaryData[i].percentage);
  }
  // if(total==100){
  // this.benficiary =false

  const validateBeneficiaries = this.addbenificaryData.reduce((acc: any, obj: any) => {
    const compositeKey = `${obj.customerType}_${obj.type}`;
    if (!acc[compositeKey]) {
      acc[compositeKey] = 0;
    }
    acc[compositeKey] += parseFloat(obj.percentage);
    return acc;
  }, {});

  let beneficiaryError = "";
  for (const key in validateBeneficiaries) {
    if (validateBeneficiaries.hasOwnProperty(key)) {
      const [customerType, type] = key.split("_");
      console.log(`The customerType ${customerType} and type ${type} with perc: ${validateBeneficiaries[key]}`);

      if (validateBeneficiaries[key] != 100) {
        beneficiaryError += `CustomerType ${customerType} and type ${type} has percentage issue. \n`
      }
    }
  }




  if (beneficiaryError != "") {
    this.benficiaryCount = true
    this.benficiaryCountMessage = this.lang.Distributed_percentages_must_equal_100_and_not_more
    return false;
  }

  else {
    this.benficiary = false

  }

}



public benficiaryconfirm(){
  this.benficiaryCount =false
}
public editbenficiary(e:any, i:any, broker:any){
this.addeditbenficiary =true
console.log(broker)

if(broker.isMinor=="true"){

  this.benficiaryForm.get('isMinor')?.setValidators(Validators.required);
  this.benficiaryForm.get('isMinor')?.updateValueAndValidity();


  this.benficiaryForm.get('dob')?.setValidators(Validators.required);
  this.benficiaryForm.get('dob')?.updateValueAndValidity();

  this.benficiaryForm.get('trusteeFirstName')?.setValidators(Validators.required);
  this.benficiaryForm.get('trusteeFirstName')?.updateValueAndValidity();


  this.benficiaryForm.get('trusteeLastName')?.setValidators(Validators.required);
  this.benficiaryForm.get('trusteeLastName')?.updateValueAndValidity();

}else{
  this.benficiaryForm.get('isMinor')?.clearValidators();
  this.benficiaryForm.get('isMinor')?.updateValueAndValidity();

  this.benficiaryForm.get('dob')?.clearValidators();
  this.benficiaryForm.get('dob')?.updateValueAndValidity();

  this.benficiaryForm.get('trusteeFirstName')?.clearValidators();
  this.benficiaryForm.get('trusteeFirstName')?.updateValueAndValidity();


  this.benficiaryForm.get('trusteeLastName')?.clearValidators();
  this.benficiaryForm.get('trusteeLastName')?.updateValueAndValidity();
}
this.benficiaryForm.patchValue(broker)

this.editBenficiaryIndex = i;
this.BenficairyModalAddAndUpdateButton = 'Update';

}
public checkpercentagevalue(event:any){
  console.log(event.target.value)
  if(event.target.value<=100){


  }else{
    this.benficiaryForm.controls['percentage'].reset();
    this.benficiaryForm.controls['percentage'].markAsTouched();
  }

}
  public provincialCoverageHealth(value: any) {
    // alert("test")
    if (value.target.value === 'true') {
      this.spouseplaceholdername = this.lang.Name_As_Shown_On_Provincial_Health_Card;

      this.userForm.get('dateOfArrival')?.clearValidators();
      this.userForm.get('dateOfArrival')?.updateValueAndValidity();
      this.userForm.get('isEmployeeImigrant')?.clearValidators();
      this.userForm.get('isEmployeeImigrant')?.updateValueAndValidity();
      this.userForm.get('applylandedstaus')?.clearValidators();
      this.userForm.get('applylandedstaus')?.updateValueAndValidity();
      this.userForm.get('countryOrigin')?.clearValidators();
      this.userForm.get('countryOrigin')?.updateValueAndValidity();
      this.userForm.get('coverageRequiredOfEmployee')?.clearValidators();
      this.userForm.get('coverageRequiredOfEmployee')?.updateValueAndValidity();



      if (this.userForm.get('having_spouse')?.value === 'true' || this.userForm.get('having_dependent_children')?.value === 'true') {

        this.userForm.get('coverageRequiredOfDependents')?.clearValidators();
        this.userForm.get('coverageRequiredOfDependents')?.reset();
        this.userForm.get('coverageRequiredOfDependents')?.updateValueAndValidity();


      }else{
        this.userForm.get('coverageRequiredOfDependents')?.clearValidators();
        this.userForm.get('coverageRequiredOfDependents')?.reset();
        this.userForm.get('coverageRequiredOfDependents')?.updateValueAndValidity();



      }
    } else {
      this.spouseplaceholdername = this.lang.First_Name;

      this.userForm.get('dateOfArrival')?.setValidators(Validators.required);
      this.userForm
        .get('isEmployeeImigrant')
        ?.setValidators(Validators.required);
      this.userForm.get('countryOrigin')?.setValidators(Validators.required);
      this.userForm
        .get('coverageRequiredOfEmployee')
        ?.setValidators(Validators.required);
      // this.checkhealthcardstatus = true;
      if (this.userForm.get('having_spouse')?.value == 'true' || this.userForm.get('having_dependent_children')?.value == 'true') {


        this.userForm
          .get('coverageRequiredOfDependents')
          ?.setValidators([Validators.required]);
          this.userForm.get('coverageRequiredOfDependents')?.updateValueAndValidity();


    }
    else{
      this.userForm.get('coverageRequiredOfDependents')?.reset();
      this.userForm.get('coverageRequiredOfDependents')?.clearValidators();
      this.userForm.get('coverageRequiredOfDependents')?.updateValueAndValidity();

    }
    }


  }

  public landedimmigrantStatus(value: any) {
    // alert("test")
    if (value.target.value == 'true') {
      this.userForm.get('applylandedstaus')?.clearValidators();
      this.userForm.get('applylandedstaus')?.updateValueAndValidity();
    } else {
      this.userForm.get('applylandedstaus')?.setValidators(Validators.required);
      this.userForm.get('applylandedstaus')?.updateValueAndValidity();

      // this.checkhealthcardstatus = true;
    }
  }

  public dependentCoverage(value: any) {
    if (value.target.value == 'true') {
      this.userForm
        .get('dependentdateOfArrival')
        ?.setValidators(Validators.required);
      this.userForm
        .get('dependentLandedImigrant')
        ?.setValidators(Validators.required);
      this.userForm
        .get('dependentMorethan3years')
        ?.setValidators(Validators.required);
      this.userForm
        .get('dependentcountryOrigin')
        ?.setValidators(Validators.required);
    } else {
      this.userForm.get('dependentdateOfArrival')?.clearValidators();
      this.userForm.get('dependentdateOfArrival')?.updateValueAndValidity();

      this.userForm.get('dependentLandedImigrant')?.clearValidators();
      this.userForm.get('dependentLandedImigrant')?.updateValueAndValidity();

      this.userForm.get('dependentapplylandedstaus')?.clearValidators();
      this.userForm.get('dependentapplylandedstaus')?.updateValueAndValidity();

      this.userForm.get('dependentMorethan3years')?.clearValidators();
      this.userForm.get('dependentMorethan3years')?.updateValueAndValidity();

      this.userForm.get('dependentcountryOrigin')?.clearValidators();
      this.userForm.get('dependentcountryOrigin')?.updateValueAndValidity();
      // this.checkhealthcardstatus = true;
    }
  }

  public dependentLandedImigrant(value: any) {
    // alert("test")
    if (value.target.value == 'true') {
      this.userForm.get('dependentapplylandedstaus')?.clearValidators();
      this.userForm.get('dependentapplylandedstaus')?.updateValueAndValidity();
    } else {
      this.userForm
        .get('dependentapplylandedstaus')
        ?.setValidators([Validators.required]);
      // this.checkhealthcardstatus = true;
    }
  }

  public havingchildren(event: any) {
    //this.consoleMessage("havingchildren: ");
    if (event.target.value == 'true') {
      this.userForm
        .get('no_of_children')
        ?.setValue(this.noOfChildrenChangedValue);

      if (this.userForm.value.provincial_health_coverage == 'false') {
        this.userForm
          .get('coverageRequiredOfDependents')
          ?.setValidators([Validators.required]);
      }
    } else {
      if (this.userForm.value.having_spouse == 'true') {
        if (this.userForm.value.provincial_health_coverage == 'false') {
          this.userForm
            .get('coverageRequiredOfDependents')
            ?.setValidators([Validators.required]);
        }
      } else {
        // this.showlandeddependentDetails =false;

        // $("#coverageRequiredOfDependentsno").click();
        this.userForm.get('coverageRequiredOfDependents')?.reset();
        this.userForm.get('dependentdateOfArrival')?.reset();
        this.userForm.get('dependentcountryOrigin')?.reset();
        this.userForm.get('dependentLandedImigrant')?.reset();
        this.userForm.get('dependentapplylandedstaus')?.reset();
        this.userForm.get('dependentMorethan3years')?.reset();
        this.userForm.get('coverageRequiredOfDependents')?.clearValidators();
        this.userForm
          .get('coverageRequiredOfDependents')
          ?.updateValueAndValidity();
      }

      // this.childrenhealthinformation = false;
      this.childInfoAddDataArray.shift();
      this.childTableRowsLength = this.childInfoAddDataArray.length;
      this.userForm.get('childrenDetails')?.reset();
      this.userForm.get('childrenDetails')?.clearValidators();
      this.userForm.get('no_of_children')?.clearValidators();
      this.userForm.get('no_of_children')?.updateValueAndValidity();
    }
  }

  public childAddFun() {
    this.showModal = true;
    this.childInfoModalAddAndUpdateButton = this.lang.Add;
    this.editChildInfo =''
  }
  public termandconditions() {
    this.showModelDialog = true;
    this.pdfData = this.formConfigResult.links.termsConditions;
    this.header = this.lang.Terms_Conditions
  }

  public advisorDisclousre() {
    this.showModelDialog = true;
    this.pdfData = this.formConfigResult.links.disclosureAgreement;
    this.header = this.lang.Advisor_Disclosure
  }

  public DeleteChild(index: any) {
    this.deleteChildIndex = index;
    this.showDeleteModal = true;
  }
  public EditChild(index: any) {
    this.editChildIndex = index;
    this.childInfoModalAddAndUpdateButton = this.lang.Update;
    this.showModal = true;

    this.editChildInfo = this.childInfoAddDataArray[index];

    console.log(this.editChildInfo);
  }
  onConfirmed(confirmed: boolean) {
    if (confirmed) {
      // console.log(this.deleteChildIndex);

      this.childInfoAddDataArray.splice(this.deleteChildIndex, 1);
      this.showDeleteModal = false;
      // console.log('Confirmation result:', confirmed);
      this.childTableRowsLength = this.childInfoAddDataArray.length;
      this.userForm
        .get('no_of_children')
        ?.setValue(this.childInfoAddDataArray.length);

      if (this.childInfoAddDataArray.length == 0) {
        this.userForm.get('having_dependent_children')?.setValue('false');
        this.userForm.get('no_of_children')?.clearValidators();
      this.userForm.get('no_of_children')?.updateValueAndValidity();
      }
    } else {
      this.showDeleteModal = false;
    }
  }

  onPdfConfirmed(confirmed: boolean) {
    if (confirmed) {
      this.showModelDialog = false;
    } else {
      this.showModelDialog = false;
    }
  }
  onPADPdfConfirmed(confirmed: boolean) {
    if (confirmed) {
      this.showPADModelDiaslog = false;
    } else {
      this.showPADModelDiaslog = false;
      this.acceptAggrement()
    }
  }
  onClosedModelpopup(confirmed: boolean) {
    if (confirmed) {
      this.ShowModelPopup = false;
    } else {
      this.ShowModelPopup = false;
    }
  }

  public acceptAggrement(){
    setTimeout(() => {

      let inputData={
        "url": sessionStorage.getItem("fileurl"),
        "filename": sessionStorage.getItem("filename"),
        "signature":sessionStorage.getItem("signature")
      }

        this._SignupformService.padsignature(inputData).subscribe((result: any) => {
          if(result.status=="200"){

              this.showPADModelDiaslog = true
              this.pdfData = result.data.url
              this.header = "PAD Agreement"
              sessionStorage.setItem("failed","")


          } else {
            this.failedarray.push("updatePAD")
            //this.consoleMessage(this.failedarray)
            sessionStorage.setItem("failed",JSON.stringify(this.failedarray))

          }

          })




  }, 1000);
  }

  onSubmitForm(data: any) {
    this.childInfoAddDataArray[this.childTableEditRowIndex];
    // console.log('Form submitted:', data);
    if (this.childInfoModalAddAndUpdateButton == this.lang.Add) {
      this.childInfoAddDataArray.push(data);
      this.childTableRowsLength = this.childInfoAddDataArray.length;
      this.userForm
        .get('no_of_children')
        ?.setValue(this.childInfoAddDataArray.length);

      // console.log(this.childTableRowsLength);
      // console.log(this.childInfoAddDataArray);

      this.showModal = false;
    } else {
      this.childInfoAddDataArray[this.editChildIndex] = data;
      this.showModal = false;
    }
  }

  onCloseModal() {
    this.showModal = false;
  }
  onClose(){
    this.benficiary =false
  }
  onCloseaddeditbenificary(){
    this.addeditbenficiary =false
    this.benficiaryForm.reset()
  }
  deleteChildrenSwal(index: any) {
    // Swal.fire({
    //   title: this.lang.Info,
    //   text: "Are you sure you want to delete?",
    //   showCancelButton: true,
    //   confirmButtonColor: "#10104d",
    //   cancelButtonColor: "#10104d",
    //   width: "30%",
    //   confirmButtonText: "Yes",
    //   customClass: {
    //     header: 'swal-header-orange',
    //     popup: 'custom-swal-dialog',
    //     confirmButton: 'custom-ok-button-class',
    //   }
    // }).then((result:any) => {
    //   if (result.value) {
    //     this.childInforTableDelete(index);
    //   }
    // });
  }

  childInforTableDelete(index: any) {
    if (this.childInfoAddDataArray.length == 1) {
      // document.getElementById('Dependentchildrenno').click();
    }

    this.childInfoAddDataArray.splice(index, 1);
    this.noOfChildrenChangedValue = this.childInfoAddDataArray.length;

    this.childTableRowsLength = this.childInfoAddDataArray.length;
  }

  public childdsiablility(value: any) {
    // this.childendisability = value.target.checked;
  }
  onSubmit(value: any, tabId: number): void {
    // let data = this.userForm.value;
    // console.log(data)
    // this.submitted = true;

    // console.log(this.tabIndex)

    let obj: any = {
      dob: value.date_of_birth,
      age: this.calculateAge(value.date_of_birth),
      gender: value.gender,
      havingSpouse: value.having_spouse === "true" ? true : false,
    };

    if (value.havingspouse === "true") {
      obj.spouseDetails = {
        dob: value.spousedateofbirth,
        age: this.calculateAge("02-08-2004"),
        gender: value.spousegender,
      };
    }
    //clearing allform Data when clicking on previous and loading
    this.allFormData = {}
    // this._SignupformService.lifeInsurance(obj).subscribe((result: any) => {
    //   this.premiums = result.data;
    //   console.log({ result });
    // });

    if (this.userForm.invalid) {
      this.userForm.markAllAsTouched();
      const invalid: any = [];
      const controls: any = this.userForm.controls;
      //this.consoleMessage(controls)







      // childrenDetails: this.formBuilder.array([this.initChildDetails()]),
      // childrenDetails:  new FormControl(''),

      for (let name in controls) {
        if (controls[name].invalid) {
          if (name == 'dependentdateOfArrival') {
            name = this.lang.Select_dependent_Date_of_Arriaval;
          }
          if (name == 'dependentLandedImigrant') {
            name = this.lang.Select_dependent_Date_of_Arriaval;
          }
          if (name == 'dependentMorethan3years') {
            name = this.lang.Select_dependent_living_status_in_Canada;
          }
          if (name == 'coverageRequiredOfDependents') {
            name = this.lang.Select_dependent_coverage;
          }
          if (name == 'dependentapplylandedstaus') {
            name = this.lang.Select_dependent_apply_landed_immigrant;
          }
          if (name == 'dependentcountryOrigin') {
            name = this.lang.Select_dependent_Country_Origin;
          }
          if (name == 'dateOfArrival') {
            name = this.lang.Select_employee_date_of_arrival;
          }
          if (name == 'isEmployeeImigrant') {
            name = this.lang.Select_employee_immigrant_status;
          }
          if (name == 'applylandedstaus') {
            name = this.lang.Select_employee_apply_landed_status;
          }
          if (name == 'countryOrigin') {
            name = this.lang.Select_employee_country_of_origin;
          }
          if (name == 'coverageRequiredOfEmployee') {
            name = this.lang.Select_employee_coverage;
          }
          if (name == 'planEnrollmentDate') {
            name = this.lang.Select_Plan_Enrollment_Date;
          }
          if (name == 'firstName') {
            name = this.lang.Enter_First_Name;
          }
          if (name == 'lastName') {
            name = this.lang.Enter_Last_Name;
          }
          if (name == 'email') {
            name = this.lang.Enter_Email;
          }
          if (name == 'phone_number') {
            name = this.lang.Enter_Phone_Number;
          }
          if (name == 'street_address_line1') {
            name = this.lang.Enter_Street_Address;
          }
          if (name == 'city') {
            name = this.lang.Enter_City;
          }
          if (name == 'province') {
            name = this.lang.Select_Province_of_Residence;
          }
          if (name == 'workingProvince') {
            name = this.lang.Select_Province_of_Employment;
          }
          if (name == 'postal_code') {
            name = this.lang.Enter_Postal_Code;
          }
          if (name == 'gender') {
            name = this.lang.Select_Gender;
          }
          if (name == 'date_of_hiring') {
            name = this.lang.Select_Date_of_hire;
          }
          if (name == 'date_of_birth') {
            name = this.lang.Select_Date_of_birth;
          }
          if (name == 'company_name') {
            name = this.lang.Enter_Company_Name;
          }
          if (name == 'job_title') {
            name = this.lang.Enter_Job_Title;
          }
          if (name == 'working_20hours') {
            name = this.lang.Choose_work_hours_status;
          }
          if (name == 'hours_per_week') {
            name = this.lang.Enter_Hours_per_week;
          }
          if (name == 'provincial_health_coverage') {
            name = this.lang.Choose_Health_Card_status;
          }
          if (name == 'having_spouse') {
            name = this.lang.Choose_Spouse_status;
          }
          if (name == 'spousefirstname') {
            name = this.lang.Enter_Spouse_First_Name;
          }
          if (name == 'spouselastname') {
            name = this.lang.Enter_Spouse_Last_Name;
          }
          if (name == 'spousegender') {
            name = this.lang.Select_Spouse_Gender;
          }
          if (name == 'spouseemail') {
            name = this.lang.Enter_Spouse_Email;
          }
          if (name == 'spousedateofbirth') {
            name = this.lang.Select_Spouse_Date_of_birth;
          }
          if (name == 'having_dependent_children') {
            name = this.lang.Choose_Dependent_Children_status;
          }
          if (name == 'spousehealthcard') {
            name = this.lang.Choose_Spouse_Insurance_status;
          }
          if (name == 'Spousenameofthecarrier') {
            name = this.lang.Enter_Spouse_name_of_Carrier;
          }
          if (name == 'spousecob') {
            name = this.lang.Select_Spouse_COB;
          }
          if (name == 'termsandconditions') {
            name = this.lang.Select_Terms_and_Conditions;
          }
          if (name == 'disclouseradvisor') {
            name = this.lang.Select_Advisor_Disclosure;
          }
          invalid.push(name);
        }
      }
      this.missingInformation =true
      // jQuery("#missingInfo-modal").modal("show");

      this.missingInfo = invalid;
      ////this.consoleMessage(invalid)
      return invalid;
    } else {
      let SpouseDetails: any = {};

      SpouseDetails =
        value.having_spouse == 'true'
          ? {
              first_name: this.capitalize(value.spousefirstname),
              last_name: this.capitalize(value.spouselastname),
              firstName: this.capitalize(value.spousefirstname),
              lastName: this.capitalize(value.spouselastname),
              email: value.spouseemail || '',
              date_of_birth: this._CustomDatePipe.transform(
                value.spousedateofbirth
              ),
              dob: this._CustomDatePipe.transform(value.spousedateofbirth),
              gender: value.spousegender,
              is_spouse_having_healthcard:
                value.spousehealthcard == 'true' ? true : false,
              spouse_carrier_name:
                value.spousehealthcard == 'true'
                  ? value.Spousenameofthecarrier
                  : '',
              cobCoverage:
                value.spousehealthcard == 'true' ? value.spousecob : '',
            }
          : {};

      let mainDetails: any = {};

      //this.consoleMessage(value.applylandedstaus)
      mainDetails = {
        dateOfArrival:
          this._CustomDatePipe.transform(value.dateOfArrival) || '',
        countryOfOrigin: value.countryOrigin || '',
        isLandedImmigrant: value.isEmployeeImigrant?Boolean(value.isEmployeeImigrant):false,
        coverageRequired: value.coverageRequiredOfEmployee?Boolean(value.coverageRequiredOfEmployee) : false,
        applyingForLandingStatus: value.applylandedstaus
          ? Boolean(value.applylandedstaus)
          : false,
        dependentDetails:
          value.having_dependent_children == 'true' ||
          value.having_dependent_children == 'true'
            ? {
                coverageRequired:value.coverageRequiredOfDependents?
                  Boolean(value.coverageRequiredOfDependents):false,
                dateOfArrival:
                  value.coverageRequiredOfDependents == 'true'
                    ? this._CustomDatePipe.transform(
                        value.dependentdateOfArrival
                      )
                    : '',
                countryOfOrigin:
                  value.coverageRequiredOfDependents == 'true'
                    ? value.dependentcountryOrigin
                    : '',
                isLandedImmigrant:
                  value.coverageRequiredOfDependents == 'true'
                    ? Boolean(value.dependentLandedImigrant)
                    : false,
                applyingForLandingStatus: value.dependentMorethan3years
                  ? Boolean(value.dependentMorethan3years)
                  : false,
                stayFor3Months:
                  value.coverageRequiredOfDependents == 'true'
                    ? Boolean(value.dependentMorethan3years)
                    : false,
              }
            : {},
      };
      let childrenarray:any = [];
      let childrenobj: any = {};
      let childarry:any =[]
if(this.childInfoAddDataArray.length > 0){
  this.childInfoAddDataArray.forEach((element:any) => {
        childrenobj = {};
        childrenobj.first_name = this.capitalize(element.firstName);
        childrenobj.last_name = this.capitalize(element.lastName);
        childrenobj.firstName = this.capitalize(element.firstName);
        childrenobj.lastName = this.capitalize(element.lastName);
        childrenobj.gender = element.gender;
        childrenobj.date_of_birth = this._CustomDatePipe.transform(
          element.date_of_birth
        );
        childrenobj.dob = this._CustomDatePipe.transform(
          element.date_of_birth
        );
        childrenobj.is_child_having_healthcard =
          element.is_child_having_healthcard == "true" ? true : false;
        childrenobj.child_carrier_name = element.child_carrier_name || "";
        childrenobj.enrolledInUniversity =
          element.enrolledInUniversity == "true" ? true : false;
        childrenobj.isDisabled =
          element.isDisabled == "true" ? true : false;
        childrenobj.graduationDay =
          this._CustomDatePipe.transform(element.graduationDay) || "";
        childrenarray.push(childrenobj);
      });
    }
      let inputData: any = {
        recaptchaCheck: false,
        g_recaptcha_response: '',

        planEnrollmentDate: this.userForm.value.planEnrollmentDate
          ? this._CustomDatePipe.transform(
              this.userForm.value.planEnrollmentDate
            )
          : undefined,
        first_name: this.capitalize(value.firstName),
        firstName: this.capitalize(value.firstName),
        last_name: this.capitalize(value.lastName),
        lastName: this.capitalize(value.lastName),
        email: value.email,
        phone_number: value.phone_number,
        // phone_number:"+1 (514) 336-5595",
        // "marital_status":value.matrialstatus,
        street_address_line1: value.street_address_line1,
        street_address_line2: value.street_address_line2 || '',
        city: this.capitalize(value.city),
        province: value.province,
        province_id: parseInt(this.provineList.id),
        country: 'Canada',
        country_id: 1,
        postal_code: value.postal_code,
        date_of_birth: this._CustomDatePipe.transform(value.date_of_birth),
        dob: this._CustomDatePipe.transform(value.date_of_birth),
        gender: value.gender,
        company_name: value.company_name,
        job_title: this.capitalize(value.job_title),
        date_of_hiring: this._CustomDatePipe.transform(value.date_of_hiring),
        working_20hours: value.working_20hours == 'true' ? true : false,
        hours_per_week:
          value.working_20hours == 'true' ? parseInt(value.hours_per_week) : 0,
        provincial_health_coverage:
          value.provincial_health_coverage == 'true' ? true : false,
        work_visa: '',
        having_spouse: value.having_spouse == 'true' ? true : false,
        spouse_details: SpouseDetails,
        having_dependent_children:
          value.having_dependent_children == 'true' ? true : false,
        no_of_children:
          value.having_dependent_children == 'true' ? value.no_of_children : 0,
        children_details:
        childrenarray.length > 0
            ? childrenarray
            : '',
        signature: '',
        fusebillCustomer: true,
        formId: parseInt(this.formConfigResult.form.id),
        brokerId: parseInt(this.formConfigResult.form.brokerId),
        // "formId":1,
        termsandconditions: true,
        disclouseradvisor: true,
        state: this.provineList.name,
        // state_id: this.state_id,
        state_id: parseInt(this.provineList.id),
        fusebillStateId: parseInt(this.provineList.fusebillId),
        fusebillCountryId: 124,
        // "fusebillCustomerId":sessionStorage.getItem("fusebillCustomerId") || null,
        currency: 'CAD',
        currencySymbol: '$',
        apt: value.apt,
        pageUrl: window.location.href,

        //  "fusebillCustomerAddressId":sessionStorage.getItem("fusebillCustomerAddressId") || null
      };

      this.CreditCradFirstName=  inputData.firstName
      this.CreditCradLastName=  inputData.lastName


      if (
        inputData.provincial_health_coverage == false
      ) {
        inputData['phrDetails'] = mainDetails;
      }
      if (this.userForm.get('having_dependent_children')?.value === 'true') {
        if (this.childTableRowsLength > 0) {
        }
        else{
          const invalid:any =[]
          this.missingInformation =true


        invalid.push('Please add at least one child details');
        this.missingInfo = invalid;
        return invalid;
        };
      }
      this._SignupformService.validation(inputData).subscribe({
          next: (result: any) => {
            if (result.status == '200') {
              if (this.activeTab < this.tabs.length - 1) {
                this.previousTab = this.activeTab;
                this.activeTab++;
                this.visitedTabs.add(this.previousTab);
              }

              // this.setActiveTab(1)
              // this.staticData = [
              //   {
              //     mainheading: "Term Life",
              //     type: "term-Life",
              //     h1: "Member/Spouse Term Life Insurance",
              //     d1: " (Units of $25,000 to $500,000 max.)",
              //     h2: "Dependent Children Term Life Insurance*",
              //     d2: "(Units of $5,000 to $10,000 max. – Available only if the member is insured or applying for Term Life Insurance)",
              //     total: 0,
              //     member: {
              //       total: 0,
              //       defaultRange: 50000,
              //       maxRange: 500000,
              //       stepCount: 25000,
              //       dob: "12-10-1990",
              //       gender: "male",
              //       maximumGuaranteedCoverage: 50000,
              //     },
              //     spouse: {
              //       total: 0,
              //       defaultRange: 50000,
              //       maxRange: 500000,
              //       stepCount: 25000,
              //       dob: "12-10-1992",
              //       gender: "female",
              //       maximumGuaranteedCoverage: 50000,
              //     },
              //     dependent: {
              //       total: 0,
              //       defaultRange: 0,
              //       maxRange: 10000,
              //       stepCount: 5000,
              //       dob: "12-10-2000",
              //       gender: "male",
              //     },
              //   },
              //   // {
              //   //   mainheading: "Critical Illness",
              //   //   type: "critical-Illness",
              //   //   h1: "Member/Spouse Critical Illness Insurance",
              //   //   d1: " (Units of $25,000 to $500,000 max.)",
              //   //   h2: "Dependent Children Critical Illness Insurance*",
              //   //   d2: "(Units of $5,000 to $10,000 max. – Available only if the member is insured or applying for Term Life Insurance)",
              //   //   total: 0,
              //   //   member: {
              //   //     total: 0,
              //   //     defaultRange: 50000,
              //   //     maxRange: 500000,
              //   //     stepCount: 25000,
              //   //     dob: "12-10-1990",
              //   //     gender: "male",
              //   //     maximumGuaranteedCoverage: 50000,
              //   //   },
              //   //   spouse: {
              //   //     total: 0,
              //   //     defaultRange: 50000,
              //   //     maxRange: 500000,
              //   //     stepCount: 25000,
              //   //     dob: "12-10-1992",
              //   //     gender: "female",
              //   //     maximumGuaranteedCoverage: 50000,
              //   //   },
              //   //   dependent: {
              //   //     total: 0,
              //   //     defaultRange: 0,
              //   //     maxRange: 10000,
              //   //     stepCount: 5000,
              //   //     dob: "12-10-2000",
              //   //     gender: "male",
              //   //   },
              //   // },
              // ];


              // this.cardBackground = ["#245cab", "#337e91"];
              // this.cardColors = ["#0F4C9F", "#136D84"];

              // this.staticData.map((data: any) => {
              //   this.updateFormData(data.type, 'member', { coverage: data.member.defaultRange })
              //   if (this.userForm.value.having_spouse === 'true') {
              //     this.updateFormData(data.type, 'spouse', { coverage: data.spouse.defaultRange });
              //   }
              //   if (this.userForm.value.having_dependent_children  === 'true') {
              //     this.updateFormData(data.type, 'dependent', { coverage: data.dependent.defaultRange });
              //   }
              // })

              this.plansData = result.data.packages;
              this.allFormData={}
              this.formData ={}
              sessionStorage.setItem('totalAmount', '0');
              sessionStorage.setItem('totalAmountUI', '0');

              sessionStorage.setItem('plansummary', '[]');
              sessionStorage.setItem('selectedPlans', '{}');
              sessionStorage.setItem('selectedPlansLife', '{}');
              sessionStorage.setItem('plansummarymain', '[]');
              sessionStorage.setItem('plansummaryopt', '[]');
              // sessionStorage.removeItem("paymentMethod")   ////here
              sessionStorage.removeItem('paymentfail');
              sessionStorage.setItem('plansummaryLife', '[]');
              sessionStorage.setItem('planssummarymainLife', '[]');
              sessionStorage.removeItem('addbenificaryData');
              sessionStorage.removeItem("Spousesignature")
              this.addbenificaryData =[];
              this.planAmount =''
              this.plansnexttab = true;
              let data:any = JSON.parse(sessionStorage.getItem("plansummary")|| '[]');
              let obj = {};

              if (data) {

                data.forEach((element:any,index:any) => {
                  obj = {
                    planid: element.id,
                  };

                  setTimeout(() => {

                    const dom: HTMLElement = this.elementRef.nativeElement;
                    const plan: any = dom.querySelector("#plancheck" + element.id);


                    if (element.options && element.options.length > 0) {
                      // $("#plancheck" + element.id).prop("checked", true);
                      element.products.forEach((products:any,index:any) => {
                        const planCheck = this.renderer.selectRootElement("#plancheck"+ products.id, true);

                        // console.log(planCheck)
                        this.renderer.setProperty(planCheck, 'checked', true);
                      })
                    } else {
                      element.products.forEach((products:any,index:any) => {
                        // $("#plancheck" + products.id).click();
                        const planCheck = this.renderer.selectRootElement("#plancheck"+ products.id, true);

                        // console.log(planCheck)
                        this.renderer.setProperty(planCheck, 'checked', true);
                      })
                    }

                    // $("#plancheck" + element.id).prop("checked", true);
                    // plan.checked =true
                  }, 100);

                  // this.plancheckarray.push(element.id)
                });

              }
              sessionStorage.setItem("stateid", result.data.province.id);
              sessionStorage.setItem(
                "maritalStatus",
                result.data.customer.maritalStatus
              );

              sessionStorage.setItem(
                "planDetailsArray",
                JSON.stringify(this.plansData)
              );
              setTimeout(() => {
                sessionStorage.setItem('Data', JSON.stringify(inputData));

                sessionStorage.setItem(
                  'enrollmentdate',
                  inputData.planEnrollmentDate
                );
              }, 2000);

              this.applicantSpousename = value.spousefirstname || "";
          this.applicantfirstname = inputData.firstName;

          // console.log(this.applicantfirstname)

              childrenarray.forEach((element:any) => {
              childarry.push(element.first_name);
            });
            this.applicantChildrenname = childarry || "";
              if (
                value.having_spouse == "true" &&
                value.having_dependent_children == "true"
              ) {
                this.completeapplicantinfo =
                  this.lang.Based_on_the_information_provided_you_your_spouse
                  +
                  " " +this.applicantSpousename +
                  " " +
                  this.lang.and_your_dependent_s+
                  " " +
                  this.applicantChildrenname +
                  " " +
                  this.lang.are_eligible_to_apply_for_the_following_plans;
              } else if (value.having_spouse == "true") {
                this.completeapplicantinfo =
                  this.lang.Based_on_the_information_provided_you_your_spouse +
                  " " +
                  this.applicantSpousename +
                  " " +
                  this.lang.are_eligible_to_apply_for_the_following_plans;
              } else if (value.having_dependent_children == "true") {
                this.completeapplicantinfo =
                  this.lang.Based_on_the_information_provided_you_and_your_dependent_s +
                  " " +
                  this.applicantChildrenname +
                  " " +
                  this.lang.are_eligible_to_apply_for_the_following_plans;
              } else {
                this.completeapplicantinfo = this.lang.Based_on_the_information_provided_you_are_eligible_to_apply_for_the_following_plans;
              }
              sessionStorage.setItem(
                "completeapplicantinfo",
                this.completeapplicantinfo
              );
            } else {


                this.ShowModelPopup =true
                this.ShowModelPopupMessage =result.message
                ? result.message
                : result.error
                this.ShowModelPopupTitle =this.lang.Info
            }
          },
          error: (error) => {
            if ([0, 500, 501, 502, 503].includes(error.status)) {

                this.ShowModelPopup =true
                this.ShowModelPopupMessage = this.lang.Sorry_the_server_is_currently_undergoing_maintenance_Please_try_again_later_We_apologize_for_your_inconvenience;
                this.ShowModelPopupTitle =this.lang.Info
            } else {



                this.ShowModelPopup =true
                this.ShowModelPopupMessage = error.error.message
                ? error.error.message
                : error.error.error
                this.ShowModelPopupTitle =this.lang.Info
            }
          },
        });
    }
  }

  public getSliderBackground(
    rangeValue: any,
    maxRange: any,
    stepCount: any,
    defaultRange:any
  ): any {
    const percentage = (rangeValue / maxRange) * 100;
    let gradientColor = "white";


    // if (percentage >= 8 && stepCount === 25000) {
    //   gradientColor = "green";
    // }
    // return `linear-gradient(to right, ${gradientColor} ${percentage}%, white ${percentage}%)`;



    if (rangeValue<= defaultRange) {
      this.greenValue =(defaultRange / maxRange)*100;
      this.names= [
        { label: 'Guaranteed', position: this.greenValue },    // Display "Start" at 0%
        { label: 'Pending', position: 100-this.greenValue}, // Display "Quarter" at 25%

      ];
      this.showyellowbar =false
      return `linear-gradient(to right, green ${(defaultRange / maxRange)*100}%, lightgray 0%)`;
    } else if (rangeValue > defaultRange) {
      const greenPart = adjustWith2DecimalsForUI((defaultRange / maxRange)*100);
      console.log(greenPart)
      this.yellowValue =adjustWith2DecimalsForUI((100-greenPart)*100);
      // const yellowPart = ((rangeValue - defaultRange) / 4.5) + greenPart;
      const yellowPart =adjustWith2DecimalsForUI((rangeValue / maxRange)*100)

      console.log(yellowPart)
      this.names= [
        { label: 'Guaranteed', position: greenPart },    // Display "Start" at 0%
        { label: 'Pending', position: yellowPart}, // Display "Quarter" at 25%

      ];
      this.showyellowbar =true
      return `linear-gradient(to right,  green 0%,
      green ${greenPart}%,
      yellow ${greenPart}%,
      yellow ${yellowPart}%,
      lightgray  ${yellowPart}%,
      lightgray  100%)`;
      // return`linear-gradient(to right, green ${greenPart}%, yellow ${yellowPart}%)`;
          // return `linear-gradient(to right, red 30%, yellow 70%)`;
      //  return `linear-gradient(to right, green 0%, green 30%, yellow 30%, yellow 100%)`  working
          //  return `linear-gradient(to right, green 0%, green ${greenPart}%, yellow ${greenPart}%, yellow 100%)`
      //  return `linear-gradient(to right, red ${greenPart}%, yellow ${yellowPart}%)`
      // return`linear-gradient(to right, green 0%, green ${greenPart}%, yellow ${yellowPart}%, yellow 100%)`;

    }
  }

  public isDataAvailable(type: string, category: string, feild?: string): boolean {
    // console.log(this.allFormData)
    // console.log(type)
    // console.log(category)
    // console.log(feild)
    const bool = feild ? !(this.allFormData[type] && this.allFormData[type][category] && this.allFormData[type][category][feild]) : !(this.allFormData[type] && this.allFormData[type][category]);
    return bool
  }
  public isDataAvailableSpouseDataa(type: string, category: string, feild?: string): boolean {
    console.log(this.allFormData)
    console.log(type)
    console.log(category)
    console.log(feild)

      // const bool = feild?this.allFormData[type] && this.allFormData[type][category]:false;
      // return bool

    const bool = feild ? !(this.allFormData[type] && this.allFormData[type][category] && this.allFormData[type][category][feild]) : !(this.allFormData[type] && this.allFormData[type][category]);
    return bool
  }
  public isDataAvailableSpouse(type: string, category: string, feild?: string): boolean {

    if(this.allFormData[type]){
    console.log(this.allFormData[type]['member'])
    }

    if(this.allFormData[type] && this.allFormData[type]['member']){
      return false
    }
    else{
      return true
    }


  }
  public generateTicks(maxRange: any, stepCount: any): any[] {
    const ticks = [];
    const totalTicks = maxRange / stepCount;

    for (let i = 0; i <= totalTicks; i++) {
      ticks.push(i * stepCount);
    }

    return ticks;
  }

  public updateTotal(data: any) {
    const memberPremium =
      data.member && data.member.premium > 0 ? data.member.premium : 0;
    const spousePremium =
      data.spouse && data.spouse.premium > 0 ? data.spouse.premium : 0;
    const dependentPremium =
      data.dependent && data.dependent.premium > 0 ? data.dependent.premium : 0;
    const total = memberPremium + spousePremium + dependentPremium;

    return total;
  }
  public updateFormData(
    type: string,
    category: string,
    newData: any,
    remove: boolean = false
  ): void {


    if (this.lastUpdatedType !== type) {
      this.formData = this.allFormData[type]
        ? { ...this.allFormData[type] }
        : {};
    }

    let updatedFormData = { ...this.formData };
    let shouldUpdateTotal = false;
console.log(updatedFormData)
    if (remove) {
      delete updatedFormData[category];
      delete updatedFormData.total;
    } else if (typeof newData === "object" && newData !== null) {
      updatedFormData[category] = { ...updatedFormData[category], ...newData };
      shouldUpdateTotal = "coverage" in newData || category === "dependent" || "isSmoker" in newData;
    } else {
      updatedFormData[category] = newData;
    }

    if (
      shouldUpdateTotal &&
      ["member", "spouse", "dependent"].includes(category)
    ) {
      updatedFormData.total = this.updateTotal(updatedFormData);
    }

    this.formData = updatedFormData;
    this.allFormData[type] = { ...this.formData };
    // this.allFormData = { ...this.allFormData, [type]: this.formData };
    this.lastUpdatedType = type;
    console.log("formData:", this.allFormData);


  }

  public calculateAge(dob: string) {
    const today = new Date();
    const birthDate = new Date(dob);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }

  public updatePremium(
    type: string,
    category: string,
    newIsSmokerValue: string
  ) {

    //  if (this.allFormData[type][category]) {
    //   const isSmokerValue = event.target.value=== "yes" ? "smoker" : "nonSmoker";

    //   console.log(isSmokerValue)
    //   let gender:any = this.userForm.value.gender?.toLowerCase()
    //   let value: any = 0;
    //   console.log(this.allFormData[type][category]["coverage"])
    //   value=this.allFormData[type][category]["coverage"][gender][isSmokerValue]
    //   console.log(value)
    //   const premium = (this.allFormData[type][category]["coverage"].maximumCoverageWithoutEvidence * value) / 1000;
    //   console.log(parseFloat(premium.toFixed(2)))
    //   this.updateFormData(type, category, {
    //     isSmoker: isSmokerValue,
    //     premium: parseFloat(premium.toFixed(2))
    //   });
    // }

    console.log(this.allFormData)
    // const coverage = this.allFormData[type][category].coverage.maximumCoverageWithoutEvidence;

    const coverage = this.allFormData[type][category].coverage.maximumCoverageWithoutEvidence/25000;
    const isSmoker = newIsSmokerValue === "yes" ? "smoker" : "nonSmoker";
    let value: any = 0;
    let gender:any = this.userForm.value.gender?.toLowerCase()
    let spouseGender:any = this.userForm.value.spousegender?.toLowerCase()

    console.log(spouseGender)

    if (category === "member") {
      value = this.allFormData[type][category]["coverage"]["price"][gender][isSmoker]
    }

    if (category === "spouse") {
      value = this.allFormData[type][category]["coverage"]["price"][spouseGender][isSmoker]
    }
    console.log({ value })

    this.updateFormData(type, category, { constant: value });

    if (coverage > 0) {
      // const premium = (coverage * value) / 1000;

      const premium = (coverage * value);
      return parseFloat(premium.toFixed(2));
    }
    console.log("formData:", this.allFormData);
    return 0;

  }

  public updateDependentPremium(type: string, category: string, coverage: number) {
    // const constant = coverage > 5000 ? 2.56 : 1.28;
    // this.updateFormData(type, category, { constant: constant });

    // const p =
    //   (constant * coverage) / 1000;
    // return parseFloat(p.toFixed(2));
  }

  public onCheckboxChangeP(
    event: any,
    type: string,
    category: string,
    key1: string,
    value1: any,
    key2?: string,
    value2?: any
  ): void {

console.log(this.allFormData)


    console.log(value1)
    console.log(value1?.member)
    // console.log(value1?.spouse)
    if(category=='member'){
      this.updateFormData(type, 'member', { coverage: value1.member })
    }
    if(category=='spouse'){
      if (this.userForm.value.having_spouse === 'true') {
        this.updateFormData(type, 'spouse', { coverage: value1.spouse });
      }
    }



    let planSummaryLife =JSON.parse(sessionStorage.getItem('plansummaryLife') || '[]');
    if (event.target.checked) {
      this.updateFormData(type, category, { [key1]: value1 });
      const dom: HTMLElement = this.elementRef.nativeElement;
      const plan: any = dom.querySelector("."+category+"-" + type + "-no");
      console.log(plan)
      setTimeout(() => {
        // plan.click()
      }, 100);


    } else {

      console.log(type)
      console.log(category)
      this.updateFormData(type, category, {}, true);

      if(category=='spouse'){
        this.updateFormData(type, 'member', {}, true);


        const planCheck = this.renderer.selectRootElement("#planCheckLife" + type+"-"+'MEMBER', true);
        this.renderer.setProperty(planCheck, 'checked', false);
      }
      if(category=='member'){
        this.updateFormData(type, 'spouse', {}, true);

        planSummaryLife.forEach((element:any,index:any) => {

         if(element.coverage=='MEMBER & SPOUSE'){
          const planCheck = this.renderer.selectRootElement("#planCheckLife" + type+"-"+'SPOUSE', true);
          this.renderer.setProperty(planCheck, 'checked', false);
         }

        })

      }
      this.unselectplanLife(event)
    }


  }

  public onInputChangeP(e: Event, type: string, category: string): void {
    const inputElement = e.target as HTMLInputElement;
    if (inputElement && this.allFormData[type][category]) {
      const obj = { [inputElement.name]: inputElement.value };
      this.updateFormData(type, category, obj);
    }
  }




  public handleRangeChangeForDependent(
    value: any,
    type: string,
    category: string
  ): void {
    // if (this.allFormData[type][category]) {
    //   this.updateFormData(type, category, {
    //     // coverage: value,
    //     premium: this.updateDependentPremium(type, category, value),
    //   });
    // }
  }

//   public handleRadioChange(event: any, type: string, category: string,options:any,plan:any,product:any,pckage:any,group:any,value:any): void {


//     // console.log(plan)
//     // console.log(product)
//     // console.log(pckage)
//     // console.log(group)

//     let isSmokerValue = event.target.value;
//     this.allFormData[type][category].coverage=value

//     if (this.allFormData[type][category]) {

//       this.updateFormData(type, category, {
//         isSmoker: isSmokerValue,
//         premium: this.updatePremium(type, category, isSmokerValue),
//       });
//     }
//     this.rangeValues = [];
//     console.log(this.allFormData)
//     let plansummaryLife = JSON.parse(sessionStorage.getItem('plansummaryLife') || '[]');
// let lifeCoverage=''
// let memberObj:any={}
// let spouseObj:any={}

// if(category=="member"){
//   lifeCoverage="MEMBER"
// }
// else{
// lifeCoverage="MEMBER & SPOUSE"
// }


//     let plandetails = event.target.attributes.data.value;

//     let plandetailsobj = plandetails.split('##');


//     plansummaryLife.forEach((element:any,index:any) => {

//       if(element.planname==plandetailsobj[14]){
//         plansummaryLife.splice(index,1)
//       }
//       sessionStorage.setItem('plansummaryLife', JSON.stringify(plansummaryLife));

//     })

//     setTimeout(() => {
//       // console.log(this.allFormData[type][category].premium)

//       let obj: any = {
//         isBundle: plan.isBundle,
//         // enrollmentDate: moment(
//         //   this.userForm.get("planenrollmentdate").value,
//         //   "MM-DD-YYYY"
//         // ).format("YYYY-MM-DD"),
//         enrollmentDate: this.userForm.value.planEnrollmentDate
//           ? this._CustomDatePipe.transform(
//               this.userForm.value.planEnrollmentDate
//             )
//           : undefined,
//         // "name":plandetailsobj[1]+"-"+plandetailsobj[2],
//         name: plandetailsobj[14],
//         details: plandetailsobj[0],
//         packagename: plandetailsobj[0],
//         groupName: plandetailsobj[1],
//         amount: this.allFormData[type].total,
//         // amount: this.allFormData[type][category].premium,
//         planCoverage: plandetailsobj[2],
//         planPrice: adjustWith2DecimalsForUI(this.allFormData[type].total),
//         amountUI:
//           '$' +
//           adjustWith2DecimalsForUI(this.allFormData[type].total),
//         gst:
//           plandetailsobj[4] == null ||
//           plandetailsobj[4] == '' ||
//           plandetailsobj[4] == undefined ||
//           plandetailsobj[4] == 'null'
//             ? 0
//             : parseFloat(plandetailsobj[4]),
//         hst:
//           plandetailsobj[5] == null ||
//           plandetailsobj[5] == '' ||
//           plandetailsobj[5] == undefined ||
//           plandetailsobj[5] == 'null'
//             ? 0
//             : parseFloat(plandetailsobj[5]),
//         pst:
//           plandetailsobj[6] == null ||
//           plandetailsobj[6] == '' ||
//           plandetailsobj[6] == undefined ||
//           plandetailsobj[6] == 'null'
//             ? 0
//             : parseFloat(plandetailsobj[6]),
//         qst:
//           plandetailsobj[17] == null ||
//           plandetailsobj[17] == '' ||
//           plandetailsobj[17] == undefined ||
//           plandetailsobj[17] == 'null'
//             ? 0
//             : parseFloat(plandetailsobj[17]),
//         gstCheck:
//           plandetailsobj[4] == null ||
//           plandetailsobj[4] == '' ||
//           plandetailsobj[4] == undefined ||
//           plandetailsobj[4] == 'null'
//             ? false
//             : true,
//         hstCheck:
//           plandetailsobj[5] == null ||
//           plandetailsobj[5] == '' ||
//           plandetailsobj[5] == undefined ||
//           plandetailsobj[5] == 'null'
//             ? false
//             : true,
//         pstCheck:
//           plandetailsobj[6] == null ||
//           plandetailsobj[6] == '' ||
//           plandetailsobj[6] == undefined ||
//           plandetailsobj[6] == 'null'
//             ? false
//             : true,
//         qstCheck:
//           plandetailsobj[17] == null ||
//           plandetailsobj[17] == '' ||
//           plandetailsobj[17] == undefined ||
//           plandetailsobj[17] == 'null'
//             ? false
//             : true,
//         id: parseFloat(plandetailsobj[7]),
//         fusebillPlanID:
//           plandetailsobj[8] == null ||
//           plandetailsobj[8] == '' ||
//           plandetailsobj[8] == undefined ||
//           plandetailsobj[8] == 'null'
//             ? 0
//             : parseFloat(plandetailsobj[8]),
//         planFrequencyID:
//           plandetailsobj[9] == null ||
//           plandetailsobj[9] == '' ||
//           plandetailsobj[9] == undefined ||
//           plandetailsobj[9] == 'null'
//             ? 0
//             : parseFloat(plandetailsobj[9]),
//         optIn: plandetailsobj[10] == 'true' ? true : false,
//         planname: plandetailsobj[14],
//         planLevel: parseInt(plandetailsobj[15]),
//         packageId: parseInt(plandetailsobj[16]),
//         options: [],
//         version:plan.version,
//         description: plan.description,
//         insuranceType:plan.insuranceType

//         //  "disallowedPlanLevels":plandetailsobj[11]
//       };
//       if (plandetailsobj[11] != null || plandetailsobj[11] != 'null') {
//         if (plandetailsobj[11].includes(plandetailsobj[12])) {
//         }
//       }
//      ///this.consoleMessage(obj)
//       this.productobj = {
//         id: product.id,
//         productId: product.productId,
//         name: product.name,
//         planProductId: product.planProductId,
//         price: this.allFormData[type].total,
//         tax: this._decimalPipe
//           .transform(
//             product.calculatedTax ? product.calculatedTax.tax : 0,
//             '1.2-2'
//           )
//           ?.replace(/,/g, ''),
//         total: this._decimalPipe
//           .transform(
//             product.calculatedTax ? product.calculatedTax.total : 0,
//             '1.2-2'
//           )
//           ?.replace(/,/g, ''),
//         tax_details:
//           product.bundledTaxes && product.bundledTaxes.length > 0
//             ? product.bundledTaxes
//             : product.taxesDataJSON,
//         calculatedTax: this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]),
//         bundledProducts: product.bundledProducts,
//         planCoverage: lifeCoverage,
//         planLevel: product.planlevel ? product.planlevel.id : '',
//         planLevelParent: product.planlevel ? product.planlevel.parentId : '',
//         description: product.description,
//         coverage: lifeCoverage,
//         version:product.version
//       };

//       this.productobj.total = this._decimalPipe
//       .transform(
//         this.productobj.calculatedTax ? this.productobj.calculatedTax.total : 0,
//         '1.2-2'
//       )
//       ?.replace(/,/g, '')
//       this.productobj.tax = this._decimalPipe
//       .transform(
//         this.productobj.calculatedTax ? this.productobj.calculatedTax.tax : 0,
//         '1.2-2'
//       )
//       ?.replace(/,/g, '')

//       if(category=="member"){

//         memberObj={
//       "planStartDate":this.userForm.value.planEnrollmentDate
//       ? this._CustomDatePipe.transform(
//           this.userForm.value.planEnrollmentDate
//         )
//       : undefined,
//       "units":(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
//       "standardUnits":this.allFormData[type]["member"].coverage.minimumCoverage,
//       "coverage":"MEMBER",
//       "guaranteedAcceptance":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >=
//       this.allFormData[type]["member"].coverage.defaultRange?true:false, //defalutRange
//       "pendingMedicalQuestionaire":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <
//       this.allFormData[type]["member"].coverage.defaultRange?true:false,
//       "smoker":this.allFormData[type]["member"].isSmoker=="yes"?true:false,
//       "age":this.allFormData[type]["member"].coverage.age,
//       "gender":this.userForm.value.gender?.toLowerCase(),
//       "province":"ON",
//       "provinceId":12,
//       "amount":this.allFormData[type]["member"].premium,
//       "tax": adjustWith2DecimalsForUI(this.productobj.tax),
//       "taxRate":"", //not mandatory,
//       "taxName":"",
//       "coverageValue":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
//       "premium":this.allFormData[type]["member"].constant,
//       "total":adjustWith2DecimalsForUI(this.productobj.total),
//       "coverageUnitPrice":this.allFormData[type]["member"].coverage.coverageUnitPrice,
//       "defaultRange":this.allFormData[type]["member"].coverage.defaultRange,
//       "maximumCoverageWithEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithEvidence,
//       "maximumCoverageWithoutEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
//       "minimumCoverage":this.allFormData[type]["member"].coverage.minimumCoverage,
//         }
//         obj["member"]= memberObj
//         obj["spouse"]= {}
//       }
//       else{

//         memberObj={
//           "planStartDate":this.userForm.value.planEnrollmentDate
//       ? this._CustomDatePipe.transform(
//           this.userForm.value.planEnrollmentDate
//         )
//       : undefined,
//           "units":(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
//           "standardUnits":this.allFormData[type]["member"].coverage.minimumCoverage,
//           "coverage":"MEMBER",
//           "guaranteedAcceptance":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >=
//           this.allFormData[type]["member"].coverage.defaultRange?true:false, //defalutRange
//           "pendingMedicalQuestionaire":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <
//           this.allFormData[type]["member"].coverage.defaultRange?true:false,
//           "smoker":this.allFormData[type]["member"].isSmoker=="yes"?true:false,
//           "age":this.allFormData[type]["member"].coverage.age,
//           "gender":this.userForm.value.gender?.toLowerCase(),
//           "province":"ON",
//           "provinceId":12,
//           "amount":this.allFormData[type]["member"].premium,
//           "tax": adjustWith2DecimalsForUI(this.productobj.tax),
//           "taxRate":"", //not mandatory,
//           "taxName":"",
//           "coverageValue":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
//           "premium":this.allFormData[type]["member"].constant,
//           "total":adjustWith2DecimalsForUI(this.productobj.total),
//           "coverageUnitPrice":this.allFormData[type]["member"].coverage.coverageUnitPrice,
//           "defaultRange":this.allFormData[type]["member"].coverage.defaultRange,
//           "maximumCoverageWithEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithEvidence,
//           "maximumCoverageWithoutEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
//           "minimumCoverage":this.allFormData[type]["member"].coverage.minimumCoverage,
//             }
//             spouseObj={
//               "planStartDate":this.userForm.value.planEnrollmentDate
//       ? this._CustomDatePipe.transform(
//           this.userForm.value.planEnrollmentDate
//         )
//       : undefined,
//               "units":(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
//               "standardUnits":this.allFormData[type]["spouse"].coverage.minimumCoverage,
//               "coverage":"MEMBER & SPOUSE",
//               "guaranteedAcceptance":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >=
//       this.allFormData[type]["spouse"].coverage.defaultRange?true:false, //defalutRange
//       "pendingMedicalQuestionaire":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <
//       this.allFormData[type]["spouse"].coverage.defaultRange?true:false,
//               "smoker":this.allFormData[type]["spouse"].isSmoker=="yes"?true:false,
//               "age":this.allFormData[type]["spouse"].coverage.age,
//               "gender":this.userForm.value.spousegender?.toLowerCase(),
//               "province":"ON",
//               "provinceId":12,
//               "amount":this.allFormData[type]["spouse"].premium,
//               "tax":adjustWith2DecimalsForUI(this.productobj.tax),
//               "taxRate":"", //not mandatory,
//               "taxName":"",
//               "coverageValue":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
//               "premium":this.allFormData[type]["spouse"].constant,
//               "total":adjustWith2DecimalsForUI(this.productobj.total),
//               "coverageUnitPrice":this.allFormData[type]["spouse"].coverage.coverageUnitPrice,
//               "defaultRange":this.allFormData[type]["spouse"].coverage.defaultRange,
//               "maximumCoverageWithEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithEvidence,
//               "maximumCoverageWithoutEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
//               "minimumCoverage":this.allFormData[type]["spouse"].coverage.minimumCoverage,
//                 }

//       obj["member"]= memberObj
//       obj["spouse"]= spouseObj
//       }

//       let obj1: any = {
//         packageId: plan.packageId,
//         packageName: pckage.name,

//         planproductname: this.productobj.name,

//         groupid: plan.planLevel,
//         groupName: group.name,
//         id: plan.id,
//         name: plan.name,
//         planLevel: plan.planLevel ? plan.planLevel : group.id,
//         planLevelParent: group.parentId,
//         fusebillPlanID: plan.fusebillId,
//         planFrequencyID: plan.frqMonthly,
//         isBundle: plan.isBundle,
//         coverage: lifeCoverage,
//         planCoverage: product.planCoverage,
//         bundledProducts: [],
//         products: [],
//         version:plan.version

//       };
//       obj1.products.push(this.productobj);
//       //this.consoleMessage(obj1)
//       // console.log(obj)
//       // console.log(obj1)
//       this.addtoplansummaryLife(obj, obj1);
//     }, 100);
//     for (let i = this.minValue; i <= this.allFormData[type][category]?.coverage?.maximumCoverageWithEvidence; i += 50000) {
//       this.rangeValues.push(i);
//     }

//   }
// public handleRangeChange(value: any, type: string, category: string,options:any,plan:any,product:any,pckage:any,group:any,event:any,data:any): void {
//   // console.log(value)
//   console.log(type)
//   console.log(event)
//   this.allFormData[type][category].coverage=data

//   // this.sliderValue = this.allFormData[type][category].coverage.maximumCoverageWithoutEvidence


//   this.rangeValues = [];

//   // return values;

//   // this.minimumCoverage =this.allFormData[type][category].coverage.defaultRange

//   // this.minimumCoverage = this.allFormData[type][category].coverage.maximumCoverageWithEvidence - this.allFormData[type][category].coverage.maximumCoverageWithoutEvidence
//   // this.extraCoverage = this.allFormData[type][category].coverage.maximumCoverageWithoutEvidence
//   // if (this.coverageValue > this.minimumCoverage) {
//   //   this.extraCoverage = this.coverageValue - this.minimumCoverage;
//   // } else {
//   //   this.extraCoverage = 0;
//   // }



//   if (this.allFormData[type][category]) {
//     this.updateFormData(type, category, {
//       // coverage: value,
//       isSmoker: this.allFormData[type][category].isSmoker,
//       premium: this.updatePremium(type, category, this.allFormData[type][category].isSmoker),
//     });
//   }
//   let lifeCoverage=''
//   let memberObj:any={}
// let spouseObj:any={}
// if(category=="member"){
// lifeCoverage="MEMBER"
// }else{
// lifeCoverage="MEMBER & SPOUSE"
// }
//   console.log(this.plansData)
//   let plansummaryLife = JSON.parse(sessionStorage.getItem('plansummaryLife') || '[]');


//   let plandetails = event.target.attributes.data.value;

//   let plandetailsobj = plandetails.split('##');


//   plansummaryLife.forEach((element:any,index:any) => {

//     if(element.planname==plandetailsobj[14]){
//       plansummaryLife.splice(index,1)
//     }
//     sessionStorage.setItem('plansummaryLife', JSON.stringify(plansummaryLife));

//   })
//   setTimeout(() => {
//     // console.log(this.allFormData[type][category].premium)

//     let obj: any = {
//       isBundle: plan.isBundle,
//       // enrollmentDate: moment(
//       //   this.userForm.get("planenrollmentdate").value,
//       //   "MM-DD-YYYY"
//       // ).format("YYYY-MM-DD"),
//       enrollmentDate: this.userForm.value.planEnrollmentDate
//         ? this._CustomDatePipe.transform(
//             this.userForm.value.planEnrollmentDate
//           )
//         : undefined,
//       // "name":plandetailsobj[1]+"-"+plandetailsobj[2],
//       name: plandetailsobj[14],
//       details: plandetailsobj[0],
//       packagename: plandetailsobj[0],
//       groupName: plandetailsobj[1],
//       amount: this.allFormData[type].total,
//       // amount: this.allFormData[type][category].premium,
//       planCoverage: plandetailsobj[2],
//       planPrice: adjustWith2DecimalsForUI(this.allFormData[type].total),
//       amountUI:
//         '$' +
//         adjustWith2DecimalsForUI(this.allFormData[type].total),
//       gst:
//         plandetailsobj[4] == null ||
//         plandetailsobj[4] == '' ||
//         plandetailsobj[4] == undefined ||
//         plandetailsobj[4] == 'null'
//           ? 0
//           : parseFloat(plandetailsobj[4]),
//       hst:
//         plandetailsobj[5] == null ||
//         plandetailsobj[5] == '' ||
//         plandetailsobj[5] == undefined ||
//         plandetailsobj[5] == 'null'
//           ? 0
//           : parseFloat(plandetailsobj[5]),
//       pst:
//         plandetailsobj[6] == null ||
//         plandetailsobj[6] == '' ||
//         plandetailsobj[6] == undefined ||
//         plandetailsobj[6] == 'null'
//           ? 0
//           : parseFloat(plandetailsobj[6]),
//       qst:
//         plandetailsobj[17] == null ||
//         plandetailsobj[17] == '' ||
//         plandetailsobj[17] == undefined ||
//         plandetailsobj[17] == 'null'
//           ? 0
//           : parseFloat(plandetailsobj[17]),
//       gstCheck:
//         plandetailsobj[4] == null ||
//         plandetailsobj[4] == '' ||
//         plandetailsobj[4] == undefined ||
//         plandetailsobj[4] == 'null'
//           ? false
//           : true,
//       hstCheck:
//         plandetailsobj[5] == null ||
//         plandetailsobj[5] == '' ||
//         plandetailsobj[5] == undefined ||
//         plandetailsobj[5] == 'null'
//           ? false
//           : true,
//       pstCheck:
//         plandetailsobj[6] == null ||
//         plandetailsobj[6] == '' ||
//         plandetailsobj[6] == undefined ||
//         plandetailsobj[6] == 'null'
//           ? false
//           : true,
//       qstCheck:
//         plandetailsobj[17] == null ||
//         plandetailsobj[17] == '' ||
//         plandetailsobj[17] == undefined ||
//         plandetailsobj[17] == 'null'
//           ? false
//           : true,
//       id: parseFloat(plandetailsobj[7]),
//       fusebillPlanID:
//         plandetailsobj[8] == null ||
//         plandetailsobj[8] == '' ||
//         plandetailsobj[8] == undefined ||
//         plandetailsobj[8] == 'null'
//           ? 0
//           : parseFloat(plandetailsobj[8]),
//       planFrequencyID:
//         plandetailsobj[9] == null ||
//         plandetailsobj[9] == '' ||
//         plandetailsobj[9] == undefined ||
//         plandetailsobj[9] == 'null'
//           ? 0
//           : parseFloat(plandetailsobj[9]),
//       optIn: plandetailsobj[10] == 'true' ? true : false,
//       planname: plandetailsobj[14],
//       planLevel: parseInt(plandetailsobj[15]),
//       packageId: parseInt(plandetailsobj[16]),
//       options: [],
//       version:plan.version,
//       description: plan.description,
//       insuranceType:plan.insuranceType

//       //  "disallowedPlanLevels":plandetailsobj[11]
//     };
//     if (plandetailsobj[11] != null || plandetailsobj[11] != 'null') {
//       if (plandetailsobj[11].includes(plandetailsobj[12])) {
//       }
//     }
//    ///this.consoleMessage(obj)
//     this.productobj = {
//       id: product.id,
//       productId: product.productId,
//       name: product.name,
//       planProductId: product.planProductId,
//       price: this.allFormData[type].total,
//       tax: this._decimalPipe
//         .transform(
//           product.calculatedTax ? product.calculatedTax.tax : 0,
//           '1.2-2'
//         )
//         ?.replace(/,/g, ''),
//       total: this._decimalPipe
//         .transform(
//           product.calculatedTax ? product.calculatedTax.total : 0,
//           '1.2-2'
//         )
//         ?.replace(/,/g, ''),
//       tax_details:
//         product.bundledTaxes && product.bundledTaxes.length > 0
//           ? product.bundledTaxes
//           : product.taxesDataJSON,
//       calculatedTax: this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]),
//       bundledProducts: product.bundledProducts,
//       planCoverage: lifeCoverage,
//       planLevel: product.planlevel ? product.planlevel.id : '',
//       planLevelParent: product.planlevel ? product.planlevel.parentId : '',
//       description: product.description,
//       coverage: lifeCoverage,
//       version:product.version
//     };

//     this.productobj.total = this._decimalPipe
//     .transform(
//       this.productobj.calculatedTax ? this.productobj.calculatedTax.total : 0,
//       '1.2-2'
//     )
//     ?.replace(/,/g, '')
//     this.productobj.tax = this._decimalPipe
//     .transform(
//       this.productobj.calculatedTax ? this.productobj.calculatedTax.tax : 0,
//       '1.2-2'
//     )
//     ?.replace(/,/g, '')
//     // console.log(this.allFormData)
//     // console.log(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence )
//     // console.log(this.allFormData[type]["member"].coverage.defaultRange)
//     // console.log((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000)

//     // console.log(((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000)*this.allFormData[type][category].constant)
//     if(category=="member"){

//       memberObj={
//     "planStartDate":this.userForm.value.planEnrollmentDate
//     ? this._CustomDatePipe.transform(
//         this.userForm.value.planEnrollmentDate
//       )
//     : undefined,
//     "units":(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
//     "standardUnits":this.allFormData[type]["member"].coverage.minimumCoverage,
//     "coverage":"MEMBER",
//     "guaranteedAcceptance":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >=
//     this.allFormData[type]["member"].coverage.defaultRange?true:false, //defalutRange
//     "pendingMedicalQuestionaire":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=
//     this.allFormData[type]["member"].coverage.defaultRange?true:false,


//     "guaranteedAcceptanceAmount":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),
//     "pendingApprovalAmount":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000)*this.allFormData[type]["member"].constant:0 ||0,
//     "smoker":this.allFormData[type]["member"].isSmoker=="yes"?true:false,
//     "age":this.allFormData[type]["member"].coverage.age,
//     "gender":this.userForm.value.gender?.toLowerCase(),
//     "province":"ON",
//     "provinceId":12,
//     "amount":this.allFormData[type]["member"].premium,
//     "tax": adjustWith2DecimalsForUI(this.productobj.tax),
//     "taxRate":"", //not mandatory,
//     "taxName":"",
//     "coverageValue":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
//     "premium":this.allFormData[type]["member"].constant,
//     "total":adjustWith2DecimalsForUI(this.productobj.total),
//     "coverageUnitPrice":this.allFormData[type]["member"].coverage.coverageUnitPrice,
//     "defaultRange":this.allFormData[type]["member"].coverage.defaultRange,
//     "maximumCoverageWithEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithEvidence,
//     "maximumCoverageWithoutEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
//     "minimumCoverage":this.allFormData[type]["member"].coverage.minimumCoverage,
//       }
//       memberObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).tax
//       memberObj["guaranteedAcceptanceTotal"] =this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).total

//       memberObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000)*this.allFormData[type]["member"].constant:0,product.taxesDataJSON??[]).tax
//       memberObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000)*this.allFormData[type]["member"].constant:0,product.taxesDataJSON??[]).total

//       obj["member"]= memberObj
//       obj["spouse"]= {}

//       if(this.allFormData[type]['spouse']){
//         spouseObj={
//           "planStartDate":this.userForm.value.planEnrollmentDate
//   ? this._CustomDatePipe.transform(
//       this.userForm.value.planEnrollmentDate
//     )
//   : undefined,
//           "units":(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
//           "standardUnits":this.allFormData[type]["spouse"].coverage.minimumCoverage,
//           "coverage":"MEMBER & SPOUSE",
//           "guaranteedAcceptance":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >=
//   this.allFormData[type]["spouse"].coverage.defaultRange?true:false, //defalutRange
//   "pendingMedicalQuestionaire":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <
//   this.allFormData[type]["spouse"].coverage.defaultRange?true:false,
//   "guaranteedAcceptanceAmount":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type]["spouse"].constant),
//   "pendingApprovalAmount":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["spouse"].coverage.defaultRange)/25000)*this.allFormData[type]["spouse"].constant:0 ||0,
//           "smoker":this.allFormData[type]["spouse"].isSmoker=="yes"?true:false,
//           "age":this.allFormData[type]["spouse"].coverage.age,
//           "gender":this.userForm.value.spousegender?.toLowerCase(),
//           "province":"ON",
//           "provinceId":12,
//           "amount":this.allFormData[type]["spouse"].premium,
//           "tax":adjustWith2DecimalsForUI(this.productobj.tax),
//           "taxRate":"", //not mandatory,
//           "taxName":"",
//           "coverageValue":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
//           "premium":this.allFormData[type]["spouse"].constant,
//           "total":adjustWith2DecimalsForUI(this.productobj.total),
//           "coverageUnitPrice":this.allFormData[type]["spouse"].coverage.coverageUnitPrice,
//           "defaultRange":this.allFormData[type]["spouse"].coverage.defaultRange,
//           "maximumCoverageWithEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithEvidence,
//           "maximumCoverageWithoutEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
//           "minimumCoverage":this.allFormData[type]["spouse"].coverage.minimumCoverage,
//             }






//         obj["member"]= memberObj
//         obj["spouse"]= spouseObj

//         spouseObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type]["spouse"].constant),product.taxesDataJSON??[]).tax
//         spouseObj["guaranteedAcceptanceTotal"] =this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type]["spouse"].constant),product.taxesDataJSON??[]).total

//         spouseObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["spouse"].coverage.defaultRange)/25000)*this.allFormData[type]["spouse"].constant:0,product.taxesDataJSON??[]).tax
//         spouseObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["spouse"].coverage.defaultRange)/25000)*this.allFormData[type]["spouse"].constant:0,product.taxesDataJSON??[]).total
//           }




//     }
//     else{

//       memberObj={
//         "planStartDate":this.userForm.value.planEnrollmentDate
//     ? this._CustomDatePipe.transform(
//         this.userForm.value.planEnrollmentDate
//       )
//     : undefined,
//         "units":(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
//         "standardUnits":this.allFormData[type]["member"].coverage.minimumCoverage,
//         "coverage":"MEMBER",
//         "guaranteedAcceptance":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >=
//         this.allFormData[type]["member"].coverage.defaultRange?true:false, //defalutRange
//         "pendingMedicalQuestionaire":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <
//         this.allFormData[type]["member"].coverage.defaultRange?true:false,
//         "guaranteedAcceptanceAmount":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),
//         "pendingApprovalAmount":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000)*this.allFormData[type]["member"].constant:0 ||0,
//         "smoker":this.allFormData[type]["member"].isSmoker=="yes"?true:false,
//         "age":this.allFormData[type]["member"].coverage.age,
//         "gender":this.userForm.value.gender?.toLowerCase(),
//         "province":"ON",
//         "provinceId":12,
//         "amount":this.allFormData[type]["member"].premium,
//         "tax":adjustWith2DecimalsForUI(this.productobj.tax),
//         "taxRate":"", //not mandatory,
//         "taxName":"",
//         "coverageValue":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
//         "premium":this.allFormData[type]["member"].constant,
//         "total":adjustWith2DecimalsForUI(this.productobj.total),
//         "coverageUnitPrice":this.allFormData[type]["member"].coverage.coverageUnitPrice,
//     "defaultRange":this.allFormData[type]["member"].coverage.defaultRange,
//     "maximumCoverageWithEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithEvidence,
//     "maximumCoverageWithoutEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
//     "minimumCoverage":this.allFormData[type]["member"].coverage.minimumCoverage,
//           }
//           spouseObj={
//             "planStartDate":this.userForm.value.planEnrollmentDate
//     ? this._CustomDatePipe.transform(
//         this.userForm.value.planEnrollmentDate
//       )
//     : undefined,
//             "units":(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
//             "standardUnits":this.allFormData[type]["spouse"].coverage.minimumCoverage,
//             "coverage":"MEMBER & SPOUSE",
//             "guaranteedAcceptance":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >=
//     this.allFormData[type]["spouse"].coverage.defaultRange?true:false, //defalutRange
//     "pendingMedicalQuestionaire":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <
//     this.allFormData[type]["spouse"].coverage.defaultRange?true:false,
//     "guaranteedAcceptanceAmount":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type][category].constant),
//     "pendingApprovalAmount":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["spouse"].coverage.defaultRange)/25000)*this.allFormData[type]["spouse"].constant:0 ||0,
//     "smoker":this.allFormData[type]["spouse"].isSmoker=="yes"?true:false,
//             "age":this.allFormData[type]["spouse"].coverage.age,
//             "gender":this.userForm.value.spousegender?.toLowerCase(),
//             "province":"ON",
//             "provinceId":12,
//             "amount":this.allFormData[type]["spouse"].premium,
//             "tax":adjustWith2DecimalsForUI(this.productobj.tax),
//             "taxRate":"", //not mandatory,
//             "taxName":"",
//             "coverageValue":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
//             "premium":this.allFormData[type]["spouse"].constant,
//             "total":adjustWith2DecimalsForUI(this.productobj.total),
//             "coverageUnitPrice":this.allFormData[type]["spouse"].coverage.coverageUnitPrice,
//     "defaultRange":this.allFormData[type]["spouse"].coverage.defaultRange,
//     "maximumCoverageWithEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithEvidence,
//     "maximumCoverageWithoutEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
//     "minimumCoverage":this.allFormData[type]["spouse"].coverage.minimumCoverage,
//               }

//     obj["member"]= memberObj
//     obj["spouse"]= spouseObj
//     memberObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).tax
//     memberObj["guaranteedAcceptanceTotal"] =this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).total
//     spouseObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type]["spouse"].constant),product.taxesDataJSON??[]).tax
//     spouseObj["guaranteedAcceptanceTotal"] =this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type]["sposue"].constant),product.taxesDataJSON??[]).total

//     memberObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000)*this.allFormData[type]["member"].constant:0,product.taxesDataJSON??[]).tax
//     memberObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000)*this.allFormData[type]["member"].constant:0,product.taxesDataJSON??[]).total

//     spouseObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000)*this.allFormData[type]["member"].constant:0,product.taxesDataJSON??[]).tax
//     spouseObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000)*this.allFormData[type]["member"].constant:0,product.taxesDataJSON??[]).total

//     }

//     let obj1: any = {
//       packageId: plan.packageId,
//       packageName: pckage.name,

//       planproductname: this.productobj.name,

//       groupid: plan.planLevel,
//       groupName: group.name,
//       id: plan.id,
//       name: plan.name,
//       planLevel: plan.planLevel ? plan.planLevel : group.id,
//       planLevelParent: group.parentId,
//       fusebillPlanID: plan.fusebillId,
//       planFrequencyID: plan.frqMonthly,
//       isBundle: plan.isBundle,
//       coverage: lifeCoverage,
//       planCoverage: product.planCoverage,
//       bundledProducts: [],
//       products: [],
//       version:plan.version

//     };
//     obj1.products.push(this.productobj);
//     //this.consoleMessage(obj1)
//     // console.log(obj)
//     // console.log(obj1)
//     this.addtoplansummaryLife(obj, obj1);
//   }, 100);
//   for (let i = this.minValue; i <= this.allFormData[type][category]?.coverage?.maximumCoverageWithEvidence; i += 50000) {
//     this.rangeValues.push(i);
//   }
// }
// public handleRadioChange(event: any, type: string, category: string,options:any,plan:any,product:any,pckage:any,group:any,value:any): void {


//   // console.log(plan)
//   this.rangeValues =[]

//   let isSmokerValue = event.target.value;
//   this.allFormData[type][category].coverage=value

//   if (this.allFormData[type][category]) {

//     this.updateFormData(type, category, {
//       isSmoker: isSmokerValue,
//       premium: this.updatePremium(type, category, isSmokerValue),
//     });
//   }

//   console.log(this.allFormData)
//   let plansummaryLife = JSON.parse(sessionStorage.getItem('plansummaryLife') || '[]');
// let lifeCoverage=''
// let memberObj:any={}
// let spouseObj:any={}

// if(category=="member"){
// lifeCoverage="MEMBER"
// }
// else{
// lifeCoverage="MEMBER & SPOUSE"
// }

// if(this.allFormData[type]['spouse']){
//   lifeCoverage="MEMBER & SPOUSE"

// }

//   let plandetails = event.target.attributes.data.value;

//   let plandetailsobj = plandetails.split('##');


//   plansummaryLife.forEach((element:any,index:any) => {

//     if(element.planname==plandetailsobj[14]){
//       plansummaryLife.splice(index,1)
//     }
//     sessionStorage.setItem('plansummaryLife', JSON.stringify(plansummaryLife));

//   })

//   setTimeout(() => {
//     // console.log(this.allFormData[type][category].premium)

//     let obj: any = {
//       isBundle: plan.isBundle,
//       // enrollmentDate: moment(
//       //   this.userForm.get("planenrollmentdate").value,
//       //   "MM-DD-YYYY"
//       // ).format("YYYY-MM-DD"),
//       enrollmentDate: this.userForm.value.planEnrollmentDate
//         ? this._CustomDatePipe.transform(
//             this.userForm.value.planEnrollmentDate
//           )
//         : undefined,
//       // "name":plandetailsobj[1]+"-"+plandetailsobj[2],
//       name: plandetailsobj[14],
//       details: plandetailsobj[0],
//       packagename: plandetailsobj[0],
//       groupName: plandetailsobj[1],
//       amount: this.allFormData[type].total,
//       // amount: this.allFormData[type][category].premium,
//       planCoverage: plandetailsobj[2],
//       planPrice: adjustWith2DecimalsForUI(this.allFormData[type].total),
//       amountUI:
//         '$' +
//         adjustWith2DecimalsForUI(this.allFormData[type].total),
//       gst:
//         plandetailsobj[4] == null ||
//         plandetailsobj[4] == '' ||
//         plandetailsobj[4] == undefined ||
//         plandetailsobj[4] == 'null'
//           ? 0
//           : parseFloat(plandetailsobj[4]),
//       hst:
//         plandetailsobj[5] == null ||
//         plandetailsobj[5] == '' ||
//         plandetailsobj[5] == undefined ||
//         plandetailsobj[5] == 'null'
//           ? 0
//           : parseFloat(plandetailsobj[5]),
//       pst:
//         plandetailsobj[6] == null ||
//         plandetailsobj[6] == '' ||
//         plandetailsobj[6] == undefined ||
//         plandetailsobj[6] == 'null'
//           ? 0
//           : parseFloat(plandetailsobj[6]),
//       qst:
//         plandetailsobj[17] == null ||
//         plandetailsobj[17] == '' ||
//         plandetailsobj[17] == undefined ||
//         plandetailsobj[17] == 'null'
//           ? 0
//           : parseFloat(plandetailsobj[17]),
//       gstCheck:
//         plandetailsobj[4] == null ||
//         plandetailsobj[4] == '' ||
//         plandetailsobj[4] == undefined ||
//         plandetailsobj[4] == 'null'
//           ? false
//           : true,
//       hstCheck:
//         plandetailsobj[5] == null ||
//         plandetailsobj[5] == '' ||
//         plandetailsobj[5] == undefined ||
//         plandetailsobj[5] == 'null'
//           ? false
//           : true,
//       pstCheck:
//         plandetailsobj[6] == null ||
//         plandetailsobj[6] == '' ||
//         plandetailsobj[6] == undefined ||
//         plandetailsobj[6] == 'null'
//           ? false
//           : true,
//       qstCheck:
//         plandetailsobj[17] == null ||
//         plandetailsobj[17] == '' ||
//         plandetailsobj[17] == undefined ||
//         plandetailsobj[17] == 'null'
//           ? false
//           : true,
//       id: parseFloat(plandetailsobj[7]),
//       fusebillPlanID:
//         plandetailsobj[8] == null ||
//         plandetailsobj[8] == '' ||
//         plandetailsobj[8] == undefined ||
//         plandetailsobj[8] == 'null'
//           ? 0
//           : parseFloat(plandetailsobj[8]),
//       planFrequencyID:
//         plandetailsobj[9] == null ||
//         plandetailsobj[9] == '' ||
//         plandetailsobj[9] == undefined ||
//         plandetailsobj[9] == 'null'
//           ? 0
//           : parseFloat(plandetailsobj[9]),
//       optIn: plandetailsobj[10] == 'true' ? true : false,
//       planname: plandetailsobj[14],
//       planLevel: parseInt(plandetailsobj[15]),
//       packageId: parseInt(plandetailsobj[16]),
//       options: [],
//       version:plan.version,
//       description: plan.description,
//       insuranceType:plan.insuranceType

//       //  "disallowedPlanLevels":plandetailsobj[11]
//     };
//     if (plandetailsobj[11] != null || plandetailsobj[11] != 'null') {
//       if (plandetailsobj[11].includes(plandetailsobj[12])) {
//       }
//     }
//    ///this.consoleMessage(obj)
//     this.productobj = {
//       id: product.id,
//       productId: product.productId,
//       name: product.name,
//       planProductId: product.planProductId,
//       price:adjustWith2DecimalsForUI(this.allFormData[type].total),
//       tax: this._decimalPipe
//         .transform(
//           product.calculatedTax ? product.calculatedTax.tax : 0,
//           '1.2-2'
//         )
//         ?.replace(/,/g, ''),
//       total: this._decimalPipe
//         .transform(
//           product.calculatedTax ? product.calculatedTax.total : 0,
//           '1.2-2'
//         )
//         ?.replace(/,/g, ''),
//       tax_details:
//         product.bundledTaxes && product.bundledTaxes.length > 0
//           ? product.bundledTaxes
//           : product.taxesDataJSON,
//       calculatedTax: this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]),
//       bundledProducts: product.bundledProducts,
//       planCoverage: lifeCoverage,
//       planLevel: product.planlevel ? product.planlevel.id : '',
//       planLevelParent: product.planlevel ? product.planlevel.parentId : '',
//       description: product.description,
//       coverage: lifeCoverage,
//       version:product.version
//     };

//     this.productobj.total = this._decimalPipe
//     .transform(
//       this.productobj.calculatedTax ? this.productobj.calculatedTax.total : 0,
//       '1.2-2'
//     )
//     ?.replace(/,/g, '')
//     this.productobj.tax = this._decimalPipe
//     .transform(
//       this.productobj.calculatedTax ? this.productobj.calculatedTax.tax : 0,
//       '1.2-2'
//     )
//     ?.replace(/,/g, '')


//     if(category=="member"){

//       memberObj={
//     "planStartDate":this.userForm.value.planEnrollmentDate
//     ? this._CustomDatePipe.transform(
//         this.userForm.value.planEnrollmentDate
//       )
//     : undefined,
//     "units":(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
//     "standardUnits":this.allFormData[type]["member"].coverage.minimumCoverage,
//     "coverage":"MEMBER",
//     "guaranteedAcceptance":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=
//     this.allFormData[type]["member"].coverage.defaultRange?true:false, //defalutRange
//     "pendingMedicalQuestionaire":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >
//     this.allFormData[type]["member"].coverage.defaultRange?true:false,
//     // "guaranteedAcceptanceAmount":this.allFormData[type]["member"].coverageValue <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverageValue/25000)*this.allFormData[type][category].constant):this.allFormData[type]["member"].coverage.defaultRange,

//     "guaranteedAcceptanceAmount":adjustWith2DecimalsForUI(this.allFormData[type]["member"].coverageValue <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverageValue/25000)*this.allFormData[type][category].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type][category].constant)),
//     "pendingApprovalAmount":adjustWith2DecimalsForUI(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000)*this.allFormData[type][category].constant:0 )||0,
//     "smoker":this.allFormData[type]["member"].isSmoker=="yes"?true:false,
//     "age":this.allFormData[type]["member"].coverage.age,
//     "gender":this.userForm.value.gender?.toLowerCase(),
//     "province":"ON",
//     "provinceId":12,
//     "amount":this.allFormData[type]["member"].premium,
//     "tax": adjustWith2DecimalsForUI(this.productobj.tax),
//     "taxRate":"", //not mandatory,
//     "taxName":"",
//     "coverageValue":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
//     "premium":this.allFormData[type]["member"].constant,
//     "total":adjustWith2DecimalsForUI(this.productobj.total),
//     "coverageUnitPrice":this.allFormData[type]["member"].coverage.coverageUnitPrice,
//     "defaultRange":this.allFormData[type]["member"].coverage.defaultRange,
//     "maximumCoverageWithEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithEvidence,
//     "maximumCoverageWithoutEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
//     "minimumCoverage":this.allFormData[type]["member"].coverage.minimumCoverage,
//       }

//       memberObj["guaranteedAcceptanceTax"] = adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverageValue <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverageValue/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).tax)
//       memberObj["guaranteedAcceptanceTotal"] =adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverageValue <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverageValue/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).total)

//       memberObj["pendingApprovalTax"] = adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000)*this.allFormData[type]["member"].constant:0,product.taxesDataJSON??[]).tax)
//       memberObj["pendingApprovalTotal"] = adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000)*this.allFormData[type]["member"].constant:0,product.taxesDataJSON??[]).total)

//       obj["member"]= memberObj
//       obj["spouse"]= {}
//       if(this.allFormData[type]['spouse']){
//       spouseObj={
//         "planStartDate":this.userForm.value.planEnrollmentDate
// ? this._CustomDatePipe.transform(
//     this.userForm.value.planEnrollmentDate
//   )
// : undefined,
//         "units":(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
//         "standardUnits":this.allFormData[type]["spouse"].coverage.minimumCoverage,
//         "coverage":"MEMBER & SPOUSE",
//         "guaranteedAcceptance":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=
// this.allFormData[type]["spouse"].coverage.defaultRange?true:false, //defalutRange
// "pendingMedicalQuestionaire":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >
// this.allFormData[type]["spouse"].coverage.defaultRange?true:false,
// "guaranteedAcceptanceAmount":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type][category].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type][category].constant),
// "pendingApprovalAmount":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["spouse"].coverage.defaultRange)/25000)*this.allFormData[type][category].constant:0 ||0,
//         "smoker":this.allFormData[type]["spouse"].isSmoker=="yes"?true:false,
//         "age":this.allFormData[type]["spouse"].coverage.age,
//         "gender":this.userForm.value.spousegender?.toLowerCase(),
//         "province":"ON",
//         "provinceId":12,
//         "amount":this.allFormData[type]["spouse"].premium,
//         "tax":adjustWith2DecimalsForUI(this.productobj.tax),
//         "taxRate":"", //not mandatory,
//         "taxName":"",
//         "coverageValue":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
//         "premium":this.allFormData[type]["spouse"].constant,
//         "total":adjustWith2DecimalsForUI(this.productobj.total),
//         "coverageUnitPrice":this.allFormData[type]["spouse"].coverage.coverageUnitPrice,
//         "defaultRange":this.allFormData[type]["spouse"].coverage.defaultRange,
//         "maximumCoverageWithEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithEvidence,
//         "maximumCoverageWithoutEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
//         "minimumCoverage":this.allFormData[type]["spouse"].coverage.minimumCoverage,
//           }






//       obj["member"]= memberObj
//       obj["spouse"]= spouseObj

//       spouseObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type][category].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type][category].constant),product.taxesDataJSON??[]).tax
//       spouseObj["guaranteedAcceptanceTotal"] =this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type][category].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type][category].constant),product.taxesDataJSON??[]).total

//       spouseObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["spouse"].coverage.defaultRange)/25000)*this.allFormData[type][category].constant:0,product.taxesDataJSON??[]).tax
//       spouseObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["spouse"].coverage.defaultRange)/25000)*this.allFormData[type][category].constant:0,product.taxesDataJSON??[]).total
//         }
//     }
//     else{

//       memberObj={
//         "planStartDate":this.userForm.value.planEnrollmentDate
//     ? this._CustomDatePipe.transform(
//         this.userForm.value.planEnrollmentDate
//       )
//     : undefined,
//         "units":(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
//         "standardUnits":this.allFormData[type]["member"].coverage.minimumCoverage,
//         "coverage":"MEMBER",
//         "guaranteedAcceptance":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=
//         this.allFormData[type]["member"].coverage.defaultRange?true:false, //defalutRange
//         "pendingMedicalQuestionaire":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >
//         this.allFormData[type]["member"].coverage.defaultRange?true:false,
//         "guaranteedAcceptanceAmount":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),

//         "pendingApprovalAmount":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000)*this.allFormData[type]["member"].constant:0 ||0,
//         "smoker":this.allFormData[type]["member"].isSmoker=="yes"?true:false,
//         "age":this.allFormData[type]["member"].coverage.age,
//         "gender":this.userForm.value.gender?.toLowerCase(),
//         "province":"ON",
//         "provinceId":12,
//         "amount":this.allFormData[type]["member"].premium,
//         "tax": adjustWith2DecimalsForUI(this.productobj.tax),
//         "taxRate":"", //not mandatory,
//         "taxName":"",
//         "coverageValue":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
//         "premium":this.allFormData[type]["member"].constant,
//         "total":adjustWith2DecimalsForUI(this.productobj.total),
//         "coverageUnitPrice":this.allFormData[type]["member"].coverage.coverageUnitPrice,
//         "defaultRange":this.allFormData[type]["member"].coverage.defaultRange,
//         "maximumCoverageWithEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithEvidence,
//         "maximumCoverageWithoutEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
//         "minimumCoverage":this.allFormData[type]["member"].coverage.minimumCoverage,
//           }
//           spouseObj={
//             "planStartDate":this.userForm.value.planEnrollmentDate
//     ? this._CustomDatePipe.transform(
//         this.userForm.value.planEnrollmentDate
//       )
//     : undefined,
//             "units":(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
//             "standardUnits":this.allFormData[type]["spouse"].coverage.minimumCoverage,
//             "coverage":"MEMBER & SPOUSE",
//             "guaranteedAcceptance":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=
//     this.allFormData[type]["spouse"].coverage.defaultRange?true:false, //defalutRange
//     "pendingMedicalQuestionaire":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >
//     this.allFormData[type]["spouse"].coverage.defaultRange?true:false,
//     "guaranteedAcceptanceAmount":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type][category].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type][category].constant),
//     "pendingApprovalAmount":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["spouse"].coverage.defaultRange)/25000)*this.allFormData[type][category].constant:0 ||0,
//             "smoker":this.allFormData[type]["spouse"].isSmoker=="yes"?true:false,
//             "age":this.allFormData[type]["spouse"].coverage.age,
//             "gender":this.userForm.value.spousegender?.toLowerCase(),
//             "province":"ON",
//             "provinceId":12,
//             "amount":this.allFormData[type]["spouse"].premium,
//             "tax":adjustWith2DecimalsForUI(this.productobj.tax),
//             "taxRate":"", //not mandatory,
//             "taxName":"",
//             "coverageValue":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
//             "premium":this.allFormData[type]["spouse"].constant,
//             "total":adjustWith2DecimalsForUI(this.productobj.total),
//             "coverageUnitPrice":this.allFormData[type]["spouse"].coverage.coverageUnitPrice,
//             "defaultRange":this.allFormData[type]["spouse"].coverage.defaultRange,
//             "maximumCoverageWithEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithEvidence,
//             "maximumCoverageWithoutEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
//             "minimumCoverage":this.allFormData[type]["spouse"].coverage.minimumCoverage,
//               }

//     obj["member"]= memberObj
//     obj["spouse"]= spouseObj


//     memberObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).tax
//     memberObj["guaranteedAcceptanceTotal"] =this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).total

//     spouseObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type]["spouse"].constant),product.taxesDataJSON??[]).tax
//     spouseObj["guaranteedAcceptanceTotal"] =this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type]["spouse"].constant),product.taxesDataJSON??[]).total

//     memberObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000)*this.allFormData[type]["member"].constant:0,product.taxesDataJSON??[]).tax
//     memberObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000)*this.allFormData[type]["member"].constant:0,product.taxesDataJSON??[]).total

//     spouseObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["spouse"].coverage.defaultRange)/25000)*this.allFormData[type]["spouse"].constant:0,product.taxesDataJSON??[]).tax
//     spouseObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["spouse"].coverage.defaultRange)/25000)*this.allFormData[type]["spouse"].constant:0,product.taxesDataJSON??[]).total

//     }
//     let MSguranteeAcceptanceAmount = adjustWith2DecimalsForUI(memberObj["guaranteedAcceptanceAmount"]+(spouseObj["guaranteedAcceptanceAmount"]??0))
//       let MSguranteeAcceptancetTAX = adjustWith2DecimalsForUI(memberObj["guaranteedAcceptanceTax"]+(spouseObj["guaranteedAcceptanceTax"]??0))
//       let MSguranteeAcceptancetTotal = adjustWith2DecimalsForUI(memberObj["guaranteedAcceptanceTotal"]+(spouseObj["guaranteedAcceptanceTotal"]??0))

//       let MSpendingApprovalAmount = adjustWith2DecimalsForUI(memberObj["pendingApprovalAmount"]+(spouseObj["pendingApprovalAmount"]??0))
//       let MSpendingApprovalTAX = adjustWith2DecimalsForUI(memberObj["pendingApprovalTax"]+(spouseObj["pendingApprovalTax"]??0))
//       let MASpendingApprovalTotal = adjustWith2DecimalsForUI(memberObj["pendingApprovalTotal"]+(spouseObj["pendingApprovalTotal"]??0))

//       let obj1: any = {
//         packageId: plan.packageId,
//         packageName: pckage.name,

//         planproductname: this.productobj.name,

//         groupid: plan.planLevel,
//         groupName: group.name,
//         id: plan.id,
//         name: plan.name,
//         planLevel: plan.planLevel ? plan.planLevel : group.id,
//         planLevelParent: group.parentId,
//         fusebillPlanID: plan.fusebillId,
//         planFrequencyID: plan.frqMonthly,
//         isBundle: plan.isBundle,
//         coverage: lifeCoverage,
//         planCoverage: product.planCoverage,
//         bundledProducts: [],
//         products: [],
//         version:plan.version,
//         guaranteedAcceptanceDetails:{
//           "amount":MSguranteeAcceptanceAmount,
//           "tax":MSguranteeAcceptancetTAX,
//           "total":MSguranteeAcceptancetTotal,
//           "products": [{
//             id: product.id,
//             productId: product.productId,
//             name: product.name,
//             planProductId: product.planProductId,
//             price: adjustWith2DecimalsForUI(this.allFormData[type].total),
//             tax_details:
//               product.bundledTaxes && product.bundledTaxes.length > 0
//                 ? product.bundledTaxes
//                 : product.taxesDataJSON,
//             calculatedTax: this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]),
//             bundledProducts: product.bundledProducts,
//             planCoverage: lifeCoverage,
//             planLevel: product.planlevel ? product.planlevel.id : '',
//             planLevelParent: product.planlevel ? product.planlevel.parentId : '',
//             description: product.description,
//             coverage: lifeCoverage,
//             version:product.version,
//             total :this._decimalPipe
//             .transform(
//               this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]).total : 0,
//               '1.2-2'
//             )
//             ?.replace(/,/g, ''),
//             tax : this._decimalPipe
//             .transform(
//               this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]).tax : 0,
//               '1.2-2'
//             )
//             ?.replace(/,/g, '')
//           }]


//         },
//         pendingApprovalDetails:{
//           "amount":MSpendingApprovalAmount,
//           "tax":MSpendingApprovalTAX,
//           "total":MASpendingApprovalTotal,
//           "products": [{
//             id: product.id,
//             productId: product.productId,
//             name: product.name,
//             planProductId: product.planProductId,
//             price: adjustWith2DecimalsForUI(this.allFormData[type].total),
//             tax_details:
//               product.bundledTaxes && product.bundledTaxes.length > 0
//                 ? product.bundledTaxes
//                 : product.taxesDataJSON,
//             calculatedTax: this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]),
//             bundledProducts: product.bundledProducts,
//             planCoverage: lifeCoverage,
//             planLevel: product.planlevel ? product.planlevel.id : '',
//             planLevelParent: product.planlevel ? product.planlevel.parentId : '',
//             description: product.description,
//             coverage: lifeCoverage,
//             version:product.version,
//             total :this._decimalPipe
//             .transform(
//               this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]).total : 0,
//               '1.2-2'
//             )
//             ?.replace(/,/g, ''),
//             tax : this._decimalPipe
//             .transform(
//               this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]).tax : 0,
//               '1.2-2'
//             )
//             ?.replace(/,/g, '')
//           }]
//         },
//         selectedDetails:{
//           "amount": adjustWith2DecimalsForUI(this.allFormData[type].total),
//           "total":adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]).total : 0),

//           "tax" :adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]).tax : 0),
//           "products":[this.productobj]
//         }


//       };

//       obj["guaranteedAcceptanceDetails"]=obj1.guaranteedAcceptanceDetails
//       obj["pendingApprovalDetails"]=obj1.pendingApprovalDetails
//       obj["selectedDetails"]=obj1.selectedDetails
//     this.addtoplansummaryLife(obj, obj1);
//   }, 100);
//   for (let i = this.minValue; i <= this.allFormData[type][category]?.coverage?.maximumCoverageWithEvidence; i += 100000) {
//     this.rangeValues.push(i);
//   }

//   /*
//     Form Here the code works for auto slection of Smoker in Life and critical
//      */

//   //   plansummaryLife.forEach((element:any,index:any) => {


//   //     if(category=="member"){

//   //         if(element.member.smoker==isSmokerValue){
//   //       }
//   //       else{


//   //         const dom: HTMLElement = this.elementRef.nativeElement;

//   //         const plan: any = dom.querySelector("."+"member"+"-" + element.planCoverage + "-"+isSmokerValue);
//   //         console.log(plan)
//   //         setTimeout(() => {
//   //           plan.click()
//   //         }, 1000);

//   //     }
//   //     }
//   //     if(category=="spouse"){


//   //         if(element.spouse.smoker==isSmokerValue){
//   //       }
//   //       else{


//   //         const dom: HTMLElement = this.elementRef.nativeElement;

//   //         const plan: any = dom.querySelector("."+"spouse"+"-" + element.planCoverage + "-"+isSmokerValue);
//   //         console.log(plan)
//   //         setTimeout(() => {
//   //           plan.click()
//   //         }, 1000);

//   //     }
//   //     }




//   // })
// }

//   public handleRangeChange(value: any, type: string, category: string,options:any,plan:any,product:any,pckage:any,group:any,event:any,data:any): void {
//     // console.log(value)

//     this.allFormData[type][category].coverage=data
//     this.rangeValues = [];
//     // this.sliderValue = this.allFormData[type][category].coverage.maximumCoverageWithoutEvidence
//     this.greenWidth = (this.allFormData[type][category].coverage.defaultRange) / 1000;
//     this.orangeWidth = 100 - this.greenWidth;
//     this.filledWidth = (this.allFormData[type][category].coverage.maximumCoverageWithoutEvidence / this.allFormData[type][category].coverage.maximumCoverageWithEvidence) * 100;
//     this.cutoffWidth = this.greenWidth;

//     console.log(this.greenWidth)
//     console.log(this.orangeWidth)
//     console.log(this.filledWidth)
//     console.log(this.cutoffWidth)

//     this.minimumCoverage =this.allFormData[type][category].coverage.defaultRange

//     // this.minimumCoverage = this.allFormData[type][category].coverage.maximumCoverageWithEvidence - this.allFormData[type][category].coverage.maximumCoverageWithoutEvidence
//     this.extraCoverage = this.allFormData[type][category].coverage.maximumCoverageWithoutEvidence
//     // if (this.coverageValue > this.minimumCoverage) {
//     //   this.extraCoverage = this.coverageValue - this.minimumCoverage;
//     // } else {
//     //   this.extraCoverage = 0;
//     // }

//     this.units = Array.from({ length: (this.allFormData[type][category].coverage.maximumCoverageWithEvidence - this.minValue) / 25 + 1 }, (_, i) => i * 25 + this.minValue);
// console.log(this.units)
//     if (this.allFormData[type][category]) {
//       this.updateFormData(type, category, {
//         // coverage: value,
//         isSmoker: this.allFormData[type][category].isSmoker,
//         premium: this.updatePremium(type, category, this.allFormData[type][category].isSmoker),
//       });
//     }
//     let lifeCoverage=''
//     let memberObj:any={}
// let spouseObj:any={}
// if(category=="member"){
//   lifeCoverage="MEMBER"
// }else{
// lifeCoverage="MEMBER & SPOUSE"
// }
//     console.log(this.plansData)
//     let plansummaryLife = JSON.parse(sessionStorage.getItem('plansummaryLife') || '[]');


//     let plandetails = event.target.attributes.data.value;

//     let plandetailsobj = plandetails.split('##');


//     plansummaryLife.forEach((element:any,index:any) => {

//       if(element.planname==plandetailsobj[14]){
//         plansummaryLife.splice(index,1)
//       }
//       sessionStorage.setItem('plansummaryLife', JSON.stringify(plansummaryLife));

//     })
//     setTimeout(() => {
//       // console.log(this.allFormData[type][category].premium)

//       let obj: any = {
//         isBundle: plan.isBundle,
//         // enrollmentDate: moment(
//         //   this.userForm.get("planenrollmentdate").value,
//         //   "MM-DD-YYYY"
//         // ).format("YYYY-MM-DD"),
//         enrollmentDate: this.userForm.value.planEnrollmentDate
//           ? this._CustomDatePipe.transform(
//               this.userForm.value.planEnrollmentDate
//             )
//           : undefined,
//         // "name":plandetailsobj[1]+"-"+plandetailsobj[2],
//         name: plandetailsobj[14],
//         details: plandetailsobj[0],
//         packagename: plandetailsobj[0],
//         groupName: plandetailsobj[1],
//         amount: this.allFormData[type].total,
//         // amount: this.allFormData[type][category].premium,
//         planCoverage: plandetailsobj[2],
//         planPrice: adjustWith2DecimalsForUI(this.allFormData[type].total),
//         amountUI:
//           '$' +
//           adjustWith2DecimalsForUI(this.allFormData[type].total),
//         gst:
//           plandetailsobj[4] == null ||
//           plandetailsobj[4] == '' ||
//           plandetailsobj[4] == undefined ||
//           plandetailsobj[4] == 'null'
//             ? 0
//             : parseFloat(plandetailsobj[4]),
//         hst:
//           plandetailsobj[5] == null ||
//           plandetailsobj[5] == '' ||
//           plandetailsobj[5] == undefined ||
//           plandetailsobj[5] == 'null'
//             ? 0
//             : parseFloat(plandetailsobj[5]),
//         pst:
//           plandetailsobj[6] == null ||
//           plandetailsobj[6] == '' ||
//           plandetailsobj[6] == undefined ||
//           plandetailsobj[6] == 'null'
//             ? 0
//             : parseFloat(plandetailsobj[6]),
//         qst:
//           plandetailsobj[17] == null ||
//           plandetailsobj[17] == '' ||
//           plandetailsobj[17] == undefined ||
//           plandetailsobj[17] == 'null'
//             ? 0
//             : parseFloat(plandetailsobj[17]),
//         gstCheck:
//           plandetailsobj[4] == null ||
//           plandetailsobj[4] == '' ||
//           plandetailsobj[4] == undefined ||
//           plandetailsobj[4] == 'null'
//             ? false
//             : true,
//         hstCheck:
//           plandetailsobj[5] == null ||
//           plandetailsobj[5] == '' ||
//           plandetailsobj[5] == undefined ||
//           plandetailsobj[5] == 'null'
//             ? false
//             : true,
//         pstCheck:
//           plandetailsobj[6] == null ||
//           plandetailsobj[6] == '' ||
//           plandetailsobj[6] == undefined ||
//           plandetailsobj[6] == 'null'
//             ? false
//             : true,
//         qstCheck:
//           plandetailsobj[17] == null ||
//           plandetailsobj[17] == '' ||
//           plandetailsobj[17] == undefined ||
//           plandetailsobj[17] == 'null'
//             ? false
//             : true,
//         id: parseFloat(plandetailsobj[7]),
//         fusebillPlanID:
//           plandetailsobj[8] == null ||
//           plandetailsobj[8] == '' ||
//           plandetailsobj[8] == undefined ||
//           plandetailsobj[8] == 'null'
//             ? 0
//             : parseFloat(plandetailsobj[8]),
//         planFrequencyID:
//           plandetailsobj[9] == null ||
//           plandetailsobj[9] == '' ||
//           plandetailsobj[9] == undefined ||
//           plandetailsobj[9] == 'null'
//             ? 0
//             : parseFloat(plandetailsobj[9]),
//         optIn: plandetailsobj[10] == 'true' ? true : false,
//         planname: plandetailsobj[14],
//         planLevel: parseInt(plandetailsobj[15]),
//         packageId: parseInt(plandetailsobj[16]),
//         options: [],
//         version:plan.version,
//         description: plan.description,
//         insuranceType:plan.insuranceType

//         //  "disallowedPlanLevels":plandetailsobj[11]
//       };
//       if (plandetailsobj[11] != null || plandetailsobj[11] != 'null') {
//         if (plandetailsobj[11].includes(plandetailsobj[12])) {
//         }
//       }
//      ///this.consoleMessage(obj)
//       this.productobj = {
//         id: product.id,
//         productId: product.productId,
//         name: product.name,
//         planProductId: product.planProductId,
//         price: this.allFormData[type].total,
//         tax: this._decimalPipe
//           .transform(
//             product.calculatedTax ? product.calculatedTax.tax : 0,
//             '1.2-2'
//           )
//           ?.replace(/,/g, ''),
//         total: this._decimalPipe
//           .transform(
//             product.calculatedTax ? product.calculatedTax.total : 0,
//             '1.2-2'
//           )
//           ?.replace(/,/g, ''),
//         tax_details:
//           product.bundledTaxes && product.bundledTaxes.length > 0
//             ? product.bundledTaxes
//             : product.taxesDataJSON,
//         calculatedTax: this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]),
//         bundledProducts: product.bundledProducts,
//         planCoverage: lifeCoverage,
//         planLevel: product.planlevel ? product.planlevel.id : '',
//         planLevelParent: product.planlevel ? product.planlevel.parentId : '',
//         description: product.description,
//         coverage: lifeCoverage,
//         version:product.version
//       };

//       this.productobj.total = this._decimalPipe
//       .transform(
//         this.productobj.calculatedTax ? this.productobj.calculatedTax.total : 0,
//         '1.2-2'
//       )
//       ?.replace(/,/g, '')
//       this.productobj.tax = this._decimalPipe
//       .transform(
//         this.productobj.calculatedTax ? this.productobj.calculatedTax.tax : 0,
//         '1.2-2'
//       )
//       ?.replace(/,/g, '')

//       if(category=="member"){

//         memberObj={
//           "planStartDate":this.userForm.value.planEnrollmentDate
//       ? this._CustomDatePipe.transform(
//           this.userForm.value.planEnrollmentDate
//         )
//       : undefined,
//       "units":(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
//       "standardUnits":this.allFormData[type]["member"].coverage.minimumCoverage,
//       "coverage":"MEMBER",
//       "guaranteedAcceptance":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >=
//       this.allFormData[type]["member"].coverage.defaultRange?true:false, //defalutRange
//       "pendingMedicalQuestionaire":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <
//       this.allFormData[type]["member"].coverage.defaultRange?true:false,
//       "smoker":this.allFormData[type]["member"].isSmoker=="yes"?true:false,
//       "age":this.allFormData[type]["member"].coverage.age,
//       "gender":this.userForm.value.gender?.toLowerCase(),
//       "province":"ON",
//       "provinceId":12,
//       "amount":this.allFormData[type]["member"].premium,
//       "tax":adjustWith2DecimalsForUI(this.productobj.tax),
//       "taxRate":"", //not mandatory,
//       "taxName":"",
//       "coverageValue":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
//       "premium":this.allFormData[type]["member"].constant,
//       "total":adjustWith2DecimalsForUI(this.productobj.total),
//       "coverageUnitPrice":this.allFormData[type]["member"].coverage.coverageUnitPrice,
//       "defaultRange":this.allFormData[type]["member"].coverage.defaultRange,
//       "maximumCoverageWithEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithEvidence,
//       "maximumCoverageWithoutEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
//       "minimumCoverage":this.allFormData[type]["member"].coverage.minimumCoverage,


//         }
//         obj["member"]= memberObj
//         obj["spouse"]= {}
//       }
//       else{

//         memberObj={
//           "planStartDate":this.userForm.value.planEnrollmentDate
//       ? this._CustomDatePipe.transform(
//           this.userForm.value.planEnrollmentDate
//         )
//       : undefined,
//           "units":(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
//           "standardUnits":this.allFormData[type]["member"].coverage.minimumCoverage,
//           "coverage":"MEMBER",
//           "guaranteedAcceptance":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >=
//           this.allFormData[type]["member"].coverage.defaultRange?true:false, //defalutRange
//           "pendingMedicalQuestionaire":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <
//           this.allFormData[type]["member"].coverage.defaultRange?true:false,
//           "smoker":this.allFormData[type]["member"].isSmoker=="yes"?true:false,
//           "age":this.allFormData[type]["member"].coverage.age,
//           "gender":this.userForm.value.gender?.toLowerCase(),
//           "province":"ON",
//           "provinceId":12,
//           "amount":this.allFormData[type]["member"].premium,
//           "tax":adjustWith2DecimalsForUI(this.productobj.tax),
//           "taxRate":"", //not mandatory,
//           "taxName":"",
//           "coverageValue":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
//           "premium":this.allFormData[type]["member"].constant,
//           "total":adjustWith2DecimalsForUI(this.productobj.total),
//           "coverageUnitPrice":this.allFormData[type]["member"].coverage.coverageUnitPrice,
//       "defaultRange":this.allFormData[type]["member"].coverage.defaultRange,
//       "maximumCoverageWithEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithEvidence,
//       "maximumCoverageWithoutEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
//       "minimumCoverage":this.allFormData[type]["member"].coverage.minimumCoverage,
//             }
//             spouseObj={
//               "planStartDate":this.userForm.value.planEnrollmentDate
//       ? this._CustomDatePipe.transform(
//           this.userForm.value.planEnrollmentDate
//         )
//       : undefined,
//               "units":(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
//               "standardUnits":this.allFormData[type]["spouse"].coverage.minimumCoverage,
//               "coverage":"MEMBER & SPOUSE",
//               "guaranteedAcceptance":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >=
//       this.allFormData[type]["spouse"].coverage.defaultRange?true:false, //defalutRange
//       "pendingMedicalQuestionaire":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <
//       this.allFormData[type]["spouse"].coverage.defaultRange?true:false,
//               "smoker":this.allFormData[type]["spouse"].isSmoker=="yes"?true:false,
//               "age":this.allFormData[type]["spouse"].coverage.age,
//               "gender":this.userForm.value.spousegender?.toLowerCase(),
//               "province":"ON",
//               "provinceId":12,
//               "amount":this.allFormData[type]["spouse"].premium,
//               "tax":adjustWith2DecimalsForUI(this.productobj.tax),
//               "taxRate":"", //not mandatory,
//               "taxName":"",
//               "coverageValue":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
//               "premium":this.allFormData[type]["spouse"].constant,
//               "total":adjustWith2DecimalsForUI(this.productobj.total),
//               "coverageUnitPrice":this.allFormData[type]["spouse"].coverage.coverageUnitPrice,
//       "defaultRange":this.allFormData[type]["spouse"].coverage.defaultRange,
//       "maximumCoverageWithEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithEvidence,
//       "maximumCoverageWithoutEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
//       "minimumCoverage":this.allFormData[type]["spouse"].coverage.minimumCoverage,
//                 }

//       obj["member"]= memberObj
//       obj["spouse"]= spouseObj
//       }

//       let obj1: any = {
//         packageId: plan.packageId,
//         packageName: pckage.name,

//         planproductname: this.productobj.name,

//         groupid: plan.planLevel,
//         groupName: group.name,
//         id: plan.id,
//         name: plan.name,
//         planLevel: plan.planLevel ? plan.planLevel : group.id,
//         planLevelParent: group.parentId,
//         fusebillPlanID: plan.fusebillId,
//         planFrequencyID: plan.frqMonthly,
//         isBundle: plan.isBundle,
//         coverage: lifeCoverage,
//         planCoverage: product.planCoverage,
//         bundledProducts: [],
//         products: [],
//         version:plan.version

//       };
//       obj1.products.push(this.productobj);
//       //this.consoleMessage(obj1)
//       // console.log(obj)
//       // console.log(obj1)
//       this.addtoplansummaryLife(obj, obj1);
//     }, 100);
//     for (let i = this.minValue; i <= this.allFormData[type][category]?.coverage?.maximumCoverageWithEvidence; i += 50000) {
//       this.rangeValues.push(i);
//     }
//   }

// public handleRangeChange(value: any, type: string, category: string,options:any,plan:any,product:any,pckage:any,group:any,event:any,data:any): void {
//   // console.log(value)
//   // console.log(type)
//   // console.log(event)

//   if(data.maximumCoverageWithoutEvidence==0){
//     data.maximumCoverageWithoutEvidence=25000
//   }
//   this.allFormData[type][category].coverage=data



//   this.rangeValues = [];

//   // return values;

//   this.minimumCoverage =this.allFormData[type][category].coverage.defaultRange

//   // this.minimumCoverage = this.allFormData[type][category].coverage.maximumCoverageWithEvidence - this.allFormData[type][category].coverage.maximumCoverageWithoutEvidence
//   this.extraCoverage = this.allFormData[type][category].coverage.maximumCoverageWithoutEvidence
//   // if (this.coverageValue > this.minimumCoverage) {
//   //   this.extraCoverage = this.coverageValue - this.minimumCoverage;
//   // } else {
//   //   this.extraCoverage = 0;
//   // }



//   if (this.allFormData[type][category]) {
//     this.updateFormData(type, category, {
//       // coverage: value,
//       isSmoker: this.allFormData[type][category].isSmoker,
//       premium: this.updatePremium(type, category, this.allFormData[type][category].isSmoker),
//     });
//   }
//   let lifeCoverage=''
//   let memberObj:any={}
// let spouseObj:any={}
// if(category=="member"){
// lifeCoverage="MEMBER"
// }else{
// lifeCoverage="MEMBER & SPOUSE"
// }
// if(this.allFormData[type]['spouse']){
//   lifeCoverage="MEMBER & SPOUSE"

// }
//   console.log(this.plansData)
//   let plansummaryLife = JSON.parse(sessionStorage.getItem('plansummaryLife') || '[]');


//   let plandetails = event.target.attributes.data.value;

//   let plandetailsobj = plandetails.split('##');


//   plansummaryLife.forEach((element:any,index:any) => {

//     if(element.planname==plandetailsobj[14]){
//       plansummaryLife.splice(index,1)
//     }
//     sessionStorage.setItem('plansummaryLife', JSON.stringify(plansummaryLife));

//   })
//   setTimeout(() => {
//     // console.log(this.allFormData[type][category].premium)

//     let obj: any = {
//       isBundle: plan.isBundle,
//       // enrollmentDate: moment(
//       //   this.userForm.get("planenrollmentdate").value,
//       //   "MM-DD-YYYY"
//       // ).format("YYYY-MM-DD"),
//       enrollmentDate: this.userForm.value.planEnrollmentDate
//         ? this._CustomDatePipe.transform(
//             this.userForm.value.planEnrollmentDate
//           )
//         : undefined,
//       // "name":plandetailsobj[1]+"-"+plandetailsobj[2],
//       name: plandetailsobj[14],
//       details: plandetailsobj[0],
//       packagename: plandetailsobj[0],
//       groupName: plandetailsobj[1],
//       amount: this.allFormData[type].total,
//       // amount: this.allFormData[type][category].premium,
//       planCoverage: plandetailsobj[2],
//       planPrice: adjustWith2DecimalsForUI(this.allFormData[type].total),
//       amountUI:
//         '$' +
//         adjustWith2DecimalsForUI(this.allFormData[type].total),
//       gst:
//         plandetailsobj[4] == null ||
//         plandetailsobj[4] == '' ||
//         plandetailsobj[4] == undefined ||
//         plandetailsobj[4] == 'null'
//           ? 0
//           : parseFloat(plandetailsobj[4]),
//       hst:
//         plandetailsobj[5] == null ||
//         plandetailsobj[5] == '' ||
//         plandetailsobj[5] == undefined ||
//         plandetailsobj[5] == 'null'
//           ? 0
//           : parseFloat(plandetailsobj[5]),
//       pst:
//         plandetailsobj[6] == null ||
//         plandetailsobj[6] == '' ||
//         plandetailsobj[6] == undefined ||
//         plandetailsobj[6] == 'null'
//           ? 0
//           : parseFloat(plandetailsobj[6]),
//       qst:
//         plandetailsobj[17] == null ||
//         plandetailsobj[17] == '' ||
//         plandetailsobj[17] == undefined ||
//         plandetailsobj[17] == 'null'
//           ? 0
//           : parseFloat(plandetailsobj[17]),
//       gstCheck:
//         plandetailsobj[4] == null ||
//         plandetailsobj[4] == '' ||
//         plandetailsobj[4] == undefined ||
//         plandetailsobj[4] == 'null'
//           ? false
//           : true,
//       hstCheck:
//         plandetailsobj[5] == null ||
//         plandetailsobj[5] == '' ||
//         plandetailsobj[5] == undefined ||
//         plandetailsobj[5] == 'null'
//           ? false
//           : true,
//       pstCheck:
//         plandetailsobj[6] == null ||
//         plandetailsobj[6] == '' ||
//         plandetailsobj[6] == undefined ||
//         plandetailsobj[6] == 'null'
//           ? false
//           : true,
//       qstCheck:
//         plandetailsobj[17] == null ||
//         plandetailsobj[17] == '' ||
//         plandetailsobj[17] == undefined ||
//         plandetailsobj[17] == 'null'
//           ? false
//           : true,
//       id: parseFloat(plandetailsobj[7]),
//       fusebillPlanID:
//         plandetailsobj[8] == null ||
//         plandetailsobj[8] == '' ||
//         plandetailsobj[8] == undefined ||
//         plandetailsobj[8] == 'null'
//           ? 0
//           : parseFloat(plandetailsobj[8]),
//       planFrequencyID:
//         plandetailsobj[9] == null ||
//         plandetailsobj[9] == '' ||
//         plandetailsobj[9] == undefined ||
//         plandetailsobj[9] == 'null'
//           ? 0
//           : parseFloat(plandetailsobj[9]),
//       optIn: plandetailsobj[10] == 'true' ? true : false,
//       planname: plandetailsobj[14],
//       planLevel: parseInt(plandetailsobj[15]),
//       packageId: parseInt(plandetailsobj[16]),
//       options: [],
//       version:plan.version,
//       description: plan.description,
//       insuranceType:plan.insuranceType

//       //  "disallowedPlanLevels":plandetailsobj[11]
//     };
//     if (plandetailsobj[11] != null || plandetailsobj[11] != 'null') {
//       if (plandetailsobj[11].includes(plandetailsobj[12])) {
//       }
//     }
//    ///this.consoleMessage(obj)
//     this.productobj = {
//       id: product.id,
//       productId: product.productId,
//       name: product.name,
//       planProductId: product.planProductId,
//       price:adjustWith2DecimalsForUI(this.allFormData[type].total),
//       tax: this._decimalPipe
//         .transform(
//           product.calculatedTax ? product.calculatedTax.tax : 0,
//           '1.2-2'
//         )
//         ?.replace(/,/g, ''),
//       total: this._decimalPipe
//         .transform(
//           product.calculatedTax ? product.calculatedTax.total : 0,
//           '1.2-2'
//         )
//         ?.replace(/,/g, ''),
//       tax_details:
//         product.bundledTaxes && product.bundledTaxes.length > 0
//           ? product.bundledTaxes
//           : product.taxesDataJSON,
//       calculatedTax: this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]),
//       bundledProducts: product.bundledProducts,
//       planCoverage: lifeCoverage,
//       planLevel: product.planlevel ? product.planlevel.id : '',
//       planLevelParent: product.planlevel ? product.planlevel.parentId : '',
//       description: product.description,
//       coverage: lifeCoverage,
//       version:product.version
//     };

//     this.productobj.total = this._decimalPipe
//     .transform(
//       this.productobj.calculatedTax ? this.productobj.calculatedTax.total : 0,
//       '1.2-2'
//     )
//     ?.replace(/,/g, '')
//     this.productobj.tax = this._decimalPipe
//     .transform(
//       this.productobj.calculatedTax ? this.productobj.calculatedTax.tax : 0,
//       '1.2-2'
//     )
//     ?.replace(/,/g, '')
//     // console.log(this.allFormData)
//     // console.log(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence )
//     // console.log(this.allFormData[type]["member"].coverage.defaultRange)
//     // console.log((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000)

//     // console.log(((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000)*this.allFormData[type][category].constant)
//     if(category=="member"){

//       memberObj={
//     "planStartDate":this.userForm.value.planEnrollmentDate
//     ? this._CustomDatePipe.transform(
//         this.userForm.value.planEnrollmentDate
//       )
//     : undefined,
//     "units":(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
//     "standardUnits":this.allFormData[type]["member"].coverage.minimumCoverage,
//     "coverage":"MEMBER",
//     "guaranteedAcceptance":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=
//     this.allFormData[type]["member"].coverage.defaultRange?true:false, //defalutRange
//     "pendingMedicalQuestionaire":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >
//     this.allFormData[type]["member"].coverage.defaultRange?true:false,


//     "guaranteedAcceptanceAmount":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),
//     "pendingApprovalAmount":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000)*this.allFormData[type]["member"].constant:0 ||0,
//     "smoker":this.allFormData[type]["member"].isSmoker=="yes"?true:false,
//     "age":this.allFormData[type]["member"].coverage.age,
//     "gender":this.userForm.value.gender?.toLowerCase(),
//     "province":"ON",
//     "provinceId":12,
//     "amount":this.allFormData[type]["member"].premium,
//     "tax": adjustWith2DecimalsForUI(this.productobj.tax),
//     "taxRate":"", //not mandatory,
//     "taxName":"",
//     "coverageValue":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
//     "premium":this.allFormData[type]["member"].constant,
//     "total":adjustWith2DecimalsForUI(this.productobj.total),
//     "coverageUnitPrice":this.allFormData[type]["member"].coverage.coverageUnitPrice,
//     "defaultRange":this.allFormData[type]["member"].coverage.defaultRange,
//     "maximumCoverageWithEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithEvidence,
//     "maximumCoverageWithoutEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
//     "minimumCoverage":this.allFormData[type]["member"].coverage.minimumCoverage,
//       }
//       memberObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).tax
//       memberObj["guaranteedAcceptanceTotal"] =this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).total

//       memberObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000)*this.allFormData[type]["member"].constant:0,product.taxesDataJSON??[]).tax
//       memberObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000)*this.allFormData[type]["member"].constant:0,product.taxesDataJSON??[]).total

//       obj["member"]= memberObj
//       obj["spouse"]= {}

//       if(this.allFormData[type]['spouse']){
//         spouseObj={
//           "planStartDate":this.userForm.value.planEnrollmentDate
//   ? this._CustomDatePipe.transform(
//       this.userForm.value.planEnrollmentDate
//     )
//   : undefined,
//           "units":(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
//           "standardUnits":this.allFormData[type]["spouse"].coverage.minimumCoverage,
//           "coverage":"MEMBER & SPOUSE",
//           "guaranteedAcceptance":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=
//   this.allFormData[type]["spouse"].coverage.defaultRange?true:false, //defalutRange
//   "pendingMedicalQuestionaire":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >
//   this.allFormData[type]["spouse"].coverage.defaultRange?true:false,
//   "guaranteedAcceptanceAmount":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type]["spouse"].constant),
//   "pendingApprovalAmount":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["spouse"].coverage.defaultRange)/25000)*this.allFormData[type]["spouse"].constant:0 ||0,
//           "smoker":this.allFormData[type]["spouse"].isSmoker=="yes"?true:false,
//           "age":this.allFormData[type]["spouse"].coverage.age,
//           "gender":this.userForm.value.spousegender?.toLowerCase(),
//           "province":"ON",
//           "provinceId":12,
//           "amount":this.allFormData[type]["spouse"].premium,
//           "tax":adjustWith2DecimalsForUI(this.productobj.tax),
//           "taxRate":"", //not mandatory,
//           "taxName":"",
//           "coverageValue":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
//           "premium":this.allFormData[type]["spouse"].constant,
//           "total":adjustWith2DecimalsForUI(this.productobj.total),
//           "coverageUnitPrice":this.allFormData[type]["spouse"].coverage.coverageUnitPrice,
//           "defaultRange":this.allFormData[type]["spouse"].coverage.defaultRange,
//           "maximumCoverageWithEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithEvidence,
//           "maximumCoverageWithoutEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
//           "minimumCoverage":this.allFormData[type]["spouse"].coverage.minimumCoverage,
//             }






//         obj["member"]= memberObj
//         obj["spouse"]= spouseObj

//         spouseObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type]["spouse"].constant),product.taxesDataJSON??[]).tax
//         spouseObj["guaranteedAcceptanceTotal"] =this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type]["spouse"].constant),product.taxesDataJSON??[]).total

//         spouseObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["spouse"].coverage.defaultRange)/25000)*this.allFormData[type]["spouse"].constant:0,product.taxesDataJSON??[]).tax
//         spouseObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["spouse"].coverage.defaultRange)/25000)*this.allFormData[type]["spouse"].constant:0,product.taxesDataJSON??[]).total
//           }




//     }
//     else{

//       memberObj={
//         "planStartDate":this.userForm.value.planEnrollmentDate
//     ? this._CustomDatePipe.transform(
//         this.userForm.value.planEnrollmentDate
//       )
//     : undefined,
//         "units":(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
//         "standardUnits":this.allFormData[type]["member"].coverage.minimumCoverage,
//         "coverage":"MEMBER",
//         "guaranteedAcceptance":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=
//         this.allFormData[type]["member"].coverage.defaultRange?true:false, //defalutRange
//         "pendingMedicalQuestionaire":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >
//         this.allFormData[type]["member"].coverage.defaultRange?true:false,
//         "guaranteedAcceptanceAmount":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),
//         "pendingApprovalAmount":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000)*this.allFormData[type]["member"].constant:0 ||0,
//         "smoker":this.allFormData[type]["member"].isSmoker=="yes"?true:false,
//         "age":this.allFormData[type]["member"].coverage.age,
//         "gender":this.userForm.value.gender?.toLowerCase(),
//         "province":"ON",
//         "provinceId":12,
//         "amount":this.allFormData[type]["member"].premium,
//         "tax":adjustWith2DecimalsForUI(this.productobj.tax),
//         "taxRate":"", //not mandatory,
//         "taxName":"",
//         "coverageValue":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
//         "premium":this.allFormData[type]["member"].constant,
//         "total":adjustWith2DecimalsForUI(this.productobj.total),
//         "coverageUnitPrice":this.allFormData[type]["member"].coverage.coverageUnitPrice,
//     "defaultRange":this.allFormData[type]["member"].coverage.defaultRange,
//     "maximumCoverageWithEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithEvidence,
//     "maximumCoverageWithoutEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
//     "minimumCoverage":this.allFormData[type]["member"].coverage.minimumCoverage,
//           }
//           spouseObj={
//             "planStartDate":this.userForm.value.planEnrollmentDate
//     ? this._CustomDatePipe.transform(
//         this.userForm.value.planEnrollmentDate
//       )
//     : undefined,
//             "units":(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
//             "standardUnits":this.allFormData[type]["spouse"].coverage.minimumCoverage,
//             "coverage":"MEMBER & SPOUSE",
//             "guaranteedAcceptance":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=
//     this.allFormData[type]["spouse"].coverage.defaultRange?true:false, //defalutRange
//     "pendingMedicalQuestionaire":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >
//     this.allFormData[type]["spouse"].coverage.defaultRange?true:false,
//     "guaranteedAcceptanceAmount":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type][category].constant),
//     "pendingApprovalAmount":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["spouse"].coverage.defaultRange)/25000)*this.allFormData[type]["spouse"].constant:0 ||0,
//     "smoker":this.allFormData[type]["spouse"].isSmoker=="yes"?true:false,
//             "age":this.allFormData[type]["spouse"].coverage.age,
//             "gender":this.userForm.value.spousegender?.toLowerCase(),
//             "province":"ON",
//             "provinceId":12,
//             "amount":this.allFormData[type]["spouse"].premium,
//             "tax":adjustWith2DecimalsForUI(this.productobj.tax),
//             "taxRate":"", //not mandatory,
//             "taxName":"",
//             "coverageValue":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
//             "premium":this.allFormData[type]["spouse"].constant,
//             "total":adjustWith2DecimalsForUI(this.productobj.total),
//             "coverageUnitPrice":this.allFormData[type]["spouse"].coverage.coverageUnitPrice,
//     "defaultRange":this.allFormData[type]["spouse"].coverage.defaultRange,
//     "maximumCoverageWithEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithEvidence,
//     "maximumCoverageWithoutEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
//     "minimumCoverage":this.allFormData[type]["spouse"].coverage.minimumCoverage,
//               }

//     obj["member"]= memberObj
//     obj["spouse"]= spouseObj
//     memberObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).tax
//     memberObj["guaranteedAcceptanceTotal"] =this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).total
//     spouseObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type]["spouse"].constant),product.taxesDataJSON??[]).tax
//     spouseObj["guaranteedAcceptanceTotal"] =this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type]["spouse"].constant),product.taxesDataJSON??[]).total

//     memberObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000)*this.allFormData[type]["member"].constant:0,product.taxesDataJSON??[]).tax
//     memberObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000)*this.allFormData[type]["member"].constant:0,product.taxesDataJSON??[]).total

//     spouseObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000)*this.allFormData[type]["member"].constant:0,product.taxesDataJSON??[]).tax
//     spouseObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000)*this.allFormData[type]["member"].constant:0,product.taxesDataJSON??[]).total

//     }

//     let obj1: any = {
//       packageId: plan.packageId,
//       packageName: pckage.name,

//       planproductname: this.productobj.name,

//       groupid: plan.planLevel,
//       groupName: group.name,
//       id: plan.id,
//       name: plan.name,
//       planLevel: plan.planLevel ? plan.planLevel : group.id,
//       planLevelParent: group.parentId,
//       fusebillPlanID: plan.fusebillId,
//       planFrequencyID: plan.frqMonthly,
//       isBundle: plan.isBundle,
//       coverage: lifeCoverage,
//       planCoverage: product.planCoverage,
//       bundledProducts: [],
//       products: [],
//       version:plan.version

//     };
//     obj1.products.push(this.productobj);
//     //this.consoleMessage(obj1)
//     // console.log(obj)
//     // console.log(obj1)
//     this.addtoplansummaryLife(obj, obj1);
//   }, 100);
//   for (let i = this.minValue; i <= this.allFormData[type][category]?.coverage?.maximumCoverageWithEvidence; i += 100000) {
//     this.rangeValues.push(i);
//   }
// }
public handleRadioChange(event: any, type: string, category: string,options:any,plan:any,product:any,pckage:any,group:any,value:any): void {
  let plansummaryLife = JSON.parse(sessionStorage.getItem('plansummaryLife') || '[]');
  let isSmokerValue = event.target.value;
  let plandetails = event.target.attributes.data.value;

  let plandetailsobj = plandetails.split('##');
  let smokerCheck:any =''

  if(isSmokerValue=="no"){
    smokerCheck=false
  }
  else{
    smokerCheck =true
  }

  this.rangeValues =[]


  this.allFormData[type][category].coverage=value

  if (this.allFormData[type][category]) {

    this.updateFormData(type, category, {
      isSmoker: isSmokerValue,
      premium: this.updatePremium(type, category, isSmokerValue),
    });
  }

  console.log(this.allFormData)
let lifeCoverage=''
let memberObj:any={}
let spouseObj:any={}

if(category=="member"){
lifeCoverage="MEMBER"
}
else{
lifeCoverage="MEMBER & SPOUSE"
}

if(this.allFormData[type]['spouse']){
lifeCoverage="MEMBER & SPOUSE"

}







  plansummaryLife.forEach((element:any,index:any) => {

    if(element.planname==plandetailsobj[14]){
      plansummaryLife.splice(index,1)
    }
    sessionStorage.setItem('plansummaryLife', JSON.stringify(plansummaryLife));

  })
  let memberPrice = this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000):0
  let spousePrice:any;
  if(this.allFormData[type]['spouse']){
    spousePrice = this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["spouse"].coverage.defaultRange)/25000):0
    }


  setTimeout(() => {
    // console.log(this.allFormData[type][category].premium)

    let obj: any = {
      isBundle: plan.isBundle,
      // enrollmentDate: moment(
      //   this.userForm.get("planenrollmentdate").value,
      //   "MM-DD-YYYY"
      // ).format("YYYY-MM-DD"),
      enrollmentDate: this.userForm.value.planEnrollmentDate
        ? this._CustomDatePipe.transform(
            this.userForm.value.planEnrollmentDate
          )
        : undefined,
      // "name":plandetailsobj[1]+"-"+plandetailsobj[2],
      name: plandetailsobj[14],
      details: plandetailsobj[0],
      packagename: plandetailsobj[0],
      groupName: plandetailsobj[1],
      amount: this.allFormData[type].total,
      // amount: this.allFormData[type][category].premium,
      planCoverage: plandetailsobj[2],
      planPrice: adjustWith2DecimalsForUI(this.allFormData[type].total),
      amountUI:
        '$' +
        adjustWith2DecimalsForUI(this.allFormData[type].total),
      gst:
        plandetailsobj[4] == null ||
        plandetailsobj[4] == '' ||
        plandetailsobj[4] == undefined ||
        plandetailsobj[4] == 'null'
          ? 0
          : parseFloat(plandetailsobj[4]),
      hst:
        plandetailsobj[5] == null ||
        plandetailsobj[5] == '' ||
        plandetailsobj[5] == undefined ||
        plandetailsobj[5] == 'null'
          ? 0
          : parseFloat(plandetailsobj[5]),
      pst:
        plandetailsobj[6] == null ||
        plandetailsobj[6] == '' ||
        plandetailsobj[6] == undefined ||
        plandetailsobj[6] == 'null'
          ? 0
          : parseFloat(plandetailsobj[6]),
      qst:
        plandetailsobj[17] == null ||
        plandetailsobj[17] == '' ||
        plandetailsobj[17] == undefined ||
        plandetailsobj[17] == 'null'
          ? 0
          : parseFloat(plandetailsobj[17]),
      gstCheck:
        plandetailsobj[4] == null ||
        plandetailsobj[4] == '' ||
        plandetailsobj[4] == undefined ||
        plandetailsobj[4] == 'null'
          ? false
          : true,
      hstCheck:
        plandetailsobj[5] == null ||
        plandetailsobj[5] == '' ||
        plandetailsobj[5] == undefined ||
        plandetailsobj[5] == 'null'
          ? false
          : true,
      pstCheck:
        plandetailsobj[6] == null ||
        plandetailsobj[6] == '' ||
        plandetailsobj[6] == undefined ||
        plandetailsobj[6] == 'null'
          ? false
          : true,
      qstCheck:
        plandetailsobj[17] == null ||
        plandetailsobj[17] == '' ||
        plandetailsobj[17] == undefined ||
        plandetailsobj[17] == 'null'
          ? false
          : true,
      id: parseFloat(plandetailsobj[7]),
      fusebillPlanID:
        plandetailsobj[8] == null ||
        plandetailsobj[8] == '' ||
        plandetailsobj[8] == undefined ||
        plandetailsobj[8] == 'null'
          ? 0
          : parseFloat(plandetailsobj[8]),
      planFrequencyID:
        plandetailsobj[9] == null ||
        plandetailsobj[9] == '' ||
        plandetailsobj[9] == undefined ||
        plandetailsobj[9] == 'null'
          ? 0
          : parseFloat(plandetailsobj[9]),
      optIn: plandetailsobj[10] == 'true' ? true : false,
      planname: plandetailsobj[14],
      planLevel: parseInt(plandetailsobj[15]),
      packageId: parseInt(plandetailsobj[16]),
      options: [],
      version:plan.version,
      description: plan.description,
      insuranceType:plan.insuranceType

      //  "disallowedPlanLevels":plandetailsobj[11]
    };
    if (plandetailsobj[11] != null || plandetailsobj[11] != 'null') {
      if (plandetailsobj[11].includes(plandetailsobj[12])) {
      }
    }
   ///this.consoleMessage(obj)
    this.productobj = {
      id: product.id,
      productId: product.productId,
      name: product.name,
      planProductId: product.planProductId,
      price: adjustWith2DecimalsForUI(this.allFormData[type].total),
      tax: this._decimalPipe
        .transform(
          product.calculatedTax ? product.calculatedTax.tax : 0,
          '1.2-2'
        )
        ?.replace(/,/g, ''),
      total: this._decimalPipe
        .transform(
          product.calculatedTax ? product.calculatedTax.total : 0,
          '1.2-2'
        )
        ?.replace(/,/g, ''),
      tax_details:
        product.bundledTaxes && product.bundledTaxes.length > 0
          ? product.bundledTaxes
          : product.taxesDataJSON,
      calculatedTax: this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]),
      bundledProducts: product.bundledProducts,
      planCoverage: lifeCoverage,
      planLevel: product.planlevel ? product.planlevel.id : '',
      planLevelParent: product.planlevel ? product.planlevel.parentId : '',
      description: product.description,
      coverage: lifeCoverage,
      version:product.version
    };

    this.productobj.total = this._decimalPipe
    .transform(
      this.productobj.calculatedTax ? this.productobj.calculatedTax.total : 0,
      '1.2-2'
    )
    ?.replace(/,/g, '')
    this.productobj.tax = this._decimalPipe
    .transform(
      this.productobj.calculatedTax ? this.productobj.calculatedTax.tax : 0,
      '1.2-2'
    )
    ?.replace(/,/g, '')


    if(category=="member"){

      memberObj={
    "planStartDate":this.userForm.value.planEnrollmentDate
    ? this._CustomDatePipe.transform(
        this.userForm.value.planEnrollmentDate
      )
    : undefined,
    "units":(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
    "standardUnits":this.allFormData[type]["member"].coverage.minimumCoverage,
    "coverage":"MEMBER",
    "guaranteedAcceptance":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=
    this.allFormData[type]["member"].coverage.defaultRange?true:false, //defalutRange
    "pendingMedicalQuestionaire":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >
    this.allFormData[type]["member"].coverage.defaultRange?true:false,
    // "guaranteedAcceptanceAmount":this.allFormData[type]["member"].coverageValue <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverageValue/25000)*this.allFormData[type][category].constant):this.allFormData[type]["member"].coverage.defaultRange,

    "guaranteedAcceptanceAmount":adjustWith2DecimalsForUI(this.allFormData[type]["member"].coverageValue <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverageValue/25000)*this.allFormData[type][category].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type][category].constant)),
    "pendingApprovalAmount":adjustWith2DecimalsForUI(memberPrice*this.allFormData[type][category].constant )||0,
    "smoker":this.allFormData[type]["member"].isSmoker=="yes"?true:false,
    "age":this.allFormData[type]["member"].coverage.age,
    "gender":this.userForm.value.gender?.toLowerCase(),
    "province":"ON",
    "provinceId":12,
    "amount":this.allFormData[type]["member"].premium,
    "tax": adjustWith2DecimalsForUI(this.productobj.tax),
    "taxRate":"", //not mandatory,
    "taxName":"",
    "coverageValue":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
    "premium":this.allFormData[type]["member"].constant,
    "total":adjustWith2DecimalsForUI(this.productobj.total),
    "coverageUnitPrice":this.allFormData[type]["member"].coverage.coverageUnitPrice,
    "defaultRange":this.allFormData[type]["member"].coverage.defaultRange,
    "maximumCoverageWithEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithEvidence,
    "maximumCoverageWithoutEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
    "minimumCoverage":this.allFormData[type]["member"].coverage.minimumCoverage,
      }

      memberObj["guaranteedAcceptanceTax"] = adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverageValue <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverageValue/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).tax)
      memberObj["guaranteedAcceptanceTotal"] =adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverageValue <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverageValue/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).total)

      memberObj["pendingApprovalTax"] = adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(memberPrice*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).tax)
      memberObj["pendingApprovalTotal"] = adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(memberPrice*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).total)

      obj["member"]= memberObj
      obj["spouse"]= {}
      if(this.allFormData[type]['spouse']){
      spouseObj={
        "planStartDate":this.userForm.value.planEnrollmentDate
? this._CustomDatePipe.transform(
    this.userForm.value.planEnrollmentDate
  )
: undefined,
        "units":(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
        "standardUnits":this.allFormData[type]["spouse"].coverage.minimumCoverage,
        "coverage":"MEMBER & SPOUSE",
        "guaranteedAcceptance":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=
this.allFormData[type]["spouse"].coverage.defaultRange?true:false, //defalutRange
"pendingMedicalQuestionaire":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >
this.allFormData[type]["spouse"].coverage.defaultRange?true:false,
"guaranteedAcceptanceAmount":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type][category].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type][category].constant),
"pendingApprovalAmount":adjustWith2DecimalsForUI(spousePrice*this.allFormData[type][category].constant )||0,
        "smoker":this.allFormData[type]["spouse"].isSmoker=="yes"?true:false,
        "age":this.allFormData[type]["spouse"].coverage.age,
        "gender":this.userForm.value.spousegender?.toLowerCase(),
        "province":"ON",
        "provinceId":12,
        "amount":this.allFormData[type]["spouse"].premium,
        "tax":adjustWith2DecimalsForUI(this.productobj.tax),
        "taxRate":"", //not mandatory,
        "taxName":"",
        "coverageValue":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
        "premium":this.allFormData[type]["spouse"].constant,
        "total":adjustWith2DecimalsForUI(this.productobj.total),
        "coverageUnitPrice":this.allFormData[type]["spouse"].coverage.coverageUnitPrice,
        "defaultRange":this.allFormData[type]["spouse"].coverage.defaultRange,
        "maximumCoverageWithEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithEvidence,
        "maximumCoverageWithoutEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
        "minimumCoverage":this.allFormData[type]["spouse"].coverage.minimumCoverage,
          }






      obj["member"]= memberObj
      obj["spouse"]= spouseObj

      spouseObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type][category].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type][category].constant),product.taxesDataJSON??[]).tax
      spouseObj["guaranteedAcceptanceTotal"] =this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type][category].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type][category].constant),product.taxesDataJSON??[]).total

      spouseObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(spousePrice*this.allFormData[type][category].constant,product.taxesDataJSON??[]).tax
      spouseObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(spousePrice*this.allFormData[type][category].constant,product.taxesDataJSON??[]).total
        }
    }
    else{

      memberObj={
        "planStartDate":this.userForm.value.planEnrollmentDate
    ? this._CustomDatePipe.transform(
        this.userForm.value.planEnrollmentDate
      )
    : undefined,
        "units":(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
        "standardUnits":this.allFormData[type]["member"].coverage.minimumCoverage,
        "coverage":"MEMBER",
        "guaranteedAcceptance":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=
        this.allFormData[type]["member"].coverage.defaultRange?true:false, //defalutRange
        "pendingMedicalQuestionaire":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >
        this.allFormData[type]["member"].coverage.defaultRange?true:false,
        "guaranteedAcceptanceAmount":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),

        "pendingApprovalAmount":adjustWith2DecimalsForUI(memberPrice*this.allFormData[type][category].constant )||0,
        "smoker":this.allFormData[type]["member"].isSmoker=="yes"?true:false,
        "age":this.allFormData[type]["member"].coverage.age,
        "gender":this.userForm.value.gender?.toLowerCase(),
        "province":"ON",
        "provinceId":12,
        "amount":this.allFormData[type]["member"].premium,
        "tax": adjustWith2DecimalsForUI(this.productobj.tax),
        "taxRate":"", //not mandatory,
        "taxName":"",
        "coverageValue":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
        "premium":this.allFormData[type]["member"].constant,
        "total":adjustWith2DecimalsForUI(this.productobj.total),
        "coverageUnitPrice":this.allFormData[type]["member"].coverage.coverageUnitPrice,
        "defaultRange":this.allFormData[type]["member"].coverage.defaultRange,
        "maximumCoverageWithEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithEvidence,
        "maximumCoverageWithoutEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
        "minimumCoverage":this.allFormData[type]["member"].coverage.minimumCoverage,
          }
          spouseObj={
            "planStartDate":this.userForm.value.planEnrollmentDate
    ? this._CustomDatePipe.transform(
        this.userForm.value.planEnrollmentDate
      )
    : undefined,
            "units":(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
            "standardUnits":this.allFormData[type]["spouse"].coverage.minimumCoverage,
            "coverage":"MEMBER & SPOUSE",
            "guaranteedAcceptance":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=
    this.allFormData[type]["spouse"].coverage.defaultRange?true:false, //defalutRange
    "pendingMedicalQuestionaire":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >
    this.allFormData[type]["spouse"].coverage.defaultRange?true:false,
    "guaranteedAcceptanceAmount":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type][category].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type][category].constant),
    "pendingApprovalAmount":adjustWith2DecimalsForUI(spousePrice*this.allFormData[type][category].constant )||0,
            "smoker":this.allFormData[type]["spouse"].isSmoker=="yes"?true:false,
            "age":this.allFormData[type]["spouse"].coverage.age,
            "gender":this.userForm.value.spousegender?.toLowerCase(),
            "province":"ON",
            "provinceId":12,
            "amount":this.allFormData[type]["spouse"].premium,
            "tax":adjustWith2DecimalsForUI(this.productobj.tax),
            "taxRate":"", //not mandatory,
            "taxName":"",
            "coverageValue":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
            "premium":this.allFormData[type]["spouse"].constant,
            "total":adjustWith2DecimalsForUI(this.productobj.total),
            "coverageUnitPrice":this.allFormData[type]["spouse"].coverage.coverageUnitPrice,
            "defaultRange":this.allFormData[type]["spouse"].coverage.defaultRange,
            "maximumCoverageWithEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithEvidence,
            "maximumCoverageWithoutEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
            "minimumCoverage":this.allFormData[type]["spouse"].coverage.minimumCoverage,
              }

    obj["member"]= memberObj
    obj["spouse"]= spouseObj


    memberObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).tax
    memberObj["guaranteedAcceptanceTotal"] =this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).total

    spouseObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type]["spouse"].constant),product.taxesDataJSON??[]).tax
    spouseObj["guaranteedAcceptanceTotal"] =this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type]["spouse"].constant),product.taxesDataJSON??[]).total

    memberObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(memberPrice*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).tax
    memberObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(memberPrice*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).total

    spouseObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(spousePrice*this.allFormData[type]["spouse"].constant,product.taxesDataJSON??[]).tax
    spouseObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(spousePrice*this.allFormData[type]["spouse"].constant,product.taxesDataJSON??[]).total

    }

    let MSguranteeAcceptanceAmount = adjustWith2DecimalsForUI(memberObj["guaranteedAcceptanceAmount"]+(spouseObj["guaranteedAcceptanceAmount"]??0))
    let MSguranteeAcceptancetTAX = adjustWith2DecimalsForUI(memberObj["guaranteedAcceptanceTax"]+(spouseObj["guaranteedAcceptanceTax"]??0))
    let MSguranteeAcceptancetTotal = adjustWith2DecimalsForUI(memberObj["guaranteedAcceptanceTotal"]+(spouseObj["guaranteedAcceptanceTotal"]??0))

    let MSpendingApprovalAmount = adjustWith2DecimalsForUI(memberObj["pendingApprovalAmount"]+(spouseObj["pendingApprovalAmount"]??0))
    let MSpendingApprovalTAX = adjustWith2DecimalsForUI(memberObj["pendingApprovalTax"]+(spouseObj["pendingApprovalTax"]??0))
    let MSpendingApprovalTotal = adjustWith2DecimalsForUI(memberObj["pendingApprovalTotal"]+(spouseObj["pendingApprovalTotal"]??0))

    let obj1: any = {
      packageId: plan.packageId,
      packageName: pckage.name,

      planproductname: this.productobj.name,

      groupid: plan.planLevel,
      groupName: group.name,
      id: plan.id,
      name: plan.name,
      planLevel: plan.planLevel ? plan.planLevel : group.id,
      planLevelParent: group.parentId,
      fusebillPlanID: plan.fusebillId,
      planFrequencyID: plan.frqMonthly,
      isBundle: plan.isBundle,
      coverage: lifeCoverage,
      planCoverage: product.planCoverage,
      bundledProducts: [],
      products: [],
      version:plan.version,
      guaranteedAcceptanceDetails:{
        "amount":MSguranteeAcceptanceAmount,
        "tax":MSguranteeAcceptancetTAX,
        "total":MSguranteeAcceptancetTotal,
        "products": [{
          id: product.id,
          productId: product.productId,
          name: product.name,
          planProductId: product.planProductId,
          price: adjustWith2DecimalsForUI(this.allFormData[type].total),
          tax_details:
            product.bundledTaxes && product.bundledTaxes.length > 0
              ? product.bundledTaxes
              : product.taxesDataJSON,
          calculatedTax: this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]),
          bundledProducts: product.bundledProducts,
          planCoverage: lifeCoverage,
          planLevel: product.planlevel ? product.planlevel.id : '',
          planLevelParent: product.planlevel ? product.planlevel.parentId : '',
          description: product.description,
          coverage: lifeCoverage,
          version:product.version,
          total :this._decimalPipe
          .transform(
            this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]).total : 0,
            '1.2-2'
          )
          ?.replace(/,/g, ''),
          tax : this._decimalPipe
          .transform(
            this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]).tax : 0,
            '1.2-2'
          )
          ?.replace(/,/g, '')
        }]


      },
      pendingApprovalDetails:{
        "amount":MSpendingApprovalAmount,
        "tax":this._decimalPipe
        .transform(
          this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]).tax : 0,
          '1.2-2'
        )
        ?.replace(/,/g, ''),
        "total":this._decimalPipe
        .transform(
          this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]).total : 0,
          '1.2-2'
        )
        ?.replace(/,/g, ''),
        "products": [{
          id: product.id,
          productId: product.productId,
          name: product.name,
          planProductId: product.planProductId,
          price: adjustWith2DecimalsForUI(this.allFormData[type].total),
          tax_details:
            product.bundledTaxes && product.bundledTaxes.length > 0
              ? product.bundledTaxes
              : product.taxesDataJSON,
          calculatedTax: this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]),
          bundledProducts: product.bundledProducts,
          planCoverage: lifeCoverage,
          planLevel: product.planlevel ? product.planlevel.id : '',
          planLevelParent: product.planlevel ? product.planlevel.parentId : '',
          description: product.description,
          coverage: lifeCoverage,
          version:product.version,
          total :this._decimalPipe
          .transform(
            this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]).total : 0,
            '1.2-2'
          )
          ?.replace(/,/g, ''),
          tax : this._decimalPipe
          .transform(
            this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]).tax : 0,
            '1.2-2'
          )
          ?.replace(/,/g, '')
        }]
      },
      selectedDetails:{
        "amount": adjustWith2DecimalsForUI(this.allFormData[type].total),
        "total":adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]).total : 0),

        "tax" :adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]).tax : 0),
        "products":[this.productobj]
      }


    };

    obj["guaranteedAcceptanceDetails"]=obj1.guaranteedAcceptanceDetails
    obj["pendingApprovalDetails"]=obj1.pendingApprovalDetails
    obj["selectedDetails"]=obj1.selectedDetails
    obj1.products.push(this.productobj);

    this.addtoplansummaryLife(obj, obj1);
  }, 100);
  for (let i = this.minValue; i <= this.allFormData[type][category]?.coverage?.maximumCoverageWithEvidence; i += 100000) {
    this.rangeValues.push(i);
  }


  /*
  Form Here the code works for auto slection of Smoker in Life and critical vice versa
   */

  plansummaryLife.forEach((element:any,index:any) => {


    if(category=="member"){

        if(element.member.smoker==isSmokerValue){
      }
      else{


        const dom: HTMLElement = this.elementRef.nativeElement;

        const plan: any = dom.querySelector("."+"member"+"-" + element.planCoverage + "-"+isSmokerValue);
        console.log(plan)
        setTimeout(() => {
          plan.click()
        }, 1000);

    }
    }
    if(category=="spouse"){


        if(element.spouse.smoker==isSmokerValue){
      }
      else{


        const dom: HTMLElement = this.elementRef.nativeElement;

        const plan: any = dom.querySelector("."+"spouse"+"-" + element.planCoverage + "-"+isSmokerValue);
        console.log(plan)
        setTimeout(() => {
          plan.click()
        }, 1000);

    }
    }




})
}

public handleRangeChange(value: any, type: string, category: string,options:any,plan:any,product:any,pckage:any,group:any,event:any,data:any): void {
  // console.log(value)
  // console.log(type)
  // console.log(event)
  // console.log(data)


  if(data.maximumCoverageWithoutEvidence==0){
    data.maximumCoverageWithoutEvidence=25000
  }
  this.allFormData[type][category].coverage=data

  // this.sliderValue = this.allFormData[type][category].coverage.maximumCoverageWithoutEvidence


  this.rangeValues = [];




  if (this.allFormData[type][category]) {
    this.updateFormData(type, category, {
      // coverage: value,
      isSmoker: this.allFormData[type][category].isSmoker,
      premium: this.updatePremium(type, category, this.allFormData[type][category].isSmoker),
    });
  }
  let lifeCoverage=''
  let memberObj:any={}
let spouseObj:any={}
if(category=="member"){
lifeCoverage="MEMBER"
}else{
lifeCoverage="MEMBER & SPOUSE"
}

if(this.allFormData[type]['spouse']){
lifeCoverage="MEMBER & SPOUSE"

}
  console.log(this.plansData)
  let plansummaryLife = JSON.parse(sessionStorage.getItem('plansummaryLife') || '[]');


  let plandetails = event.target.attributes.data.value;

  let plandetailsobj = plandetails.split('##');


  plansummaryLife.forEach((element:any,index:any) => {

    if(element.planname==plandetailsobj[14]){
      plansummaryLife.splice(index,1)
    }
    sessionStorage.setItem('plansummaryLife', JSON.stringify(plansummaryLife));

  })
  setTimeout(() => {
    // console.log(this.allFormData[type][category].premium)

    let obj: any = {
      isBundle: plan.isBundle,
      // enrollmentDate: moment(
      //   this.userForm.get("planenrollmentdate").value,
      //   "MM-DD-YYYY"
      // ).format("YYYY-MM-DD"),
      enrollmentDate: this.userForm.value.planEnrollmentDate
        ? this._CustomDatePipe.transform(
            this.userForm.value.planEnrollmentDate
          )
        : undefined,
      // "name":plandetailsobj[1]+"-"+plandetailsobj[2],
      name: plandetailsobj[14],
      details: plandetailsobj[0],
      packagename: plandetailsobj[0],
      groupName: plandetailsobj[1],
      amount: this.allFormData[type].total,
      // amount: this.allFormData[type][category].premium,
      planCoverage: plandetailsobj[2],
      planPrice: adjustWith2DecimalsForUI(this.allFormData[type].total),
      amountUI:
        '$' +
        adjustWith2DecimalsForUI(this.allFormData[type].total),
      gst:
        plandetailsobj[4] == null ||
        plandetailsobj[4] == '' ||
        plandetailsobj[4] == undefined ||
        plandetailsobj[4] == 'null'
          ? 0
          : parseFloat(plandetailsobj[4]),
      hst:
        plandetailsobj[5] == null ||
        plandetailsobj[5] == '' ||
        plandetailsobj[5] == undefined ||
        plandetailsobj[5] == 'null'
          ? 0
          : parseFloat(plandetailsobj[5]),
      pst:
        plandetailsobj[6] == null ||
        plandetailsobj[6] == '' ||
        plandetailsobj[6] == undefined ||
        plandetailsobj[6] == 'null'
          ? 0
          : parseFloat(plandetailsobj[6]),
      qst:
        plandetailsobj[17] == null ||
        plandetailsobj[17] == '' ||
        plandetailsobj[17] == undefined ||
        plandetailsobj[17] == 'null'
          ? 0
          : parseFloat(plandetailsobj[17]),
      gstCheck:
        plandetailsobj[4] == null ||
        plandetailsobj[4] == '' ||
        plandetailsobj[4] == undefined ||
        plandetailsobj[4] == 'null'
          ? false
          : true,
      hstCheck:
        plandetailsobj[5] == null ||
        plandetailsobj[5] == '' ||
        plandetailsobj[5] == undefined ||
        plandetailsobj[5] == 'null'
          ? false
          : true,
      pstCheck:
        plandetailsobj[6] == null ||
        plandetailsobj[6] == '' ||
        plandetailsobj[6] == undefined ||
        plandetailsobj[6] == 'null'
          ? false
          : true,
      qstCheck:
        plandetailsobj[17] == null ||
        plandetailsobj[17] == '' ||
        plandetailsobj[17] == undefined ||
        plandetailsobj[17] == 'null'
          ? false
          : true,
      id: parseFloat(plandetailsobj[7]),
      fusebillPlanID:
        plandetailsobj[8] == null ||
        plandetailsobj[8] == '' ||
        plandetailsobj[8] == undefined ||
        plandetailsobj[8] == 'null'
          ? 0
          : parseFloat(plandetailsobj[8]),
      planFrequencyID:
        plandetailsobj[9] == null ||
        plandetailsobj[9] == '' ||
        plandetailsobj[9] == undefined ||
        plandetailsobj[9] == 'null'
          ? 0
          : parseFloat(plandetailsobj[9]),
      optIn: plandetailsobj[10] == 'true' ? true : false,
      planname: plandetailsobj[14],
      planLevel: parseInt(plandetailsobj[15]),
      packageId: parseInt(plandetailsobj[16]),
      options: [],
      version:plan.version,
      description: plan.description,
      insuranceType:plan.insuranceType

      //  "disallowedPlanLevels":plandetailsobj[11]
    };
    if (plandetailsobj[11] != null || plandetailsobj[11] != 'null') {
      if (plandetailsobj[11].includes(plandetailsobj[12])) {
      }
    }
   ///this.consoleMessage(obj)
    this.productobj = {
      id: product.id,
      productId: product.productId,
      name: product.name,
      planProductId: product.planProductId,
      price:adjustWith2DecimalsForUI(this.allFormData[type].total),
      tax: this._decimalPipe
        .transform(
          product.calculatedTax ? product.calculatedTax.tax : 0,
          '1.2-2'
        )
        ?.replace(/,/g, ''),
      total: this._decimalPipe
        .transform(
          product.calculatedTax ? product.calculatedTax.total : 0,
          '1.2-2'
        )
        ?.replace(/,/g, ''),
      tax_details:
        product.bundledTaxes && product.bundledTaxes.length > 0
          ? product.bundledTaxes
          : product.taxesDataJSON,
      calculatedTax: this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]),
      bundledProducts: product.bundledProducts,
      planCoverage: lifeCoverage,
      planLevel: product.planlevel ? product.planlevel.id : '',
      planLevelParent: product.planlevel ? product.planlevel.parentId : '',
      description: product.description,
      coverage: lifeCoverage,
      version:product.version
    };

    this.productobj.total = this._decimalPipe
    .transform(
      this.productobj.calculatedTax ? this.productobj.calculatedTax.total : 0,
      '1.2-2'
    )
    ?.replace(/,/g, '')
    this.productobj.tax = this._decimalPipe
    .transform(
      this.productobj.calculatedTax ? this.productobj.calculatedTax.tax : 0,
      '1.2-2'
    )
    ?.replace(/,/g, '')
    // console.log(this.allFormData)
    // console.log(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence )
    // console.log(this.allFormData[type]["member"].coverage.defaultRange)
    // console.log((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000)

    // console.log(((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000)*this.allFormData[type][category].constant)

    let memberPrice = this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000):0
    let spousePrice:any;
    if(this.allFormData[type]['spouse']){
      spousePrice = this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["spouse"].coverage.defaultRange)/25000):0
      }
    // console.log(price)
    // console.log(price*this.allFormData[type]["member"].constant)
    // console.log(product.taxesDataJSON)
    // console.log(this.calculateTaxForProductTaxesData(price*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]))
    // console.log(this.calculateTaxForProductTaxesData(price*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).tax)
    // console.log(this.calculateTaxForProductTaxesData(price*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).total)
    if(category=="member"){

      memberObj={
    "planStartDate":this.userForm.value.planEnrollmentDate
    ? this._CustomDatePipe.transform(
        this.userForm.value.planEnrollmentDate
      )
    : undefined,
    "units":(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
    "standardUnits":this.allFormData[type]["member"].coverage.minimumCoverage,
    "coverage":"MEMBER",
    "guaranteedAcceptance":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=
    this.allFormData[type]["member"].coverage.defaultRange?true:false, //defalutRange
    "pendingMedicalQuestionaire":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >
    this.allFormData[type]["member"].coverage.defaultRange?true:false,


    "guaranteedAcceptanceAmount":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),
    "pendingApprovalAmount":adjustWith2DecimalsForUI(memberPrice*this.allFormData[type][category].constant )||0,
    "smoker":this.allFormData[type]["member"].isSmoker=="yes"?true:false,
    "age":this.allFormData[type]["member"].coverage.age,
    "gender":this.userForm.value.gender?.toLowerCase(),
    "province":"ON",
    "provinceId":12,
    "amount":this.allFormData[type]["member"].premium,
    "tax": adjustWith2DecimalsForUI(this.productobj.tax),
    "taxRate":"", //not mandatory,
    "taxName":"",
    "coverageValue":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
    "premium":this.allFormData[type]["member"].constant,
    "total":adjustWith2DecimalsForUI(this.productobj.total),
    "coverageUnitPrice":this.allFormData[type]["member"].coverage.coverageUnitPrice,
    "defaultRange":this.allFormData[type]["member"].coverage.defaultRange,
    "maximumCoverageWithEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithEvidence,
    "maximumCoverageWithoutEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
    "minimumCoverage":this.allFormData[type]["member"].coverage.minimumCoverage,
      }
      memberObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).tax
      memberObj["guaranteedAcceptanceTotal"] =this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).total

      memberObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(memberPrice*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).tax
      memberObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(memberPrice*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).total

      obj["member"]= memberObj
      obj["spouse"]= {}

      if(this.allFormData[type]['spouse']){
        spouseObj={
          "planStartDate":this.userForm.value.planEnrollmentDate
  ? this._CustomDatePipe.transform(
      this.userForm.value.planEnrollmentDate
    )
  : undefined,
          "units":(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
          "standardUnits":this.allFormData[type]["spouse"].coverage.minimumCoverage,
          "coverage":"MEMBER & SPOUSE",
          "guaranteedAcceptance":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=
  this.allFormData[type]["spouse"].coverage.defaultRange?true:false, //defalutRange
  "pendingMedicalQuestionaire":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >
  this.allFormData[type]["spouse"].coverage.defaultRange?true:false,
  "guaranteedAcceptanceAmount":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type]["spouse"].constant),
  "pendingApprovalAmount":adjustWith2DecimalsForUI(spousePrice*this.allFormData[type][category].constant )||0,
          "smoker":this.allFormData[type]["spouse"].isSmoker=="yes"?true:false,
          "age":this.allFormData[type]["spouse"].coverage.age,
          "gender":this.userForm.value.spousegender?.toLowerCase(),
          "province":"ON",
          "provinceId":12,
          "amount":this.allFormData[type]["spouse"].premium,
          "tax":adjustWith2DecimalsForUI(this.productobj.tax),
          "taxRate":"", //not mandatory,
          "taxName":"",
          "coverageValue":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
          "premium":this.allFormData[type]["spouse"].constant,
          "total":adjustWith2DecimalsForUI(this.productobj.total),
          "coverageUnitPrice":this.allFormData[type]["spouse"].coverage.coverageUnitPrice,
          "defaultRange":this.allFormData[type]["spouse"].coverage.defaultRange,
          "maximumCoverageWithEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithEvidence,
          "maximumCoverageWithoutEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
          "minimumCoverage":this.allFormData[type]["spouse"].coverage.minimumCoverage,
            }






        obj["member"]= memberObj
        obj["spouse"]= spouseObj

        spouseObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type]["spouse"].constant),product.taxesDataJSON??[]).tax
        spouseObj["guaranteedAcceptanceTotal"] =this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type]["spouse"].constant),product.taxesDataJSON??[]).total

        spouseObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(spousePrice*this.allFormData[type]["spouse"].constant,product.taxesDataJSON??[]).tax
        spouseObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(spousePrice*this.allFormData[type]["spouse"].constant,product.taxesDataJSON??[]).total
          }




    }
    else{

      memberObj={
        "planStartDate":this.userForm.value.planEnrollmentDate
    ? this._CustomDatePipe.transform(
        this.userForm.value.planEnrollmentDate
      )
    : undefined,
        "units":(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
        "standardUnits":this.allFormData[type]["member"].coverage.minimumCoverage,
        "coverage":"MEMBER",
        "guaranteedAcceptance":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=
        this.allFormData[type]["member"].coverage.defaultRange?true:false, //defalutRange
        "pendingMedicalQuestionaire":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >
        this.allFormData[type]["member"].coverage.defaultRange?true:false,
        "guaranteedAcceptanceAmount":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),
        "pendingApprovalAmount":adjustWith2DecimalsForUI(memberPrice*this.allFormData[type][category].constant )||0,
        "smoker":this.allFormData[type]["member"].isSmoker=="yes"?true:false,
        "age":this.allFormData[type]["member"].coverage.age,
        "gender":this.userForm.value.gender?.toLowerCase(),
        "province":"ON",
        "provinceId":12,
        "amount":this.allFormData[type]["member"].premium,
        "tax":adjustWith2DecimalsForUI(this.productobj.tax),
        "taxRate":"", //not mandatory,
        "taxName":"",
        "coverageValue":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
        "premium":this.allFormData[type]["member"].constant,
        "total":adjustWith2DecimalsForUI(this.productobj.total),
        "coverageUnitPrice":this.allFormData[type]["member"].coverage.coverageUnitPrice,
    "defaultRange":this.allFormData[type]["member"].coverage.defaultRange,
    "maximumCoverageWithEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithEvidence,
    "maximumCoverageWithoutEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
    "minimumCoverage":this.allFormData[type]["member"].coverage.minimumCoverage,
          }
          spouseObj={
            "planStartDate":this.userForm.value.planEnrollmentDate
    ? this._CustomDatePipe.transform(
        this.userForm.value.planEnrollmentDate
      )
    : undefined,
            "units":(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
            "standardUnits":this.allFormData[type]["spouse"].coverage.minimumCoverage,
            "coverage":"MEMBER & SPOUSE",
            "guaranteedAcceptance":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=
    this.allFormData[type]["spouse"].coverage.defaultRange?true:false, //defalutRange
    "pendingMedicalQuestionaire":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >
    this.allFormData[type]["spouse"].coverage.defaultRange?true:false,
    "guaranteedAcceptanceAmount":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type][category].constant),
    "pendingApprovalAmount":adjustWith2DecimalsForUI(spousePrice*this.allFormData[type][category].constant )||0,
    "smoker":this.allFormData[type]["spouse"].isSmoker=="yes"?true:false,
            "age":this.allFormData[type]["spouse"].coverage.age,
            "gender":this.userForm.value.spousegender?.toLowerCase(),
            "province":"ON",
            "provinceId":12,
            "amount":this.allFormData[type]["spouse"].premium,
            "tax":adjustWith2DecimalsForUI(this.productobj.tax),
            "taxRate":"", //not mandatory,
            "taxName":"",
            "coverageValue":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
            "premium":this.allFormData[type]["spouse"].constant,
            "total":adjustWith2DecimalsForUI(this.productobj.total),
            "coverageUnitPrice":this.allFormData[type]["spouse"].coverage.coverageUnitPrice,
    "defaultRange":this.allFormData[type]["spouse"].coverage.defaultRange,
    "maximumCoverageWithEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithEvidence,
    "maximumCoverageWithoutEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
    "minimumCoverage":this.allFormData[type]["spouse"].coverage.minimumCoverage,
              }

    obj["member"]= memberObj
    obj["spouse"]= spouseObj

    // let price = this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence > this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000):0*this.allFormData[type]["member"].constant

    //   console.log(price)
    //   console.log(this.calculateTaxForProductTaxesData(price,product.taxesDataJSON??[]))
    memberObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).tax
    memberObj["guaranteedAcceptanceTotal"] =this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).total
    spouseObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type]["spouse"].constant),product.taxesDataJSON??[]).tax
    spouseObj["guaranteedAcceptanceTotal"] =this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type]["spouse"].constant),product.taxesDataJSON??[]).total

    memberObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(memberPrice*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).tax
    memberObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(memberPrice*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).total

    spouseObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(spousePrice*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).tax
    spouseObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(spousePrice*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).total

    }


    console.log(memberObj["pendingApprovalTax"])
    let MSguranteeAcceptanceAmount = adjustWith2DecimalsForUI(memberObj["guaranteedAcceptanceAmount"]+(spouseObj["guaranteedAcceptanceAmount"]??0))
    let MSguranteeAcceptancetTAX = adjustWith2DecimalsForUI(memberObj["guaranteedAcceptanceTax"]+(spouseObj["guaranteedAcceptanceTax"]??0))
    let MSguranteeAcceptancetTotal = adjustWith2DecimalsForUI(memberObj["guaranteedAcceptanceTotal"]+(spouseObj["guaranteedAcceptanceTotal"]??0))

    let MSpendingApprovalAmount = adjustWith2DecimalsForUI(memberObj["pendingApprovalAmount"]+(spouseObj["pendingApprovalAmount"]??0))
    let MSpendingApprovalTAX = adjustWith2DecimalsForUI(memberObj["pendingApprovalTax"]+(spouseObj["pendingApprovalTax"]??0))
    let MSpendingApprovalTotal = adjustWith2DecimalsForUI(memberObj["pendingApprovalTotal"]+(spouseObj["pendingApprovalTotal"]??0))

    let obj1: any = {
      packageId: plan.packageId,
      packageName: pckage.name,

      planproductname: this.productobj.name,

      groupid: plan.planLevel,
      groupName: group.name,
      id: plan.id,
      name: plan.name,
      planLevel: plan.planLevel ? plan.planLevel : group.id,
      planLevelParent: group.parentId,
      fusebillPlanID: plan.fusebillId,
      planFrequencyID: plan.frqMonthly,
      isBundle: plan.isBundle,
      coverage: lifeCoverage,
      planCoverage: product.planCoverage,
      bundledProducts: [],
      products: [],
      version:plan.version,
      guaranteedAcceptanceDetails:{
        "amount":MSguranteeAcceptanceAmount,
        "tax":MSguranteeAcceptancetTAX,
        "total":MSguranteeAcceptancetTotal,
        "products": [{
          id: product.id,
          productId: product.productId,
          name: product.name,
          planProductId: product.planProductId,
          price: adjustWith2DecimalsForUI(MSguranteeAcceptanceAmount),
          tax_details:
            product.bundledTaxes && product.bundledTaxes.length > 0
              ? product.bundledTaxes
              : product.taxesDataJSON,
          calculatedTax: this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]),
          bundledProducts: product.bundledProducts,
          planCoverage: lifeCoverage,
          planLevel: product.planlevel ? product.planlevel.id : '',
          planLevelParent: product.planlevel ? product.planlevel.parentId : '',
          description: product.description,
          coverage: lifeCoverage,
          version:product.version,
          total :this._decimalPipe
          .transform(
            this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]).total : 0,
            '1.2-2'
          )
          ?.replace(/,/g, ''),
          tax : this._decimalPipe
          .transform(
            this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]).tax : 0,
            '1.2-2'
          )
          ?.replace(/,/g, '')
        }]


      },
      pendingApprovalDetails:{
        "amount":MSpendingApprovalAmount,
        "tax":this._decimalPipe
        .transform(
          this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]).tax : 0,
          '1.2-2'
        )
        ?.replace(/,/g, ''),
        "total":this._decimalPipe
        .transform(
          this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]).total : 0,
          '1.2-2'
        )
        ?.replace(/,/g, ''),
        "products": [{
          id: product.id,
          productId: product.productId,
          name: product.name,
          planProductId: product.planProductId,
          price: adjustWith2DecimalsForUI(MSpendingApprovalAmount),
          tax_details:
            product.bundledTaxes && product.bundledTaxes.length > 0
              ? product.bundledTaxes
              : product.taxesDataJSON,
          calculatedTax: this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]),
          bundledProducts: product.bundledProducts,
          planCoverage: lifeCoverage,
          planLevel: product.planlevel ? product.planlevel.id : '',
          planLevelParent: product.planlevel ? product.planlevel.parentId : '',
          description: product.description,
          coverage: lifeCoverage,
          version:product.version,
          total :this._decimalPipe
          .transform(
            this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]).total : 0,
            '1.2-2'
          )
          ?.replace(/,/g, ''),
          tax : this._decimalPipe
          .transform(
            this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]).tax : 0,
            '1.2-2'
          )
          ?.replace(/,/g, '')
        }]
      },
      selectedDetails:{
        "amount": adjustWith2DecimalsForUI(this.allFormData[type].total),
        "total":adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]).total : 0),

        "tax" :adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]).tax : 0),
        "products":[this.productobj]
      }


    };

    obj["guaranteedAcceptanceDetails"]=obj1.guaranteedAcceptanceDetails
    obj["pendingApprovalDetails"]=obj1.pendingApprovalDetails
    obj["selectedDetails"]=obj1.selectedDetails


    obj1.products.push(this.productobj);
    this.addtoplansummaryLife(obj, obj1);
  }, 100);
  for (let i = this.minValue; i <= this.allFormData[type][category]?.coverage?.maximumCoverageWithEvidence; i += 100000) {
    this.rangeValues.push(i);
  }
}

  public calculateTaxForProductTaxesData(price: number, taxesData: any) {
    // this.console.log(taxesData)
    // [{"tax_name":"PST","tax_code":"0002","plan_product_id":177927,"tax_description":"PST on Health Insurance","business_tax_number":"709505879TR0001","tax_rate":0.09,"tax_sku":"pstonhealthinsurance"}]
    let calculatedTax: any = {}
    let tax:any = 0;
    let planProductIds: any = []
    let planProductInfos: any = []
    if (taxesData && taxesData != undefined && taxesData != null && taxesData != "null") {
      for (const taxData of taxesData) {
        tax += adjustWith2DecimalsForUI(price * taxData.tax_rate);
        planProductIds.push(taxData.plan_product_id);
        let planProductInfo: any = {};
        // planProductInfo.name = taxData.tax_description + " " + (taxData.business_tax_number ?? "");
        planProductInfo.name = taxData.tax_name;
        planProductInfo.description = taxData.tax_description;
        planProductInfo.price = adjustWith2DecimalsForUI(price * taxData.tax_rate);
        planProductInfo.planProductUniqueId = taxData.plan_product_id;
        planProductInfo.isIncluded = true;
        planProductInfo.taxRate = taxData.tax_rate;
        planProductInfo.taxRatePercentage = adjustWith2DecimalsForUI((taxData.tax_rate) * 100) + "%";
        planProductInfos.push(planProductInfo);
      }
    }

    let total = adjustWith2DecimalsForUI(price + tax)
    calculatedTax.price = adjustWith2DecimalsForUI(price);
    calculatedTax.tax = adjustWith2DecimalsForUI(tax);
    calculatedTax.total = adjustWith2DecimalsForUI(total);

    calculatedTax.planProductIds = planProductIds;
    calculatedTax.planProductInfos = planProductInfos;

    return calculatedTax;

  }

  public transformData() {
    let transformedData: any = [];
    let id = 12;

    Object.keys(this.allFormData).forEach(key => {
      const plan = this.allFormData[key];
      if (plan.total > 0) {
        const hasMember: boolean = plan.hasOwnProperty('member');

        const hasSpouse = plan.hasOwnProperty('spouse');
        const hasChildren = plan.hasOwnProperty('dependent');

        const transformedObject: any = {
          name: key.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
          id: id++,
          havingSpouse: hasSpouse,
          havingChildren: hasChildren,
          total: plan.total,
        }

        transformedObject.member = {}

        if (hasMember && plan.member.premium > 0) {
          transformedObject.member = {
            isSmoker: plan.member.isSmoker,
            total: plan.member.premium,
            units: plan.member.coverage,
            // placeOfBirth: plan.member.PlaceOfBirth,
            premium: plan.member.constant
          }
        }

        transformedObject.spouse = {}

        if (hasSpouse && plan.spouse.premium > 0) {
          transformedObject.spouse = {
            isSmoker: plan.spouse.isSmoker,
            total: plan.spouse.premium,
            units: plan.spouse.coverage,
            // placeOfBirth: plan.spouse.PlaceOfBirth,
            premium: plan.spouse.constant

          };
        }

        transformedObject.dependent = []

        if (hasChildren && plan.dependent.premium > 0) {
          const dep = this.signUpData.childrenDetails.map((d:any) => {
            return {
              childFirstName: d.childFirstName,
              childLastName: d.childLastName,
              //  total: plan.dependent.premium/this.signUpData.childrenDetails.length,
              total: plan.dependent.premium,
              units: plan.dependent.coverage,
              premium: plan.dependent.constant,
              // placeOfBirth:
              //   plan.dependent[`${d.childFirstName}-${d.childLastName}`],
            };
          });

          transformedObject.dependent = dep;
          transformedObject.dependent.allDependentPremium = {
            total: plan.dependent.premium,
            units: plan.dependent.coverage,
            premium: plan.dependent.constant
          }

        }

        transformedData.push(transformedObject);
      }
    });

    return transformedData;
  }
  public onCheckboxChange(
    e: any,
    allowMultiple: any,
    disallowedPlanLevels: any,
    options: any,
    pname: any,
    groupname: any,
    plancoverage: any,
    planprice: any,
    gst: any,
    hst: any,
    pst: any,
    event: any,
    event1: any,
    packageindex: any,
    groupindex: any,
    planindex: any,
    plan: any,
    product: any,
    pckage: any,
    group: any,
    coverage: any
  ) {
    // e.preventDefault();
    // let multiple = allowMultiple ? Boolean(allowMultiple.data[0]) : false;

    let multiple = allowMultiple==true?true: false;

    console.log(multiple)

    if(e.target.checked){
             //unselect all checkboxes with same id(fix for single.couple and family multiple seelction)
             const dom: HTMLElement = this.elementRef.nativeElement;

             const samePlanIdElements = dom.querySelectorAll('#' + e.target.id);
             console.log(samePlanIdElements)

             for (let i = 0; i < samePlanIdElements.length; i++) {
               let elem = samePlanIdElements[i];
               let element:any = elem;
              //  console.log(elem)

               if (element.checked) {
                 element.checked = false;
                 this.unselectplan(elem);

               }
             }
             e.target.checked =true
    }else{
    //      this.planCoverageName = ''
    // this.insuranceplanid =''
    }

    // console.log(multiple)

    // console.log(plan)
    // console.log(product)


    //this.consoleMessage(disallowedPlanLevels)

    console.log(disallowedPlanLevels)

    if (disallowedPlanLevels != null) {
      //this.consoleMessage(disallowedPlanLevels)
      let disallowed_plans = disallowedPlanLevels.split(',');
      const dom1: HTMLElement = this.elementRef.nativeElement;
      const disallowed_elements = dom1.querySelectorAll(
        ".plansscreen input[type='checkbox']"
      );

      // disallowed_elements.forEach((elem: any) => {

      // console.log(disallowed_elements)

      for (let i = 0; i < disallowed_elements.length; i++) {
        let elem: any = disallowed_elements[i];
        // elementcv.checked=false

        if (!elem.attributes.data) {
          continue;
        }

        let plandetails = elem.attributes.data.value;

        let plandetailsobj = plandetails.split('##');

        //this.consoleMessage(plandetailsobj)

        // console.log(disallowed_plans)   //[1,10,19,20,21]
        // console.log(plandetailsobj[12])  //[24]
        // console.log(plandetailsobj[20]) //[10]
        // console.log(plandetailsobj[12])  //[24]
        // console.log(plandetailsobj[13])
        // console.log(plandetailsobj[19])
        // console.log(plandetailsobj[21])


        // console.log(typeof plandetailsobj[21])
        // console.log(plandetailsobj[12])
        // console.log(plandetailsobj[13])
        // console.log(plandetailsobj[20])


        let isBundle= plandetailsobj[21]=="true"?true:false
        // console.log(isBundle)
        if (e.target.checked) {

          if (!elem.disabled) {

            if (plandetailsobj[12] == null || plandetailsobj[12] == '') {
              if(isBundle){
                if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
                  //  elem.checked=false
                  console.log("1")
                  elem.disabled = true;
                  this.disabledelement =
                    'Already included in GroupBenefitz All-In';
                }
              }else{
                if (disallowed_plans.indexOf(plandetailsobj[20]) >= 0) {
                  //  elem.checked=false
                  console.log("2")

                  console.log(elem)

                  elem.disabled = true;
                  this.disabledelement =
                    'Already included in GroupBenefitz All-In';
                }
              }

            } else {

              if(isBundle){
                if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
                  console.log(elem)

                  if(elem.checked){
                    console.log("checkehd")
                    elem.checked = false;
                    this.unselectplan(elem);
                  }

                    elem.disabled = true;

                    this.disabledelement =
                      'Already included in GroupBenefitz All-In';
                  }
              }else{
                if (disallowed_plans.indexOf(plandetailsobj[20]) >= 0) {
                  console.log("4")
                  console.log(elem)
                  elem.disabled = true;

                  this.disabledelement =
                    'Already included in GroupBenefitz All-In';
                }
              }

            }
          }
          if (elem.checked) {

            if (plandetailsobj[12] == null || plandetailsobj[12] == '') {
              if(isBundle){
              if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
                elem.checked = false;
                this.unselectplan(elem);
              }

            }
            else{
              if (disallowed_plans.indexOf(plandetailsobj[20]) >= 0) {
                elem.checked = false;
                this.unselectplan(elem);
              }
            }
            } else {
              if(isBundle){
                if (disallowed_plans.indexOf(plandetailsobj[12]) >= 0) {
                  elem.checked = false;
                  this.unselectplan(elem);
                  // elem.disabled =true
                }
              }
              else{
                if (disallowed_plans.indexOf(plandetailsobj[20]) >= 0) {
                  elem.checked = false;
                  this.unselectplan(elem);
                  // elem.disabled =true
                }
              }

            }
          }
        }
        else {
          if (elem.disabled) {
            if (plandetailsobj[12] == null || plandetailsobj[12] == '') {
              if(isBundle){
              if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
                //  elem.checked=false
                elem.disabled = false;
              }
            }else{
              if (disallowed_plans.indexOf(plandetailsobj[20]) >= 0) {
                //  elem.checked=false
                elem.disabled = false;
              }
            }
            }
            if(isBundle){
              if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {

                  elem.disabled = false;

                  this.disabledelement =
                    'Already included in GroupBenefitz All-In';
                }
            }else{
              if (disallowed_plans.indexOf(plandetailsobj[20]) >= 0) {

                elem.disabled = false;

                this.disabledelement =
                  'Already included in GroupBenefitz All-In';
              }
            }

            // else {
            //   if (disallowed_plans.indexOf(plandetailsobj[12]) >= 0) {
            //     //  elem.checked=false
            //     elem.disabled = false;
            //   }
            // }
          }
          if (elem.checked) {

            console.log("elechecked")

            if (plandetailsobj[12] == null || plandetailsobj[12] == '') {
              if(isBundle){
              if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
                elem.checked = false;
                console.log("elechecked1")
                this.unselectplan(elem);
              }

            }
            else{
              if (disallowed_plans.indexOf(plandetailsobj[20]) >= 0) {
                elem.checked = false;
                console.log("elechecked2")
                this.unselectplan(elem);
              }
            }
            } else {
              if(isBundle){
                if (disallowed_plans.indexOf(plandetailsobj[12]) >= 0) {
                  elem.checked = false;
                  this.unselectplan(elem);
                  // elem.disabled =true
                }
              }
              else{
                if (disallowed_plans.indexOf(plandetailsobj[20]) >= 0) {
                  elem.checked = false;
                  this.unselectplan(elem);
                  // elem.disabled =true
                }
              }

            }
          }
        }
      }
    }

    let sameplanclassname = `newPlan${plan.id}`
    let covergaClassNmae =`coverage${coverage.toUpperCase()}` // //////this.consoleMessage(e)
    let classname = e.target.className.replace(sameplanclassname,'').replace(covergaClassNmae,'');
    classname = classname.split(' ').join('.').replaceAll('..','.');

    console.log(classname)
    console.log(sameplanclassname)
    // //////this.consoleMessage(classname)
    const dom: HTMLElement = this.elementRef.nativeElement;
    const elements = dom.querySelectorAll(classname);
    // const elements = dom.querySelectorAll(e.target.className);

    // const samePlanElements = dom.querySelectorAll('.' + sameplanclassname);

    // //////this.consoleMessage(elements);

    let element: any;
    let elementcv: any;

    if (!multiple) {
      console.log("multiple elesehere")
      if (e.target.checked) {

         console.log(elements)
        for (let i = 0; i < elements.length; i++) {
          let elem = elements[i];
          element = elem;

          if (element.checked) {

            this.unselectplan(elem);
            element.checked = false;
          }
        }

        e.checked = true;
        e.target.checked = true;
      } else {
      }
    }
    else {

      // console.log("elesehere")
      if (e.target.checked) {


        // for (let i = 0; i < samePlanElements.length; i++) {
        //   let elem = samePlanElements[i];
        //   element = elem;

        //   if (element.checked) {

        //     this.unselectplan(elem);
        //     element.checked = false;
        //   }
        // }

        e.checked = true;
        e.target.checked = true;
      } else {
      }
      // alert("!")
      //no checking for other checkboxes you can selet multiple
    }
    setTimeout(() => {
      if (e.target.checked) {
        this.selectplan(e.target, options, plan, product, pckage, group,disallowedPlanLevels);
      } else {
        this.unselectplan(e.target);
      }
    }, 10);
  }
  gotoPersonalInfoPage(): void {
    // console.log(this.previousTab);
    this.activeTab--;
    this.planAmount =0.00
  }

  gotoPlansPage(): void {
    this.activeTab--;
    let data:any = JSON.parse(sessionStorage.getItem("plansummary")|| '[]');
    let obj = {};

    if (data) {

      data.forEach((element:any,index:any) => {
        obj = {
          planid: element.id,
        };

        setTimeout(() => {

          const dom: HTMLElement = this.elementRef.nativeElement;
          const plan: any = dom.querySelector("#plancheck" + element.id);


          if (element.options && element.options.length > 0) {
            if(element.isBundle){
              const planCheck = this.renderer.selectRootElement("#plancheck"+ element.id, true);
              const plan: any = dom.querySelector("#plancheck" + element.id + ".coverage"+element.coverage);

              this.renderer.setProperty(plan, 'checked', true);
              if (element.disallowedPlanLevels != null) {
                //this.consoleMessage(disallowedPlanLevels)
                let disallowed_plans = element.disallowedPlanLevels.split(',');
                const dom1: HTMLElement = this.elementRef.nativeElement;
                const disallowed_elements = dom1.querySelectorAll(
                  ".plansscreen input[type='checkbox']"
                );

                // disallowed_elements.forEach((elem: any) => {

                // console.log(disallowed_elements)

                for (let i = 0; i < disallowed_elements.length; i++) {
                  let elem: any = disallowed_elements[i];
                  // elementcv.checked=false

                  if (!elem.attributes.data) {
                    continue;
                  }

                  let plandetails = elem.attributes.data.value;

                  let plandetailsobj = plandetails.split('##');




                  let isBundle= plandetailsobj[21]=="true"?true:false
                  // console.log(isBundle)


                    if (!elem.disabled) {

                      if (plandetailsobj[12] == null || plandetailsobj[12] == '') {
                        if(isBundle){
                          if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
                            //  elem.checked=false
                            console.log("1")
                            elem.disabled = true;
                            this.disabledelement =
                              'Already included in GroupBenefitz All-In';
                          }
                        }else{
                          if (disallowed_plans.indexOf(plandetailsobj[20]) >= 0) {
                            //  elem.checked=false
                            console.log("2")

                            console.log(elem)

                            elem.disabled = true;
                            this.disabledelement =
                              'Already included in GroupBenefitz All-In';
                          }
                        }

                      }

                      else {

                        if(isBundle){
                          if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
                            console.log(elem)

                            if(elem.checked){
                              console.log("checkehd")
                              elem.checked = false;
                              this.unselectplan(elem);
                            }

                              elem.disabled = true;

                              this.disabledelement =
                                'Already included in GroupBenefitz All-In';
                            }
                        }else{
                          if (disallowed_plans.indexOf(plandetailsobj[20]) >= 0) {
                            console.log("4")
                            console.log(elem)
                            elem.disabled = true;

                            this.disabledelement =
                              'Already included in GroupBenefitz All-In';
                          }
                        }

                      }
                    }





                }
              }
            }
           else{
            element.products.forEach((products:any,index:any) => {
              if(element.coverage){
                const plan: any = dom.querySelector("#plancheck" + products.planLevel + ".coverage"+element.coverage);


                this.renderer.setProperty(plan, 'checked', true);
              }
              else{
                const planCheck = this.renderer.selectRootElement("#plancheck"+ products.planLevel, true);
                this.renderer.setProperty(planCheck, 'checked', true);

              }
              if (element.disallowedPlanLevels != null) {
                //this.consoleMessage(disallowedPlanLevels)
                let disallowed_plans = element.disallowedPlanLevels.split(',');
                const dom1: HTMLElement = this.elementRef.nativeElement;
                const disallowed_elements = dom1.querySelectorAll(
                  ".plansscreen input[type='checkbox']"
                );

                // disallowed_elements.forEach((elem: any) => {

                // console.log(disallowed_elements)

                for (let i = 0; i < disallowed_elements.length; i++) {
                  let elem: any = disallowed_elements[i];
                  // elementcv.checked=false

                  if (!elem.attributes.data) {
                    continue;
                  }

                  let plandetails = elem.attributes.data.value;

                  let plandetailsobj = plandetails.split('##');




                  let isBundle= plandetailsobj[21]=="true"?true:false
                  // console.log(isBundle)


                    if (!elem.disabled) {

                      if (plandetailsobj[12] == null || plandetailsobj[12] == '') {
                        if(isBundle){
                          if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
                            //  elem.checked=false
                            console.log("1")
                            elem.disabled = true;
                            this.disabledelement =
                              'Already included in GroupBenefitz All-In';
                          }
                        }else{
                          if (disallowed_plans.indexOf(plandetailsobj[20]) >= 0) {
                            //  elem.checked=false
                            console.log("2")

                            console.log(elem)

                            elem.disabled = true;
                            this.disabledelement =
                              'Already included in GroupBenefitz All-In';
                          }
                        }

                      }

                      else {

                        if(isBundle){
                          if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
                            console.log(elem)

                            if(elem.checked){
                              console.log("checkehd")
                              elem.checked = false;
                              this.unselectplan(elem);
                            }

                              elem.disabled = true;

                              this.disabledelement =
                                'Already included in GroupBenefitz All-In';
                            }
                        }else{
                          if (disallowed_plans.indexOf(plandetailsobj[20]) >= 0) {
                            console.log("4")
                            console.log(elem)
                            elem.disabled = true;

                            this.disabledelement =
                              'Already included in GroupBenefitz All-In';
                          }
                        }

                      }
                    }





                }
              }
            })
           }

          } else {
            if(element.isBundle){
              const dom: HTMLElement = this.elementRef.nativeElement;

                  console.log(element.coverage)
              const plan: any = dom.querySelector("#plancheck" + element.id + ".coverage"+element.coverage);
              const planCheck = this.renderer.selectRootElement("#plancheck"+ element.id, true);

              this.renderer.setProperty(plan, 'checked', true);



              // planCheck.click()
              if (element.disallowedPlanLevels != null) {
                //this.consoleMessage(disallowedPlanLevels)
                let disallowed_plans = element.disallowedPlanLevels.split(',');
                const dom1: HTMLElement = this.elementRef.nativeElement;
                const disallowed_elements = dom1.querySelectorAll(
                  ".plansscreen input[type='checkbox']"
                );

                // disallowed_elements.forEach((elem: any) => {

                // console.log(disallowed_elements)

                for (let i = 0; i < disallowed_elements.length; i++) {
                  let elem: any = disallowed_elements[i];
                  // elementcv.checked=false

                  if (!elem.attributes.data) {
                    continue;
                  }

                  let plandetails = elem.attributes.data.value;

                  let plandetailsobj = plandetails.split('##');




                  let isBundle= plandetailsobj[21]=="true"?true:false
                  // console.log(isBundle)


                    if (!elem.disabled) {

                      if (plandetailsobj[12] == null || plandetailsobj[12] == '') {
                        if(isBundle){
                          if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
                            //  elem.checked=false
                            console.log("1")
                            elem.disabled = true;
                            this.disabledelement =
                              'Already included in GroupBenefitz All-In';
                          }
                        }else{
                          if (disallowed_plans.indexOf(plandetailsobj[20]) >= 0) {
                            //  elem.checked=false
                            console.log("2")

                            console.log(elem)

                            elem.disabled = true;
                            this.disabledelement =
                              'Already included in GroupBenefitz All-In';
                          }
                        }

                      }

                      else {

                        if(isBundle){
                          if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
                            console.log(elem)

                            if(elem.checked){
                              console.log("checkehd")
                              elem.checked = false;
                              this.unselectplan(elem);
                            }

                              elem.disabled = true;

                              this.disabledelement =
                                'Already included in GroupBenefitz All-In';
                            }
                        }else{
                          if (disallowed_plans.indexOf(plandetailsobj[20]) >= 0) {
                            console.log("4")
                            console.log(elem)
                            elem.disabled = true;

                            this.disabledelement =
                              'Already included in GroupBenefitz All-In';
                          }
                        }

                      }
                    }





                }
              }

            }
           else{
            element.products.forEach((products:any,index:any) => {

              if(element.coverage){
                const plan: any = dom.querySelector("#plancheck" + products.planLevel + ".coverage"+element.coverage);


                this.renderer.setProperty(plan, 'checked', true);
              }
              else{
                const planCheck = this.renderer.selectRootElement("#plancheck"+ products.planLevel, true);
                this.renderer.setProperty(planCheck, 'checked', true);

              }


            })
            if (element.disallowedPlanLevels != null) {
              //this.consoleMessage(disallowedPlanLevels)
              let disallowed_plans = element.disallowedPlanLevels.split(',');
              const dom1: HTMLElement = this.elementRef.nativeElement;
              const disallowed_elements = dom1.querySelectorAll(
                ".plansscreen input[type='checkbox']"
              );

              // disallowed_elements.forEach((elem: any) => {

              // console.log(disallowed_elements)

              for (let i = 0; i < disallowed_elements.length; i++) {
                let elem: any = disallowed_elements[i];
                // elementcv.checked=false

                if (!elem.attributes.data) {
                  continue;
                }

                let plandetails = elem.attributes.data.value;

                let plandetailsobj = plandetails.split('##');




                let isBundle= plandetailsobj[21]=="true"?true:false
                // console.log(isBundle)


                  if (!elem.disabled) {

                    if (plandetailsobj[12] == null || plandetailsobj[12] == '') {
                      if(isBundle){
                        if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
                          //  elem.checked=false
                          console.log("1")
                          elem.disabled = true;
                          this.disabledelement =
                            'Already included in GroupBenefitz All-In';
                        }
                      }else{
                        if (disallowed_plans.indexOf(plandetailsobj[20]) >= 0) {
                          //  elem.checked=false
                          console.log("2")

                          console.log(elem)

                          elem.disabled = true;
                          this.disabledelement =
                            'Already included in GroupBenefitz All-In';
                        }
                      }

                    }

                    else {

                      if(isBundle){
                        if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
                          console.log(elem)

                          if(elem.checked){
                            console.log("checkehd")
                            elem.checked = false;
                            this.unselectplan(elem);
                          }

                            elem.disabled = true;

                            this.disabledelement =
                              'Already included in GroupBenefitz All-In';
                          }
                      }else{
                        if (disallowed_plans.indexOf(plandetailsobj[20]) >= 0) {
                          console.log("4")
                          console.log(elem)
                          elem.disabled = true;

                          this.disabledelement =
                            'Already included in GroupBenefitz All-In';
                        }
                      }

                    }
                  }
              }
            }
           }
            // element.products.forEach((products:any,index:any) => {
            //   // $("#plancheck" + products.id).click();
            //   const planCheck = this.renderer.selectRootElement("#plancheck"+ products.id, true);

            //   // console.log(planCheck)
            //   this.renderer.setProperty(planCheck, 'checked', true);
            // })
          }

          // $("#plancheck" + element.id).prop("checked", true);
          // plan.checked =true
        }, 100);

        // this.plancheckarray.push(element.id)
      });

    }

    let dataLife:any = JSON.parse(sessionStorage.getItem("plansummaryLife")|| '[]');

    if (dataLife) {

      dataLife.forEach((element:any,index:any) => {

        setTimeout(() => {

          const dom: HTMLElement = this.elementRef.nativeElement;
          // const plan: any = dom.querySelector("#planCheckLife" + element.planCoverage+"-"+coverage);



            element.products.forEach((products:any,index:any) => {
              // $("#plancheck" + products.id).click();
              if(element.coverage=='MEMBER'){
                element.coverage='MEMBER'
                const planCheck = this.renderer.selectRootElement("#planCheckLife" + element.planCoverage+"-"+element.coverage, true);

                this.renderer.setProperty(planCheck, 'checked', true);
              }else{
                element.coverage='SPOUSE'
                const planCheck = this.renderer.selectRootElement("#planCheckLife" + element.planCoverage+"-"+element.coverage, true);

                this.renderer.setProperty(planCheck, 'checked', true);
                const planCheck1 = this.renderer.selectRootElement("#planCheckLife" + element.planCoverage+"-"+'MEMBER', true);

                this.renderer.setProperty(planCheck1, 'checked', true);
              }
              // if(element.coverage=='MEMBER'){
              //   element.coverage='MEMBER'
              // }else{
              //   element.coverage='SPOUSE'
              // }
              // const planCheck = this.renderer.selectRootElement("#planCheckLife" + element.planCoverage+"-"+element.coverage, true);

              // // console.log(planCheck)
              // this.renderer.setProperty(planCheck, 'checked', true);
            })


          // $("#plancheck" + element.id).prop("checked", true);
          // plan.checked =true
        }, 100);

        // this.plancheckarray.push(element.id)
      });

    }
  }
  // paymentpage(): void{
  //   if (this.activeTab < this.tabs.length - 1) {
  //     this.previousTab = this.activeTab;
  //     this.activeTab++;
  //   }
  // }

  public paymentpage12(tabId: number) {



    this.paymentForm.get('paymentRadioCheck')?.setValue("CC");


    let inputData = JSON.parse(sessionStorage.getItem('Data') || '[]');
    let Data = JSON.parse(sessionStorage.getItem('plansummarymain') || '[]');
    let iafgData = JSON.parse(sessionStorage.getItem('plansummaryLife') || '[]');
this.paymentemail =inputData.email || this.userForm.get('email')?.value
this.paymentFirstName =this.capitalize(inputData.firstName) || this.userForm.get('firstName')?.value;
      this.paymentLastName =this.capitalize(inputData.lastName) || this.userForm.get('lastName')?.value

    if (inputData.provincial_health_coverage == false) {
      let phrPlanSelected = Data.filter((p: any) => p.packageId == 10);
      let healthPlanSelected = Data.filter((p: any) => p.packageId == 1);
      let benficiaryList =JSON.parse(sessionStorage.getItem('addbenificaryData') || '[]')




      if (phrPlanSelected.length > 0) {
        if (this.activeTab < this.tabs.length - 1) {
          this.previousTab = this.activeTab;
          this.activeTab++;
          this.visitedTabs.add(this.previousTab);
        }
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            this.scrollContainer.nativeElement.scrollTo(0, 0);
          }
        });
        this.imagedisplay = false;
        setTimeout(() => {
          sessionStorage.setItem('pageName', 'Enrollment Summary');
        }, 10);
        this.planssummarymain = JSON.parse(
          sessionStorage.getItem('plansummarymain') || '[]'
        );

        this.planssummaryopt = JSON.parse(
          sessionStorage.getItem('plansummaryopt') || '[]'
        );
        let array: any = [];

        this.planSummaryMainResult =
          array.sort((a: any, b: any) => a.id - b.id) || '';

        this.planssummarymain =
          this.planssummarymain.sort(
            (a: any, b: any) => a.packageId - b.packageId
          ) || '';
        this.finalenrollemntsummary = this.planssummarymain;
        this.finalenrollemntsummaryLife =this.planssummarymainLife

        const combinedArray: any = [];
        const groupedByParent: any = {};
        //  this.paymentForm.get('FirstName')

        for (const obj of this.planSummaryMainResult) {
          //this.consoleMessage(obj)

          const name = obj.packageName;

          const products = obj.products;
          if (!groupedByParent[name]) {
            groupedByParent[name] = [];
          }
          groupedByParent[name] = groupedByParent[name].concat(products);
        }
        // Push grouped child arrays into combinedArray

        for (const name in groupedByParent) {
          combinedArray.push({ name, products: groupedByParent[name] });
        }

        this.planSummaryMainResult = combinedArray;

        let plansData = {
          customerName: inputData.firstName + '' + inputData.lastName,
          contactNum: inputData.phone_number,
          dob: inputData.date_of_birth,
          email: inputData.email,
          process: 'planSelection',
          request: { plans: Data },
        };

        //this.consoleMessage(plansData)
        // this.loginService.savePlan(plansData).subscribe((result: any) => {
        //   //this.consoleMessage(result)
        // })
        // $("#planSelectionInfo").css("display", "block");
      }
      else {
        if (healthPlanSelected.length > 0) {

            this.ShowModelPopup =true
            this.ShowModelPopupMessage =this.lang.Please_select_a_Provincial_Health_Replacement_Plan_along_with_a_Health_Dental_Insurance_Plan;
            this.ShowModelPopupTitle =this.lang.Info
        }
        else{
          this.ShowModelPopup =true
          this.ShowModelPopupMessage =this.lang.Please_select_a_Provincial_Health_Replacement_Plan;
          this.ShowModelPopupTitle =this.lang.Info
        }


      }

    }
    else {

      let iafgPlanSelected = iafgData.filter((p: any) => p.packageId == 13);
      let healthPlanSelected = Data.filter((p: any) => p.packageId == 1);

      let benficiaryList =JSON.parse(sessionStorage.getItem('addbenificaryData') || '[]')
      console.log(iafgPlanSelected)
      console.log(healthPlanSelected)
if(iafgPlanSelected.length>0){
  if(healthPlanSelected.length>0){
      console.log("here")
      if (this.activeTab < this.tabs.length - 1) {
        this.previousTab = this.activeTab;
        this.activeTab++;
      }
        setTimeout(() => {
          sessionStorage.setItem('pageName', 'Enrollment Summary');
        }, 10);
        this.planssummarymain = JSON.parse(
          sessionStorage.getItem('plansummarymain') || '[]'
        );

        this.planssummaryopt = JSON.parse(
          sessionStorage.getItem('plansummaryopt') || '[]'
        );
        let array: any = [];

        this.planSummaryMainResult =
          array.sort((a: any, b: any) => a.id - b.id) || '';

        this.planssummarymain =
          this.planssummarymain.sort(
            (a: any, b: any) => a.packageId - b.packageId
          ) || '';
        this.finalenrollemntsummary = this.planssummarymain;
        this.finalenrollemntsummaryLife =this.planssummarymainLife

        const combinedArray: any = [];
        const groupedByParent: any = {};
        //  this.paymentForm.get('FirstName')

        for (const obj of this.planSummaryMainResult) {
          //this.consoleMessage(obj)

          const name = obj.packageName;

          const products = obj.products;
          if (!groupedByParent[name]) {
            groupedByParent[name] = [];
          }
          groupedByParent[name] = groupedByParent[name].concat(products);
        }
        // Push grouped child arrays into combinedArray

        for (const name in groupedByParent) {
          combinedArray.push({ name, products: groupedByParent[name] });
        }

        this.planSummaryMainResult = combinedArray;

        //this.consoleMessage(this.planSummaryMainResult)

        // let inputData = JSON.parse(sessionStorage.getItem("Data"));
        // let Data = JSON.parse(sessionStorage.getItem("plansummarymain"))

        let plansData = {
          customerName: inputData.firstName + '' + inputData.lastName,
          contactNum: inputData.phone_number,
          dob: inputData.date_of_birth,
          email: inputData.email,
          process: 'planSelection',
          request: { plans: Data },
        };

        //this.consoleMessage(plansData)
        this._SignupformService.savePlan(plansData).subscribe((result: any) => {
        })


  }
  else{
    this.ShowModelPopup =true
    this.ShowModelPopupMessage = this.lang.Please_select_a_Health_Dental_Plan_along_with_a_Insurance_Plan;
    this.ShowModelPopupTitle =this.lang.Info
  }

}
else{

  console.log("here")
  if (this.activeTab < this.tabs.length - 1) {
    this.previousTab = this.activeTab;
    this.activeTab++;
  }
    setTimeout(() => {
      sessionStorage.setItem('pageName', 'Enrollment Summary');
    }, 10);
    this.planssummarymain = JSON.parse(
      sessionStorage.getItem('plansummarymain') || '[]'
    );

    this.planssummaryopt = JSON.parse(
      sessionStorage.getItem('plansummaryopt') || '[]'
    );
    let array: any = [];

    this.planSummaryMainResult =
      array.sort((a: any, b: any) => a.id - b.id) || '';

    this.planssummarymain =
      this.planssummarymain.sort(
        (a: any, b: any) => a.packageId - b.packageId
      ) || '';
    this.finalenrollemntsummary = this.planssummarymain;
    this.finalenrollemntsummaryLife =this.planssummarymainLife

    const combinedArray: any = [];
    const groupedByParent: any = {};
    //  this.paymentForm.get('FirstName')

    for (const obj of this.planSummaryMainResult) {
      //this.consoleMessage(obj)

      const name = obj.packageName;

      const products = obj.products;
      if (!groupedByParent[name]) {
        groupedByParent[name] = [];
      }
      groupedByParent[name] = groupedByParent[name].concat(products);
    }
    // Push grouped child arrays into combinedArray

    for (const name in groupedByParent) {
      combinedArray.push({ name, products: groupedByParent[name] });
    }

    this.planSummaryMainResult = combinedArray;

    //this.consoleMessage(this.planSummaryMainResult)

    // let inputData = JSON.parse(sessionStorage.getItem("Data"));
    // let Data = JSON.parse(sessionStorage.getItem("plansummarymain"))

    let plansData = {
      customerName: inputData.firstName + '' + inputData.lastName,
      contactNum: inputData.phone_number,
      dob: inputData.date_of_birth,
      email: inputData.email,
      process: 'planSelection',
      request: { plans: Data },
    };

    //this.consoleMessage(plansData)
    this._SignupformService.savePlan(plansData).subscribe((result: any) => {
    })
}


    }

  }

  public paymentpage(tabId: number){
    this.bankpayForm.reset();
    this.banknameres = '';
    // this.paymentForm.reset();
    this.paymentForm.get('paymentRadioCheck')?.setValue("CC");

    let benficiryData=JSON.parse(sessionStorage.getItem('addbenificaryData') || '[]');
    let inputData = JSON.parse(sessionStorage.getItem('Data') || '[]');
    let Data = JSON.parse(sessionStorage.getItem('plansummarymain') || '[]');
    let iafgData = JSON.parse(sessionStorage.getItem('plansummaryLife') || '[]');
    let phrPlanSelected = Data.filter((p: any) => p.packageId == 10);
    let healthPlanSelected = Data.filter((p: any) => p.packageId == 1);
    let iafgDataSelectedPlan = iafgData.filter((p: any) => p.packageId == 13);
    let iafgTermplanCheck = iafgData.filter((p: any) => p.planLevel == 44);
    let benficiaryList =JSON.parse(sessionStorage.getItem('addbenificaryData') || '[]')
    this.paymentemail =inputData.email || this.userForm.get('email')?.value
    this.paymentFirstName =this.capitalize(inputData.firstName) || this.userForm.get('firstName')?.value;
      this.paymentLastName =this.capitalize(inputData.lastName) || this.userForm.get('lastName')?.value
      let iafgTermplanSpouseCheck = iafgData.filter((p: any) => p.coverage == 'MEMBER & SPOUSE');

      // if(iafgTermplanCheck.length>0){

      //   if(this.addbenificaryData.length>0){
      //     this.benficiary =false;
      //   }else{
      //     this.benficiary =true;

      //     return false;

      //   }

      // }
      if(iafgTermplanCheck && iafgTermplanCheck.length>0){

        if(this.addbenificaryData.length>0){
          this.benficiary =false;
          const validateBeneficiaries = this.addbenificaryData.reduce((acc: any, obj: any) => {
            const compositeKey = `${obj.customerType}_${obj.type}`;
            if (!acc[compositeKey]) {
              acc[compositeKey] = 0;
            }
            acc[compositeKey] += parseFloat(obj.percentage);
            return acc;
          }, {});

          let beneficiaryError = "";
          for (const key in validateBeneficiaries) {
            if (validateBeneficiaries.hasOwnProperty(key)) {
              const [customerType, type] = key.split("_");
              console.log(`The customerType ${customerType} and type ${type} with perc: ${validateBeneficiaries[key]}`);

              if (validateBeneficiaries[key] != 100) {
                beneficiaryError += `CustomerType ${customerType} and type ${type} has percentage issue. \n`
              }
            }
          }

          if(!this.beneficiaryError){
            this.benficiary =false

            if(iafgTermplanSpouseCheck.length>0){

              if(sessionStorage.getItem("Spousesignature") || ""){
                // this.gotosumamrypageLogic()
              }else{
                this.showSpouseSignature = true
                this.signatureNeeded = this.signaturePad.isEmpty();
                // if(this.signatureNeeded==false){

                //   const base64Data = this.signaturePad.toDataURL();
                //   // console.log(base64Data);
                //   sessionStorage.setItem("Spousesignature", base64Data);

                // }
                // else{
                //   sessionStorage.setItem("signature", "");
                //   this.showpaderrorMessage= true
                // }

              }
            }else{
              // this.gotosumamrypageLogic()
            }



          }
          else{
            this.benficiary =true
            // this.benficiaryCount =true
            // this.benficiaryCountMessage='Please Enter Benefeciary Percentage equal to 100'
          }


        }else{
          this.benficiary =true;
          if(this.addbenificaryData.length>0){

          }else{
            this.showBenificiaryForm =true
          }


          return false;

        }

      }else{
        this.addbenificaryData =[]
        // this.gotosumamrypageLogic()
      }
    if (inputData.provincial_health_coverage == false) {

      if(phrPlanSelected.length>0){
        if (healthPlanSelected.length > 0) {
          if(iafgDataSelectedPlan.length>0){

            if (healthPlanSelected.length > 0) {

              if(iafgTermplanCheck.length>0){

                var total = 0;
                for (let i = 0; i < benficiryData.length; i++) {
                  total += parseFloat(benficiryData[i].percentage);
                }

                if(!this.beneficiaryError){
                  this.benficiary =false
                  this.showbenificaryTable =false;
                  this.gotosumamrypageLogic()
                }else{
                  this.benficiary =true
                  this.showbenificaryTable=true;
                  this.showaddmorebenficiary =true;

                }

              }
              else{
                this.gotosumamrypageLogic()
              }



          }
          else{
            this.ShowModelPopup =true
            this.ShowModelPopupMessage =this.lang.Please_select_a_Health_Dental_Insurance_Plan_along_with_a_Life_or_Critical_Insurance_Plan;
            this.ShowModelPopupTitle =this.lang.Info
          }
          }
          else{
            this.gotosumamrypageLogic()
          }
      }
      else{
        this.ShowModelPopup =true
        this.ShowModelPopupMessage =this.lang.Please_select_a_Provincial_Health_Replacement_Plan_along_with_a_Health_Dental_Insurance_Plan;
        this.ShowModelPopupTitle =this.lang.Info
      }

      }


      else {
        if (healthPlanSelected.length > 0) {
            this.ShowModelPopup =true
            this.ShowModelPopupMessage =this.lang.Please_select_a_Provincial_Health_Replacement_Plan;
            this.ShowModelPopupTitle =this.lang.Info
        }
        else{
          this.ShowModelPopup =true
          this.ShowModelPopupMessage =this.lang.Please_select_a_Provincial_Health_Replacement_Plan_along_with_a_Health_Dental_Insurance_Plan;
          this.ShowModelPopupTitle =this.lang.Info
        }


      }
    }
    else{

      if(iafgDataSelectedPlan.length>0){

        if (healthPlanSelected.length > 0) {

          if(iafgTermplanCheck.length>0){

            var total = 0;
            for (let i = 0; i < benficiryData.length; i++) {
              total += parseFloat(benficiryData[i].percentage);
            }

            if(!this.beneficiaryError){
              this.benficiary =false
              this.showbenificaryTable =false;


              this.gotosumamrypageLogic()
            }else{
              this.benficiary =true
              this.showbenificaryTable=true;
              this.showaddmorebenficiary =true;

            }

          }
          else{

            this.gotosumamrypageLogic()
          }



      }
      else{
        this.ShowModelPopup =true
        this.ShowModelPopupMessage =this.lang.Please_select_a_Health_Dental_Insurance_Plan_along_with_a_Life_or_Critical_Insurance_Plan;
        this.ShowModelPopupTitle =this.lang.Info
      }
      }
      else{
        this.gotosumamrypageLogic()
      }

    }
  }
public genderCheck(event:any){

  // console.log(event?.target.value)

  // if(event.target.value !='Male' || event.target.value !='Female'){
  //   if(this.iafgCheck){
  //     this.iafginfo =true
  //   }
  //   else{
  //     this.iafginfo =false
  //   }

  // }
  // else{
  //   this.iafginfo =false

  // }

}

public closeConfirmModel(){
  this.ConfirmModel = false
}
  public gotosumamrypageLogic(){



    let iafgData = JSON.parse(sessionStorage.getItem('plansummaryLife') || '[]');

    let iafgTermplanCheck = iafgData.filter((p: any) => p.planLevel == 45);
    let iafgTermLifeplanCheck = iafgData.filter((p: any) => p.planLevel == 44);
if(iafgData.length>1){
  this.ConfirmModel =true
}



    if(iafgTermplanCheck.length>0){
      this.criticalplancheck =true
    }
    else{
      this.criticalplancheck =false
    }

    if(iafgTermLifeplanCheck.length>0){
      this.termLifeplancheck =true
    }
    else{
      this.termLifeplancheck =false
    }
    let inputData = JSON.parse(sessionStorage.getItem('Data') || '[]');
    let Data = JSON.parse(sessionStorage.getItem('plansummarymain') || '[]');
    if (this.activeTab < this.tabs.length - 1) {
      this.previousTab = this.activeTab;
      this.activeTab++;
      this.visitedTabs.add(this.previousTab);
    }
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.scrollContainer.nativeElement.scrollTo(0, 0);
      }
    });
    this.imagedisplay = false;
    setTimeout(() => {
      sessionStorage.setItem('pageName', 'Enrollment Summary');
    }, 10);
    this.planssummarymain = JSON.parse(
      sessionStorage.getItem('plansummarymain') || '[]'
    );

    this.planssummaryopt = JSON.parse(
      sessionStorage.getItem('plansummaryopt') || '[]'
    );
    let array: any = [];

    this.planSummaryMainResult =
      array.sort((a: any, b: any) => a.id - b.id) || '';

    this.planssummarymain =
      this.planssummarymain.sort(
        (a: any, b: any) => a.packageId - b.packageId
      ) || '';
    this.finalenrollemntsummary = this.planssummarymain;
    this.finalenrollemntsummaryLife =this.planssummarymainLife

    console.log(this.finalenrollemntsummaryLife)

    const combinedArray: any = [];
    const groupedByParent: any = {};
    //  this.paymentForm.get('FirstName')

    for (const obj of this.planSummaryMainResult) {
      //this.consoleMessage(obj)

      const name = obj.packageName;

      const products = obj.products;
      if (!groupedByParent[name]) {
        groupedByParent[name] = [];
      }
      groupedByParent[name] = groupedByParent[name].concat(products);
    }
    // Push grouped child arrays into combinedArray

    for (const name in groupedByParent) {
      combinedArray.push({ name, products: groupedByParent[name] });
    }

    this.planSummaryMainResult = combinedArray;

    let plansData = {
      customerName: inputData.firstName + '' + inputData.lastName,
      contactNum: inputData.phone_number,
      dob: inputData.date_of_birth,
      email: inputData.email,
      process: 'planSelection',
      request: { plans: Data },
    };
    this._SignupformService.savePlan(plansData).subscribe((result: any) => {
    })
  }
  public checkbankdetails(event: any) {
    // this.bankdetailsverify =  true
    if (event.target.value == 'yes') {
    } else {
      this.bankpayForm.reset();
      this.imagedisplay = false;
      sessionStorage.removeItem('fileurl');
      sessionStorage.removeItem('filename');
      // this.bankpayForm.get('bankcode').value
    }

    this.verifybankdetails = false;
  }
  public verifybank() {
    // alert()

    // this.isVisible =0

    this.banknameres = '';

    let inputData = {
      bankCode: this.bankpayForm.controls['banknumber'].value,
      branchCode: this.bankpayForm.controls['transitnumber'].value,
    };

    this._SignupformService.getbanknames(inputData).subscribe(
      (result: any) => {
        if (result.status == '200') {
          // this.bankdetailsverify =false
          this.verifybutton = false;
          this.bankverifyDetails = result.data;
          this.banknameres =
            result.data.bank.name +
            '\n' +
            result.data.address.split(',').join('\n');
          // this.invalidbankdetails = false;
        } else if (result.status == '202') {
          // this.toastrService.warning(result.message, '');
          this.bankerrormsg = result.message;
          // this.invalidbankdetails = true;
          this.verifybankdetails = false;
        } else {
          // this.toastrService.warning(result.message, '');
          this.bankerrormsg = result.message;
          // this.invalidbankdetails = true;
          this.verifybankdetails = false;
        }
      },
      (error) => {
        alert('Please check the provided bank details');
        // this.toastrService.warning(
        //   "Please check the provided bank details",
        //   ""
        // );
      }
    );
  }
  public touchedmsg() {
    // this.invalidbankdetails = false;
    this.bankerrormsg =''
    if (
      this.bankpayForm.controls['banknumber'].value?.length == 3 &&
      this.bankpayForm.controls['transitnumber'].value?.length == 5 &&
      (this.bankpayForm.controls['accountnumber'].value?.length ?? 0) >= 5
    ) {
      this.verifybankdetails = true;
      this.verifybutton = true;
    } else {
      this.verifybankdetails = false;
      this.verifybutton = false;
    }
  }
  public voidcheckupload(event: any) {
    this.imagedisplay =false
    sessionStorage.removeItem('fileurl');
    sessionStorage.removeItem('filename');
    sessionStorage.removeItem('signature');
    var fileExtension = '.' + event.target.files[0].name.split('.').pop();

    // event.target.files[0].name ="void_cheque_"+Date.now()+fileExtension

    // "void_cheque_"+Date.now()+fileExt
    ////this.consoleMessage(event)
    this.isFileUploaded = true;
    // alert(event.target.files[0].name.replace(event.target.files[0].name,"void.pdf"))
    this.bankfile = event.target.files[0];
    this.Shared.setMessage(this.bankfile);
    // this.bankfile.name = (this.bankfile.name,"void_cheque_"+Date.now()+fileExtension)
    // alert(fileExtension.toLowerCase())
    var allowedMimes = [
      'image/jpeg',
      'image/pjpeg',
      'image/png',
      'application/pdf',
    ];

    let error = false;

    let message = '';

    if (allowedMimes.includes(this.bankfile.type)) {
      if (this.bankfile.size <= 1000 * 1024) {
        //300KB

        error = false;
      } else {
        error = true;
        // this.ShowModelPopup =true
        this.ShowModelPopupMessage =this.lang.File_size_is_too_large_maximum_file_size_is_1MB;
        // this.ShowModelPopupTitle =this.lang.Info
      }
    } else {
      error = true;
      // this.ShowModelPopup =true
      this.ShowModelPopupMessage =this.lang.Invalid_file_type_Only_jpg_png_image_pdf_files_are_allowed;
      // this.ShowModelPopupTitle =this.lang.Info
    }

    if (error) {
      // this.showDeleteModal = true;
      // this.modalMessage = message;

      this.ShowModelPopup =true
      this.ShowModelPopupMessage =this.ShowModelPopupMessage
      this.ShowModelPopupTitle =this.lang.Info
      this.bankpayForm.get('voidcheck')?.reset();
      this.isFileUploaded = true;
    } else {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);

      //this.consoleMessage(reader)

      if (event.target.files[0].type == 'application/pdf') {
        // alert("1")
        this.pdfview = true;
        this.normalview = false;
      } else {
        this.pdfview = false;
        this.normalview = true;
      }

      reader.onload = (_event) => {
        this.imagedisplay =true
        this.imagedisplay = reader.result;
      };

      //     if(this.imagedisplay ==false){
      //       Swal.fire({
      //         title: this.lang.Info,
      //         html: "Invalid file uploaded, please try again",

      //         showCancelButton: false,
      //         confirmButtonText: "Ok",
      //       }).then((result) => {
      //         if (result.value) {

      // this.bankpayForm.get('voidcheck').reset();
      // this.isFileUploaded =true
      //         } else {
      //         }
      //       });
      //     }
    }

    // sessionStorage.setItem("files", event.target.files[0]);
    // localStorage.setItem("files", JSON.stringify(event.target.files[0]));

    // sessionStorage.setItem("voidCheque",this.bankfile)
  }

  public homeaddresscheck(event: any) {
    if (event.target.checked == true) {
      sessionStorage.setItem('homeAddress', 'true');

      // this.aptcheck = this.userForm.get("apt_suite")?.value;
      this.bankpayForm
        .get('bankstreetaddress')
        ?.setValue(this.userForm.controls['street_address_line1'].value);
      this.bankpayForm
        .get('bankstreetaddressline2')
        ?.setValue(this.userForm.controls['street_address_line2'].value);
      this.bankpayForm
        .get('bankcity')
        ?.setValue(this.userForm.controls['city'].value);
      this.bankpayForm
        .get('bankprovince')
        ?.setValue(this.userForm.controls['province'].value);
      this.bankpayForm
        .get('bankpostalcode')
        ?.setValue(this.userForm.controls['postal_code'].value);
    } else {
      sessionStorage.setItem('homeAddress', 'false');
      // this.bankpayForm?.setValue({'bankstreetaddress':'','bankstreetaddressline2':'','bankcity':'','bankprovince':'','bankpostalcode':''})
      this.bankpayForm.get('bankstreetaddress')?.setValue('');
      this.bankpayForm.get('bankstreetaddressline2')?.setValue('');
      this.bankpayForm.get('bankcity')?.setValue('');
      this.bankpayForm.get('bankprovince')?.setValue('');
      this.bankpayForm.get('bankpostalcode')?.setValue('');
    }
  }
  public homeaddresscheckCC(event: any) {
    if (event.target.checked == true) {
      sessionStorage.setItem('homeAddress', 'true');

      // this.aptcheck = this.userForm.get("apt_suite")?.value;
      this.paymentForm
        .get('streetaddress')
        ?.setValue(this.userForm.controls['street_address_line1'].value);
      this.paymentForm
        .get('streetaddressline2')
        ?.setValue(this.userForm.controls['street_address_line2'].value);
      this.paymentForm
        .get('city')
        ?.setValue(this.userForm.controls['city'].value);
      this.paymentForm
        .get('province')
        ?.setValue(this.userForm.controls['province'].value);
      this.paymentForm
        .get('postalcode')
        ?.setValue(this.userForm.controls['postal_code'].value);
    } else {
      sessionStorage.setItem('homeAddress', 'false');
      // this.bankpayForm?.setValue({'bankstreetaddress':'','bankstreetaddressline2':'','bankcity':'','bankprovince':'','bankpostalcode':''})
      this.paymentForm.get('streetaddress')?.setValue('');
      this.paymentForm.get('streetaddressline2')?.setValue('');
      this.paymentForm.get('city')?.setValue('');
      this.paymentForm.get('province')?.setValue('');
      this.paymentForm.get('postalcode')?.setValue('');
    }
  }

  public showmodelsign(){
// console.log("tets1")
    this.generatePADaggrement =""

    let inputData={
      "url": sessionStorage.getItem("fileurl"),
      "filename": sessionStorage.getItem("filename"),
      "signature":sessionStorage.getItem("signature")
    }



      this._SignupformService.padsignature(inputData).subscribe((result: any) => {
        if(result.status=="200"){
          // jQuery("#PADpafaggrement1-modal").modal("show");
          this.padAggrementFinalModel =true
          this.generatePADaggrement = result.data.url
          this.signaturestatus =false
          this.agreestatus =true
          sessionStorage.setItem("failed","")

        } else {
          this.failedarray.push("updatePAD")
          //this.consoleMessage(this.failedarray)
          sessionStorage.setItem("failed",JSON.stringify(this.failedarray))
          // Swal.fire({
          //   title: "Error",
          //   text: result.message ? result.message : result.error,
          //   icon: "warning",
          //   width: "30%",
          //   showCancelButton: false,
          //   confirmButtonText: "Ok",
          // }).then((result) => {
          //   if (result.value) {
          //   } else {
          //     // this.router.navigate(['pages/signup-form']);
          //   }
          // });
        }

        })



}
  public showpadaggrement() {
    if (
      sessionStorage.getItem('fileurl') &&
      sessionStorage.getItem('filename') &&
      sessionStorage.getItem('signature')
    ) {
      this.showmodelsign()

      // console.log("sdasds")
    }
    else {
      // console.log("sdasds2")
      let Data = JSON.parse(sessionStorage.getItem('Data') || '{}');
      this.agreestatus = false
      this.signaturestatus = true
      // alert(this.bankdetailsverify)

      if (this.bankpayForm.valid) {
        let addressobject = {
          address1:
            this.bankpayForm.get('bankstreetaddress')?.value ||
            Data.street_address_line1 ||
            '',
          address2:
            this.bankpayForm.get('bankstreetaddressline2')?.value ||
            Data.street_address_line2 ||
            '',
          apt: this.bankpayForm.get('bankaptcheck')?.value || Data.apt || '',
          city: this.bankpayForm.get('bankcity')?.value || Data.city || '',
          province:
            this.bankpayForm.get('bankprovince')?.value || Data.province || '',
          postalCode:
            this.bankpayForm.get('bankpostalcode')?.value ||
            Data.postal_code ||
            '',
          phone:Data.phone_number,
          email: Data.email,
        };

        let bankAddress = this.bankverifyDetails.address;

        let bankAdressArr = bankAddress.split(',');

        let bankCity = bankAdressArr[bankAdressArr.length - 2].replace(' ', '');

        let provincePostal = bankAdressArr[bankAdressArr.length - 1];

        let provincePostalArr = provincePostal.split(' ');

        let bankProvince = provincePostalArr[provincePostalArr.length - 3];

        let bankPostalCode =
          provincePostalArr[provincePostalArr.length - 2] +
          ' ' +
          provincePostalArr[provincePostalArr.length - 1];

        let bankobject = {
          name: this.bankverifyDetails.bank.name,
          address: this.bankverifyDetails.bank.address.split(',')[0],
          city: bankAdressArr[bankAdressArr.length - 2].replace(' ', ''),
          province: provincePostalArr[provincePostalArr.length - 3],
          postalCode:
            provincePostalArr[provincePostalArr.length - 2] +
            ' ' +
            provincePostalArr[provincePostalArr.length - 1],
        };

        let inputData1 = {
          bankCode: this.bankpayForm.get('banknumber')?.value,
          branchCode: this.bankpayForm.get('transitnumber')?.value,
          accountNumber: this.bankpayForm.get('accountnumber')?.value,
        };

        // ////this.consoleMessage(this.bankfile)
        // this.bankfile[0].name ="test.pdf"
        var fileExtension = '.' + this.bankfile.name.split('.').pop();

        // event.target.files[0].name ="void_cheque_"+Date.now()+fileExtension
        var encBank = btoa(JSON.stringify(inputData1));
        var formData: any = new FormData();
        formData.append('firstName', Data.firstName);
        formData.append('lastName', Data.lastName);
        formData.append('address', JSON.stringify(addressobject));
        formData.append('bank', JSON.stringify(bankobject));
        formData.append(
          'files',
          this.bankfile,
          'void_cheque_' + Date.now() + fileExtension.toLowerCase()
        );
        // formData.append("files", this.bankfile.name,"void.pdf");
        formData.append('session', encBank) || '';
        formData.append('timestamp', new Date().getTime());

        // ////this.consoleMessage(formData)
        // ////this.consoleMessage(this.bankfile)

        this._SignupformService
          .padaddgrementdetails(formData)
          .subscribe((result: any) => {
            if (result.status == '200') {
              this.padAggrementModel =true
              this.generatePADaggrement = result.data.url

              // jQuery("#PADpafaggrement-modal").modal("show");

              sessionStorage.setItem('fileurl', result.data.url);
              sessionStorage.setItem('filename', result.data.filename);
              // this.showPADModelDiaslog =true
              // this.header = "PAD Agreement"
              // this.pdfData =result.data.url
            } else {



      this.ShowModelPopup =true
      this.ShowModelPopupMessage =result.message
      ? result.message
      : result.error
      this.ShowModelPopupTitle =this.lang.Info
            }
          });
      } else {
        this.bankpayForm.markAllAsTouched();
        // this.bankpayForm.get("banknumber")?.markAsTouched();
        // this.bankpayForm.get("transitnumber")?.markAsTouched();
        // this.bankpayForm.get("accountnumber")?.markAsTouched();
        // this.bankpayForm.get("bankname")?.markAsTouched();
        // this.bankpayForm.get("voidcheck")?.markAsTouched();
        // this.bankpayForm.get("checkbankdetails")?.markAsTouched();
        // this.bankpayForm.get("bankaptcheck")?.markAsTouched();
        // this.bankpayForm.get("bankstreetaddress")?.markAsTouched();
        // this.bankpayForm.get("bankcity")?.markAsTouched();
        // this.bankpayForm.get("bankprovince")?.markAsTouched();
        // this.bankpayForm.get("bankpostalcode")?.markAsTouched();

        // if (this.signaturecheck == true) {
        //   this.signaturemessage = true;
        // }
      }
    }

    // this.createCutomerPADagreement(data)
  }

  public agreepadconditions(){
     this.acceptaggrepad = false
    this.padaggrementerror =""
    this.padAggrementFinalModel=false
    this.signaturestatus=false
    this.agreestatus=false
    this.rejectPADModel=false
    this.closePADModel =true
    this.showagree =false
    // jQuery("#PADpafaggrement1-modal").modal("hide");

    // this.acceptaggrepad = false
    // this.padaggrementerror =""
    // this.showagree =false
    // this.showReject =false
    // this.showClose =true
  }
  public closePADAdggrementModel(){
    this.padAggrementModel=false
    this.padAggrementFinalModel=false
  }

  public rejectPADpafaggrement() {
    this.acceptaggrepad=true
      // this.padaggrementerror = "Please check the PAD aggrement"

this.padAggrementModel=false
this.padAggrementFinalModel=false

  }
  public rejectPADpafaggrement1() {
    this.acceptaggrepad=true
      // this.padaggrementerror = "Please check the PAD aggrement"

this.padAggrementModel=false
this.padAggrementFinalModel=false

  }
  public signaturestartmodel(){
    // jQuery("#drawsignaturemodel-modal").modal("show");
    this.signModelForBankPay =true
    // this.clearPadSignature();
    this.generatePADaggrement=""
    // this.acceptaggrepad ==true
  }


  public saveIAFGsignature(){
    // this.padaggrementerror =""
    this.signatureNeeded = this.signaturePad.isEmpty();
    if(this.signatureNeeded==false){
      const base64Data = this.signaturePad.toDataURL();
      // console.log(base64Data);
      sessionStorage.setItem("Spousesignature", base64Data);
      this.showSpouseSignature =false
    }
    else{
      sessionStorage.setItem("Spousesignature", "");
      this.showIAFGerrorMessage= true
    }


  }
  public savePadsignature(){
    this.acceptaggrepad = false
    // this.padaggrementerror =""
    this.signatureNeeded = this.signaturePad.isEmpty();
    if(this.signatureNeeded==false){
      const base64Data = this.signaturePad.toDataURL();
      // console.log(base64Data);
      sessionStorage.setItem("signature", base64Data);
    }
    else{
      sessionStorage.setItem("signature", "");
      this.showpaderrorMessage= true
    }
    setTimeout(() => {
      if(sessionStorage.getItem("signature")){
      // if(sessionStorage.getItem("signature") && sessionStorage.getItem("signature")?.length>0){
        this.signModelForBankPay =false

        setTimeout(() => {
          this.padAggrementModel =false
          this.showmodelsign();

          // console.log("test")
        }, 10);
      }
      else{
  // this.signaturemessagepad = true;
  // this.signaturePad.clear();

  // console.log("tetshere")
      }
    }, 100);

  }
  // <!-- [class.text-green-700]="activeTab === i"
  // [class.animate-pluse]="activeTab === i"
  // [class.bg-green-200]="activeTab === i"
  public creditcardpaysubmit(value:any, value1:any) {

    this.signatureNeeded = this.signaturePad.isEmpty();
    if(this.signatureNeeded==false){

      const base64Data = this.signaturePad.toDataURL();
      // console.log(base64Data);
      sessionStorage.setItem("signature", base64Data);

    }
    else{
      sessionStorage.setItem("signature", "");
      this.signaturemessagecc =true

    }
    let inputData = JSON.parse(sessionStorage.getItem("Data")||'{}');

    if(sessionStorage.getItem("Spousesignature")){
      inputData.spouseSignature = sessionStorage.getItem("Spousesignature")
    }

    inputData.signature= sessionStorage.getItem("signature") || "";

    let iafgData = JSON.parse(sessionStorage.getItem('plansummaryLife') || '[]');

    let iafgTermplanCheck = iafgData.filter((p: any) => p.planLevel == 44);

    if (iafgTermplanCheck.length > 0) {

      if (this.addbenificaryData.length > 0) {
        this.benficiary = false;

        const validateBeneficiaries = this.addbenificaryData.reduce((acc: any, obj: any) => {
          const compositeKey = `${obj.customerType}_${obj.type}`;
          if (!acc[compositeKey]) {
            acc[compositeKey] = 0;
          }
          acc[compositeKey] += parseFloat(obj.percentage);
          return acc;
        }, {});

        let beneficiaryError = "";
        for (const key in validateBeneficiaries) {
          if (validateBeneficiaries.hasOwnProperty(key)) {
            const [customerType, type] = key.split("_");
            console.log(`The customerType ${customerType} and type ${type} with perc: ${validateBeneficiaries[key]}`);

            if (validateBeneficiaries[key] != 100) {
              beneficiaryError += `CustomerType ${customerType} and type ${type} has percentage issue. \n`
            }
          }
        }




        if (beneficiaryError != "") {
          this.benficiaryCount = true
          this.benficiaryCountMessage = this.lang.Distributed_percentages_must_equal_100_and_not_more
          return false;
        }

        else {
          this.benficiary = false

          if (this.addbenificaryData && this.addbenificaryData.length > 0) {
            this.addbenificaryData =
              this.addbenificaryData
                .map((v: any) => ({ ...v, contactDetails: {contingentBeneficiary:{

                  firstName:v.contingentFirstName,

                  lastName:v.contingentLastName,

                  }},trusteeDetails: {
                  firstName:v.trusteeFirstName || "",
                  lastName:v.trusteeLastName || "",
                  }
                }))
            inputData.havingBeneficiaries = true
          }
          else {
            this.benficiary = true;

            return false;
          }
          inputData.beneficiariesDetails = this.addbenificaryData

        }


      }


      else {
        this.benficiary = true;
        if (this.addbenificaryData.length > 0) {

        } else {
          this.showBenificiaryForm = true
        }


        return false;

      }

    } else {
      // this.gotosumamrypageLogic()
    }
    let provincelist = JSON.parse(sessionStorage.getItem("provincelist") || "");

    if (provincelist.includes(inputData.province_id)) {
    } else {
      if (this.formConfigResult.assign_default_form == true) {

        inputData.formId = this.formConfigResult.default_form.id;
        inputData.brokershipTransfered = true;
        inputData.originalFormId = this.formConfigResult.form.id;
      } else {
      }
    }

    if (sessionStorage.getItem("fusebillCustomerAddressId")) {
      inputData.fusebillCustomerAddressId =
        sessionStorage.getItem("fusebillCustomerAddressId") || null;
    }
    if (
      sessionStorage.getItem("fusebillCustomerId")
    ) {
      inputData.fusebillCustomerId =
        sessionStorage.getItem("fusebillCustomerId") || null;
    }
    if (
      sessionStorage.getItem("fusebillCustomerBillingAddressId")
    ) {
      inputData.fusebillCustomerBillingAddressId =
        sessionStorage.getItem("fusebillCustomerBillingAddressId") || null;
    }
    if (
      sessionStorage.getItem("customerId")
    ) {
      inputData.customerId = sessionStorage.getItem("customerId") || null;
      // inputData.billing_address.customer_id= parseInt(sessionStorage.getItem("customerId")) || null
    }
    inputData.billing_sameas_homeAddress =
      sessionStorage.getItem("homeAddress") == "true" ? true : false;

    if (sessionStorage.getItem("homeAddress") == "true") {
      inputData.billing_address = {
        // "customer_id": parseInt(sessionStorage.getItem("customerId")) || null,
        // "fusebill_customer_id": parseInt(sessionStorage.getItem("fusebillCustomerId")) || null,
        line1: inputData.street_address_line1 || "",
        line2: inputData.street_address_line2 || "",
        city: inputData.city || "",
        state: inputData.province || "",
        state_id: parseInt(this.provineList.id), // this.fusebillid = parseInt(element.fusebillId);
        // ostate_id:this.state_id
        fusebillStateId: parseInt(this.provineList.fusebillId),
        fusebillCountryId: 124,
        country: "Canada",
        country_id: 1,
        // ocountry_id: 1,
        postal_zip: inputData.postal_code || "",
        apt: inputData.apt || "",
      };
    } else {
      inputData.billing_address = {
        // "customer_id": parseInt(sessionStorage.getItem("customerId")) || null,
        // "fusebill_customer_id": parseInt(sessionStorage.getItem("fusebillCustomerId")) || null,
        line1: this.paymentForm.get("streetaddress")?.value || "",
        line2: this.paymentForm.get("streetaddressline2")?.value || "",
        city: this.paymentForm.get("city")?.value || "",
        state: this.paymentForm.get("province")?.value || "",
        state_id: parseInt(this.provineListcreditPayment.id), // this.fusebillid = parseInt(element.fusebillId);
        // ostate_id:this.state_id
        fusebillStateId: parseInt(this.provineListcreditPayment.fusebillId),
        fusebillCountryId: 124,
        country: "Canada",
        country_id: 1,
        // ocountry_id: 1,
        postal_zip: this.paymentForm.get("postalcode")?.value || "",
        apt: this.paymentForm.get("aptcheck")?.value || "",
      };
    }

    if (
      sessionStorage.getItem("customerId") &&
      sessionStorage.getItem("fusebillCustomerId")
    ) {
      inputData.billing_address.customer_id =
        parseInt(sessionStorage.getItem("customerId") || '0') || null;
      inputData.billing_address.fusebill_customer_id =
        parseInt(sessionStorage.getItem("fusebillCustomerId")|| '0') || null;
    }

    // ////this.consoleMessage(inputData);

    if (this.paymentForm.invalid) {
      const invalid = [];
      this.paymentForm.markAllAsTouched();
      const controls:any = this.paymentForm.controls;
      for (let name in controls) {
        //this.consoleMessage(name)
        if (controls[name].invalid) {

          if (name == "CardNumber") {
            name = this.lang.Enter_Card_Number;
          }
          if (name == "ExpirationMonth") {
            name = this.lang.Select_Expiry_Month;
          }
          if (name == "ExporationYear") {
            name = this.lang.Select_Expiry_Year;
          }
          if (name == "cvv") {
            name = this.lang.Enter_CVV
          }
          if (name == "streetaddress") {
            name = this.lang.Enter_Street_Address;
          }
          if (name == "city") {
            name = this.lang.Enter_City;
          }
          if (name == "province") {
            name = this.lang.Select_Province;
          }
          if(name=="postalcode"){
            name = this.lang.Enter_Postal_Code;

          }
          if (name == "recaptchaReactive") {
            name = this.lang.Select_Recaptcha;
          }


          invalid.push(name);
        }

      }
      this.missingInformation =true
      this.missingInfo = invalid;
      //this.consoleMessage(invalid)
      return invalid;
    }
if(this.signatureNeeded==true){
  const invalid = [];
  invalid.push("Draw the Signature");
  this.missingInformation =false

      this.missingInfo = invalid;
      //this.consoleMessage(invalid)
      return invalid;
}
    if (
      this.paymentForm.valid && this.signatureNeeded==false
    ) {


      this._SignupformService.login1(inputData).subscribe(
        (result: any) => {

          if (result.status == "200") {
            sessionStorage.removeItem("paymentfail");

            // ////this.consoleMessage(result.data.province);
            // sessionStorage.setItem("stateid",result.data.province.id)
            if (result.data.customer.fusebill) {
              sessionStorage.setItem(
                "fusebillCustomerId",
                result.data.customer.fusebill.customerId
              );
              sessionStorage.setItem(
                "publicApiKey",
                result.data.customer.fusebill.publicApiKey
              );
              if (result.data.customer.fusebill.customerAddressId) {
                sessionStorage.setItem(
                  "fusebillCustomerAddressId",
                  result.data.customer.fusebill.customerAddressId
                );
              }
              if (result.data.customer.fusebill.customerBillingAddressId) {
                sessionStorage.setItem(
                  "fusebillCustomerBillingAddressId",
                  result.data.customer.fusebill.customerBillingAddressId
                );
              }
            }
            sessionStorage.setItem("userKey", result.data.userKey);
            sessionStorage.setItem("customerId", result.data.customer.id);
            // sessionStorage.setItem("publicApiKey",result.data.customer.fusebill.publicApiKey)
            sessionStorage.setItem("normalcustomerid", result.data.customer.id);
            sessionStorage.setItem(
              "enrollmentdate",
              inputData.planEnrollmentDate
            );
            this.customerid = result.data.customer.fusebill.customerId;
            this.publicapikey = result.data.customer.fusebill.publicApiKey;
            setTimeout(() => {
              value1.submit();
              // }
            }, 100);
          }

          else if(result.status == "206"){

            this.ShowModelPopup =true
            this.ShowModelPopupMessage =result.message
            this.ShowModelPopupTitle =this.lang.Info





          }
            else {

                if(result.error || result.errors){


                  this.ShowModelPopup =true
                  this.ShowModelPopupMessage =result.error ? result.error[0].value : result.errors[0].value,
                  this.ShowModelPopupTitle =this.lang.Info

                }

                this.ShowModelPopup =true
                this.ShowModelPopupMessage =result.message
                this.ShowModelPopupTitle =this.lang.Info
            }

        },
        (error) => {
          if([0,500,501,502,503].includes(error.status)){
            this.ShowModelPopup =true
            this.ShowModelPopupMessage = this.lang.Sorry_the_server_is_currently_undergoing_maintenance_Please_try_again_later_We_apologize_for_your_inconvenience;
            this.ShowModelPopupTitle =this.lang.Info

          }
          else{
            this.ShowModelPopup =true
            this.ShowModelPopupMessage =error.error.message ? error.error.message : error.error.error,
            this.ShowModelPopupTitle =this.lang.Info

        }
        }
      );
    } else {
      // this.paymentForm.markAsAllTouched();

      if (this.signaturecheckcc == true) {
        this.signaturemessagecc = true;
      }



    }
  }
  public bankpayformsubmit(value:any) {
    let inputData = JSON.parse(sessionStorage.getItem("Data") || '{}');

    if(sessionStorage.getItem("Spousesignature")){
      inputData.spouseSignature = sessionStorage.getItem("Spousesignature")
    }
    inputData.signature= sessionStorage.getItem("signature") || "";

    let provincelist = JSON.parse(sessionStorage.getItem("provincelist") || "");
    // ['trusteeDetails']={'firstName':'','lastName':''}

    let iafgData = JSON.parse(sessionStorage.getItem('plansummaryLife') || '[]');

    let iafgTermplanCheck = iafgData.filter((p: any) => p.planLevel == 44);

    if (iafgTermplanCheck.length > 0) {

      if (this.addbenificaryData.length > 0) {
        this.benficiary = false;

        const validateBeneficiaries = this.addbenificaryData.reduce((acc: any, obj: any) => {
          const compositeKey = `${obj.customerType}_${obj.type}`;
          if (!acc[compositeKey]) {
            acc[compositeKey] = 0;
          }
          acc[compositeKey] += parseFloat(obj.percentage);
          return acc;
        }, {});

        let beneficiaryError = "";
        for (const key in validateBeneficiaries) {
          if (validateBeneficiaries.hasOwnProperty(key)) {
            const [customerType, type] = key.split("_");
            console.log(`The customerType ${customerType} and type ${type} with perc: ${validateBeneficiaries[key]}`);

            if (validateBeneficiaries[key] != 100) {
              beneficiaryError += `CustomerType ${customerType} and type ${type} has percentage issue. \n`
            }
          }
        }




        if (beneficiaryError != "") {
          this.benficiaryCount = true
          this.benficiaryCountMessage = this.lang.Distributed_percentages_must_equal_100_and_not_more
          return false;
        }

        else {
          this.benficiary = false

          if (this.addbenificaryData && this.addbenificaryData.length > 0) {
            this.addbenificaryData =
              this.addbenificaryData
                .map((v: any) => ({ ...v, contactDetails: {contingentBeneficiary:{

                  firstName:v.contingentFirstName,

                  lastName:v.contingentLastName,

                  }},trusteeDetails: {
                  firstName:v.trusteeFirstName || "",
                  lastName:v.trusteeLastName || "",
                  }
                }))
            inputData.havingBeneficiaries = true
          }
          else {
            this.benficiary = true;

            return false;
          }
          inputData.beneficiariesDetails = this.addbenificaryData

        }


      }


      else {
        this.benficiary = true;
        if (this.addbenificaryData.length > 0) {

        } else {
          this.showBenificiaryForm = true
        }


        return false;

      }

    } else {
      // this.gotosumamrypageLogic()
    }

    // console.log(this.addbenificaryData)

    // return false
    if (provincelist.includes(inputData.province_id)) {
    } else {
      if (this.formConfigResult.assign_default_form == true) {
        // alert("1");
        inputData.formId = this.formConfigResult.default_form.id;
        inputData.brokershipTransfered = true;
        inputData.originalFormId = this.formConfigResult.form.id;
      } else {
      }
    }

    if (
      sessionStorage.getItem("fusebillCustomerAddressId")
    ) {
      inputData.fusebillCustomerAddressId =
        sessionStorage.getItem("fusebillCustomerAddressId") || null;
    }
    if (
      sessionStorage.getItem("fusebillCustomerId")
    ) {
      inputData.fusebillCustomerId =
        sessionStorage.getItem("fusebillCustomerId") || null;
    }
    if (
      sessionStorage.getItem("fusebillCustomerBillingAddressId")
    ) {
      inputData.fusebillCustomerBillingAddressId =
        sessionStorage.getItem("fusebillCustomerBillingAddressId") || null;
    }
    if (
      sessionStorage.getItem("customerId")
    ) {
      inputData.customerId = sessionStorage.getItem("customerId") || null;
    }
    inputData.billing_sameas_homeAddress =
      sessionStorage.getItem("homeAddress") == "true" ? true : false;

    if (sessionStorage.getItem("homeAddress") == "true") {
      inputData.billing_address = {
        // "customer_id": parseInt(sessionStorage.getItem("customerId")) || null,
        // "fusebill_customer_id": parseInt(sessionStorage.getItem("fusebillCustomerId")) || null,
        line1: inputData.street_address_line1,
        line2: inputData.street_address_line2,
        city: inputData.city,
        state: inputData.province,
        state_id: parseInt(this.provineList.id), // this.fusebillid = parseInt(element.fusebillId);
        fusebillStateId: parseInt(this.provineList.fusebillId),
        fusebillCountryId: 124,
        country: "Canada",
        country_id: 1,
        // ocountry_id: 1,
        postal_zip: inputData.postalcode,
        apt: inputData.apt || "",
      };
    } else {

      //this.consoleMessage(this.paymentForm)
      inputData.billing_address = {
        line1: this.bankpayForm.get("bankstreetaddress")?.value,
        line2: this.bankpayForm.get("bankstreetaddressline2")?.value,
        city: this.bankpayForm.get("bankcity")?.value,
        state: this.bankpayForm.get("bankprovince")?.value,
        state_id: parseInt(this.provineListPayment.id),
        fusebillStateId: parseInt(this.provineListPayment.fusebillId),
        fusebillCountryId: 124,

        country: "Canada",
        country_id: 1,
        // ocountry_id: 1,
        postal_zip: this.bankpayForm.get("bankpostalcode")?.value,
        apt: this.bankpayForm.get("bankaptcheck")?.value ||"",
      };
    }

    if (
      sessionStorage.getItem("customerId") &&
      sessionStorage.getItem("fusebillCustomerId")
    ) {
      inputData.billing_address.customer_id =
        parseInt(sessionStorage.getItem("customerId")||"0") || null;
      inputData.billing_address.fusebill_customer_id =
        parseInt(sessionStorage.getItem("fusebillCustomerId")||"0") || null;


    }
    if(this.acceptaggrepad==true){
      this.padaggrementerror = this.lang.Please_check_the_PAD_agreement
    }
    else{
      this.padaggrementerror =""
    }
    if (this.bankpayForm.invalid) {
      // this.bankpayForm.markAsTouched();
      this.bankpayForm.markAllAsTouched();
      const invalid = [];
      const controls:any = this.bankpayForm.controls;
      for (let name in controls) {
        //this.consoleMessage(name)
        if (controls[name].invalid) {

          if (name == "banknumber") {
            name = this.lang.Enter_Bank_Number;
          }
          if (name == "transitnumber") {
            name = this.lang.Enter_Transit_Number;
          }
          if (name == "accountnumber") {
            name = this.lang.Enter_Account_Number;
          }
          if (name == "bankname") {
            name = this.lang.Verify_the_bank_details;
          }

          if (name == "voidcheck") {
            name = this.lang.Uplaod_VoidCheck;
          }
          if (name == "bankstreetaddress") {
            name = this.lang.Enter_Street_Address;
          }
          if (name == "bankcity") {
            name = this.lang.Enter_City;
          }
          if (name == "bankprovince") {
            name = this.lang.Select_Province;
          }
          if(name=="bankpostalcode"){
            name = this.lang.Enter_Postal_Code;

          }
          if (name == "recaptchaReactive") {
            name = this.lang.Select_Recaptcha;
          }


          invalid.push(name);
        }

      }
      // jQuery("#missingInfo-modal").modal("show");
      this.missingInformation =true
      this.missingInfo = invalid;
      //this.consoleMessage(invalid)
      return invalid;
    }
if(this.acceptaggrepad==true){
  const invalid = [];
  invalid.push("Accept the PAD");
  // jQuery("#missingInfo-modal").modal("show");
  this.missingInformation =true
      this.missingInfo = invalid;
      //this.consoleMessage(invalid)
      return invalid;
}

    if (this.bankpayForm.valid  && this.cartcheckvalue == false) {
      let enrollmentData={
        "customerName":inputData.firstName +""+inputData.lastName,
        "contactNum":inputData.phone_number,
        "dob":inputData.date_of_birth,
        "email":inputData.email,
        "process":"enrollmentSumamry",
        "request":{"paymentMethod":"PAD","signature":sessionStorage.getItem("signature") || "","bankCode": this.bankpayForm.get("banknumber")?.value,
        "branchCode": this.bankpayForm.get("transitnumber")?.value,"voidcheque":this.imagedisplay}
      }

          this._SignupformService.savePlan(enrollmentData).subscribe((result: any) => {
            //this.consoleMessage(result)
          })
      this._SignupformService.login1(inputData).subscribe(
        (result: any) => {

          if (result.status == "200") {
            sessionStorage.removeItem("paymentfail");
            // this.planssummary = JSON.parse(
            //   sessionStorage.getItem("plansummary")
            // );
            // let obj = {};

            // this.planstab = 0;
            // this.cartamount = true;
            // ////this.consoleMessage(result.data.province);
            if (result.data.customer.fusebill) {
              sessionStorage.setItem(
                "fusebillCustomerId",
                result.data.customer.fusebill.customerId
              );
              sessionStorage.setItem(
                "publicApiKey",
                result.data.customer.fusebill.publicApiKey
              );
              if (result.data.customer.fusebill.customerAddressId) {
                sessionStorage.setItem(
                  "fusebillCustomerAddressId",
                  result.data.customer.fusebill.customerAddressId
                );
              }
              if (result.data.customer.fusebill.customerBillingAddressId) {
                sessionStorage.setItem(
                  "fusebillCustomerBillingAddressId",
                  result.data.customer.fusebill.customerBillingAddressId
                );
              }
            }
            sessionStorage.setItem("userKey", result.data.userKey);
            sessionStorage.setItem("customerId", result.data.customer.id);
            // sessionStorage.setItem("publicApiKey",result.data.customer.fusebill.publicApiKey)
            sessionStorage.setItem("normalcustomerid", result.data.customer.id);
            sessionStorage.setItem(
              "enrollmentdate",
              inputData.planEnrollmentDate
            );
            this.customerid = result.data.customer.fusebill.customerId;
            this.publicapikey = result.data.customer.fusebill.publicApiKey;

            setTimeout(() => {
              // if(this.validateaddresscheck==true){
              //   this.validateaddress(this.paymentForm.value,"1")

              // }
              // else{
              this.bankformsubmitdetails();
              // }
            }, 100);
          }
          else if(result.status == "206"){
            this.ShowModelPopup =true
            this.ShowModelPopupMessage =result.message
            this.ShowModelPopupTitle =this.lang.Info


          }

             else {
                if(result.error || result.errors){

                  this.ShowModelPopup =true
                  this.ShowModelPopupMessage =result.error ? result.error[0].value : result.errors[0].value,
                  this.ShowModelPopupTitle =this.lang.Info


                }
                else{

                  this.ShowModelPopup =true
                  this.ShowModelPopupMessage =result.data.message
                  this.ShowModelPopupTitle =this.lang.Info

                }



            }

        },
        (error) => {
          if([0,500,501,502,503].includes(error.status)){

            this.ShowModelPopup =true
            this.ShowModelPopupMessage = this.lang.Sorry_the_server_is_currently_undergoing_maintenance_Please_try_again_later_We_apologize_for_your_inconvenience;
            this.ShowModelPopupTitle =this.lang.Info


          }
          else{

            this.ShowModelPopup =true
            this.ShowModelPopupMessage =error.error.message ? error.error.message : error.error.error,
            this.ShowModelPopupTitle =this.lang.Info


        }
        }
      );
    }
    else {
      this.bankpayForm.markAllAsTouched();
      // this.bankpayForm.get("banknumber").markAsTouched();
      // this.bankpayForm.get("transitnumber").markAsTouched();
      // this.bankpayForm.get("accountnumber").markAsTouched();
      // this.bankpayForm.get("voidcheck").markAsTouched();
      // this.bankpayForm.get("checkbankdetails").markAsTouched();
      // this.bankpayForm.get("bankaptcheck").markAsTouched();
      // this.bankpayForm.get("bankstreetaddress").markAsTouched();
      // this.bankpayForm.get("bankcity").markAsTouched();
      // this.bankpayForm.get("bankprovince").markAsTouched();
      // this.bankpayForm.get("bankpostalcode").markAsTouched();

      if (this.signaturecheck == true) {
        this.signaturemessage = true;
      }
      // this.bankpayForm.get("recaptchaReactive").markAsTouched();
      if(this.cartcheckvalue ==true){
        this.ShowModelPopup =true
        this.ShowModelPopupMessage =this.lang.Please_check_the_Plan_Selection_Page
        this.ShowModelPopupTitle =this.lang.Info



      }
      // this.bankscrollToFirstInvalidControl();
    }
  }

  public bankformsubmitdetails() {
    var formData: any = new FormData();

    let personalInfo = JSON.parse(sessionStorage.getItem("Data")||'{}');

    let bankinputData = {
      bankCode: this.bankpayForm.get("banknumber")?.value,
      branchCode: this.bankpayForm.get("transitnumber")?.value,
      bankName: this.bankpayForm.get("bankname")?.value,
      accountNumber: this.bankpayForm.get("accountnumber")?.value,
    };


    ////this.consoleMessage(sessionStorage.getItem("totalAmount"))
    // ////this.consoleMessage(this._decimalPipe.transform(sessionStorage.getItem("totalAmount"),"1.2-2"))
    // ////this.consoleMessage(this._decimalPipe.transform(parseInt(sessionStorage.getItem("totalAmount")),"1.2-2"))
    // ////this.consoleMessage(this._decimalPipe.transform(parseFloat(sessionStorage.getItem("totalAmount")),"1.2-2"))
    let inputData = {
      customerId: parseInt(sessionStorage.getItem("normalcustomerid")||'0'),
      bankCode: this.bankpayForm.get("banknumber")?.value,
      branchCode: this.bankpayForm.get("transitnumber")?.value,
      accountNumber: this.bankpayForm.get("accountnumber")?.value,

      amount: this._decimalPipe.transform(sessionStorage.getItem("totalAmount"),"1.2-2")?.replace(/,/g, ""),
      totalAmount: this._decimalPipe.transform(sessionStorage.getItem("totalAmount"),"1.2-2")?.replace(/,/g, ""),

      totalAmountUI: sessionStorage.getItem("totalAmountUI"),
      enrollmentDate: sessionStorage.getItem("enrollmentdate"),
      customerName: personalInfo.firstName + " " + personalInfo.lastName,
    };

    ////this.consoleMessage(inputData)

    var encBank = btoa(JSON.stringify(inputData));
    // formData.append("session", encBank);
    // formData.append("timestamp",new Date().getTime());
    // formData.append("files", this.bankfile);

    // ////this.consoleMessage(this.bankfile)

    sessionStorage.setItem("session", encBank);
    let link=sessionStorage.getItem("formlink")

    // this.router.navigate([link+"/paymentResponse"]);
    this.router.navigate(["/payment/form"]);

    return false;

  }
  getTabClass(index: any): object {

//     if(this.visitedTabs.has(index) && this.activeTab !== index){
//       return 'bg-green-700'
// }
//     if(this.visitedTabs.has(index) && this.activeTab !== index){
//       return 'text-white'
//     }
//     if(this.activeTab <index){
//       return 'bg-gray-700'
//     }
//     if(this.activeTab === index){
//       return 'border-b-2 border-green-700'
//     }


    return {
      'text-white':this.visitedTabs.has(index) && this.activeTab !== index,
      'border-b-2 border-green-700': this.activeTab === index,

      'bg-green-700': this.visitedTabs.has(index) && this.activeTab !== index,


    };
    // 'bg-gray-200': this.activeTab <index,
    // return {
    //   'bg-green-300 text-green': this.activeTab === index,
    //   'bg-gray-200 text-black': this.activeTab !== index,
    //   'border-b-2 border-green-700': this.activeTab === index,
    //   'hover:bg-green-300': this.activeTab !== index,
    //   'bg-green-700': this.visitedTabs.has(index) && this.activeTab !== index && this.activeTab,  // Custom style for visited tabs

    // };
    // if (index === this.activeTab) {
    //   return 'text-green-700 animate-pulse bg-green-200'; // Active tab
    // } else if (index === this.previousTab) {
    //   return 'bg-green-700 text-white'; // Previous tab
    // } else {
    //   return 'bg-gray-300'; // Other tabs
    // }
  }

  // getTabClass(index: number) {
  //   return this.activeTab === index ? 'bg-blue-500 text-black' : 'bg-green-700 text-white';
  // }
  numbersOnly(event: any) {
    const key = event.key || String.fromCharCode(event.keyCode);
    // Check if the key is an alphabetical character (A-Z or a-z) or a space
    if (/(^[0-9 \-]+$)/.test(key)) {
      return true;
    } else {
      return false;
    }
  }
  public checkDateOfBirthMinor(event: any) {


    const birthDate = new Date(event.target.value);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();





    if (event.type === 'input') {
      if(event.target.value ==''){
        this.benficiaryForm.controls['dob'].reset();
        this.benficiaryForm.controls['dob'].markAsTouched();
      }
    }else{
    if (event.target.value == 'Invalid date') {
      this.benficiaryForm.controls['dob'].reset();
      this.benficiaryForm.controls['dob'].markAsTouched();
    }
    if(event.target.value ==''){
      this.benficiaryForm.controls['dob'].reset();
      this.benficiaryForm.controls['dob'].markAsTouched();
    }
  }
  }
  public checkDateOfBirth(event: any) {


    const birthDate = new Date(event.target.value);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();



    if (age > 17 && age < 70) {
      console.log("true")
      this.memberDateofBirth =true
    } else {
      console.log("false")
      this.memberDateofBirth =false
    }

    if (event.type === 'input') {
      if(event.target.value ==''){
        this.userForm.controls['date_of_birth'].reset();
        this.userForm.controls['date_of_birth'].markAsTouched();
      }
    }else{
    if (event.target.value == 'Invalid date') {
      this.userForm.controls['date_of_birth'].reset();
      this.userForm.controls['date_of_birth'].markAsTouched();
    }
    if(event.target.value ==''){
      this.userForm.controls['date_of_birth'].reset();
      this.userForm.controls['date_of_birth'].markAsTouched();
    }
  }
  }
  public checkDateOfHire(event: any) {

console.log(event.target.value)
    if (event.type === 'input') {
      if(event.target.value ==''){
        this.userForm.controls['date_of_hiring'].reset();
        this.userForm.controls['date_of_hiring'].markAsTouched();
      }
    }
    else{
      if (event.target.value == 'Invalid date') {
        this.userForm.controls['date_of_hiring'].reset();
        this.userForm.controls['date_of_hiring'].markAsTouched();
      }
      if(event.target.value ==''){
        this.userForm.controls['date_of_hiring'].reset();
        this.userForm.controls['date_of_hiring'].markAsTouched();
      }
    }

  }
  public checkDateOfArrival(event: any) {

    if (event.type === 'input') {
      if(event.target.value ==''){
        this.userForm.controls['dateOfArrival'].reset();
        this.userForm.controls['dateOfArrival'].markAsTouched();
      }
    }
    else{
    if (event.target.value == 'Invalid date') {
      this.userForm.controls['dateOfArrival'].reset();
      this.userForm.controls['dateOfArrival'].markAsTouched();
    }
    if(event.target.value ==''){
      this.userForm.controls['dateOfArrival'].reset();
      this.userForm.controls['dateOfArrival'].markAsTouched();
    }
  }
  }

  public checkSpouseDateofBirth(event: any) {
    if (event.type === 'input') {
      if(event.target.value ==''){
        this.userForm.controls['spousedateofbirth'].reset();
        this.userForm.controls['spousedateofbirth'].markAsTouched();
      }
    }else{
    if (event.target.value == 'Invalid date') {
      this.userForm.controls['spousedateofbirth'].reset();
      this.userForm.controls['spousedateofbirth'].markAsTouched();
    }
    if(event.target.value ==''){
      this.userForm.controls['spousedateofbirth'].reset();
      this.userForm.controls['spousedateofbirth'].markAsTouched();
    }
  }
  }
  public checkDateOfADependentArrival(event: any) {

    if (event.type === 'input') {
      if(event.target.value ==''){
        this.userForm.controls['dependentdateOfArrival'].reset();
        this.userForm.controls['dependentdateOfArrival'].markAsTouched();
      }
    }else{
    if (event.target.value == 'Invalid date') {
      this.userForm.controls['dependentdateOfArrival'].reset();
      this.userForm.controls['dependentdateOfArrival'].markAsTouched();
    }
    if(event.target.value ==''){
      this.userForm.controls['dependentdateOfArrival'].reset();
      this.userForm.controls['dependentdateOfArrival'].markAsTouched();
    }
  }
  }

  // public checkDateOfgraducation(event:any){
  //   if(event.target.value=="Invalid date"){
  //     this.childFormGroup.get('childInfoGraduationday').reset();
  //     this.childFormGroup.get('childInfoGraduationday').markAsTouched();
  //   }
  // }

  public selectplan(
    elementcv: any,
    options: any,
    plan: any,
    product: any,
    pckage: any,
    group: any,
    disallowedPlanLevels:any
  ) {

    console.log(options)

    let plandetails = elementcv.attributes.data.value;

    let plandetailsobj = plandetails.split('##');
    if (options && options.length > 0) {
      let coverage = product.planCoverage;

      coverage = ['SINGLE', 'COUPLE', 'FAMILY'].includes(coverage)
        ? coverage
        : null;
      ////this.consoleMessage(obj)
      this.productobj = {
        id: product.id,
        productId: product.productId,
        name: product.name,
        planProductId: product.planProductId,
        price: this._decimalPipe
          .transform(product.price1 || product.price, '1.2-2')
          ?.replace(/,/g, ''),
        tax: this._decimalPipe
          .transform(
            product.calculatedTax ? product.calculatedTax.tax : 0,
            '1.2-2'
          )
          ?.replace(/,/g, ''),
        total: this._decimalPipe
          .transform(
            product.calculatedTax ? product.calculatedTax.total : 0,
            '1.2-2'
          )
          ?.replace(/,/g, ''),
        tax_details:
          product.bundledTaxes && product.bundledTaxes.length > 0
            ? product.bundledTaxes
            : product.taxesDataJSON,
        calculatedTax: product.calculatedTax,
        bundledProducts: product.bundledProducts,
        planCoverage: product.planCoverage,
        planLevel: product.planlevel ? product.planlevel.id : '',
        planLevelParent: product.planlevel ? product.planlevel.parentId : '',
        description: product.description,
        coverage: coverage,
      };

      let obj1: any = {
        packageId: plan.packageId,
        packageName: pckage.name,

        planproductname: this.productobj.name,

        groupid: plan.planLevel,
        groupName: group.name,
        id: plan.id,
        name: plan.name,
        planLevel: plan.planLevel ? plan.planLevel : group.id,
        planLevelParent: group.parentId,
        fusebillPlanID: plan.fusebillId,
        planFrequencyID: plan.frqMonthly,
        isBundle: plan.isBundle,
        coverage: coverage,
        planCoverage: product.planCoverage,
        bundledProducts: [],
        products: [],
      };
      obj1.products.push(this.productobj);
      this.planobjdata = obj1;

      this.openplanoptions(elementcv, options, plan, product);
    }

    else {
      let obj: any = {
        isBundle: plan.isBundle,
        disallowedPlanLevels: disallowedPlanLevels,
        // enrollmentDate: moment(
        //   this.userForm.get("planenrollmentdate").value,
        //   "MM-DD-YYYY"
        // ).format("YYYY-MM-DD"),
        enrollmentDate: this.userForm.value.planEnrollmentDate
          ? this._CustomDatePipe.transform(
              this.userForm.value.planEnrollmentDate
            )
          : undefined,
        // "name":plandetailsobj[1]+"-"+plandetailsobj[2],
        name: plandetailsobj[14],
        details: plandetailsobj[0],
        packagename: plandetailsobj[0],
        groupName: plandetailsobj[1],
        amount: parseFloat(product.calculatedTax.price),
        planCoverage: plandetailsobj[2],
        planPrice: parseFloat(product.calculatedTax.price),
        amountUI:
          '$' +
          this._decimalPipe.transform(product.calculatedTax.price, '1.2-2'),
        gst:
          plandetailsobj[4] == null ||
          plandetailsobj[4] == '' ||
          plandetailsobj[4] == undefined ||
          plandetailsobj[4] == 'null'
            ? 0
            : parseFloat(plandetailsobj[4]),
        hst:
          plandetailsobj[5] == null ||
          plandetailsobj[5] == '' ||
          plandetailsobj[5] == undefined ||
          plandetailsobj[5] == 'null'
            ? 0
            : parseFloat(plandetailsobj[5]),
        pst:
          plandetailsobj[6] == null ||
          plandetailsobj[6] == '' ||
          plandetailsobj[6] == undefined ||
          plandetailsobj[6] == 'null'
            ? 0
            : parseFloat(plandetailsobj[6]),
        qst:
          plandetailsobj[17] == null ||
          plandetailsobj[17] == '' ||
          plandetailsobj[17] == undefined ||
          plandetailsobj[17] == 'null'
            ? 0
            : parseFloat(plandetailsobj[17]),
        gstCheck:
          plandetailsobj[4] == null ||
          plandetailsobj[4] == '' ||
          plandetailsobj[4] == undefined ||
          plandetailsobj[4] == 'null'
            ? false
            : true,
        hstCheck:
          plandetailsobj[5] == null ||
          plandetailsobj[5] == '' ||
          plandetailsobj[5] == undefined ||
          plandetailsobj[5] == 'null'
            ? false
            : true,
        pstCheck:
          plandetailsobj[6] == null ||
          plandetailsobj[6] == '' ||
          plandetailsobj[6] == undefined ||
          plandetailsobj[6] == 'null'
            ? false
            : true,
        qstCheck:
          plandetailsobj[17] == null ||
          plandetailsobj[17] == '' ||
          plandetailsobj[17] == undefined ||
          plandetailsobj[17] == 'null'
            ? false
            : true,
        id: parseFloat(plandetailsobj[7]),
        fusebillPlanID:
          plandetailsobj[8] == null ||
          plandetailsobj[8] == '' ||
          plandetailsobj[8] == undefined ||
          plandetailsobj[8] == 'null'
            ? 0
            : parseFloat(plandetailsobj[8]),
        planFrequencyID:
          plandetailsobj[9] == null ||
          plandetailsobj[9] == '' ||
          plandetailsobj[9] == undefined ||
          plandetailsobj[9] == 'null'
            ? 0
            : parseFloat(plandetailsobj[9]),
        optIn: plandetailsobj[10] == 'true' ? true : false,
        planname: plandetailsobj[14],
        planLevel: parseInt(plandetailsobj[15]),
        packageId: parseInt(plandetailsobj[16]),
        options: [],
        version:plan.version,
        description: plan.description,
        insuranceType:plan.insuranceType,
        //  "disallowedPlanLevels":plandetailsobj[11]
      };
      if (plandetailsobj[11] != null || plandetailsobj[11] != 'null') {
        if (plandetailsobj[11].includes(plandetailsobj[12])) {
        }
      }

      let coverage = product.planCoverage;

      coverage = ['SINGLE', 'COUPLE', 'FAMILY'].includes(coverage)
        ? coverage
        : null;
      ////this.consoleMessage(obj)
      this.productobj = {
        id: product.id,
        productId: product.productId,
        name: product.name,
        planProductId: product.planProductId,
        price: this._decimalPipe
          .transform(product.price1 || product.price, '1.2-2')
          ?.replace(/,/g, ''),
        tax: this._decimalPipe
          .transform(
            product.calculatedTax ? product.calculatedTax.tax : 0,
            '1.2-2'
          )
          ?.replace(/,/g, ''),
        total: this._decimalPipe
          .transform(
            product.calculatedTax ? product.calculatedTax.total : 0,
            '1.2-2'
          )
          ?.replace(/,/g, ''),
        tax_details:
          product.bundledTaxes && product.bundledTaxes.length > 0
            ? product.bundledTaxes
            : product.taxesDataJSON,
        calculatedTax: product.calculatedTax,
        bundledProducts: product.bundledProducts,
        planCoverage: product.planCoverage,
        planLevel: product.planlevel ? product.planlevel.id : '',
        planLevelParent: product.planlevel ? product.planlevel.parentId : '',
        description: product.description,
        coverage: coverage,
      };

      let obj1: any = {
        packageId: plan.packageId,
        packageName: pckage.name,

        planproductname: this.productobj.name,

        groupid: plan.planLevel,
        groupName: group.name,
        id: plan.id,
        name: plan.name,
        planLevel: plan.planLevel ? plan.planLevel : group.id,
        planLevelParent: group.parentId,
        fusebillPlanID: plan.fusebillId,
        planFrequencyID: plan.frqMonthly,
        isBundle: plan.isBundle,
        coverage: coverage,
        planCoverage: product.planCoverage,
        bundledProducts: [],
        products: [],
      };
      obj1.products.push(this.productobj);
      //this.consoleMessage(obj1)

      this.addtoplansummary(obj, obj1);
    }
  }

  public unselectplan(elementcv: any) {

    // this.planCoverageName = ''
    // this.insuranceplanid =''

    let plandetails = elementcv.attributes.data.value;

    let plandetailsobj = plandetails.split('##');
    let obj = {
      // enrollmentDate: moment(
      //   this.userForm.get("planenrollmentdate").value,
      //   "MM-DD-YYYY"
      // ).format("YYYY-MM-DD"),
      enrollmentDate: this.userForm.value.planEnrollmentDate
        ? this._CustomDatePipe.transform(this.userForm.value.planEnrollmentDate)
        : undefined,
      // "name":plandetailsobj[1]+"-"+plandetailsobj[2],
      name: plandetailsobj[14],
      details: plandetailsobj[0],
      packagename: plandetailsobj[0],
      groupName: plandetailsobj[1],
      amount: parseFloat(plandetailsobj[3]),
      planCoverage: plandetailsobj[2],
      planPrice: parseFloat(plandetailsobj[3]),
      amountUI: '$' + this._decimalPipe.transform(plandetailsobj[3], '1.2-2'),
      gst:
        plandetailsobj[4] == null ||
        plandetailsobj[4] == '' ||
        plandetailsobj[4] == undefined ||
        plandetailsobj[4] == 'null'
          ? 0
          : parseFloat(plandetailsobj[4]),
      hst:
        plandetailsobj[5] == null ||
        plandetailsobj[5] == '' ||
        plandetailsobj[5] == undefined ||
        plandetailsobj[5] == 'null'
          ? 0
          : parseFloat(plandetailsobj[5]),
      pst:
        plandetailsobj[6] == null ||
        plandetailsobj[6] == '' ||
        plandetailsobj[6] == undefined ||
        plandetailsobj[6] == 'null'
          ? 0
          : parseFloat(plandetailsobj[6]),
      qst:
        plandetailsobj[17] == null ||
        plandetailsobj[17] == '' ||
        plandetailsobj[17] == undefined ||
        plandetailsobj[17] == 'null'
          ? 0
          : parseFloat(plandetailsobj[17]),
      gstCheck:
        plandetailsobj[4] == null ||
        plandetailsobj[4] == '' ||
        plandetailsobj[4] == undefined ||
        plandetailsobj[4] == 'null'
          ? false
          : true,
      hstCheck:
        plandetailsobj[5] == null ||
        plandetailsobj[5] == '' ||
        plandetailsobj[5] == undefined ||
        plandetailsobj[5] == 'null'
          ? false
          : true,
      pstCheck:
        plandetailsobj[6] == null ||
        plandetailsobj[6] == '' ||
        plandetailsobj[6] == undefined ||
        plandetailsobj[6] == 'null'
          ? false
          : true,
      qstCheck:
        plandetailsobj[17] == null ||
        plandetailsobj[17] == '' ||
        plandetailsobj[17] == undefined ||
        plandetailsobj[17] == 'null'
          ? false
          : true,
      id: parseFloat(plandetailsobj[7]),
      fusebillPlanID:
        plandetailsobj[8] == null ||
        plandetailsobj[8] == '' ||
        plandetailsobj[8] == undefined ||
        plandetailsobj[8] == 'null'
          ? 0
          : parseFloat(plandetailsobj[8]),
      planFrequencyID:
        plandetailsobj[9] == null ||
        plandetailsobj[9] == '' ||
        plandetailsobj[9] == undefined ||
        plandetailsobj[9] == 'null'
          ? 0
          : parseFloat(plandetailsobj[9]),
      optIn: plandetailsobj[10] == 'true' ? true : false,
      planname: plandetailsobj[14],
      planLevel: parseInt(plandetailsobj[15]),
      packageId: parseInt(plandetailsobj[16]),
      options: [],
      //  "disallowedPlanLevels":plandetailsobj[11]
    };
    if (plandetailsobj[11] != null || plandetailsobj[11] != 'null') {
      if (plandetailsobj[11].includes(plandetailsobj[12])) {
      }
    }

    this.removeplansummary(obj);
  }
  public unselectplanLife(elementcv: any) {

    // this.planCoverageName = ''
    // this.insuranceplanid =''
    console.log(elementcv)

    let plandetails = elementcv.target.attributes.data.value;

    let plandetailsobj = plandetails.split('##');
    let obj = {
      // enrollmentDate: moment(
      //   this.userForm.get("planenrollmentdate").value,
      //   "MM-DD-YYYY"
      // ).format("YYYY-MM-DD"),
      enrollmentDate: this.userForm.value.planEnrollmentDate
        ? this._CustomDatePipe.transform(this.userForm.value.planEnrollmentDate)
        : undefined,
      // "name":plandetailsobj[1]+"-"+plandetailsobj[2],
      name: plandetailsobj[14],
      details: plandetailsobj[0],
      packagename: plandetailsobj[0],
      groupName: plandetailsobj[1],
      amount: parseFloat(plandetailsobj[3]),
      planCoverage: plandetailsobj[2],
      planPrice: parseFloat(plandetailsobj[3]),
      amountUI: '$' + this._decimalPipe.transform(plandetailsobj[3], '1.2-2'),
      gst:
        plandetailsobj[4] == null ||
        plandetailsobj[4] == '' ||
        plandetailsobj[4] == undefined ||
        plandetailsobj[4] == 'null'
          ? 0
          : parseFloat(plandetailsobj[4]),
      hst:
        plandetailsobj[5] == null ||
        plandetailsobj[5] == '' ||
        plandetailsobj[5] == undefined ||
        plandetailsobj[5] == 'null'
          ? 0
          : parseFloat(plandetailsobj[5]),
      pst:
        plandetailsobj[6] == null ||
        plandetailsobj[6] == '' ||
        plandetailsobj[6] == undefined ||
        plandetailsobj[6] == 'null'
          ? 0
          : parseFloat(plandetailsobj[6]),
      qst:
        plandetailsobj[17] == null ||
        plandetailsobj[17] == '' ||
        plandetailsobj[17] == undefined ||
        plandetailsobj[17] == 'null'
          ? 0
          : parseFloat(plandetailsobj[17]),
      gstCheck:
        plandetailsobj[4] == null ||
        plandetailsobj[4] == '' ||
        plandetailsobj[4] == undefined ||
        plandetailsobj[4] == 'null'
          ? false
          : true,
      hstCheck:
        plandetailsobj[5] == null ||
        plandetailsobj[5] == '' ||
        plandetailsobj[5] == undefined ||
        plandetailsobj[5] == 'null'
          ? false
          : true,
      pstCheck:
        plandetailsobj[6] == null ||
        plandetailsobj[6] == '' ||
        plandetailsobj[6] == undefined ||
        plandetailsobj[6] == 'null'
          ? false
          : true,
      qstCheck:
        plandetailsobj[17] == null ||
        plandetailsobj[17] == '' ||
        plandetailsobj[17] == undefined ||
        plandetailsobj[17] == 'null'
          ? false
          : true,
      id: parseFloat(plandetailsobj[7]),
      fusebillPlanID:
        plandetailsobj[8] == null ||
        plandetailsobj[8] == '' ||
        plandetailsobj[8] == undefined ||
        plandetailsobj[8] == 'null'
          ? 0
          : parseFloat(plandetailsobj[8]),
      planFrequencyID:
        plandetailsobj[9] == null ||
        plandetailsobj[9] == '' ||
        plandetailsobj[9] == undefined ||
        plandetailsobj[9] == 'null'
          ? 0
          : parseFloat(plandetailsobj[9]),
      optIn: plandetailsobj[10] == 'true' ? true : false,
      planname: plandetailsobj[14],
      planLevel: parseInt(plandetailsobj[15]),
      packageId: parseInt(plandetailsobj[16]),
      options: [],
      //  "disallowedPlanLevels":plandetailsobj[11]
    };
    if (plandetailsobj[11] != null || plandetailsobj[11] != 'null') {
      if (plandetailsobj[11].includes(plandetailsobj[12])) {
      }
    }

    this.removeplansummaryLife(obj);
  }
  public addtoplansummary(obj: any, obj1: any) {
    let planSummary = JSON.parse(sessionStorage.getItem('plansummary') || '[]');

    // console.log(obj1);
    // console.log(planSummary);
    // console.log('addroplansummaryplans');
    // console.log(planSummary.length);
    // console.log(obj.id);
    // console.log(obj);

    this.addtoslectplans(obj.id, planSummary.length);
    planSummary.push(obj);

    let gstprice = 0;
    let hstprice = 0;
    let pstprice = 0;
    let qstprice = 0;

    if (obj.gst > 0) {
      gstprice = obj.planPrice * obj.gst;
      obj['gstPrice'] = gstprice
        ? this._decimalPipe.transform(gstprice, '1.2-2')
        : 0;
      obj['gstPrice'] = parseFloat(obj['gstPrice']);
    } else {
      obj['gstPrice'] = 0;
    }

    if (obj.hst > 0) {
      hstprice = obj.planPrice * obj.hst;

      obj['hstPrice'] = hstprice
        ? this._decimalPipe.transform(gstprice, '1.2-2')
        : 0;
      obj['hstPrice'] = parseFloat(obj['hstPrice']);
    } else {
      obj['hstPrice'] = 0;
    }

    if (obj.pst > 0) {
      pstprice = obj.planPrice * obj.pst;
      obj['pstPrice'] = pstprice
        ? this._decimalPipe.transform(pstprice, '1.2-2')
        : 0;
      obj['pstPrice'] = parseFloat(obj['pstPrice']);
    } else {
      obj['pstPrice'] = 0;
    }

    if (obj.qst > 0) {
      qstprice = obj.planPrice * obj.qst;

      obj['qstPrice'] = qstprice
        ? this._decimalPipe.transform(qstprice, '1.2-2')
        : 0;
      obj['qstPrice'] = parseFloat(obj['qstPrice']);
    } else {
      obj['qstPrice'] = 0;
    }

    obj['taxUI'] = '';
    if (obj['gstCheck']) {
      obj['taxUI'] += '<span>';
      if (obj['gstPrice'] == 0) {
        obj['taxUI'] += '-';
      } else {
        obj['taxUI'] +=
          '$' +
          this._decimalPipe.transform(obj['gstPrice'], '1.2-2') +
          '&nbsp;(GST)';
      }
      obj['taxUI'] += '</span>';
    }

    if (obj['pstCheck']) {
      obj['taxUI'] += '<span>';
      if (obj['pstPrice'] == 0) {
        obj['taxUI'] += '-';
      } else {
        obj['taxUI'] +=
          '$' +
          this._decimalPipe.transform(obj['pstPrice'], '1.2-2') +
          '&nbsp;(PST)';
      }
      obj['taxUI'] += '</span>';
    }

    if (obj['qstCheck']) {
      obj['taxUI'] += '<span>';
      if (obj['qstPrice'] == 0) {
        obj['taxUI'] += '-';
      } else {
        obj['taxUI'] +=
          '$' +
          this._decimalPipe.transform(obj['qstPrice'], '1.2-2') +
          '&nbsp;(QST)';
      }
      obj['taxUI'] += '</span>';
    }

    if (obj['hstCheck']) {
      obj['taxUI'] += '<span> ';
      if (obj['hstPrice'] == 0) {
        obj['taxUI'] += '-';
      } else {
        obj['taxUI'] +=
          '$' +
          this._decimalPipe.transform(obj['hstPrice'], '1.2-2') +
          '&nbsp;(HST)';
      }
      obj['taxUI'] += '</span>';
    }

    if (
      !obj['hstCheck'] &&
      !obj['gstCheck'] &&
      !obj['pstCheck'] &&
      !obj['qstCheck']
    ) {
      obj['taxUI'] += '<span>-';

      obj['taxUI'] += '</span>';
    }

    // ////this.consoleMessage(object["taxUI"])

    obj['tax'] = obj1.products
      ? this._decimalPipe.transform(
          obj1.products?.reduce((acc: any, calculatedTax: any) => {
            return acc + calculatedTax.tax;
          }, 0),
          '1.2-2'
        )
      : 0;
    obj['tax'] = parseFloat(obj['tax']);

    // obj["products"] =obj1
    obj['coverage'] = obj1.coverage;
    obj['planCoverage'] = obj1.planCoverage;
    obj['planLevelParent'] = obj1.planLevelParent;
    obj['planproductname'] = obj1.planproductname;

    obj['products'] = obj1.products;

    //this.consoleMessage(obj.planPrice)
    //this.consoleMessage(gstprice)
    //this.consoleMessage(hstprice)
    //this.consoleMessage(pstprice)

    //this.consoleMessage(qstprice)
    let pricecal = obj1.products.reduce((acc: any, calculatedTax: any) => {
      return acc + calculatedTax.total;
    }, 0);
    obj['totalPrice'] = parseFloat(pricecal);

    obj['totalUI'] = '$' + this._decimalPipe.transform(pricecal, '1.2-2');

    obj['total'] = this._decimalPipe.transform(pricecal, '1.2-2');

    //this.consoleMessage(obj1)

    obj['skuTotalPrice'] = parseFloat(obj1.products.reduce(
      (acc: any, calculatedTax: any) => {
        return acc + calculatedTax.total;
      },
      0
    ))

    let updatedSum = this.addtosum(obj.totalPrice);
    this.planssummarymain = [];
    this.planssummaryopt = [];

    planSummary.forEach((element: any) => {
      if (element.packagename != 'Opt-in') {
        this.planssummarymain.push(element);
      } else {
        this.planssummaryopt.push(element);
      }
    });

    this.plansskumain.push(obj1);

    sessionStorage.setItem('plansskumain', JSON.stringify(this.plansskumain));
    sessionStorage.setItem(
      'plansummarymain',
      JSON.stringify(this.planssummarymain)
    );
    sessionStorage.setItem(
      'plansummaryopt',
      JSON.stringify(this.planssummaryopt)
    );

    sessionStorage.setItem('plansummary', JSON.stringify(planSummary));
    let planSummaryData = JSON.parse(sessionStorage.getItem('plansummaryLife') || '[]');

    if (planSummary.length > 0 || planSummaryData.length>0) {
      this.plansnexttab = false;

    } else {
      this.plansnexttab = true;
    }
    if (updatedSum > 0) {
      this.cartcheckvalue = false;
    } else {
      this.cartcheckvalue = true;
    }

    setTimeout(() => {
      this.planssummarymainLife = JSON.parse(
        sessionStorage.getItem('planssummarymainLife') || '[]'
      );

      this.planssummarymain = JSON.parse(
        sessionStorage.getItem('plansummarymain') || '[]'
      );
      var total = 0;
      for (let i = 0; i < this.planssummarymainLife.length; i++) {
        total += parseFloat(this.planssummarymainLife[i].skuTotalPrice);
      }
      for (let i = 0; i < this.planssummarymain.length; i++) {
        total += parseFloat(this.planssummarymain[i].skuTotalPrice);
      }

      //this.consoleMessage(total);

      this.planAmount = this._decimalPipe
        .transform(total, '1.2-2')
        ?.replace(/,/g, '');
      sessionStorage.setItem('totalAmount', this.planAmount);

      if (this.planAmount > 0) {
        this.cartcheckvalue = false;
      } else {
        this.cartcheckvalue = true;
      }
    }, 100);
  }
  public addtoplansummaryLife(obj: any, obj1: any) {
    let plansummaryLife = JSON.parse(sessionStorage.getItem('plansummaryLife') || '[]');

    // console.log(obj1);
    // console.log(planSummary);
    // console.log('addroplansummaryplans');
    // console.log(planSummary.length);
    // console.log(obj.id);
    // console.log(obj);

    this.addtoslectplansLife(obj.id, plansummaryLife.length);
    plansummaryLife.push(obj);

    let gstprice = 0;
    let hstprice = 0;
    let pstprice = 0;
    let qstprice = 0;

    if (obj.gst > 0) {
      gstprice = obj.planPrice * obj.gst;
      obj['gstPrice'] = gstprice
        ? this._decimalPipe.transform(gstprice, '1.2-2')
        : 0;
      obj['gstPrice'] = parseFloat(obj['gstPrice']);
    } else {
      obj['gstPrice'] = 0;
    }

    if (obj.hst > 0) {
      hstprice = obj.planPrice * obj.hst;

      obj['hstPrice'] = hstprice
        ? this._decimalPipe.transform(gstprice, '1.2-2')
        : 0;
      obj['hstPrice'] = parseFloat(obj['hstPrice']);
    } else {
      obj['hstPrice'] = 0;
    }

    if (obj.pst > 0) {
      pstprice = obj.planPrice * obj.pst;
      obj['pstPrice'] = pstprice
        ? this._decimalPipe.transform(pstprice, '1.2-2')
        : 0;
      obj['pstPrice'] = parseFloat(obj['pstPrice']);
    } else {
      obj['pstPrice'] = 0;
    }

    if (obj.qst > 0) {
      qstprice = obj.planPrice * obj.qst;

      obj['qstPrice'] = qstprice
        ? this._decimalPipe.transform(qstprice, '1.2-2')
        : 0;
      obj['qstPrice'] = parseFloat(obj['qstPrice']);
    } else {
      obj['qstPrice'] = 0;
    }

    obj['taxUI'] = '';
    if (obj['gstCheck']) {
      obj['taxUI'] += '<span>';
      if (obj['gstPrice'] == 0) {
        obj['taxUI'] += '-';
      } else {
        obj['taxUI'] +=
          '$' +
          this._decimalPipe.transform(obj['gstPrice'], '1.2-2') +
          '&nbsp;(GST)';
      }
      obj['taxUI'] += '</span>';
    }

    if (obj['pstCheck']) {
      obj['taxUI'] += '<span>';
      if (obj['pstPrice'] == 0) {
        obj['taxUI'] += '-';
      } else {
        obj['taxUI'] +=
          '$' +
          this._decimalPipe.transform(obj['pstPrice'], '1.2-2') +
          '&nbsp;(PST)';
      }
      obj['taxUI'] += '</span>';
    }

    if (obj['qstCheck']) {
      obj['taxUI'] += '<span>';
      if (obj['qstPrice'] == 0) {
        obj['taxUI'] += '-';
      } else {
        obj['taxUI'] +=
          '$' +
          this._decimalPipe.transform(obj['qstPrice'], '1.2-2') +
          '&nbsp;(QST)';
      }
      obj['taxUI'] += '</span>';
    }

    if (obj['hstCheck']) {
      obj['taxUI'] += '<span> ';
      if (obj['hstPrice'] == 0) {
        obj['taxUI'] += '-';
      } else {
        obj['taxUI'] +=
          '$' +
          this._decimalPipe.transform(obj['hstPrice'], '1.2-2') +
          '&nbsp;(HST)';
      }
      obj['taxUI'] += '</span>';
    }

    if (
      !obj['hstCheck'] &&
      !obj['gstCheck'] &&
      !obj['pstCheck'] &&
      !obj['qstCheck']
    ) {
      obj['taxUI'] += '<span>-';

      obj['taxUI'] += '</span>';
    }

    // ////this.consoleMessage(object["taxUI"])

    obj['tax'] = obj1.products
      ? this._decimalPipe.transform(
          obj1.products?.reduce((acc: any, calculatedTax: any) => {
            return acc + calculatedTax.tax;
          }, 0),
          '1.2-2'
        )
      : 0;
    obj['tax'] = parseFloat(obj['tax']);

    // obj["products"] =obj1
    obj['coverage'] = obj1.coverage;
    obj['planCoverage'] = obj1.planCoverage;
    obj['planLevelParent'] = obj1.planLevelParent;
    obj['planproductname'] = obj1.planproductname;

    obj['products'] = obj1.products;

    //this.consoleMessage(obj.planPrice)
    //this.consoleMessage(gstprice)
    //this.consoleMessage(hstprice)
    //this.consoleMessage(pstprice)

    //this.consoleMessage(qstprice)
    let pricecal = obj1.products.reduce((acc: any, calculatedTax: any) => {
      return acc + calculatedTax.total;
    }, 0);
    obj['totalPrice'] = parseFloat(pricecal);

    obj['totalUI'] = '$' + this._decimalPipe.transform(pricecal, '1.2-2');

    obj['total'] = this._decimalPipe.transform(pricecal, '1.2-2');

    //this.consoleMessage(obj1)

    obj['skuTotalPrice'] = parseFloat(obj1.products.reduce(
      (acc: any, calculatedTax: any) => {
        return acc + calculatedTax.total;
      },
      0
    ))

    let updatedSum = this.addtosum(obj.totalPrice);
    this.planssummarymainLife = [];
    this.planssummaryopt = [];

    plansummaryLife.forEach((element: any) => {
      if (element.packagename != 'Opt-in') {
        this.planssummarymainLife.push(element);
      } else {
        this.planssummaryopt.push(element);
      }
    });

    this.plansskumain.push(obj1);

    sessionStorage.setItem('plansskumain', JSON.stringify(this.plansskumain));
    sessionStorage.setItem(
      'planssummarymainLife',
      JSON.stringify(this.planssummarymainLife)
    );
    sessionStorage.setItem(
      'plansummaryopt',
      JSON.stringify(this.planssummaryopt)
    );

    sessionStorage.setItem('plansummaryLife', JSON.stringify(plansummaryLife));
    let planSummaryData = JSON.parse(sessionStorage.getItem('plansummary') || '[]');

    if (plansummaryLife.length > 0 || planSummaryData.length>0) {
      this.plansnexttab = false;
    } else {
      this.plansnexttab = true;
    }
    if (updatedSum > 0) {
      this.cartcheckvalue = false;
    } else {
      this.cartcheckvalue = true;
    }

    setTimeout(() => {
      this.planssummarymainLife = JSON.parse(
        sessionStorage.getItem('planssummarymainLife') || '[]'
      );

      this.planssummarymain = JSON.parse(
        sessionStorage.getItem('plansummarymain') || '[]'
      );
      var total = 0;
      for (let i = 0; i < this.planssummarymainLife.length; i++) {
        total += parseFloat(this.planssummarymainLife[i].skuTotalPrice);
      }
      for (let i = 0; i < this.planssummarymain.length; i++) {
        total += parseFloat(this.planssummarymain[i].skuTotalPrice);
      }
      //this.consoleMessage(total);

      this.planAmount = this._decimalPipe
        .transform(total, '1.2-2')
        ?.replace(/,/g, '');
      sessionStorage.setItem('totalAmount', this.planAmount);

      if (this.planAmount > 0) {
        this.cartcheckvalue = false;
      } else {
        this.cartcheckvalue = true;
      }
    }, 100);
  }
  public removeplansummary(obj: any) {
    let planSummary = JSON.parse(sessionStorage.getItem('plansummary') || '[]');

    // console.log(planSummary);



    if (planSummary) {
      ////this.consoleMessage("removeinplanid"+obj.id)
      let index: any = this.getslectedplans(obj.id);
      ////this.consoleMessage(index)

      if (index > -1) {
        // console.log(index);
        planSummary.splice(index, 1);

      } else {
        return;
      }

      // ////this.consoleMessage(planSummary)
      // ////this.consoleMessage(planSummary.length)
      ////this.consoleMessage("beforeremoveplans")
      let selectedPlans = JSON.parse(
        sessionStorage.getItem('selectedPlans') || '[]'
      );
      var newselectedplans: any = {};
      for (var i = 0; i < planSummary.length; i++) {
        newselectedplans[planSummary[i].id] = i;
      }

      ////this.consoleMessage("newselectedPlans")
      ////this.consoleMessage(newselectedplans)
      sessionStorage.setItem('selectedPlans', JSON.stringify(newselectedplans));
      // this.removeslectplans(obj.id,planSummary.length)

      let gstprice = 0;
      let hstprice = 0;
      let pstprice = 0;
      let qstprice = 0;

      if (obj.gst > 0) {
        gstprice = obj.planPrice * obj.gst;
        obj['gstPrice'] = gstprice
          ? this._decimalPipe.transform(gstprice, '1.2-2')
          : 0;
        obj['gstPrice'] = parseFloat(obj['gstPrice']);
      } else {
        obj['gstPrice'] = 0;
      }

      if (obj.hst > 0) {
        hstprice = obj.planPrice * obj.hst;

        obj['hstPrice'] = hstprice
          ? this._decimalPipe.transform(gstprice, '1.2-2')
          : 0;
        obj['hstPrice'] = parseFloat(obj['hstPrice']);
      } else {
        obj['hstPrice'] = 0;
      }

      if (obj.pst > 0) {
        pstprice = obj.planPrice * obj.pst;
        obj['pstPrice'] = pstprice
          ? this._decimalPipe.transform(pstprice, '1.2-2')
          : 0;
        obj['pstPrice'] = parseFloat(obj['pstPrice']);
      } else {
        obj['pstPrice'] = 0;
      }

      if (obj.qst > 0) {
        qstprice = obj.planPrice * obj.qst;

        obj['qstPrice'] = qstprice
          ? this._decimalPipe.transform(qstprice, '1.2-2')
          : 0;
        obj['qstPrice'] = parseFloat(obj['qstPrice']);
      } else {
        obj['qstPrice'] = 0;
      }
      obj['taxUI'] = '';
      if (obj['gstCheck']) {
        obj['taxUI'] += '<span>';
        if (obj['gstPrice'] == 0) {
          obj['taxUI'] += '-';
        } else {
          obj['taxUI'] +=
            '$' +
            this._decimalPipe.transform(obj['gstPrice'], '1.2-2') +
            '&nbsp;(GST)';
        }
        obj['taxUI'] += '</span>';
      }

      if (obj['pstCheck']) {
        obj['taxUI'] += '<span>';
        if (obj['pstPrice'] == 0) {
          obj['taxUI'] += '-';
        } else {
          obj['taxUI'] +=
            '$' +
            this._decimalPipe.transform(obj['pstPrice'], '1.2-2') +
            '&nbsp;(PST)';
        }
        obj['taxUI'] += '</span>';
      }

      if (obj['qstCheck']) {
        obj['taxUI'] += '<span>';
        if (obj['qstPrice'] == 0) {
          obj['taxUI'] += '-';
        } else {
          obj['taxUI'] +=
            '$' +
            this._decimalPipe.transform(obj['qstPrice'], '1.2-2') +
            '&nbsp;(QST)';
        }
        obj['taxUI'] += '</span>';
      }

      if (obj['hstCheck']) {
        obj['taxUI'] += '<span> ';
        if (obj['hstPrice'] == 0) {
          obj['taxUI'] += '-';
        } else {
          obj['taxUI'] +=
            '$' +
            this._decimalPipe.transform(obj['hstPrice'], '1.2-2') +
            '&nbsp;(HST)';
        }
        obj['taxUI'] += '</span>';
      }

      if (
        !obj['hstCheck'] &&
        !obj['gstCheck'] &&
        !obj['pstCheck'] &&
        !obj['qstCheck']
      ) {
        obj['taxUI'] += '<span>-';

        obj['taxUI'] += '</span>';
      }

      // ////this.consoleMessage(object["taxUI"])

      obj['tax'] = parseFloat(
        this._decimalPipe.transform(
          gstprice + hstprice + pstprice + qstprice,
          '1.2-2'
        ) ?? ''
      );

      (obj['totalPrice'] =
        obj.planPrice + gstprice + hstprice + pstprice + qstprice),
        (obj['totalUI'] =
          '$' +
          this._decimalPipe.transform(
            obj.planPrice + gstprice + hstprice + pstprice + qstprice,
            '1.2-2'
          ));

      obj['total'] = parseFloat(
        this._decimalPipe.transform(
          obj.planPrice + gstprice + hstprice + pstprice + qstprice,
          '1.2-2'
        ) ?? ''
      );

      let updatedSum = this.removetosum(obj.totalPrice);
      this.planssummarymain = [];
      this.planssummaryopt = [];

      planSummary.forEach((element: any) => {
        if (element.packagename != 'Opt-in') {
          this.planssummarymain.push(element);
        } else {
          this.planssummaryopt.push(element);
        }
      });

      sessionStorage.setItem(
        'plansummarymain',
        JSON.stringify(this.planssummarymain)
      );
      sessionStorage.setItem(
        'plansummaryopt',
        JSON.stringify(this.planssummaryopt)
      );

      sessionStorage.setItem('plansummary', JSON.stringify(planSummary));
      let planSummaryLife = JSON.parse(sessionStorage.getItem('plansummaryLife') || '[]');

      if (planSummary.length > 0 || planSummaryLife.length>0) {
        this.plansnexttab = false;
      } else {
        this.plansnexttab = true;
      }
      if (updatedSum > 0) {
        this.cartcheckvalue = false;
      } else {
        this.cartcheckvalue = true;
      }
    }

    setTimeout(() => {
      // this.planssummarymain = JSON.parse(
      //   sessionStorage.getItem('plansummarymain') || '[]'
      // );

      // var total = 0;
      // for (let i = 0; i < this.planssummarymain.length; i++) {
      //   total += parseFloat(this.planssummarymain[i].skuTotalPrice);
      // }

      this.planssummarymainLife = JSON.parse(
        sessionStorage.getItem('planssummarymainLife') || '[]'
      );

      this.planssummarymain = JSON.parse(
        sessionStorage.getItem('plansummarymain') || '[]'
      );
      var total = 0;
      for (let i = 0; i < this.planssummarymainLife.length; i++) {
        total += parseFloat(this.planssummarymainLife[i].skuTotalPrice);
      }
      for (let i = 0; i < this.planssummarymain.length; i++) {
        total += parseFloat(this.planssummarymain[i].skuTotalPrice);
      }
      //this.consoleMessage(total);

      this.planAmount = this._decimalPipe
        .transform(total, '1.2-2')
        ?.replace(/,/g, '');
      sessionStorage.setItem('totalAmount', this.planAmount);
    }, 100);


    for (let i = 0; i < this.planssummarymain.length; i++) {
    if (this.planssummarymain[i].disallowedPlanLevels != null) {
      let disallowed_plans = this.planssummarymain[i].disallowedPlanLevels.split(',');
      const dom1: HTMLElement = this.elementRef.nativeElement;
      for (let i = 0; i < disallowed_plans.length; i++) {
        // console.log(disallowed_plans[i]);
        // console.log(disallowed_plans[i]);
        setTimeout(() => {
          let elem: any = dom1.querySelector(`#plancheck${disallowed_plans[i]}`)

          if(elem!=null){
          if (!elem.disabled) {

            elem.disabled = true
          }
        }
        }, 100);

      }
    }
  }
  }
  public removeplansummaryLife(obj: any) {

    console.log(obj)
    let planSummary = JSON.parse(sessionStorage.getItem('plansummaryLife') || '[]');

    // console.log(planSummary);

    if (planSummary) {
      ////this.consoleMessage("removeinplanid"+obj.id)
      let index: any = this.getslectedplansLife(obj.id);
      ////this.consoleMessage(index)

      if (index > -1) {

        planSummary.splice(index, 1);
        ////this.consoleMessage("afterremove")
        ////this.consoleMessage(planSummary)
      } else {
        return;
      }

      // ////this.consoleMessage(planSummary)
      // ////this.consoleMessage(planSummary.length)
      ////this.consoleMessage("beforeremoveplans")
      let selectedPlans = JSON.parse(
        sessionStorage.getItem('selectedPlans') || '[]'
      );
      var newselectedplans: any = {};
      for (var i = 0; i < planSummary.length; i++) {
        newselectedplans[planSummary[i].id] = i;
      }

      ////this.consoleMessage("newselectedPlans")
      ////this.consoleMessage(newselectedplans)

      sessionStorage.setItem('selectedPlansLife', JSON.stringify(newselectedplans));
      // this.removeslectplans(obj.id,planSummary.length)

      let gstprice = 0;
      let hstprice = 0;
      let pstprice = 0;
      let qstprice = 0;

      if (obj.gst > 0) {
        gstprice = obj.planPrice * obj.gst;
        obj['gstPrice'] = gstprice
          ? this._decimalPipe.transform(gstprice, '1.2-2')
          : 0;
        obj['gstPrice'] = parseFloat(obj['gstPrice']);
      } else {
        obj['gstPrice'] = 0;
      }

      if (obj.hst > 0) {
        hstprice = obj.planPrice * obj.hst;

        obj['hstPrice'] = hstprice
          ? this._decimalPipe.transform(gstprice, '1.2-2')
          : 0;
        obj['hstPrice'] = parseFloat(obj['hstPrice']);
      } else {
        obj['hstPrice'] = 0;
      }

      if (obj.pst > 0) {
        pstprice = obj.planPrice * obj.pst;
        obj['pstPrice'] = pstprice
          ? this._decimalPipe.transform(pstprice, '1.2-2')
          : 0;
        obj['pstPrice'] = parseFloat(obj['pstPrice']);
      } else {
        obj['pstPrice'] = 0;
      }

      if (obj.qst > 0) {
        qstprice = obj.planPrice * obj.qst;

        obj['qstPrice'] = qstprice
          ? this._decimalPipe.transform(qstprice, '1.2-2')
          : 0;
        obj['qstPrice'] = parseFloat(obj['qstPrice']);
      } else {
        obj['qstPrice'] = 0;
      }
      obj['taxUI'] = '';
      if (obj['gstCheck']) {
        obj['taxUI'] += '<span>';
        if (obj['gstPrice'] == 0) {
          obj['taxUI'] += '-';
        } else {
          obj['taxUI'] +=
            '$' +
            this._decimalPipe.transform(obj['gstPrice'], '1.2-2') +
            '&nbsp;(GST)';
        }
        obj['taxUI'] += '</span>';
      }

      if (obj['pstCheck']) {
        obj['taxUI'] += '<span>';
        if (obj['pstPrice'] == 0) {
          obj['taxUI'] += '-';
        } else {
          obj['taxUI'] +=
            '$' +
            this._decimalPipe.transform(obj['pstPrice'], '1.2-2') +
            '&nbsp;(PST)';
        }
        obj['taxUI'] += '</span>';
      }

      if (obj['qstCheck']) {
        obj['taxUI'] += '<span>';
        if (obj['qstPrice'] == 0) {
          obj['taxUI'] += '-';
        } else {
          obj['taxUI'] +=
            '$' +
            this._decimalPipe.transform(obj['qstPrice'], '1.2-2') +
            '&nbsp;(QST)';
        }
        obj['taxUI'] += '</span>';
      }

      if (obj['hstCheck']) {
        obj['taxUI'] += '<span> ';
        if (obj['hstPrice'] == 0) {
          obj['taxUI'] += '-';
        } else {
          obj['taxUI'] +=
            '$' +
            this._decimalPipe.transform(obj['hstPrice'], '1.2-2') +
            '&nbsp;(HST)';
        }
        obj['taxUI'] += '</span>';
      }

      if (
        !obj['hstCheck'] &&
        !obj['gstCheck'] &&
        !obj['pstCheck'] &&
        !obj['qstCheck']
      ) {
        obj['taxUI'] += '<span>-';

        obj['taxUI'] += '</span>';
      }

      // ////this.consoleMessage(object["taxUI"])

      obj['tax'] = parseFloat(
        this._decimalPipe.transform(
          gstprice + hstprice + pstprice + qstprice,
          '1.2-2'
        ) ?? ''
      );

      (obj['totalPrice'] =
        obj.planPrice + gstprice + hstprice + pstprice + qstprice),
        (obj['totalUI'] =
          '$' +
          this._decimalPipe.transform(
            obj.planPrice + gstprice + hstprice + pstprice + qstprice,
            '1.2-2'
          ));

      obj['total'] = parseFloat(
        this._decimalPipe.transform(
          obj.planPrice + gstprice + hstprice + pstprice + qstprice,
          '1.2-2'
        ) ?? ''
      );

      let updatedSum = this.removetosum(obj.totalPrice);
      this.planssummarymainLife = [];
      this.planssummaryopt = [];

      planSummary.forEach((element: any) => {
        if (element.packagename != 'Opt-in') {
          this.planssummarymainLife.push(element);
        } else {
          this.planssummaryopt.push(element);
        }
      });

      sessionStorage.setItem(
        'planssummarymainLife',
        JSON.stringify(this.planssummarymainLife)
      );
      sessionStorage.setItem(
        'plansummaryopt',
        JSON.stringify(this.planssummaryopt)
      );

      sessionStorage.setItem('plansummaryLife', JSON.stringify(planSummary));
      let planSummaryData = JSON.parse(sessionStorage.getItem('plansummary') || '[]');

      if (planSummary.length > 0 || planSummaryData.length>0) {
        this.plansnexttab = false;
      } else {
        this.plansnexttab = true;
      }
      if (updatedSum > 0) {
        this.cartcheckvalue = false;
      } else {
        this.cartcheckvalue = true;
      }
    }

    setTimeout(() => {
      // this.planssummarymain = JSON.parse(
      //   sessionStorage.getItem('plansummarymain') || '[]'
      // );

      // var total = 0;
      // for (let i = 0; i < this.planssummarymain.length; i++) {
      //   total += parseFloat(this.planssummarymain[i].skuTotalPrice);
      // }

      this.planssummarymainLife = JSON.parse(
        sessionStorage.getItem('planssummarymainLife') || '[]'
      );

      this.planssummarymain = JSON.parse(
        sessionStorage.getItem('plansummarymain') || '[]'
      );
      var total = 0;
      for (let i = 0; i < this.planssummarymainLife.length; i++) {
        total += parseFloat(this.planssummarymainLife[i].skuTotalPrice);
      }
      for (let i = 0; i < this.planssummarymain.length; i++) {
        total += parseFloat(this.planssummarymain[i].skuTotalPrice);
      }
      //this.consoleMessage(total);

      this.planAmount = this._decimalPipe
        .transform(total, '1.2-2')
        ?.replace(/,/g, '');
      sessionStorage.setItem('totalAmount', this.planAmount);
    }, 100);
  }
  public addtoslectplans(planid: number, plansumamryindex: number) {
    ////this.consoleMessage("beforeaddtoplans")
    ////this.consoleMessage(planid)
    ////this.consoleMessage(plansumamryindex)
    let selectedPlans = JSON.parse(
      sessionStorage.getItem('selectedPlans') || '{}'
    );
    ////this.consoleMessage(selectedPlans)
    selectedPlans[planid] = plansumamryindex;
    ////this.consoleMessage("afteraddtoplans")
    ////this.consoleMessage(selectedPlans)
    sessionStorage.setItem('selectedPlans', JSON.stringify(selectedPlans));
  }
  public addtoslectplansLife(planid: number, plansumamryindex: number) {
    ////this.consoleMessage("beforeaddtoplans")
    ////this.consoleMessage(planid)
    ////this.consoleMessage(plansumamryindex)
    let selectedPlans = JSON.parse(
      sessionStorage.getItem('selectedPlansLife') || '{}'
    );
    ////this.consoleMessage(selectedPlans)
    selectedPlans[planid] = plansumamryindex;
    ////this.consoleMessage("afteraddtoplans")
    ////this.consoleMessage(selectedPlans)
    sessionStorage.setItem('selectedPlansLife', JSON.stringify(selectedPlans));
  }
  public removeslectplans(planid: number, plansumamryindex: number) {
    ////this.consoleMessage("beforeremoveplans")
    let selectedPlans = JSON.parse(
      sessionStorage.getItem('selectedPlans') || '{}'
    );

    ////this.consoleMessage(selectedPlans)
    delete selectedPlans[planid];

    for (const planid in selectedPlans) {
      ////this.consoleMessage(planid)
      ////this.consoleMessage(selectedPlans[planid])

      if (selectedPlans[planid] != 0) {
        selectedPlans[planid] = selectedPlans[planid] - 1;
        if (selectedPlans[planid]) {
        }
      }
    }
    ////this.consoleMessage("adjustedselectedPlans")
    ////this.consoleMessage(selectedPlans)
    sessionStorage.setItem('selectedPlans', JSON.stringify(selectedPlans));
  }

  public getslectedplans(planid: number) {
    ////this.consoleMessage(planid)
    let selectedPlans = JSON.parse(
      sessionStorage.getItem('selectedPlans') || '{}'
    );
    ////this.consoleMessage(selectedPlans)
    return selectedPlans[planid];
  }
  public getslectedplansLife(planid: number) {
    ////this.consoleMessage(planid)
    let selectedPlans = JSON.parse(
      sessionStorage.getItem('selectedPlansLife') || '{}'
    );
    ////this.consoleMessage(selectedPlans)
    return selectedPlans[planid];
  }
  public addtosum(amount: any) {
    //this.consoleMessage("addtosum"+amount)

    // amount = Math.round((amount + Number.EPSILON) * 100) / 100;

    let selectedPlansAmount = parseFloat(
      sessionStorage.getItem('totalAmount') || '0.00'
    );

    selectedPlansAmount = selectedPlansAmount + parseFloat(amount);

    //this.consoleMessage("selectedPlansAmount"+selectedPlansAmount)
    sessionStorage.setItem(
      'totalAmount',
      this._decimalPipe
        .transform(selectedPlansAmount, '1.2-2')
        ?.replace(/,/g, '') ?? '0'
    );

    sessionStorage.setItem(
      'totalAmountUI',
      '$' +
        this._decimalPipe
          .transform(selectedPlansAmount, '1.2-2')
          ?.replace(/,/g, '')
    );

    this.planAmount = this._decimalPipe
      .transform(selectedPlansAmount, '1.2-2')
      ?.replace(/,/g, '');

    return selectedPlansAmount;
  }

  public removetosum(amount: number) {
    let selectedPlansAmount = parseFloat(
      sessionStorage.getItem('totalAmount')?.replace(/,/g, '') ?? '0'
    );

    selectedPlansAmount = selectedPlansAmount - amount;
    sessionStorage.setItem(
      'totalAmount',
      this._decimalPipe
        .transform(selectedPlansAmount, '1.2-2')
        ?.replace(/,/g, '') ?? '0'
    );
    sessionStorage.setItem(
      'totalAmountUI',
      '$' +
        this._decimalPipe
          .transform(selectedPlansAmount, '1.2-2')
          ?.replace(/,/g, '')
    );

    this.planAmount = this._decimalPipe
      .transform(selectedPlansAmount, '1.2-2')
      ?.replace(/,/g, '');
    return selectedPlansAmount;
  }



  public openplanoptions(
    elementcv: any,
    options: any,
    plan: any,
    product: any
  ) {
    let plandetails = elementcv.attributes.data.value;

    let plandetailsobj = plandetails.split('##');

    let modifiedOptions: any = [];

    // this.plandetailsobjvalue = plandetailsobj;
    //this.consoleMessage(plandetails)
    //this.consoleMessage(product.id)

    this.plandetailsobjvalue = plandetails;

    options.forEach((element: any) => {
      element.planOptionsValues.forEach((options: any) => {
        options.planOptionName = element.name;
        options.json = JSON.stringify(options);
      });

      modifiedOptions.push(element);
    });

    this.optionstitle = modifiedOptions;
    // alert("startrhetre")
    // jQuery("#showplanoptions-modal").modal("show");

    this.planOptionsModel =true
  }

  public closeplanoptions() {}
  public capitalize(str: String) {
    if (str) {
      // return str.charAt(0).toUpperCase() + str.slice(1);
      const words = str.split(' ');

      let finalres: any = words.filter((v) => v != '');
      return finalres
        .map((word: any) => {
          return word[0].toUpperCase() + word.substring(1);
        })
        .join(' ');
    }
  }
  public capitalizeFirstLetter(str: String) {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

    }
  }
  public closemissingInfoModel(){
    this.missingInformation =false
  }


  public closeoptionsselection() {
    // console.log(this.optionstitle)
    this.planOptionsModel =false
    let plandetailsobj = this.optionPlanDetailsObj.nativeElement.value.split("##");


   const planCheck = this.renderer.selectRootElement("#plancheck"+ plandetailsobj[7]);
      // this.renderer.setProperty(planCheck, 'checked', false);
      planCheck.click()


    let optionumber =this.optionNumberInput.nativeElement.value;

    this.planOptionsModel =false
    for (let i = 1; i <= optionumber; i++) {
      // $(".optionselectionmethod-" + i + ":checked").prop("checked", false);
      const radioButton = this.renderer.selectRootElement(".optionselectionmethod-" + i +":checked", true);
      this.renderer.setProperty(radioButton, 'checked', false);

    }
  }
  public planoptionselection(event:any, optionvalue:any, optionid:any, optionvalueid:any,index:any,className:any) {

    let obj={
      id:optionid,
    }

    this.planoptionsValues.push(obj)

    const radioButton = this.renderer.selectRootElement(".optionselectionmethod-" + optionid);
    this.renderer.setProperty(radioButton, 'checked', false);

    // $(".optionselectionmethod-" + optionid)
    //   .not(this)
    //   .prop("checked", false);

      const elements = document.querySelectorAll(`.${className}`+ optionid);
    elements.forEach((element: Element) => {
      this.renderer.setProperty(element, 'checked', false);
    });
    // this.checkboxes.forEach((radioButton) => {
    //   const [currentGroupIndex] = radioButton.nativeElement.id
    //     .split('-')
    //     .slice(2)
    //     .map(Number);

    //     console.log("curreny"+currentGroupIndex)
    //     console.log("indexValle"+index)
    //     console.log("curremtoption"+currentOptionId)
    //     console.log("optionid"+optionid)

    //   if (currentGroupIndex === index+1 && currentOptionId === optionid) {
    //     radioButton.nativeElement.checked = false;
    //   }
    // });

  //   const radioButton:any = this.renderer.selectRootElement(".optionselectionmethod-" + optionid, true);
  //   console.log(radioButton)
  //   radioButton.forEach((element: HTMLElement) => {
  //    this.renderer.setProperty(element, 'checked', false);
  //  });

      // this.checkboxes.forEach((checkbox) => {
      //   const [currentGroupIndex, currentOptionId] = checkbox.nativeElement.id
      //   .split('-')
      //   .slice(2)
      //   .map(Number);

      // if (currentGroupIndex === groupIndex && currentOptionId == optionid) {
      //   checkbox.nativeElement.checked = false;
      // }
      // });
//       console.log(this.checkboxes)
//       this.checkboxes.forEach((radioButton) => {
//         console.log(optionid)
//         if (radioButton.nativeElement.id.split('-')
//           .slice(2)
//           .map(Number) === optionid) {

//  }
//       });


   const radioButtonID = this.renderer.selectRootElement("#planselectionvalue-" + optionvalueid + "-" + optionid,true);
    this.renderer.setProperty(radioButtonID, 'checked', true);




    // $("#planselectionvalue-" + optionvalueid + "-" + optionid).prop(
    //   "checked",
    //   true
    // );
// console.log(this.planoptionsValues)
    this.optionmessage = "";
  }
  public confirmoptionsselection() {
    // let optionumber = $("#optionumber").val();
    // console.log(this.planoptionsValues)
    // if (this.planoptionsValues) {
    //   this.optionmessage = "Please select all plan options";
    //   return;
    // } else {
    //   this.optionmessage = "";
    // }

    let optionumber:any =this.optionNumberInput.nativeElement.value;
    //this.consoleMessage($("#plandetailsobj").val())

    // console.log(optionumber)

    let plandetailsobj = this.optionPlanDetailsObj.nativeElement.value.split("##");

// console.log(optionumber)
// console.log(plandetailsobj)

    let array = [];

    let optionarray = [];


    let checkvalue = true;

//  let finalArray=   this.planoptionsValues.filter((p: any) => p.id == 1 && p.id==2);

for (let i = 1; i <= optionumber; i++) {
  // console.log(this.renderer.selectRootElement(".optionselectionmethod-" + i +":checked").value)
  // checkvalue = checkvalue &&   this.renderer.selectRootElement(".optionselectionmethod-" + i +":checked").value;
  const dom: HTMLElement = this.elementRef.nativeElement;
  const selector = `.optionselectionmethod-${i}:checked`;
  const element = dom.querySelector(selector);

  if (!element) {
    checkvalue = false; // Set to false if any element is not checked
    break; // Exit the loop early since we found an unchecked element
  }
}

    // for (let i = 1; i <= optionumber; i++) {
    //   // console.log(this.renderer.selectRootElement(".optionselectionmethod-" + i +":checked").value)
    //   // checkvalue = checkvalue &&   this.renderer.selectRootElement(".optionselectionmethod-" + i +":checked").value;
    //   const dom: HTMLElement = this.elementRef.nativeElement;
    //   const selector = `.optionselectionmethod-${i}:checked`;
    //   const element = dom.querySelector(selector);
    //   if (element) {
    //     checkvalue= true; // Return true immediately if an element is found and checked
    //   }
    //   else{
    //     checkvalue =false
    //   }
    // }



    if (!checkvalue) {
      this.optionmessage = this.lang.Please_select_all_plan_options;
      return;
    } else {
      this.optionmessage = "";
    }

    // jQuery("#showplanoptions-modal").modal("hide");

this.planOptionsModel =false


    for (let i = 1; i <= optionumber; i++) {
      let optionobj: any = {};
// console.log(this.planoptionsValues)
      let selectedOptionValues = JSON.parse(
        this.renderer.selectRootElement(".optionselectionmethod-" + i +":checked").value
      );
      // console.log(selectedOptionValues)

      // ////this.consoleMessage(selectedOptionValues)
      optionobj = selectedOptionValues;

      optionobj.name = selectedOptionValues.planOptionName;
      optionobj.value = selectedOptionValues.value;
      optionobj.planOptionsValueId = selectedOptionValues.planOptionsValueId;

      optionarray.push(optionobj);
    }

    // let plandetailsobj = $("#plandetailsobj").val().split(",");

    let obj = {
      isBundle:"true",
      disallowedPlanLevels: plandetailsobj[22],

      // enrollmentDate: moment(
      //   this.userForm.get("planenrollmentdate").value,
      //   "MM-DD-YYYY"
      // ).format("YYYY-MM-DD"),
      enrollmentDate: this.userForm.value.planEnrollmentDate
        ? this._CustomDatePipe.transform(this.userForm.value.planEnrollmentDate)
        : undefined,
      // "name":plandetailsobj[1]+"-"+plandetailsobj[2],
      name: plandetailsobj[14],
      details: plandetailsobj[0],
      packagename: plandetailsobj[0],
      groupName: plandetailsobj[1],
      amount: parseFloat(plandetailsobj[3]),
      planCoverage: plandetailsobj[2],
      planPrice: parseFloat(plandetailsobj[3]),
      amountUI: "$" + this._decimalPipe.transform(plandetailsobj[3], "1.2-2"),
      gst:
        plandetailsobj[4] == null ||
        plandetailsobj[4] == "" ||
        plandetailsobj[4] == undefined ||
        plandetailsobj[4] == "null"
          ? 0
          : parseFloat(plandetailsobj[4]),
      hst:
        plandetailsobj[5] == null ||
        plandetailsobj[5] == "" ||
        plandetailsobj[5] == undefined ||
        plandetailsobj[5] == "null"
          ? 0
          : parseFloat(plandetailsobj[5]),
      pst:
        plandetailsobj[6] == null ||
        plandetailsobj[6] == "" ||
        plandetailsobj[6] == undefined ||
        plandetailsobj[6] == "null"
          ? 0
          : parseFloat(plandetailsobj[6]),
      qst:
        plandetailsobj[17] == null ||
        plandetailsobj[17] == "" ||
        plandetailsobj[17] == undefined ||
        plandetailsobj[17] == "null"
          ? 0
          : parseFloat(plandetailsobj[17]),
      gstCheck:
        plandetailsobj[4] == null ||
        plandetailsobj[4] == "" ||
        plandetailsobj[4] == undefined ||
        plandetailsobj[4] == "null"
          ? false
          : true,
      hstCheck:
        plandetailsobj[5] == null ||
        plandetailsobj[5] == "" ||
        plandetailsobj[5] == undefined ||
        plandetailsobj[5] == "null"
          ? false
          : true,
      pstCheck:
        plandetailsobj[6] == null ||
        plandetailsobj[6] == "" ||
        plandetailsobj[6] == undefined ||
        plandetailsobj[6] == "null"
          ? false
          : true,
      qstCheck:
        plandetailsobj[17] == null ||
        plandetailsobj[17] == "" ||
        plandetailsobj[17] == undefined ||
        plandetailsobj[17] == "null"
          ? false
          : true,
      id: parseFloat(plandetailsobj[7]),
      fusebillPlanID:
        plandetailsobj[8] == null ||
        plandetailsobj[8] == "" ||
        plandetailsobj[8] == undefined ||
        plandetailsobj[8] == "null"
          ? 0
          : parseFloat(plandetailsobj[8]),
      planFrequencyID:
        plandetailsobj[9] == null ||
        plandetailsobj[9] == "" ||
        plandetailsobj[9] == undefined ||
        plandetailsobj[9] == "null"
          ? 0
          : parseFloat(plandetailsobj[9]),
      optIn: plandetailsobj[10] == "true" ? true : false,
      planname: plandetailsobj[14],
      planLevel: parseInt(plandetailsobj[15]),
      packageId: parseInt(plandetailsobj[16]),
      options: optionarray || [],

      //  "disallowedPlanLevels":plandetailsobj[11]
    };
    if (plandetailsobj[11] != null || plandetailsobj[11] != "null") {
      if (plandetailsobj[11].includes(plandetailsobj[12])) {
        // elementcv.checked =false
      }
    }

    let obj1={}
    //this.consoleMessage(obj)

    console.log(obj)
    console.log(this.planobjdata)



  this.addtoplansummary(obj,this.planobjdata)




  }
  public showvoidcheckpreview() {
    this.imageDisplayShow= true

  }
  public closeImagePreview(){
    this.imageDisplayShow= false
  }

}

export function childrenminimumAgeValidator(minimumAge: number) {
  return (control: { value: string | number | Date }) => {
    const birthDate = new Date(control.value);

    // //this.consoleMessage(birthDate)
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();

    // //this.consoleMessage(age)
    // //this.consoleMessage(minimumAge)

    if (age == 55) {
      return { childrenminimumAge: false };
    }
    if (age > 21 || age < 0) {
      return { childrenminimumAge: true };
    }

    return null;
  };
}
export function validatehoursperweek(control: AbstractControl): any {
  if (control.value && control.value < 20) {
    return { mininvalidhours: true };
  } else if (control.value && control.value >= 81) {
    return { maxinvalidhours: true };
  }
}
export function hourselectioncondition(control: AbstractControl): any {
  if (control.value && control.value === 'false') {
    return { invalidhoursselection: true };
  }
}
export function creditcardvalidation(control: AbstractControl): any {
  if (control.value.slice(0, 2) == 34 || control.value.slice(0, 2) == 37) {
    var creditcardRegexp = /^[0-9_ ]{17,17}$/;
    if (control.value && !creditcardRegexp.test(control.value)) {
      return { invalidcreditcarddetails: true };
    }
  } else {
    var creditcardRegexp = /^[0-9_ ]{19,19}$/;
    if (control.value && !creditcardRegexp.test(control.value)) {
      return { invalidcreditcarddetails: true };
    }
  }
}
export function creditcardvalidationAmerica(control: AbstractControl): any {}
export function checkbanknumber(control: AbstractControl): any {
  if (control.value && control.value.length != 3) {
    return { checkbanknumbervalidator: true };
  }
}
export function checktransitnumber(control: AbstractControl): any {
  if (control.value && control.value.length != 5) {
    return { checktransitnumbervalidator: true };
  }
}

export function checkaccountnumber(control: AbstractControl): any {
  if (
    control.value &&
    (control.value.length < 5 || control.value.length > 12)
  ) {
    return { checkaccountnumbervalidator: true };
  }
}
export function emailValidator(control: AbstractControl): any {


  var emailRegexp =
  /^(?!\\s)*[\sA-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-z]{2,3}(?!\\s)*$/i;

  // var emailRegexp =
  //   /^(?!\\s)[_A-Za-z0-9._%+-]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$/i;

  if (control.value && !emailRegexp.test(control.value)) {
    return { invalidEmail: true };
  }
}



