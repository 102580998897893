
@if(show){
  <div class="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
    <div class="bg-white  rounded-xl shadow-lg w-full md:w-1/2">
      <div class="flex items-center justify-center font-bold text-2xl h-16 border border-b-indigo-700 bg-[#00297A] text-white">
        <h2 class="text-[14px] font-semibold">{{buttonInfo}} Dependent</h2>
        <!-- <button (click)="onClose()" class="text-gray-500 hover:text-gray-800 flex items-end justify-end">&times;</button> -->
      </div>
      <form [formGroup]="childForm" (ngSubmit)="onSubmit()">

        <div class="grid grid-cols-1 sm:grid-cols-6 gap-2 p-2">
          <div class="sm:col-span-3">
            <label for="" class="">{{lang.First_Name}} <span class="text-danger">*</span></label>
            <input class="form-control checking-field" type="text" placeholder={{lang.First_Name}}
              formControlName="firstName" (keypress)="alphabatesOnly($event)">

          @if (f['firstName'].errors && f['firstName'].touched) {
            <div class="invalid text-red-500">
              @if (f['firstName'].errors['required']) {
              <div>{{ lang.Please_enter_first_name }}</div>
              } @if (f['firstName'].errors['minlength']) {
              <div>{{ lang.First_Name_minimum_of_2_Characters_and_without_numbers }}</div>
              }
            </div>
            }

          </div>
          <div class="sm:col-span-3">
            <label for="" class="">{{lang.Last_Name}} <span class="text-danger">*</span></label>
            <input type="text" class="form-control checking-field" placeholder={{lang.Last_Name}}
              formControlName="lastName" (keypress)="alphabatesOnly($event)">


              @if (f['lastName'].errors && f['lastName'].touched) {
                <div class="invalid text-red-500">
                  @if (f['lastName'].errors['required']) {
                  <div>{{ lang.Please_enter_last_name }}</div>
                  } @if (f['lastName'].errors['minlength']) {
                  <div>{{ lang.Last_Name_minimum_of_2_Characters_and_without_numbers }}</div>
                  }
                </div>
                }



       </div>
       <div class="sm:col-span-3">
        <label for="" class="">{{lang.Gender}} <span class="text-danger">*</span></label>

        <select class="form-control" placeholder="Select" formControlName="gender">

          <option value="" selected disabled>{{lang.Select}}</option>
          <option value="Male">{{ lang.Male }}</option>
          <option value="Female">{{ lang.Female }}</option>
          <option value="Non-Binary">{{ lang.Non_Binary }}</option>
          <option value="Undisclosed"> {{ lang.undisclosed }} </option>
        </select>


        @if (f['gender'].errors && f['gender'].touched) {
          <div class="invalid text-red-500">
            @if (f['gender'].errors['required']) {
            <div>{{ lang.Please_Select_Gender }}</div>
            }

          </div>
          }

      </div>


      <div class="sm:col-span-3">
        <label for="childInfoDOB" class="">{{lang.Date_of_Birth}}<span class="text-danger">*</span></label>
        <!-- <input class="form-control checking-field" id="childInfoDOB" placeholder="MM-DD-YYYY"
          formControlName="date_of_birth"
          > -->

          <input type="text" (paste)="(false)"
          class="form-control" formControlName="date_of_birth"
          [minDate]="minDate"
          [maxDate]="maxDate" bsDatepicker
          [bsConfig]="{dateInputFormat: 'MM-DD-YYYY',showWeekNumbers: false}"
          placeholder="MM-DD-YYYY"
          bsDatepicker (blur)="checkChildDateofBirth($event)" (input)="checkChildDateofBirth($event)"
          (keypress)="numbersOnly($event)"/>

          @if (f['date_of_birth'].errors && f['date_of_birth'].touched) {
            <div class="invalid text-red-500">
              @if (f['date_of_birth'].errors['required']) {
              <div>{{ lang.Select_Date_of_birth }}</div>
              }
              @if (f['date_of_birth'].errors['childrenminimumAge']) {
                <div>{{lang.Age_must_be_below_21_years}}</div>
                }
                @if (f['date_of_birth'].errors['validGraduationDateBirth']) {
                  <div>{{lang.Please_ensure_the_graduation_date_is_greater_than_the_date_of_birth}}</div>
                  }


            </div>
            }
      </div>

      <div class="col-span-full">
        <label for="childDependentInsurence" class="">{{lang.Is_your_dependent_covered_by_another_Health_and_or_Dental_Insurance_plan}} <span class="text-danger">*</span>
        </label>
        <div class="flex items-center space-x-2">
          <input
            type="radio"
            id="true"
            formControlName="is_child_having_healthcard"
            value="true" (click)="havingchildrenDependentInsurence($event)"
            class="form-radio text-blue-600"
          />
          <label for="true" class="text-gray-700">{{lang.Yes}}</label>
        </div>

        <div class="flex items-center space-x-2">
          <input
            type="radio"
            id="false"
            formControlName="is_child_having_healthcard"
            value="false" (click)="havingchildrenDependentInsurence($event)"
            class="form-radio text-blue-600"
          />
          <label for="false" class="text-gray-700">{{lang.No}}</label>
        </div>



        @if (f['is_child_having_healthcard'].errors && f['is_child_having_healthcard'].touched) {
          <div class="invalid text-red-500">
            @if (f['is_child_having_healthcard'].errors['required']) {
            <div>{{lang.Please_select_health_card_option}}</div>
            }

          </div>
          }

          @if(childForm.get('is_child_having_healthcard')?.value === 'true'){
            <div class="sm:col-span-3">
              <label for="" class="">{{lang.Name_Of_The_Carrier}} <span class="text-danger">*</span></label>
              <input type="text" class="form-control checking-field"
                placeholder={{lang.Name_Of_The_Carrier}} formControlName="child_carrier_name">

                @if (f['child_carrier_name'].errors && f['child_carrier_name'].touched) {
                  <div class="invalid text-red-500">
                    @if (f['child_carrier_name'].errors['required']) {
                    <div>   {{ lang.Please_enter_name_of_carrier }}</div>
                    }

                  </div>
                  }



            </div>
               }

      </div>


        <div class="sm:col-span-full">
          <input type="checkbox" class="" name="" id="" formControlName="isDisabled"
           />&nbsp;{{
          lang.Dependent_with_Special_Needs }}&nbsp;

          <!-- <i class="fa fa-info-circle" (click)="dependentWithSpecialNeedsShow()"></i> -->
        </div>

      <div class="sm:col-span-full">
        <div class="form-group col-12 paddingLeft6px">
          <input type="checkbox" class="" name="" id="" formControlName="enrolledInUniversity"
            (click)="enrolledunversity($event)" />&nbsp;{{
          lang.Post_Secondary_Student }}&nbsp;

          <!-- <i class="fa fa-info-circle" (click)="postSecondaryShow()"></i> -->
        </div>

        @if(childForm.get('enrolledInUniversity')?.value){
          <div class="sm:col-span-3 w-1/2">
          <label class="graduationDay">{{ lang.Graduation_Day }}<span class="text-danger">*</span></label>

            <input type="text" class="form-control" (paste)="(false)"
                    bsDatepicker  #dp
                    placeholder="MM-DD-YYYY"
                    [bsConfig]="{dateInputFormat: 'MM-DD-YYYY',showWeekNumbers: false}"
                    bsDatepicker (blur)="checkDateOfgraducation($event)" (input)="checkDateOfgraducation($event)"
                     (keypress)="numbersOnly($event)"

                    [placement]='"top"'
                    formControlName="graduationDay" />

            @if (f['graduationDay'].errors && f['graduationDay'].touched) {
              <div class="invalid text-red-500">
                @if (f['graduationDay'].errors['required']) {
                <div>{{lang.Please_choose_graduation_date}}</div>
                }
                @if (f['graduationDay'].errors['validGraduationDate']) {
                  <div>{{lang.Please_ensure_the_graduation_date_is_greater_than_the_date_of_birth}}</div>
                  }
                  @if (f['graduationDay'].errors['dateOfBirth']) {
                    <div>{{lang.Select_Date_of_birth}}</div>
                    }
              </div>
              }

        </div>
         }
      </div>
          </div>



        <div class="flex justify-end gap-2 border border-t-indigo-700 px-2">
          <button
            type="button"
            class="w-36 h-14 mt-2 mb-2 rounded-full border-2 border-indigo-200 bg-indigo-200 p-0.5 font-bold hover:bg-white"
            (click)="onClose()"
          >
            {{ lang.Cancel }}
          </button>
          <!-- <button type="button" (click)="onClose()" class="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2">Cancel</button> -->
          <button
          type="submit"
          class="w-36 h-14 mt-2 mb-2 rounded-full border-2 border-indigo-700 p-0.5 font-bold hover:border-green-500" [disabled]="childForm.invalid">
          {{buttonInfo}}
        </button>
        </div>
      </form>
    </div>
  </div>
  }
