import { Component } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  public selectedLanguage: any;
  languageData: any;
  public lang: any = {};
  constructor(private http:HttpClient) { }

  ngOnInit(): void {
this.getLanguageTokens()

  }

  public getLanguageTokens(){
    let body= {
      "project":"SignupForm",
      "url":"Footer"
    }
    this.http
    .post('https://languageengineapi.aitestpro.com/string/project-url-strings',body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .subscribe(
      (response: any) => {
        // console.log(response)
        this.lang = {};
        // this.languageData = response.strings
        this.selectedLanguage = sessionStorage.getItem("language")
        for (let token of response.strings) {
          this.lang[token.key] = token[this.selectedLanguage];
        }

        console.log(this.lang)

      })


  }

}


