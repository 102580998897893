import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modelpopupconfirmation',
  templateUrl: './modelpopupconfirmation.component.html',
  styleUrl: './modelpopupconfirmation.component.scss'
})
export class ModelpopupconfirmationComponent {

  @Input() show = false;
  @Input() showHeaderMessage:any;
  @Input() showModelContent:any;
  @Input() languageData:any;

  @Output() closedModelpopup = new EventEmitter<boolean>();
  public lang: any = {};
  selectedLanguage: any;
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.lang = {};
    this.languageData =   this.languageData
    this.selectedLanguage = sessionStorage.getItem("language")
    for (let token of this.languageData) {
      this.lang[token.key] = token[this.selectedLanguage];
    }

  }

  closeModelpopup(){
    this.show=false;
    this.closedModelpopup.emit(false);

  }

}
