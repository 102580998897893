


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StaticApis } from './../shared/gig';
import { environment } from './../environments/environment';

// const httpOptions = {
//   headers: new HttpHeaders({
//     'Accept': "application/json",
//     'Content-Type': 'application/json',
//     'tenantid': 'bfciricle_canada_prod'

//   })
// };

[]
@Injectable({ providedIn: 'root' })
export class SignupformService {
  headers: any;
  headers1: any;
  CONFIG_DATA: any;
  Authorization:any;

  private personalinfo: string = `${environment.app.grp}${StaticApis.personalinfo}`;
  private validationdata: string = `${environment.app.grp}${StaticApis.validationdata}`;

  private savePlanDetails: string = `${environment.app.grp}${StaticApis.saveplan}`;
  private lifeInsuranceData: string = `${environment.app.grp}${StaticApis.lifeInsuranceData}`;


  private configData: string = `${environment.app.grp}${StaticApis.configData}`;
  private termsandconditionsvalue: string = `${environment.app.grp}${StaticApis.termsandconditionsvalue}`;
  private plansdetails: string = `${environment.app.grp}${StaticApis.plansdetails}`;
  private paymentdetails: string = `${StaticApis.paymentdetails}`;
  private getbanknamesvalues: string = `${environment.app.grp}${StaticApis.getbanknames}`;

  private bankdetailsregistervalues: string = `${environment.app.grp}${StaticApis.bankdetailsregistervalues}`;

  private addresscheck: string = `${environment.app.grp}${StaticApis.addresscheck}`;
  private signatureformat: string = `${environment.app.grp}${StaticApis.signatureformat}`;
  private bankvoidcheck: string = `${environment.app.grp}${StaticApis.bankvoidcheck}`;
  private padAggrement: string = `${environment.app.grp}${StaticApis.padAggrement}`;
  private padsignatureaggrement: string = `${environment.app.grp}${StaticApis.padsignatureaggrement}`;



  constructor(private http: HttpClient) {
    // this.headers = environment.headers
    this.headers =
{
    'Accept': "application/json",
    'Content-Type': 'multipart/form-data',
  }


  }




  login1(data:any): Observable<any> {
    // var Headers = this.headers;
    //return this.http.post<any>(this.loginApiEndPoint, data, httpOptions)
    return this.http.post(this.personalinfo,data,{
      // headers: Headers,
    });

    }
    validation(data:any): Observable<any> {
      // var Headers = this.headers;
      //return this.http.post<any>(this.loginApiEndPoint, data, httpOptions)
      return this.http.post(this.validationdata,data,{
        // headers: Headers,
      });

      }
      savePlan(data:any): Observable<any> {
        // var Headers = this.headers;
        //return this.http.post<any>(this.loginApiEndPoint, data, httpOptions)
        return this.http.post(this.savePlanDetails,data,{
          // headers: Headers,
        });

        }

    getbanknames(data:any): Observable<any> {
      // var Headers = this.headers;
      //return this.http.post<any>(this.loginApiEndPoint, data, httpOptions)
      return this.http.post(this.getbanknamesvalues,data,{
        // headers: Headers,
      });

      }

      lifeInsurance(data:any): Observable<any> {
        // var Headers = this.headers;
        //return this.http.post<any>(this.loginApiEndPoint, data, httpOptions)
        return this.http.post(this.lifeInsuranceData, data, {
          // headers: Headers,
        });
      }
      // bankdetailsregister(data,formData): Observable<any> {
      //   var Headers = this.headers;
      //   return this.http.post(this.bankdetailsregistervalues+"/"+data+"/register",formData,{
      //     headers: Headers,
      //   });

      //   }


      //   bankvoidcheckupload(id,data): Observable<any> {
      //     // var Headers = this.headers;
      //     return this.http.post(this.bankvoidcheck+"/"+id+"/bank/cheque",data,{
      //       // headers: Headers,
      //     });

      //     }



    // getDataConfig(id): Observable<any> {
    //   // var Headers = this.headers;
    //   //return this.http.post<any>(this.loginApiEndPoint, data, httpOptions)
    //   return this.http.get(this.configData+"/"+id,{
    //     // headers: Headers,
    //   });

    //   }
      getDataConfig(formlink:any): Observable<any> {

        // var Headers = this.headers;
        //return this.http.post<any>(this.loginApiEndPoint, data, httpOptions)

        return this.http.get(this.configData+"?"+"formLink=/"+formlink,{
          // headers: Headers,
        });

        }
        getDataConfig1(): Observable<any> {
          // var Headers = this.headers;
          //return this.http.post<any>(this.loginApiEndPoint, data, httpOptions)
          return this.http.get(this.configData,{
            // headers: Headers,
          });

          }



      getplans(provinceid:any,status:any): Observable<any> {
        // var Headers = this.headers;
        //return this.http.post<any>(this.loginApiEndPoint, data, httpOptions)
        return this.http.post(this.plansdetails+provinceid+"/"+status+"/"+"plansProductsDetailed",{
          // headers: Headers,
        });

        }
        payment(data:any): Observable<any> {
          // var Headers = this.headers;
          //return this.http.post<any>(this.loginApiEndPoint, data, httpOptions)
          return this.http.post(this.paymentdetails,data,{
            // headers: Headers,
          });

          }

          padaddgrementdetails(data:any): Observable<any> {
            // var Headers = this.headers;
            //return this.http.post<any>(this.loginApiEndPoint, data, httpOptions)
            return this.http.post(this.padAggrement,data,{
              // headers: Headers,
            });

            }
            padsignature(data:any): Observable<any> {
              // var Headers = this.headers;
              //return this.http.post<any>(this.loginApiEndPoint, data, httpOptions)
              return this.http.post(this.padsignatureaggrement,data,{
                // headers: Headers,
              });

              }


        termsandconditions(): Observable<any> {
          // var Headers = this.headers;
          //return this.http.post<any>(this.loginApiEndPoint, data, httpOptions)
          return this.http.get(this.termsandconditionsvalue,{
            // headers: Headers,
          });

          }

          addresscheckvalidation(data:any): Observable<any> {
            // var Headers = this.headers;
            //return this.http.post<any>(this.loginApiEndPoint, data, httpOptions)
            return this.http.post(this.addresscheck,data,{
              // headers: Headers,
            });

            }
            signaturecheck(data:any): Observable<any> {

              return this.http.patch(this.signatureformat,data,{
                // headers: Headers,
              });

              }




}
